import { AnatomicalAxis, Apex, FullAnatomicalAxis, PlanTypeEnum, ToolEvents, VectorUtils } from "@ortho-next/nextray-core";
import { ViewType } from "@ortho-next/nextray-core/Models/AppModel";
import { SelectedApexAnat } from "@ortho-next/nextray-core/States/State";
import { Binding } from "@ortho-next/three-base/Binding/Binding";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { PrintStateTypes, StateTypes } from "../../../nextray/States/State";
import { BindedModel, bindedModel } from "../../Models/BindedModel";
import { DeformityAnalysisType } from "../../Models/Enums";
import { ReferencePoint } from "../ReferencePoint";
import { Osteotomy } from "./Osteotomy";

export class OsteotomyAnatomical extends Osteotomy {
	public deformityAnalysisType = DeformityAnalysisType.anatomical;
	public anatomicalAxis: FullAnatomicalAxis;
	public apex: Apex;
	public selectedApex: SelectedApexAnat; //override
	public isPostOp: boolean;
	public planeType: PlanTypeEnum;
	private _dirOriginPerp: Vector3;

	public get reference(): AnatomicalAxis {
		return this.anatomicalAxis?.reference as AnatomicalAxis;
	}
	public get moving(): AnatomicalAxis {
		return this.anatomicalAxis?.moving as AnatomicalAxis;
	}

	public get isApexValid(): boolean {
		return this.anatomicalAxis.isInsideBox(this.apexPos);
	}

	public get apexPos(): Vector3 {
		return this.apex.hexagon.position;
	}

	/**
	* Calculates distance between osteotomy lines and reference or moving point line
	* If osteotomy line is above deformity apex, it takes reference point, else it takes moving point
	*/
	public get distanceToReferencePoint(): number {
		this._perpTranslToSync = undefined;
		const bisector = this.anatomicalAxis.bisector;
		const bisectorPerp = VectorUtils.getPerpendicular(bisector);
		const refPointSign = VectorUtils.computeSign(this.referencePoint.position, this.apexPos, bisectorPerp);
		const osteotomySign = VectorUtils.computeSign(this.C.position, this.apexPos, bisectorPerp);
		const proximalSideSign = VectorUtils.computeSign(this.apexPos.clone().add(this._dirOriginPerp), this.apexPos, bisectorPerp);
		const segmentToFollow = osteotomySign == proximalSideSign ? this.reference : this.moving;
		const referencePointOnSegment = VectorUtils.lines2DIntersection(this.referencePoint.position, this.referencePoint.position.clone().add(bisector), segmentToFollow.B.position, segmentToFollow.A.position);
		const distanceSign = VectorUtils.computeSign(this.C.position, this.referencePoint.position, bisectorPerp) * proximalSideSign;

		if (refPointSign == osteotomySign) {
			const intersectionOnTreeDistance = this.getDistanceBetweenPointIntersectedWithSegmentAndOtherPoint(this.C.position, bisector, segmentToFollow, referencePointOnSegment);
			return intersectionOnTreeDistance * distanceSign;
		}

		const intersectionOnTreeDistance = this.apexPos.distanceTo(referencePointOnSegment);
		const oppositeTreeDistance = this.getDistanceBetweenPointIntersectedWithSegmentAndOtherPoint(this.C.position, bisector, refPointSign != proximalSideSign ? this.reference : this.moving, this.apexPos);
		return (oppositeTreeDistance + intersectionOnTreeDistance) * distanceSign;
	}

	/**
	* Calculates distance between osteotomy lines and reference or moving point line
	* If osteotomy line is above deformity apex, it takes reference point, else it takes moving point
	*/
	public set distanceToReferencePoint(distance: number) {
		const bisector = this.anatomicalAxis.bisector;
		const bisectorPerp = VectorUtils.getPerpendicular(bisector);
		const isReferencePointOnReferenceSegmentSide = VectorUtils.arePointsOnSameSide2(this.apexPos, bisectorPerp, this.referencePoint.position, this.apexPos.clone().add(this._dirOriginPerp));
		const segmentRelativeToReferencePoint = isReferencePointOnReferenceSegmentSide ? this.reference : this.moving;
		const referencePointOnSegment = VectorUtils.lines2DIntersection(this.referencePoint.position, this.referencePoint.position.clone().add(bisector), segmentRelativeToReferencePoint.B.position, segmentRelativeToReferencePoint.A.position);
		const distanceCoraToReferencePointOnSegment = this.apexPos.distanceTo(referencePointOnSegment);
		distance = isReferencePointOnReferenceSegmentSide ? -distance : distance;
		const segmentToFollow = distance <= distanceCoraToReferencePointOnSegment ? segmentRelativeToReferencePoint : (isReferencePointOnReferenceSegmentSide ? this.moving : this.reference);
		const distanceToApply = distance <= distanceCoraToReferencePointOnSegment ? distanceCoraToReferencePointOnSegment - distance : distance - distanceCoraToReferencePointOnSegment;
		const newCenter = this.apexPos.clone().add(segmentToFollow.AB.setLength(distanceToApply));
		this.updatePerpTranslation(segmentToFollow, bisector);
		this.updateCenter(newCenter.add(this._perpTranslToSync));
		this.dispatchEvent({ type: ToolEvents.updated });
	}

	/**
	* Evaluates if osteotomy line is above or below deformity apex
	*/
	public get isOnReferenceSide(): boolean { //todo refactor
		let newSign: number;
		const ref = this.anatomicalAxis.reference as AnatomicalAxis;
		const osteotmyIntersectPoint = VectorUtils.lines2DIntersection(this.A.position, this.B.position, ref.A.position, ref.B.position);
		if (osteotmyIntersectPoint) {
			newSign = Math.sign(ref.AB_dirOrigin.clone().dot(osteotmyIntersectPoint.clone().sub(this.apexPos)));
		}
		return (newSign == 1);
	}

	public onAfterRestore(): void {
		// this.apex = this.anatomicalAxis?.apexCalculator.apexes[this.selectedApex == SelectedApexAnat.distal ? 1 : 0]; //undefined when cloned
		this.apex = this.anatomicalAxis?.apexCalculator.apexes[0]; //undefined when cloned fIX IF RESTORE THIRD LINES
	}

	constructor(viewType: ViewType, anatomicalAxis: FullAnatomicalAxis, referencePoint: ReferencePoint, isPostOp: boolean, planeType: PlanTypeEnum) {
		super(viewType, referencePoint, false);
		this.anatomicalAxis = anatomicalAxis;
		this._dirOriginPerp = this.reference?.AB_dirOrigin; //osteotomy in eoc has null reference
		this.isPostOp = isPostOp;
		this.planeType = planeType;
		this.visible = planeType === PlanTypeEnum.Deformity; // to hide eoc fracture cloned osteotomy. the binding will set this to true
		this.isEnabled = planeType === PlanTypeEnum.Deformity; //disabled in fracture

		this.bindEvent('onAfterDragMove', () => {
			this.updateWarning(viewType);
			this.dispatchEvent({ type: ToolEvents.updated });
		});

		this.anatomicalAxis?.bindEvent('onAfterDragMove', () => {
			this.updateWarning(viewType);
		});

		Binding.createCustom(`${this.id}_ostCalculationReset`, (m: BindedModel) => m.appState === StateTypes.deformityAnalysis, (value) => {
			this.dispatchEvent({ type: ToolEvents.updated });
		}, ['appState']);

		this.bindProperties();
	}

	private updateWarning(viewType: ViewType): void {
		const apexTooFarKey: keyof BindedModel = viewType === ViewType.AP ? 'ostTooFarAP' : 'ostTooFarLT';
		if (this.isPostOp || bindedModel.appState === StateTypes.EOC) { //bindedModel.isAnatomicalAxisInserted before
			bindedModel[apexTooFarKey] = !this.anatomicalAxis.isInsideBox(this.A.position, this.B.position, this.C.position);
		} else {
			bindedModel[apexTooFarKey] = false;
		}
	}

	private bindProperties(): void {

		if (this.anatomicalAxis) { //if is not cloned osteotomy
			if (this.isPostOp && this.planeType === PlanTypeEnum.Fracture) {
				this.bindProperty('visible', (m: BindedModel) => {
					return m.layer_osteotomy && m.appState === StateTypes.EOC;
				}, ['appState', 'layer_osteotomy']);
			} else {
				this.bindProperty('visible', (m: BindedModel) => {
					return m.isOsteotomyValid && m.layer_osteotomy;
				}, ['isOsteotomyValid', 'layer_osteotomy']);
			}
		}

		if (this.planeType === PlanTypeEnum.Deformity) {
			if (this.isPostOp) {
				this.bindProperty('isEnabled', (m: BindedModel) => {
					return !m.readonly && (m.appState === StateTypes.deformityAnalysis || m.printState === PrintStateTypes.deformityAnalysis);
				}, ['readonly', 'appState', 'printState']);
			} else {
				this.bindProperty('isEnabled', (m: BindedModel) => {
					return !m.readonly && ((m.appState === StateTypes.EOC && !m.EOCCropVisible) || m.printState === PrintStateTypes.deformityAnalysis);
				}, ['readonly', 'appState', 'EOCCropVisible', 'printState']);
			}
		}
	}

	public setPositionByApex(selectedApex: SelectedApexAnat, distanceToReferencePoint?: number): void {
		this.selectedApex = selectedApex;
		// this.apex = this.anatomicalAxis.apexCalculator.apexes[selectedApex == SelectedApexAnat.distal ? 1 : 0];
		this.apex = this.anatomicalAxis.apexCalculator.apexes[0]; //TODO FIX IF RESTORE THIRD LINE

		if (!(this.anatomicalAxis.moving as AnatomicalAxis).Bc) { //if fracture
			this.A.position.copy((this.anatomicalAxis.moving as AnatomicalAxis).B1.position);
			this.B.position.copy((this.anatomicalAxis.moving as AnatomicalAxis).B2.position);
			this.C.position.copy((this.anatomicalAxis.moving as AnatomicalAxis).B.position);
		} else {
			if (this.isApexValid) {
				this.placeOsteotomyByApex(this.apexPos, VectorUtils.getPerpendicular(this._dirOriginPerp));
			} else {
				this.placeOsteotomyByApex((this.anatomicalAxis.reference as AnatomicalAxis).B.position, VectorUtils.getPerpendicular(this._dirOriginPerp));
			}

			if (distanceToReferencePoint !== undefined) {
				this.distanceToReferencePoint = distanceToReferencePoint;
			}

			this.dispatchEvent({ type: ToolEvents.updated });
		}
	}

	private getDistanceBetweenPointIntersectedWithSegmentAndOtherPoint(point: Vector3, bisector: Vector3, segment: AnatomicalAxis, point2: Vector3): number {
		const intersectionOnSegment = VectorUtils.lines2DIntersection(point, point.clone().add(bisector), segment.B.position, segment.A.position);
		return point2.distanceTo(intersectionOnSegment);
	}


	protected updatePerpTranslation(segmentToFollow?: AnatomicalAxis, bisector?: Vector3): void {
		if (this._perpTranslToSync === undefined) {
			const ABCenterOnSegment = VectorUtils.lines2DIntersection(this.C.position, this.C.position.clone().add(bisector), segmentToFollow.B.position, segmentToFollow.A.position);
			this._perpTranslToSync = this.C.position.clone().sub(ABCenterOnSegment);
		}
	}

	public isOsteotomyBeyond15Degrees(): boolean {
		const segmentToFollow = this.isOnReferenceSide ? this.reference : this.moving;
		const angle = segmentToFollow.AB.angleTo(this.A.position.clone().sub(this.B.position));
		return Math.abs(angle - Math.PI / 2) > Math.PI / 12;
	}
}
