import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


/**
 * This service handles lables and validation messages.
 */
@Injectable()
export class LanguageService {

	readonly DEFAULT_LANG = 'en';

	private _lang: string = this.DEFAULT_LANG;
	private _labels: any = {};

	constructor(private http: HttpClient) { }

	/**
	 * Get current language.
	 */
	get currentLang(): string {
		return this._lang ?? this.DEFAULT_LANG;
	}

	/**
	* Get labels
	*/
	get labels(): any {
		return this._labels;
	}

	/**
	* Load labels and validation messages.
	*/
	loadLabels(lang: string = this.DEFAULT_LANG): Observable<any> {
		this._lang = lang ?? this.DEFAULT_LANG;
		return this.http.get(`assets/labels/labels-${this._lang}.json`).pipe(
			tap(labels => this._labels = labels ?? {})
		);
	}
}
