<!-- Box table -->
<div class="box-table">
  <span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
    {{ labels.CORR_AXIAL_VIEW_TABLE_COMPONENT_TITLE }}
    <div class="fake-material-icon icon-expand expand"></div>
  </span>
  <div [ngbCollapse]="isCollapsed">

    <!--TABLE-->
    <table role="none" class="table">
      <tbody>
        <tr>
          <td>{{ labels.CORR_AXIAL_VIEW_TABLE_COMPONENT_ROTATION }}</td>
          <td>
            <form class="d-flex align-items-center" [formGroup]="axialRotForm">
              <input type="text" maxlength="2" name="axialRotValue" formControlName="axialRotValue"
                class="custom-control" (keyup.enter)="updateAxialRotValue()" (blur)="updateAxialRotValue()"
                [readonly]="stateType !== stateTypeEnum.EOC"
                [ngClass]="{'is-invalid': axialRotForm.controls.axialRotValue.invalid }" />
              <div class="form-group pb-0 ml-4">
                <div class="custom-control custom-radio">
                  <input type="radio" id="axialRot_external" name="axialRotSign" class="custom-control-input"
                    formControlName="axialRotSign" (click)="updateAxialRotSign(axRotationExternalValue)"
                    [value]="axRotationExternalValue" [attr.disabled]="stateType !== stateTypeEnum.EOC ? true : null" />
                  <label class="custom-control-label" for="axialRot_external">{{externalLabel}}</label>
                </div>
                <div class="custom-control custom-radio">
                  <input type="radio" id="axialRot_internal" name="axialRotSign" class="custom-control-input"
                    formControlName="axialRotSign" (click)="updateAxialRotSign(axRotationInternalValue)"
                    [value]="axRotationInternalValue" [attr.disabled]="stateType !== stateTypeEnum.EOC ? true : null" />
                  <label class="custom-control-label" for="axialRot_internal">{{internalLabel}}</label>
                </div>
              </div>
            </form>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="invalid-feedback d-block mb-3" *ngIf="!axialRotForm.valid">{{invalidRotMsg}}</div>

  </div>
</div>
