import { CaseModel, StateTransitionData } from "@ortho-next/nextray-core";
import { Context } from "./Context";
import { Actions, State, StateTypes, WorkflowRunning } from "./State";

/**
 * State indicating that the application is loading and restoring data. At the end it will the state loaded.
 */
export class Loading extends State {
	public stateDescription = {
		isWorkflowRunning: WorkflowRunning.none,
		isPointsPositioningRunning: false
	};

	constructor(context: Context) {
		super(context, undefined, StateTypes.loading);
	}

	public initState(): void {
		// empty
	}

	protected handleChangeStateEvent<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData {
		switch (event) {
			case "setModel":
				this.dispatchToMain(event, args)
				if ((args as CaseModel).model?.state) {
					const contextState = JSON.parse((args as CaseModel).model.state);

					switch (contextState.type) { //ask retrocompatibilità
						// case StateTypes.new:
						// case StateTypes.orientated:
						case StateTypes.calibrated:
							return { type: StateTypes.calibrated, data: { caseModel: args, restore: contextState } };
						case StateTypes.deformityAnalysis:
							return { type: StateTypes.deformityAnalysis, data: { caseModel: args, restore: contextState } };
						case StateTypes.EOC:
							return { type: StateTypes.EOC, data: { caseModel: args, restore: contextState } };
						case StateTypes.templating:
							return { type: StateTypes.templating, data: { caseModel: args, restore: contextState } };
						case StateTypes.RPM:
							return { type: StateTypes.RPM, data: { caseModel: args, restore: contextState } };
						case StateTypes.tlhex:
							return { type: StateTypes.tlhex, data: { caseModel: args, restore: contextState } };
					}

					console.error("Error. Cannot restore state.");
				}

				return { type: StateTypes.deformityAnalysis, data: { caseModel: args } };
		}
	}

}
