import { AnatomicalAxis as AnatomicalAxisBase, AnatomicalAxisParams, AnatomicalAxisType } from "@ortho-next/nextray-core";
import { PrintStateTypes } from "../../States/State";
import { BindedModel } from "../../Models/BindedModel";

export class AnatomicalAxis extends AnatomicalAxisBase {

	constructor(params: AnatomicalAxisParams, isEoC = false) {
		super(params, isEoC);

		if (params.axisType == AnatomicalAxisType.moving && !isEoC) {
			this.bindProperty('visible', (m: BindedModel) => {
				return !m.EOCCropVisible || m.printState === PrintStateTypes.deformityAnalysis
			}, ['EOCCropVisible', 'printState']);
		}
	}
}
