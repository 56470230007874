import { ToolEvents } from "@ortho-next/nextray-core/Core/ITool";
import { Save, SaveChild } from "@ortho-next/nextray-core/Core/Save";
import { AnatomicalSideEnum, DraggablePoint } from "@ortho-next/nextray-core";
import { ViewType } from "@ortho-next/nextray-core/Models/AppModel";
import { MechanicalAxisFemurAP } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/FemurAnalyzerAP";
import { Plane } from "@ortho-next/nextray-core/Tools/Plane";
import { VectorUtils } from "@ortho-next/nextray-core/Utils/VectorUtils";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { OsteotomyCut } from "../../States/State";
import { Consts } from "../../Utils/Consts";
import { MeasuresUtils } from "../../Utils/MeasuresUtils";
import { MechanicalAxisAP } from "../DeformityAnalyzer/FullAnalyzerAP";
import { MechanicalAxisLT } from "../DeformityAnalyzer/FullAnalyzerLT";
import { Osteotomy } from "../Osteotomy/Osteotomy";
import { OsteotomyAP } from "../Osteotomy/OsteotomyAP";
import { OsteotomyLT } from "../Osteotomy/OsteotomyLT";
import { ClonedMechanicalAxisManager } from "./ClonedMechanicalAxisManager";
import { EoCPlane } from "./EoCPlane";
import { RedShape } from "./RedShape";
import { DeformityAnalysisType } from "src/nextray/Models/Enums";

export class EoCPlaneMechanical extends EoCPlane {
  public deformityAnalysisType = DeformityAnalysisType.mechanical;
  public osteotomy: OsteotomyAP | OsteotomyLT;
  @SaveChild() private _clonedMechanicalAxisManager: ClonedMechanicalAxisManager;
  protected _originalAxis: MechanicalAxisAP | MechanicalAxisLT;
  @Save() protected _oppositeToRotationCenter: Vector3;
  @Save() protected _oppositeToRotationCenterCloned: Vector3;
  @Save() protected _cutType: OsteotomyCut;
  private _redShape: RedShape;


  public onAfterRestore(): void {
    this._viewType === ViewType.AP && (this.clonedAxis.femur as MechanicalAxisFemurAP)?.updateNSATool();
    super.onAfterRestore();
    this._redShape.recalculateGeometry(this.osteotomy, this.clonedOsteotomy);
  }

  constructor(viewType: ViewType, side: AnatomicalSideEnum, plane: Plane, osteotomy: Osteotomy, mechanicalAxis: MechanicalAxisAP | MechanicalAxisLT) {
    super(viewType, side, plane, osteotomy, mechanicalAxis);
    this._clonedMechanicalAxisManager = new ClonedMechanicalAxisManager(this, this._originalAxis);

    this.add(
      this._redShape = new RedShape(),
      this._clonedMechanicalAxisManager.obj
    );
  }

  public get angle(): number {
    const ostDir = this.osteotomy.A.position.clone().sub(this.osteotomy.B.position);
    const clonedOstDir = this.clonedOsteotomy.A.position.clone().sub(this.clonedOsteotomy.B.position);
    return ostDir.angleTo(clonedOstDir);
  }
  public set angle(value: number) {
    console.error("set angle not necessary");
  }


  public get cortexLength(): number {
    const verticalDir = this._originalAxis.getProximalDirToCalculateApex(this.osteotomy.selectedApex);
    const ost1 = this._oppositeToRotationCenter;
    const ost2 = this._oppositeToRotationCenter.clone().add(VectorUtils.getPerpendicular(verticalDir));
    const proj = VectorUtils.projectOnVector(this._oppositeToRotationCenterCloned, ost1, ost2);
    const sign = VectorUtils.arePointsOnSameSide(ost1, ost2, Consts.planeNormal, this._oppositeToRotationCenterCloned, this._oppositeToRotationCenter.clone().add(verticalDir)) ? -1 : 1;
    return proj.distanceTo(this._oppositeToRotationCenterCloned) * sign;
  }

  public get verticalTranslation(): number {
    const verticalDir = this._originalAxis.getProximalDirToCalculateApex(this.osteotomy.selectedApex);
    const ost1 = this.osteotomy.C.position;
    const ost2 = this.osteotomy.C.position.clone().add(VectorUtils.getPerpendicular(verticalDir));
    const proj = VectorUtils.projectOnVector(this.clonedOsteotomy.C.position, ost1, ost2);
    const sign = VectorUtils.arePointsOnSameSide(ost1, ost2, Consts.planeNormal, this.clonedOsteotomy.C.position, this.osteotomy.C.position.clone().add(verticalDir)) ? -1 : 1;
    return proj.distanceTo(this.clonedOsteotomy.C.position) * sign;
  }

  public set verticalTranslation(value: number) {
    const verticalDir = this._originalAxis.getProximalDirToCalculateApex(this.osteotomy.selectedApex);
    verticalDir.y > 0 && verticalDir.multiplyScalar(-1);
    const pan = verticalDir.setLength(value - this.verticalTranslation);
    this.translationByPoint(pan.add(this.croppedPlane.position));
    this._redShape.recalculateGeometry(this.osteotomy, this.clonedOsteotomy);
    this.dispatchEvent({ type: ToolEvents.updated });
  }

  public get horizontalTranslation(): number {
    const verticalDir = this._originalAxis.getProximalDirToCalculateApex(this.osteotomy.selectedApex);
    const ost1 = this.osteotomy.C.position;
    const ost2 = this.osteotomy.C.position.clone().add(verticalDir);
    const proj = VectorUtils.projectOnVector(this.clonedOsteotomy.C.position, ost1, ost2);
    let sign = VectorUtils.arePointsOnSameSide(ost1, ost2, Consts.planeNormal, this.clonedOsteotomy.C.position, this.osteotomy.C.position.clone().add(VectorUtils.getPerpendicular(verticalDir))) ? -1 : 1;
    sign *= this._side === AnatomicalSideEnum.Left ? 1 : -1;
    return proj.distanceTo(this.clonedOsteotomy.C.position) * sign;
  }

  public get clonedAxis(): MechanicalAxisAP | MechanicalAxisLT {
    return this._clonedMechanicalAxisManager.obj;
  }

  protected bindEvents(): void {
    super.bindEvents();

    this.bindEvent("onDragMove", () => {
      this._redShape.recalculateGeometry(this.osteotomy, this.clonedOsteotomy);
    });
  }

  public cutPlane(planePoint: Vector3, verticalTransl: number, cutType: OsteotomyCut): boolean {
    this._cutType = cutType;
    if (this.executeCutPlane(planePoint)) {
      this._clonedMechanicalAxisManager.reset();
      this._clonedMechanicalAxisManager.updatePointsToUpdate();
      this.calculateRotationCenter();
      this.rotateAndTraslateAfterCut();
      this.verticalTranslation = verticalTransl;
      return true;
    }
    return false;
  }

  private calculateRotationCenter(): void {
    const pointForBisectorUp = this._originalAxis.getProximalDirToCalculateApex(this.osteotomy.selectedApex);
    const pointForBisectorDown = this._originalAxis.getDistalDirToCalculateApex(this.osteotomy.selectedApex).multiplyScalar(-1);
    const bisector = VectorUtils.getBisector(pointForBisectorUp, pointForBisectorDown); // bisector always lies in the convex angle
    const ostA = this.osteotomy.A.position.clone().sub(this.osteotomy.C.position);
    const ostAangle = ostA.angleTo(bisector);
    const bisectorAngle = bisector.angleTo(pointForBisectorUp);
    let isAinConcav = ostAangle > bisectorAngle;
    isAinConcav = this._cutType === OsteotomyCut.opening ? isAinConcav : !isAinConcav;
    this._rotationCenter = isAinConcav ? this.osteotomy.A.position : this.osteotomy.B.position;
    this._oppositeToRotationCenter = !isAinConcav ? this.osteotomy.A.position : this.osteotomy.B.position;
    this._oppositeToRotationCenterCloned = !isAinConcav ? this.clonedOsteotomy.A.position : this.clonedOsteotomy.B.position;
  }

  private rotateAndTraslateAfterCut(): void {
    const angle = this._originalAxis.getApexWithSign(this.osteotomy.selectedApex);
    const verticalDir = this._originalAxis.getProximalDirToCalculateApex(this.osteotomy.selectedApex);
    verticalDir.y < 0 && verticalDir.multiplyScalar(-1);
    const translationDirProximal = VectorUtils.getPerpendicular(verticalDir);
    this.rotationByPoint(this.ost1.position.clone().sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, -angle).add(this._rotationCenter), this.ost1);

    const distance = MeasuresUtils.getHorizontalTranslation(this.osteotomy, this._originalAxis, this._rotationCenter);
    const pan = translationDirProximal.setLength(distance);
    this.translationByPoint(pan.add(this.croppedPlane.position));
    this.dispatchEvent({ type: ToolEvents.updated }); //todo check
  }

  protected translationByPoint(vector: Vector3): void {
    const pan = vector.clone().sub(this.croppedPlane.position);
    super.translationByPoint(vector);
    this._clonedMechanicalAxisManager.makeTranslation(pan);
  }

  protected rotationByPoint(vector: Vector3, point: DraggablePoint): void { // apex funge solo se ruoti
    const angle = VectorUtils.linesAngle(point.position, vector, this._rotationCenter);
    super.rotationByPoint(vector, point);
    this._clonedMechanicalAxisManager.makeRotation(angle, this._rotationCenter);
  }

}
