import { BoneTypeEnum, ConstsBase } from "@ortho-next/nextray-core";

/**
 * Defines constant data.
 */
export class Consts extends ConstsBase {
  private static _signLabels: SignLabels;

  /**
  * 
  */
  public static get signLabels(): SignLabels {
    if (!this._signLabels) {
      this._signLabels = {};

      this._signLabels[BoneTypeEnum.LongLeg] = {
        AP: { rotation: ["Valgus", "Varus"], translation: ["Medial", "Lateral"] },
        LT: { rotation: ["Recurvatum", "Procurvatum"], translation: ["Posterior", "Anterior"] },
        axial: { rotation: ["External", "Internal"], translation: ["Short", "Long"] }
      };

      this._signLabels[BoneTypeEnum.Femur] = this._signLabels[BoneTypeEnum.LongLeg];
      this._signLabels[BoneTypeEnum.Tibia] = this._signLabels[BoneTypeEnum.LongLeg];

      this._signLabels[BoneTypeEnum.Ankle] = JSON.parse(JSON.stringify(this._signLabels[BoneTypeEnum.LongLeg])); //copy the object
      this._signLabels[BoneTypeEnum.Ankle].LT.rotation = ["Calcaneus", "Equinus"];

      this._signLabels[BoneTypeEnum.Forefoot] = {
        AP: { rotation: ["Abduction(ABD)", "Adduction(ADD)"], translation: ["Medial", "Lateral"] },
        LT: { rotation: ["Apex Plantar", "Apex Dorsal"], translation: ["Plantar", "Dorsal"] },
        axial: { rotation: ["Supination", "Pronation"], translation: ["Short", "Long"] }
      };

      this._signLabels[BoneTypeEnum.Hindfoot] = JSON.parse(JSON.stringify(this._signLabels[BoneTypeEnum.Forefoot])); //copy the object
      this._signLabels[BoneTypeEnum.Hindfoot].axial.rotation = ["Varus", "Valgus"];
    }
    
    return this._signLabels;
  }
}

/**
 * 
 */
export interface SignLabels {
  [index: string]: SignLabelsSingle;
}

/**
 * 
 */
export interface SignLabelsSingle {
  AP: SignLabelsView;
  LT: SignLabelsView;
  axial: SignLabelsView;
}

/**
 * 
 */
export interface SignLabelsView {
  rotation: string[];
  translation: string[];
}
