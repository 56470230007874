import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DeformityParameters, TlhexRepository } from '../core';
import { BaseService } from './base.service';


/**
* This service handles tlhex data.
*/
@Injectable()
export class TlhexService extends BaseService {

	checkupDeformity: DeformityParameters;

	constructor(private tlhexRepo: TlhexRepository) {
		super();
	}

	/**
	* Get deformity parameters.
	*/
	getDeformityParameters(caseId: string, userId: string): Observable<DeformityParameters> {
		return this.tlhexRepo.getDeformityParameters(caseId, userId).pipe(
			map(res => this.handleApiResponse(res)),
			tap(res => this.checkupDeformity = res)
		);
	}

	/**
	* Get EOC deformity parameters.
	*/
	getEOCDeformityParameters(caseId: string, userId: string): Observable<DeformityParameters> {
		return this.tlhexRepo.getEOCDeformityParameters(caseId, userId).pipe(
			map(res => this.handleApiResponse(res))
		);
	}

}