import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Plate, PlateSearchDictionary, PlateSearchFilters, ProductRepository, ScrewList, SearchDictionaryEnum } from '../core';
import { ModelService } from './model.service';
import { UserService } from './user.service';


/**
* This service handles product data.
*/
@Injectable()
export class ProductService {

	bomScrewList: ScrewList;

	private _isJPSExtension: boolean;
	private _hasJPSExtended: boolean;

	private _plateList: BehaviorSubject<Plate[]> = new BehaviorSubject<Plate[]>([]);

	private _segmentList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
	private _screwList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
	private _angleList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
	private _offsetList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
	private _flareList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
	private _holesList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);


	constructor(
		private prodRepo: ProductRepository,
		private modelsrv: ModelService,
		private userSrv: UserService
	) {
		this.userSrv.isJPSExtension().subscribe(val => this._isJPSExtension = val);
	}

	/**
	* Update plate list according filters options in input.
	 * @param {PlateSearchFilters} filter Filters options
	*/
	updatePlateList(filter: PlateSearchFilters): void {
		this.prodRepo.getPlateList(filter).pipe(map(response => response.result)).subscribe(list => this._plateList.next(list));
	}

	/**
	* Update dictionary list according filters options in input.
	* @param {PlateSearchFilters} filter Filters options
	*/
	updateDictionaryList(filter: PlateSearchFilters): void {
		this.updateSegmentList(filter);
		this.updateScrewList(filter);
		this.updateAngleList(filter);
		this.updateOffsetList(filter);
		this.updateFlareList(filter);
		this.updateHolesList(filter);
	}

	/**
	* Update segment dictionary list according filters options in input.
	* @param {PlateSearchFilters} filter Filters options
	*/
	updateSegmentList(filter: PlateSearchFilters): void {
		if (filter.bone == 'TIBIA') {
			const body: PlateSearchDictionary = {
				targetDictionary: SearchDictionaryEnum.SEGMENT,
				bone: filter.bone
			};
			this.getDictionary(body).subscribe(list => this._segmentList.next(list));
		}
	}

	/**
	* Update screw dictionary list according filters options in input.
	* @param {PlateSearchFilters} filter Filters options
	*/
	updateScrewList(filter: PlateSearchFilters): void {
		const body: PlateSearchDictionary = {
			targetDictionary: SearchDictionaryEnum.SIZE,
			bone: filter.bone,
			segment: filter.segment
		};
		this.getDictionary(body).subscribe(list => this._screwList.next(list));
	}

	/**
	* Update angle dictionary list according filters options in input.
	* @param {PlateSearchFilters} filter Filters options
	*/
	updateAngleList(filter: PlateSearchFilters): void {
		if (filter.bone == 'FEMUR') {
			const body: PlateSearchDictionary = {
				targetDictionary: SearchDictionaryEnum.ANGLE,
				bone: filter.bone,
				segment: filter.segment,
				size: filter.size
			};
			this.getDictionary(body).subscribe(list => this._angleList.next(list));
		}
	}

	/**
	* Update offset dictionary list according filters options in input.
	* @param {PlateSearchFilters} filter Filters options
	*/
	updateOffsetList(filter: PlateSearchFilters): void {
		if (filter.bone == 'FEMUR' && filter.segment == 'PROXIMAL') {
			const body: PlateSearchDictionary = {
				targetDictionary: SearchDictionaryEnum.OFFSET,
				bone: filter.bone,
				segment: filter.segment,
				size: filter.size,
				angle: filter.angle
			};
			this.getDictionary(body).subscribe(list => this._offsetList.next(list));
		}
	}

	/**
	* Update flare dictionary list according filters options in input.
	* @param {PlateSearchFilters} filter Filters options
	*/
	updateFlareList(filter: PlateSearchFilters): void {
		if (filter.bone == 'FEMUR' && filter.segment == 'DISTAL') {
			const body: PlateSearchDictionary = {
				targetDictionary: SearchDictionaryEnum.FLARE,
				bone: filter.bone,
				segment: filter.segment,
				size: filter.size,
				angle: filter.angle
			};
			this.getDictionary(body).subscribe(list => this._flareList.next(list));
		}
	}

	/**
	* Update holes dictionary list according filters options in input.
	* @param {PlateSearchFilters} filter Filters options
	*/
	updateHolesList(filter: PlateSearchFilters): void {
		const body: PlateSearchDictionary = {
			targetDictionary: SearchDictionaryEnum.HOLES,
			bone: filter.bone,
			segment: filter.segment,
			size: filter.size,
			angle: filter.angle,
			offset: filter.offset,
			flare: filter.flare
		};
		this.getDictionary(body).subscribe(list => this._holesList.next(list));
	}

	private getDictionary(filter: PlateSearchDictionary): Observable<string[]> {
		return this.prodRepo.getDictionary(filter).pipe(map(response => response.result));
	}

	/**
	* Get plate names of current plate list.
	*/
	get plateNames(): Observable<string[]> {
		return this._plateList.asObservable().pipe(
			map(list => list.map(plate => plate.fileName + '\n'))
		);
	}

	/**
	* Get current plate list.
	*/
	get plateList(): Observable<Plate[]> {
		return this._plateList.asObservable();
	}

	/**
	* Get current segment dictionary list.
	*/
	get segmentList(): Observable<string[]> {
		return this._segmentList.asObservable();
	}

	/**
	* Get current screw dictionary list.
	*/
	get screwList(): Observable<string[]> {
		return this._screwList.asObservable();
	}

	/**
	* Get current angle dictionary list.
	*/
	get angleList(): Observable<string[]> {
		return this._angleList.asObservable();
	}

	/**
	* Get current offset dictionary list.
	*/
	get offsetList(): Observable<string[]> {
		return this._offsetList.asObservable();
	}

	/**
	* Get current flare dictionary list.
	*/
	get flareList(): Observable<string[]> {
		return this._flareList.asObservable();
	}

	/**
	* Get current holes dictionary list.
	*/
	get holesList(): Observable<string[]> {
		return this._holesList.asObservable();
	}

	/**
	* Get screw list.
	*/
	getScrewList(plateId: string, extensions: boolean): Observable<ScrewList> {
		return this.prodRepo.getScrewList(plateId, extensions).pipe(map(response => response.result));
	}

	/**
	* Get if the user is enabled for the JPS extension and the plan has the JPS extension.
	*/
	get jpsExtension(): boolean {
		return this._isJPSExtension && this._hasJPSExtended;
	}
	/**
	* Init JPSExtended status.
	*/
	initJPSExtended(val: boolean): void {
		this._hasJPSExtended = val;
	}
	/**
	* Check JPS Extended status.
	*/
	get hasJPSExtended(): boolean {
		return this._hasJPSExtended;
	}

	/**
	* Check if JPS has to be upgraded.
	*/
	get isJPSToUpgrade(): boolean {
		return this._isJPSExtension && !this._hasJPSExtended;
	}

}
