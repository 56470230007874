import { Component, OnInit } from '@angular/core';
import { SelectedApexMech } from '@ortho-next/nextray-core/States/State';
import { BoneTypeEnum, LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component of first vertical sidebar to select osteotomy site.
*/
@Component({
	selector: 'osteotomy-workflow',
	templateUrl: './osteotomy-workflow.component.html',
	styles: []
})
export class OsteotomyWorkflowComponent extends BaseComponent implements OnInit {

	site: ApexSiteEnum;
	apexSiteEnum = ApexSiteEnum;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.site = this.apexSite();
	}

	get isLongLegVisible(): boolean {
		return this.hasLT ? this.boneTypeLT === BoneTypeEnum.LongLeg : this.isLongLeg;
	}
	get isFemurVisible(): boolean {
		return this.hasLT ? this.boneTypeLT === BoneTypeEnum.Femur : this.isFemur;
	}
	get isTibiaVisible(): boolean {
		return this.hasLT ? this.boneTypeLT === BoneTypeEnum.Tibia : this.isTibia;
	}

	/**
	 * Get apex site label.
	 */
	apexSiteLabel(apexSite: ApexSiteEnum): string {
		switch (apexSite) {
			case ApexSiteEnum.ProximalFemur: return this.langSrv.labels.APEX_SITE_PROXIMAL_FEMUR;
			case ApexSiteEnum.DistalFemur: return this.langSrv.labels.APEX_SITE_DISTAL_FEMUR;
			case ApexSiteEnum.ProximalTibia: return this.langSrv.labels.APEX_SITE_PROXIMAL_TIBIA;
			case ApexSiteEnum.DistalTibia: return this.langSrv.labels.APEX_SITE_DISTAL_TIBIA;
			default: return apexSite;
		}
	}


	/**
	* Close osteotomy site selection workflow.
	*/
	back(): void {
		this.canvasSrv.dispatch('cancelSelectingApex');
	}

	/**
	* Confirm osteotomy site selection and place osteotomy line.
	*/
	confirm(): void {
		this.confirmApexSite();
		this.placeOsteotomy();
	}

	private confirmApexSite() {
		this.canvasSrv.dispatch(this.selectedApexEvent());
	}

	private placeOsteotomy() {
		this.canvasSrv.dispatch('place-osteotomy');
	}

	private apexSite(): ApexSiteEnum {
		switch (this.selectedApex) {
			case SelectedApexMech.femurProximal: return ApexSiteEnum.ProximalFemur;
			case SelectedApexMech.femurDistal: return ApexSiteEnum.DistalFemur;
			case SelectedApexMech.tibiaProximal: return ApexSiteEnum.ProximalTibia;
			case SelectedApexMech.tibiaDistal: return ApexSiteEnum.DistalTibia;
			default: return null;
		}
	}

	private selectedApexEvent(): 'apexFemurProximal' | 'apexFemurDistal' | 'apexTibiaProximal' | 'apexTibiaDistal' {
		switch (this.site) {
			case ApexSiteEnum.ProximalFemur: return 'apexFemurProximal';
			case ApexSiteEnum.DistalFemur: return 'apexFemurDistal';
			case ApexSiteEnum.ProximalTibia: return 'apexTibiaProximal';
			case ApexSiteEnum.DistalTibia: return 'apexTibiaDistal';
			default: return null;
		}
	}

}


export enum ApexSiteEnum {
	ProximalFemur = 'Proximal Femur',
	DistalFemur = 'Distal Femur',
	ProximalTibia = 'Proximal Tibia',
	DistalTibia = 'Distal Tibia'
}
