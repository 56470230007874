import { Component, OnInit } from '@angular/core';
import { SelectedApexAnat } from '@ortho-next/nextray-core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component of first vertical sidebar to select anatomical osteotomy site.
*/
@Component({
	selector: 'aa-osteotomy-workflow',
	templateUrl: './aa-osteotomy-workflow.component.html'
})
export class AaOsteotomyWorkflowComponent extends BaseComponent implements OnInit {

	site: SelectedApexAnat;
	apexSiteEnum = SelectedApexAnat;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.site = this.selectedApex as SelectedApexAnat;
	}


	/**
	* Close osteotomy site selection workflow.
	*/
	back(): void {
		this.canvasSrv.dispatch('cancelSelectingApex');
	}

	/**
	* Confirm osteotomy site selection and place osteotomy line.
	*/
	confirm(): void {
		this.canvasSrv.dispatch('setAnatApex', this.site);
		this.canvasSrv.dispatch('place-osteotomy');
	}

	get apexSiteLabel(): AnatApexSiteEnum {
		switch (this.site) {
			case SelectedApexAnat.proximal: return AnatApexSiteEnum.Proximal;
			case SelectedApexAnat.distal: return AnatApexSiteEnum.Distal;
			default: return null;
		}
	}

}


export enum AnatApexSiteEnum {
	Proximal = 'Proximal',
	Distal = 'Distal'
}
