<div style="text-align: center;padding-bottom: 15px;">
  <h4>{{ labels.RECOMMENDED_CLINICAL_MEASUREMENTS }}</h4>
</div>
<span *ngIf="hasAP">
  <span *ngIf="osteotomySite === 'femurProximal'">
    <div class="row-detail-table">
      <strong>{{ labels.PRINT_OSTEOT_LEV_PROX_FEM }}</strong>
      <span>{{ osteotomyLevel }}</span>
    </div>
    <div class="row-detail-table">
      <strong>{{ labels.PRINT_DIST_INS_FROM_K_WIRE_PROX_FEM }}</strong>
      <span>{{ cortexWire }}</span>
    </div>
    <div class="row-detail-table">
      <strong>{{ labels.PRINT_WIRE_ANG_INS_PROX_FEM }}</strong>
      <span>{{ wireAngle }}</span>
    </div>
  </span>
  <span *ngIf="osteotomySite === 'femurDistal'">
    <div class="row-detail-table">
      <strong>{{ labels.PRINT_OSTEOT_LEV_DIST_FEM }}</strong>
      <span>{{ osteotomyLevel }}</span>
    </div>
    <div class="row-detail-table">
      <strong>{{ labels.PRINT_DIST_INS_FROM_K_WIRE_DIST_FEM }}</strong>
      <span>{{ cortexWire }}</span>
    </div>
    <div class="row-detail-table">
      <strong>{{ labels.PRINT_WIRE_ANG_INS_DIST_FEM }}</strong>
      <span>{{ wireAngle }}</span>
    </div>
  </span>
  <span *ngIf="osteotomySite === 'tibiaProximal'">
    <div class="row-detail-table">
      <strong>{{ labels.PRINT_OSTEOT_LEV_PROX_TIB }}</strong>
      <span>{{ osteotomyLevel }}</span>
    </div>
  </span>
  <span *ngIf="osteotomySite === 'tibiaDistal'">
    <div class="row-detail-table">
      <strong>{{ labels.PRINT_OSTEOT_LEV_DIST_TIB }}</strong>
      <span>{{ osteotomyLevel }}</span>
    </div>
  </span>
</span>
<div *ngIf="apWedgeAngle !== undefined" class="row-detail-table">
  <strong>{{ labelApWedgeAngle }}</strong>
  <span>{{ apWedgeAngle }}</span>
</div>
<div *ngIf="ltWedgeAngle !== undefined" class="row-detail-table">
  <strong>{{ labelLtWedgeAngle }}</strong>
  <span>{{ ltWedgeAngle }}</span>
</div>
<div *ngIf="apCortexGap !== undefined" class="row-detail-table">
  <strong>{{ labels.PRINT_AP_CORTEX_GAP }}</strong>
  <span>{{ apCortexGap }}</span>
</div>
<div *ngIf="ltCortexGap !== undefined" class="row-detail-table">
  <strong>{{ labels.PRINT_LT_CORTEX_GAP }}</strong>
  <span>{{ ltCortexGap }}</span>
</div>
<div *ngIf="translation" class="row-detail-table">
  <strong>{{ labels.PRINT_TRANSLATION }}</strong>
  <span>{{ translation }}</span>
</div>
<div *ngIf="plate" class="row-detail-table">
  <strong>{{ labels.PRINT_PLATE }}</strong>
  &nbsp;&nbsp;&nbsp;&nbsp;
  <span>99-{{ plate.id }} - {{ plate.description }}</span>
</div>
