import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IProductRepository } from './Iproduct.repository';
import { ApiResponse, Plate, PlateSearchDictionary, PlateSearchFilters, ScrewList } from './models';



@Injectable()
export class ProductRepository implements IProductRepository {

	constructor(private httpClient: HttpClient) { }

	getPlateList(filters: PlateSearchFilters): Observable<ApiResponse<Plate[]>> {
		return this.httpClient.post<ApiResponse<Plate[]>>(environment.domainRayAPI + '/api/Products/GetPlateList', filters);
	}

	getDictionary(filters: PlateSearchDictionary): Observable<ApiResponse<string[]>> {
		return this.httpClient.post<ApiResponse<string[]>>(environment.domainRayAPI + '/api/Products/GetDictionary', filters);
	}

	getScrewList(plateId: string, extensions: boolean): Observable<ApiResponse<ScrewList>> {
		let params = new HttpParams()
			.set('id', plateId)
			.set('ext', extensions);

		return this.httpClient.get<ApiResponse<ScrewList>>(environment.domainRayAPI + '/api/Products/ScrewList', { params });
	}

}
