import { ToolEvents } from "@ortho-next/nextray-core/Core/ITool";
import { MeasuresController as MeasuresControllerBase } from "@ortho-next/nextray-core/Core/MeasuresController";
import { ViewType } from "@ortho-next/nextray-core/Models/AppModel";
import { BoneTypeEnum } from "@ortho-next/nextray-core/Models/CaseModel";
import { AppModel, NextRayMeasures } from "../Models/AppModel";
import { bindedModel } from "../Models/BindedModel";
import { DeformityAnalysisType } from "../Models/Enums";
import { OsteotomyAnatomical } from "../Tools/Osteotomy/OsteotomyAnatomical";
import { Main } from "./Main";
import { MeasuresCalculator } from "./MeasuresCalculator";

/**
 * Binds measures calculations.
 */
export class MeasuresController extends MeasuresControllerBase {
	public measuresCalculator: MeasuresCalculator;
	protected _main: Main;
	protected _model: AppModel;

	protected updateMeasureInModel<K extends keyof NextRayMeasures>(...propertiesName: K[]): void {
		super.updateMeasureInModel(...propertiesName as any);
	}

	constructor(main: Main) {
		super(main, MeasuresCalculator);
	}

	/**
	 * Binds eoc measures calculations.
	 */
	public bindEoC(viewType: ViewType): void {
		viewType == ViewType.AP ? this.bindEoCAP() : this.bindEOCLT();
	}

	private bindEoCAP(): void { //todo rendere le stringhe una property dell'oggetto
		const eocPlane = this._main.toolsAP.EoCPlane;

		if (bindedModel.deformityAnalysisType === DeformityAnalysisType.mechanical) {
			const boneType = this._main.caseModel.boneType;

			eocPlane.addEventListener(ToolEvents.updated, () => {
				this.updateMeasureInModel(
					"eoc_ap_secondaryTransl",
					"eoc_ap_axialLength",
					"eoc_ap_cortexLength",
					"eoc_ap_angle"
				);
			});

			if (boneType === BoneTypeEnum.LongLeg) {
				eocPlane.addEventListener(ToolEvents.updated, () => {
					this.updateMeasureInModel(
						"eoc_ap_MAD",
						"eoc_ap_JLCA",
						"eoc_ap_kneeTranslation",
						"eoc_ap_fullLength"
					);
				});
			}

			if (boneType === BoneTypeEnum.Femur || boneType === BoneTypeEnum.LongLeg) {
				eocPlane.addEventListener(ToolEvents.updated, () => {
					this.updateMeasureInModel(
						"eoc_ap_LPFA",
						"eoc_ap_mLDFA",
						"eoc_ap_MNSA",
						"eoc_ap_MPFA",
						"eoc_ap_aLDFA",
						"eoc_ap_femurLength"
					);
				});
			}

			if (boneType === BoneTypeEnum.Tibia || boneType === BoneTypeEnum.LongLeg) {
				eocPlane.addEventListener(ToolEvents.updated, () => {
					this.updateMeasureInModel(
						"eoc_ap_MPTA",
						"eoc_ap_LDTA",
						"eoc_ap_tibiaLength"
					);
				});
			}
		} else {
			eocPlane.addEventListener(ToolEvents.updated, () => {
				this.updateMeasureInModel(
					"eoc_boneLength",
					"eoc_boneLengthSign",
					"eoc_ap_overAng",
					"eoc_ap_overAngSign",
					"eoc_ap_overTransl",
					"eoc_ap_overTranslSign"
				);
			});
		}
	}

	private bindEOCLT(): void {
		const eocPlane = this._main.toolsLT.EoCPlane;

		if (bindedModel.deformityAnalysisType === DeformityAnalysisType.mechanical) {
			eocPlane.addEventListener(ToolEvents.updated, () => {
				this.updateMeasureInModel(
					"eoc_lt_secondaryTransl",
					"eoc_lt_axialLength",
					"eoc_lt_cortexLength",
					"eoc_lt_angle",
					"eoc_lt_kneeTranslation",
					"eoc_lt_PDFA",
					"eoc_lt_ADTA",
					"eoc_lt_PPTA"
				);
			});
		} else {
			eocPlane.addEventListener(ToolEvents.updated, () => {
				this.updateMeasureInModel(
					"eoc_boneLength",
					"eoc_boneLengthSign",
					"eoc_lt_overAng",
					"eoc_lt_overAngSign",
					"eoc_lt_overTransl",
					"eoc_lt_overTranslSign"
				);
			});
		}
	}

	public bindAnatomicalAxis(viewType: ViewType): void {
		viewType == ViewType.AP ? this.bindAnatomicalAxisAP() : this.bindAnatomicalAxisLT();
	}

	private bindAnatomicalAxisAP(): void {
		const anatAxis = this._main.toolsAP.anatomicalAxis;

		if (this._main.isPostOp) {
			anatAxis.addEventListener(ToolEvents.updated, () => {
				this.updateMeasureInModel(
					"def_ap_apex1",
					"def_ap_apex2",
					"def_suggestedBoneLength"
				);
			});
		} else {
			anatAxis.addEventListener(ToolEvents.updated, () => {
				this.updateMeasureInModel(
					"def_ap_apex1",
					"def_ap_apex2"
				);
			});
		}

		anatAxis.dispatchEvent({ type: ToolEvents.updated }); //todo do it for all
	}

	private bindAnatomicalAxisLT(): void {
		const anatAxis = this._main.toolsLT.anatomicalAxis;

		if (this._main.isPostOp) {
			anatAxis.addEventListener(ToolEvents.updated, () => {
				this.updateMeasureInModel(
					"def_lt_apex1",
					"def_lt_apex2",
					"def_suggestedBoneLength"
				);
			});
		} else {
			anatAxis.addEventListener(ToolEvents.updated, () => {
				this.updateMeasureInModel(
					"def_lt_apex1",
					"def_lt_apex2"
				);
			});
		}

		anatAxis.dispatchEvent({ type: ToolEvents.updated });
	}

	public bindOsteotomy(viewType: ViewType): void {
		const osteotomy = this._main.getTools(viewType).osteotomy as OsteotomyAnatomical;

		osteotomy.addEventListener(ToolEvents.updated, () => {
			this.updateMeasureInModel(
				viewType === ViewType.AP ? "def_ap_apex1" : "def_lt_apex1",
				viewType === ViewType.AP ? "def_ap_apex2" : "def_lt_apex2",
				"def_suggestedBoneLength"
			);
		});

		osteotomy.dispatchEvent({ type: ToolEvents.updated });
	}
}
