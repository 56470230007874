    <!-- MENU JPS -->
    <div class="box-menu-settings" [ngClass]="{'active': isEocCut }">

      <div class="row-box">
        <h4>{{ labels.TOOLS_MENU_COMPONENT_TEMPLATE }}</h4>
        <div class="check-success ml-auto" *ngIf="isPlateInserted"></div>
      </div>

      <div class="divider-box"></div>

      <div class="row-box" *ngIf="stateType !== stateTypeEnum.templating; else TemplateBlock"
        [ngClass]="{'clickable': isEocCut, 'not-allowed': !isEocCut }" (click)="enableTemplate()">
        <i class="material-icons">more_horiz</i>
        <span>{{ labels.TOOLS_MENU_COMPONENT_ACTIVATE }}</span>
      </div>

      <ng-template #TemplateBlock>

        <div class="row-box clickable" (click)="openTemplateSelection()">
          <div class="fake-material-icon template"></div>
          <span>{{ labels.TOOLS_MENU_COMPONENT_JPS_PRODUCT }}</span>
        </div>

      </ng-template>

    </div>
