<!-- Box table -->
<div class="box-table">
  <span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
    {{ labels.DEF_BONE_LENGTH_TABLE_COMPONENT_TITLE }}
    <div class="fake-material-icon icon-expand expand"></div>
  </span>
  <div [ngbCollapse]="isCollapsed">

    <!--TABLE-->
    <table role="none" class="table">
      <tbody>
        <tr [class.row-selected]="!isSuggestedBoneLengthValid">
          <td>{{ labels.DEF_BONE_LENGTH_TABLE_COMPONENT_SUGGESTED_BONE_LENGTH }}</td>
          <td [highlightCell]="eocSuggestedBoneLength">{{eocSuggestedBoneLength}}
            {{labels.DEF_BONE_LENGTH_TABLE_COMPONENT_LENGTHENING}}</td>
        </tr>
      </tbody>
    </table>

    <div class="invalid-feedback d-block" *ngIf="!isSuggestedBoneLengthValid">{{invalidSuggestedBoneLengthMsg}}</div>

  </div>
</div>
