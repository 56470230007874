import { Component } from '@angular/core';
import { LanguageService } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
* Apex table of PDF print.
*/
@Component({
	selector: 'prt-apex-table',
	templateUrl: './prt-apex-table.component.html'
})
export class PrtApexTableComponent extends BaseComponent {

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	/**
	* Get apex measure of femur in AP image.
	*/
	get apFemurMeasure(): string {
		return this.defAPMeasures.def_ap_apexFemur || this.defAPMeasures.def_ap_apexFemur === 0 ? `${this.defAPMeasures.def_ap_apexFemur} ${this.apexSignLabel(this.defAPMeasures.def_ap_apexFemurSign)}` : '';
	}

	/**
	* Get apex measure of tibia in AP image.
	*/
	get apTibiaMeasure(): string {
		return this.defAPMeasures.def_ap_apexTibia || this.defAPMeasures.def_ap_apexTibia === 0 ? `${this.defAPMeasures.def_ap_apexTibia} ${this.apexSignLabel(this.defAPMeasures.def_ap_apexTibiaSign)}` : '';
	}

	/**
	* Get apex measure of tibia in Lateral image.
	*/
	get ltTibiaMeasure(): string {
		return this.defLTMeasures.def_lt_apexTibia || this.defLTMeasures.def_lt_apexTibia === 0 ? `${this.defLTMeasures.def_lt_apexTibia} ${this.apexSignLabel(this.defLTMeasures.def_lt_apexTibiaSign)}` : '';
	}

	/**
	* Get axial rotation measure of femur.
	*/
	get axRotFemurMeasure(): string {
		return this.def_femur_axialRotation || this.def_femur_axialRotation == 0 ? `${this.def_femur_axialRotation} ${this.axialRotSignLabel(this.def_femur_axialRotationSign)}` : '';
	}

	/**
	* Get axial rotation measure of tibia.
	*/
	get axRotTibiaMeasure(): string {
		return this.def_tibia_axialRotation || this.def_tibia_axialRotation === 0 ? `${this.def_tibia_axialRotation} ${this.axialRotSignLabel(this.def_tibia_axialRotationSign)}` : '';
	}

}
