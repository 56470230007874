import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component of first vertical sidebar to handle the images rotation workflow.
*/
@Component({
  selector: 'rotation-workflow',
  templateUrl: './rotation-workflow.component.html',
  styles: []
})
export class RotationWorkflowComponent extends BaseComponent implements OnInit {

  helpImg: string;
  isImgVisible: boolean;

  constructor(
    private langSrv: LanguageService,
    private canvasSrv: CanvasService
  ) {
    super(langSrv, canvasSrv);
  }

  ngOnInit() {
    this.helpImg = 'assets/images/gif/rotate.gif';
  }

  /**
  * Confirm rotation.
  */
  confirm(): void {
    this.canvasSrv.dispatch('confirmRotate');
  }

}
