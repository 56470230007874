<!--Help -->
<div class="help">
	<div class="header">
		<div class="left">
			<i class="material-icons-outlined">live_help</i>
			<h5 class="title">{{ labels.HELP_COMPONENT_TITLE }}</h5>
		</div>
		<i class="material-icons close" (click)="activeModal.dismiss()">close</i>
	</div>
	<div class="content">

		<iframe style="width: 100%; height: 99%" [src]="safeUrl" title="help content" *ngIf="safeUrl"></iframe>

	</div>
</div>