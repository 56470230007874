import { ToolEvents } from "@ortho-next/nextray-core";
import { ViewType } from "@ortho-next/nextray-core/Models/AppModel";
import { SelectedApexMech } from "@ortho-next/nextray-core/States/State";
import { VectorUtils } from "@ortho-next/nextray-core/Utils/VectorUtils";
import { Vector3 } from '@ortho-next/three-base/three.js/build/three.module';
import { BindedModel } from "../../../nextray/Models/BindedModel";
import { DeformityAnalysisType } from "../../../nextray/Models/Enums";
import { PrintStateTypes, StateTypes } from "../../../nextray/States/State";
import { MeasuresUtils } from "../../../nextray/Utils/MeasuresUtils";
import { MechanicalAxisAP } from "../DeformityAnalyzer/FullAnalyzerAP";
import { ReferencePoint } from "../ReferencePoint";
import { Osteotomy } from "./Osteotomy";

export class OsteotomyAP extends Osteotomy {
	public deformityAnalysisType = DeformityAnalysisType.mechanical;
	public mechanicalAxis: MechanicalAxisAP;
	public selectedApex: SelectedApexMech; //override

	public onAfterRestore(): void {
		this.updatePerpTranslation();
	}

	public get distanceToReferencePoint(): number {
		const intersectionOsteotomyOnAxis = VectorUtils.lines2DIntersection(this._axisPoint1, this._axisPoint2, this.C.position, this.C.position.clone().add(this._axisPerp)); //todo project
		const sign = VectorUtils.computeSign(this.C.position, this.referencePoint.position, this._axisDir);
		return this.referencePoint.position.distanceTo(intersectionOsteotomyOnAxis) * sign;
	}

	public set distanceToReferencePoint(value: number) {
		const newCenter = this.referencePoint.position.clone().add(this._axisDir.clone().setLength(value)).add(this._perpTranslToSync);
		this.updateCenter(newCenter);
	}

	/**
	 * Set osteotomy length error when initialtranslation + 1mm > lenght (Mechanical)
	 */
	protected set errorLength(value: boolean) {
		if (this._errorLength !== value) {
			this._errorLength = value;
			if (value) {
				this.dispatchEvent({ type: ToolEvents.onError });
			}
		}
	}

	constructor(mechanicalAxis: MechanicalAxisAP, referencePoint: ReferencePoint) {
		super(ViewType.AP, referencePoint, true);
		this.mechanicalAxis = mechanicalAxis;

		this.bindEvent('onAfterDragMove', () => {
			this.updatePerpTranslation();
			const distance = MeasuresUtils.getHorizontalTranslation(this, this.mechanicalAxis, this.C.position);
			this.errorLength = Math.abs(distance) + 1 > this.length;
		});

		this.bindProperty('visible', (m: BindedModel) => { // remove this binding for eoc
			return m.isOsteotomyValid && m.layer_osteotomy && (m.printState === PrintStateTypes.deformityAnalysis || (m.appState !== StateTypes.templating || !m.isPlateInserted));
		}, ['isOsteotomyValid', 'layer_osteotomy', 'printState', 'appState', 'isPlateInserted']);

		this.bindProperty('isEnabled', (m: BindedModel) => {
			return !m.readonly && !m.isRegisteringClickAP && m.appState === StateTypes.EOC && !m.EOCCropVisible;
		}, ['readonly', 'isRegisteringClickAP', 'appState', 'EOCCropVisible']);
	}

	public setPositionByApex(selectedApex: SelectedApexMech): void {
		this.selectedApex = selectedApex;
		switch (selectedApex) {
			case SelectedApexMech.femurProximal:
			case SelectedApexMech.femurDistal: {
				const femur = this.mechanicalAxis.femur;
				this.setPointsForSync(femur.mechanical.CE.position, femur.mechanical.FH.position);
				if (femur.mechanical.apex.visible) {
					this.placeOsteotomyByApex(femur.mechanical.apex.position, femur.apexBisector);
				} else {
					this.placeOsteotomyByApex(femur.FH.position.clone().lerp(femur.CE.position, 0.5), VectorUtils.getPerpendicular(femur.FH.position.clone().sub(femur.CE.position)));
				}
				break;
			}
			case SelectedApexMech.tibiaProximal:
			case SelectedApexMech.tibiaDistal: {
				const tibia = this.mechanicalAxis.tibia;
				this.setPointsForSync(tibia.CA.position, tibia.CP.position);
				if (tibia.apex.visible) {
					this.placeOsteotomyByApex(tibia.apex.position, tibia.apexBisector);
				} else {
					this.placeOsteotomyByApex(tibia.CP.position.clone().lerp(tibia.CA.position, 0.5), VectorUtils.getPerpendicular(tibia.CP.position.clone().sub(tibia.CA.position)));
				}
			}
		}

		this.updatePerpTranslation();
	}

	protected setPointsForSync(p1: Vector3, p2: Vector3): void {
		this._axisPoint1 = p1.clone();
		this._axisPoint2 = p2.clone();
		this._axisDir = p1.clone().sub(p2).normalize();
		this._axisPerp = VectorUtils.getPerpendicular(this._axisDir);
		this._axisValidPoint = p1.clone().lerp(p2, 0.5);
	}

	protected updatePerpTranslation(): void {
		if (this._axisPoint1 && this._axisPoint2) { // cloned osteotomy doesn't use them
			const intersectionOsteotomyOnAxis = VectorUtils.projectOnVector(this.C.position, this._axisPoint1, this._axisPoint2);
			this._perpTranslToSync = this.C.position.clone().sub(intersectionOsteotomyOnAxis);
		}
	}

}
