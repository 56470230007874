import { Save } from "@ortho-next/nextray-core/Core/Save";
import { Shaders } from "@ortho-next/nextray-core/Utils/Shaders";
import { BufferGeometry, Mesh, ShaderMaterial, ShapeBufferGeometry } from "@ortho-next/three-base/three.js/build/three.module";

export class CroppedPlane extends Mesh {
  @Save("attributes.position", "attributes.normal", "attributes.uv", "index")
  public geometry: ShapeBufferGeometry; //override
  public material: ShaderMaterial; //override

  public onAfterRestore = () => {
    this.geometry.computeBoundingSphere();
  }

  constructor(planeMaterial: ShaderMaterial) {
    super(new BufferGeometry(), new ShaderMaterial({
      vertexShader: Shaders.planeVertexShader,
      fragmentShader: Shaders.croppedPlaneFragmentShader,
      uniforms: {
        brightness: planeMaterial.uniforms.brightness,
        contrast: planeMaterial.uniforms.contrast,
        hFlip: planeMaterial.uniforms.hFlip,
        vFlip: planeMaterial.uniforms.vFlip,
        map: planeMaterial.uniforms.map
      }
    }));
    this.name = "CroppedPlane";
    this.isDraggable = true;
  }

  public setGeometry(geometry: ShapeBufferGeometry) {
    this.geometry.dispose();
    this.geometry = geometry;
  }
}
