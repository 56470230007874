import { SaveChild, ViewType } from "@ortho-next/nextray-core";
import { DraggablePoint } from "@ortho-next/nextray-core/Tools/Primitive/DraggablePoint";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { Consts } from "../../Utils/Consts";
import { Osteotomy } from "../Osteotomy/Osteotomy";
import { OsteotomyAnatomical } from "../Osteotomy/OsteotomyAnatomical";
import { OsteotomyAP } from "../Osteotomy/OsteotomyAP";
import { OsteotomyLT } from "../Osteotomy/OsteotomyLT";

export class ClonedOsteotomyManager {
	@SaveChild() public obj: Osteotomy;
	private _originalObj: Osteotomy;
	private _pointsToUpdate: DraggablePoint[];

	constructor(osteotomy: Osteotomy, viewType: ViewType) {
		this._originalObj = osteotomy;
		this.cloneOsteotomy(osteotomy, viewType);
		this.obj.position.setZ(4);
		this.obj.name = "Cloned osteotomy";
		this.obj.interceptedByRaycaster = false;
		this.obj.label.visible = false;

		this._pointsToUpdate = [this.obj.A, this.obj.B, this.obj.C];
	}

	public reset(): void {
		const oldOsteotomy = this._originalObj;
		this.obj.A.position.copy(oldOsteotomy.A.position);
		this.obj.B.position.copy(oldOsteotomy.B.position);
		this.obj.C.position.copy(oldOsteotomy.C.position);
	}

	private cloneOsteotomy(osteotomy: Osteotomy, viewType: ViewType): void {
		this.obj = osteotomy instanceof OsteotomyAP ? new OsteotomyAP(undefined, undefined) :
			(osteotomy instanceof OsteotomyLT ? new OsteotomyLT(undefined, undefined) : 
			new OsteotomyAnatomical(viewType, undefined, undefined, (osteotomy as OsteotomyAnatomical).isPostOp, (osteotomy as OsteotomyAnatomical).planeType));
		const oldOsteotomy = this._originalObj;
		this.obj.A.position.copy(oldOsteotomy.A.position);
		this.obj.B.position.copy(oldOsteotomy.B.position);
		this.obj.C.position.copy(oldOsteotomy.C.position);
	}

	public makeRotation(angle: number, rotationCenter: Vector3): void {
		for (const obj of this._pointsToUpdate) {
			obj.position.sub(rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(rotationCenter);
		}
	}

	public makeTranslation(pan: Vector3): void {
		for (const obj of this._pointsToUpdate) {
			obj.position.add(pan);
		}
	}

}
