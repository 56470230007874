<table aria-label="Apex measures" class="wrapper-print-table">
	<thead>
		<tr>
			<th>{{ labels.PRINT_APEX_TABLE_HEADER }}</th>
			<th *ngIf="hasAP">{{ labels.APEX_TABLE_AP_HEADER }}</th>
			<th *ngIf="hasLT">{{ labels.APEX_TABLE_LT_HEADER }}</th>
			<th>{{ labels.PRINT_APEX_TABLE_AXIAL_HEADER }}</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngIf="isFemur || isLongLeg">
			<td>{{ labels.APEX_TABLE_FEMUR }}</td>
			<td *ngIf="hasAP">{{ apFemurMeasure }}</td>
			<td *ngIf="hasLT"></td>
			<td>{{ axRotFemurMeasure }}</td>
		</tr>
		<tr id="apexTibia" *ngIf="isTibia || isLongLeg">
			<td>{{ labels.APEX_TABLE_TIBIA }}</td>
			<td *ngIf="hasAP">{{ apTibiaMeasure }}</td>
			<td *ngIf="hasLT">{{ ltTibiaMeasure }}</td>
			<td>{{ axRotTibiaMeasure }}</td>
		</tr>
	</tbody>
</table>