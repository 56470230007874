import { Component, Input, OnInit } from '@angular/core';
import { SelectedApexMech } from '@ortho-next/nextray-core/States/State';
import { LanguageService, Plan } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
* Second page of PDF print.
*/
@Component({
	selector: 'print-page-two',
	templateUrl: './page-two.component.html'
})
export class PrintPageTwoComponent extends BaseComponent implements OnInit {

	@Input() isDemo: boolean;
	@Input() today: number;

	currentCase: Plan;
	isMNSA: boolean;
	canvasSrc: string;
	isAnatomical: boolean;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit(): void {
		this.currentCase = this.canvasSrv.currentCase;
		this.isMNSA = !!this.eocMeasures.eoc_ap_MNSA && this.canvasSrv.selectedApex === SelectedApexMech.femurProximal;
		this.canvasSrc = this.canvasSrv.printResult.templating;
		this.isAnatomical = this.deformityAnalysisType === this.defTypeEnum.anatomical;
	}

}
