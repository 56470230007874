import { Injectable } from '@angular/core';
import { AxialRotationEnum, ViewType } from '@ortho-next/nextray-core';
import { NextrayMeasuresEoCAnat } from '../../nextray/Models/AppModel';
import { AxialTranslationEnum } from '../../nextray/Models/Enums';
import { AngularDeformityTypeEnum, DeformityParameters, Plan, RotationTypeEnum, TranslationTypeEnum } from '../core';
import { CanvasService } from './canvas.service';


/**
* This service handles mapping between canvas model data and tlhex data.
*/
@Injectable()
export class HexrayMapperService {

	constructor(private canvasSrv: CanvasService) { }

	private get plan(): Plan {
		return this.canvasSrv.currentCase;
	}

	private get eocMeasures(): NextrayMeasuresEoCAnat {
		return this.canvasSrv.eocMeasuresAnat;
	}

	/**
	 * Map from ray deformity data to tlhex deformity parameters request data.
	 */
	deformityRequestMapper(): DeformityParameters {
		return {
			ap: {
				angle: this.canvasSrv.anatApexProxAP.def_angDef,
				angulation: this.defAngDeformityMapper(this.canvasSrv.anatApexProxAP.def_angDefSign),
				displacement: this.canvasSrv.anatApexProxAP.def_transl,
				translation: this.defTranslMapper(this.canvasSrv.anatApexProxAP.def_translSign)
			},
			ml: {
				angle: this.canvasSrv.anatApexProxLT.def_angDef,
				angulation: this.defAngDeformityMapper(this.canvasSrv.anatApexProxLT.def_angDefSign),
				displacement: this.canvasSrv.anatApexProxLT.def_transl,
				translation: this.defTranslMapper(this.canvasSrv.anatApexProxLT.def_translSign)
			},
			ax: {
				angle: this.canvasSrv.defAnatAxialRot,
				rotation: this.rotationMapper(this.canvasSrv.defAnatAxialRotSign),
				displacement: this.defAxialTransl.value,
				translation: this.defAxialTransl.sign
			},
			footLikeStick: false
		}
	}

	private get defAxialTransl(): { value: number, sign: TranslationTypeEnum } {
		let value: number;
		let sign: TranslationTypeEnum;
		if (this.canvasSrv.isDeformity) {
			value = this.canvasSrv.defAnatAxialTrans;
			sign = this.defAxialTranslTypeMapper(this.canvasSrv.defAnatAxialTransSign);
		} else {
			value = this.canvasSrv.anatAxialTranslRef === ViewType.LT ? this.canvasSrv.anatApexProxLT.def_ax_transl : this.canvasSrv.anatApexProxAP.def_ax_transl;
			sign = this.canvasSrv.anatAxialTranslRef === ViewType.LT ? this.defTranslMapper(this.canvasSrv.anatApexProxLT.def_ax_translSign) : this.defTranslMapper(this.canvasSrv.anatApexProxAP.def_ax_translSign);
		}
		return { value, sign };
	}

	private defAngDeformityMapper(value: string): AngularDeformityTypeEnum {
		if (value == "Valgus" || value == "Abduction(ABD)")
			return AngularDeformityTypeEnum.Valgus;

		if (value == "Varus" || value == "Adduction(ADD)")
			return AngularDeformityTypeEnum.Varus;

		if (value == "Procurvatum" || value == "Apex Dorsal" || value == "Equinus")
			return AngularDeformityTypeEnum.ApexAnterior;

		if (value == "Recurvatum" || value == "Apex Plantar" || value == "Calcaneus")
			return AngularDeformityTypeEnum.ApexPosterior;

		return AngularDeformityTypeEnum.Undefined;
	}

	private defAxialTranslTypeMapper(sign: AxialTranslationEnum): TranslationTypeEnum {
		switch (sign) {
			case AxialTranslationEnum.long: return TranslationTypeEnum.Long;
			case AxialTranslationEnum.short: return TranslationTypeEnum.Short;
			default: return TranslationTypeEnum.Undefined;
		}
	}

	private defTranslMapper(value: string): TranslationTypeEnum {
		if (value == "Medial")
			return TranslationTypeEnum.Medial;

		if (value == "Posterior" || value == "Plantar")
			return TranslationTypeEnum.Posterior;

		if (value == "Anterior" || value == "Dorsal")
			return TranslationTypeEnum.Anterior;

		if (value == "Lateral")
			return TranslationTypeEnum.Lateral;

		if (value == "Short")
			return TranslationTypeEnum.Short;

		if (value == "Long")
			return TranslationTypeEnum.Long;

		return TranslationTypeEnum.Undefined;
	}

	private rotationMapper(value: AxialRotationEnum): RotationTypeEnum {
		if (value == AxialRotationEnum.external)
			return RotationTypeEnum.External;

		if (value == AxialRotationEnum.internal)
			return RotationTypeEnum.Internal;

		return RotationTypeEnum.Undefined;
	}

	/**
	 * Map from ray correction data to tlhex eoc deformity request data.
	 */
	eocRequestMapper(): DeformityParameters {
		return {
			ap: {
				angle: this.eocMeasures.eoc_ap_overAng,
				angulation: this.eocAngDeformityMapper(this.canvasSrv.eocMeasuresAnat.eoc_ap_overAngSign, ViewType.AP),
				displacement: this.eocMeasures.eoc_ap_overTransl,
				translation: this.eocTranslMapper(this.eocMeasures.eoc_ap_overTranslSign, ViewType.AP)
			},
			ml: {
				angle: this.eocMeasures.eoc_lt_overAng,
				angulation: this.eocAngDeformityMapper(this.eocMeasures.eoc_lt_overAngSign, ViewType.LT),
				displacement: this.eocMeasures.eoc_lt_overTransl,
				translation: this.eocTranslMapper(this.eocMeasures.eoc_lt_overTranslSign, ViewType.LT)
			},
			ax: {
				angle: this.canvasSrv.eocAnatAxialRot,
				rotation: this.rotationMapper(this.canvasSrv.eocAnatAxialRotSign),
				displacement: this.eocMeasures.eoc_boneLength,
				translation: this.eocAxialTranslMapper(this.eocMeasures.eoc_boneLengthSign)
			},
			footLikeStick: false
		}
	}

	private eocAngDeformityMapper(value: string, view: ViewType): AngularDeformityTypeEnum {
		if (value == "valgus")
			return view === ViewType.LT ? AngularDeformityTypeEnum.ApexPosterior : AngularDeformityTypeEnum.Valgus;

		if (value == "varus")
			return view === ViewType.LT ? AngularDeformityTypeEnum.ApexAnterior : AngularDeformityTypeEnum.Varus;

		return AngularDeformityTypeEnum.Undefined;
	}

	private eocTranslMapper(value: string, view: ViewType): TranslationTypeEnum {
		if (value == "medial")
			return view === ViewType.LT ? TranslationTypeEnum.Posterior : TranslationTypeEnum.Medial;

		if (value == "lateral")
			return view === ViewType.LT ? TranslationTypeEnum.Anterior : TranslationTypeEnum.Lateral;

		return TranslationTypeEnum.Undefined;
	}

	private eocAxialTranslMapper(value: string): TranslationTypeEnum {
		if (value == "short")
			return TranslationTypeEnum.Short;

		if (value == "long")
			return TranslationTypeEnum.Long;

		return TranslationTypeEnum.Undefined;
	}

}