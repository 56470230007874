import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { first, map, switchMap, tap } from "rxjs/operators";
import { CaseRepository, Plan, ProductFilter, ProductTypeEnum } from "../core";
import { BaseService } from "./base.service";
import { UserService } from "./user.service";


/**
* This service handles the case data.
*/
@Injectable()
export class CaseService extends BaseService {

	private _currentCase: BehaviorSubject<Plan> = new BehaviorSubject<Plan>(null);
	private _isReadOnly: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	private _caseProducts: BehaviorSubject<ProductTypeEnum[]> = new BehaviorSubject<ProductTypeEnum[]>(null);

	constructor(
		private caseRepo: CaseRepository,
		private userSrv: UserService
	) {
		super();
	}

	/**
	* Get current case.
	*/
	getCurrentCase(): Observable<Plan> {
		return this._currentCase.asObservable().pipe(first(_case => !!_case));
	}

	/**
	* Get case by id
	* @param {string} id Case GUID
	*/
	getCase(id: string): Observable<Plan> {
		return this.caseRepo.getCase(id).pipe(
			map(response => this.handleApiResponse(response)),
			tap(c => this._currentCase.next(c))
		);
	}

	/**
	* Check if is read-only case.
	*/
	isReadOnly(): Observable<boolean> {
		if (this._isReadOnly.value === null) {
			return combineLatest([this.getCurrentCase(), this.userSrv.getCurrentUser()]).pipe(
				map(([_case, _user]) => _case.userGuid !== _user.id),
				tap(res => this._isReadOnly.next(res)),
				switchMap(() => this._isReadOnly.asObservable())
			);
		}
		return this._isReadOnly.asObservable();
	}

	/**
	* Set case as read-only.
	*/
	setReadOnly(): void {
		this._isReadOnly.next(true);
	}

	/**
	 * Get available products according to plan data
	 * @param plan Plan
	 */
	getAvailableProducts(plan: Plan): Observable<ProductTypeEnum[]> {
		const filter: ProductFilter = {
			planType: plan.type,
			boneType: plan.boneType,
			hasAP: !!plan.apImageGuid,
			hasLateral: !!plan.ltImageGuid,
			isPostOperative: plan.isPostOperative
		}
		return this.caseRepo.getAvailableProducts(filter).pipe(
			map(res => this.handleApiResponse(res)),
			tap(prods => this._caseProducts.next(prods))
		);
	}

	/**
	 * Get current available products
	 */
	getPlanProducts(): Observable<ProductTypeEnum[]> {
		return this._caseProducts.asObservable();
	}

}
