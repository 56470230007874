<!-- Modal -->
<div class="modal-content">
	<div class="modal-header">
		<h5 class="modal-title" data-testid="SUBSYS4_CLOSURE_MODAL_TITLE_TXT">
			{{ labels.CLOSURE_MODAL_COMPONENT_TITLE }}
		</h5>
		<!--Serve per mettere il focus sul modale ed evitare l'autofocus sul primo bottone-->
		<button type="button"
			style="border-color: transparent; background-color: transparent; outline: none; cursor: inherit;"></button>
	</div>
	<div class="modal-body" data-testid="SUBSYS4_CLOSURE_MODAL_TEXT_TXT"
		[innerHtml]="labels.CLOSURE_MODAL_COMPONENT_TEXT"></div>
	<div class="modal-footer">
		<button data-testid="SUBSYS4_CLOSURE_MODAL_CANCEL_BTN" type="button" class="btn btn-secondary"
			(click)="activeModal.dismiss()">
			{{ labels.CLOSURE_MODAL_COMPONENT_CANCEL_BUTTON }}
		</button>
		<button data-testid="SUBSYS4_CLOSURE_MODAL_NOTSAVE_BTN" type="button" class="btn btn-primary"
			(click)="closeWithoutSave()">
			{{ labels.CLOSURE_MODAL_COMPONENT_NOT_SAVE_BUTTON }}
		</button>
		<button data-testid="SUBSYS4_CLOSURE_MODAL_SAVE_BTN" type="button" class="btn btn-primary" (click)="closeWithSave()"
			[disabled]="isSaveDisabled">
			{{ labels.CLOSURE_MODAL_COMPONENT_SAVE_BUTTON }}
		</button>
	</div>
</div>