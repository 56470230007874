import { PlateSearchFilters } from "./plate-search-filters";

/**
* Dictionary model for plates search
*/
export interface PlateSearchDictionary extends PlateSearchFilters {

  targetDictionary: SearchDictionaryEnum

}

/**
* Dicionary name for plates search
*/
export enum SearchDictionaryEnum {
  SEGMENT = 'Segment',
  BONE = 'Bone',
  SIZE = 'Size',
  ANGLE = 'Angle',
  OFFSET = 'Offset',
  FLARE = 'Flare',
  HOLES = 'Holes'
}
