    <!-- MENU TLHEX -->
    <div class="box-menu-settings" [ngClass]="{'active': isCorrectionDone }">

      <div class="row-box">
        <h4>{{ labels.TOOLS_MENU_COMPONENT_TEMPLATE }}</h4>
      </div>

      <div class="divider-box"></div>

      <div class="row-box" *ngIf="stateType !== stateTypeEnum.tlhex; else TemplateBlock"
        [ngClass]="{'clickable': isCorrectionDone, 'not-allowed': !isCorrectionDone }" (click)="enableTemplate()">
        <i class="material-icons">more_horiz</i>
        <span>{{ labels.TOOLS_MENU_COMPONENT_ACTIVATE }}</span>
      </div>

      <ng-template #TemplateBlock>

				<div class="row-box clickable" (click)="goToTlhex()">
					<i class="material-icons">double_arrow</i>
					<span>{{ labels.TOOLS_MENU_COMPONENT_GO_TO_TLHEX }}</span>
				</div>

      </ng-template>

    </div>
