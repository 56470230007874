import { NextrayModel, SaveHandlerBase, ToolToSave, ToolType, ToolUtils, ViewType } from "@ortho-next/nextray-core";
import { AppModel } from "../Models/AppModel";
import { bindedModel } from "../Models/BindedModel";
import { Context } from "../States/Context";
import { Main } from "./Main";
import { Tools } from "./Tools";

/**
 * Manages the save and restore.
 */
export class SaveHandler extends SaveHandlerBase {
	protected _main: Main; //override
	protected _context: Context; //override

	constructor(main: Main, context: Context) {
		super(main, context);
	}

	protected initMesh(tools: Tools, meshType: ToolType, viewType: ViewType, meshData: { [x: string]: any }): ToolToSave {
		switch (meshType) {
			case ToolType.referencePoint:
				return viewType === ViewType.AP ? this._main.toolsInit.initReferencePoint(ViewType.AP) : this._main.toolsInit.initReferencePoint(ViewType.LT);
			case ToolType.osteotomy:
				return viewType === ViewType.AP ? this._main.toolsInit.initOsteotomyAP() : this._main.toolsInit.initOsteotomyLT();
			case ToolType.EOCCrop:
				return this._main.toolsInit.initEoCPlane(viewType);
			case ToolType.plate:
				return this._main.toolsInit.initPlate(viewType);
		}
		return super.initMesh(tools, meshType, viewType, meshData);
	}

	public restoreData(restoreData: NextrayModel): void {
		super.restoreData(restoreData);

		bindedModel.syncAnatomicalAxis = false;
		this._main.model.syncAnatomicalAxis = false;
	}

	protected updateTools(viewType: ViewType): void {
		super.updateTools(viewType);

		const toolsToSave = viewType === ViewType.AP ? this._model.ap.tools : this._model.lt.tools;
		const tools = this._main.getTools(viewType);

		for (const tool of [ // order is the priority. Puo' essere automatizzato
			tools.referencePoint,
			tools.osteotomy,
			tools.EoCPlane,
			tools.plateManager
		]) {
			const toolToSave = ToolUtils.getStateToRestore(tool);
			toolToSave && toolsToSave.push(toolToSave);
		}
	}

	protected onBeforeAppModelStringfy = (appModel: AppModel) => {
		appModel.printResult = undefined;
	}
}
