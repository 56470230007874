import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { LanguageService } from '../../core';
import { CanvasService, PrinterService, UserService } from '../../services';
import { BaseComponent } from '../../shared';



/**
* Component of first vertical sidebar to handle the tools menu.
*/
@Component({
	selector: 'tools-menu',
	templateUrl: './tools-menu.component.html'
})
export class ToolsMenuComponent extends BaseComponent implements OnInit {

	isJPSProduct: boolean;
	isTlhexProduct: boolean;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
		private printSrv: PrinterService,
		private userSrv: UserService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.userSrv.isJPSProduct().pipe(first()).subscribe(res => {
			this.isJPSProduct = res;
		});
		this.userSrv.isTlhexProduct().pipe(first()).subscribe(res => {
			this.isTlhexProduct = res;
		});
	}


	/* FOOTER MENU  */

	/**
	 * Check if or-plan button is visible. 
	 */
	get isPrintVisible(): boolean {
		return this.stateType === this.stateTypeEnum.deformityAnalysis ||
			this.stateType === this.stateTypeEnum.EOC ||
			this.stateType === this.stateTypeEnum.templating;
	}

	/**
	 * Generate PDF OR plan.
	 */
	printOR(): void {
		if (this.isDeformityDone) {
			this.printSrv.printORPlan();
		}
	}

	/**
	 * Generate Bill of Materials PDF
	 */
	printBom(): void {
		if (this.isPlateInserted) {
			this.printSrv.printBom();
		}
	}

}
