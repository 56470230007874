import { Component } from '@angular/core';
import { LanguageService } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
* Component of table of the diameter-specific plate instrument tray information
*/
@Component({
	selector: 'plat-instrument-tray-tables',
	templateUrl: './plat-instrument-tray-tables.component.html',
})
export class PlatInstrumentTrayTablesComponent extends BaseComponent {

	is3mm: boolean;
	is3dot5mm: boolean;
	is5mm: boolean;
	title: string;
	titleColor: string;
	titleBackColor: string;

	constructor(private langSrv: LanguageService, canvasSrv: CanvasService) {
		super(langSrv);
		const screwDiameter = canvasSrv.plate.size;
		switch (screwDiameter) {
			case 3:
				this.title = this.labels['JPS_3.0MM_INST_TRAY'];
				this.is3mm = true;
				this.titleColor = 'white';
				this.titleBackColor = '#196C3D';
				break;
			case 3.5:
				this.title = this.labels['JPS_3.5MM_INST_TRAY'];
				this.is3dot5mm = true;
				this.titleColor = 'black';
				this.titleBackColor = '#FCC026';
				break;
			case 5:
				this.title = this.labels['JPS_5.0MM_INST_TRAY'];
				this.is5mm = true;
				this.titleColor = 'white';
				this.titleBackColor = '#1D3D7A';
				break;
		}
	}

}
