import { AnatomicalAxis, DraggablePoint, SaveChild } from "@ortho-next/nextray-core";
import { ToolEvents } from "@ortho-next/nextray-core/Core/ITool";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { Consts } from "../../Utils/Consts";
import { EoCPlane } from "./EoCPlane";

export class ClonedAnatomicalAxisManager {
  @SaveChild() public obj: AnatomicalAxis; //check restore
  private _EoCPlane: EoCPlane;
  private _originalObj: AnatomicalAxis;
  private _pointsToUpdate: DraggablePoint[];

  constructor(EoCPlane: EoCPlane, anatomicalAxis: AnatomicalAxis) {
    this._originalObj = anatomicalAxis;
    this._EoCPlane = EoCPlane;

    this.cloneAnatomicalAxis();

    this.obj.position.setZ(4);
    this.obj.name = "ClonedAnatomicalAxis";
    this.obj.interceptedByRaycaster = false;

    this.obj.addEventListener(ToolEvents.updated, () => {
      this._EoCPlane.dispatchEvent({ type: ToolEvents.updated });
    });

    this._EoCPlane.dispatchEvent({ type: ToolEvents.updated });
  }

  public reset(): void {
    this._originalObj.copyPositionsTo(this.obj);
    this.obj.position.set(0, 0, 4); //todo remove è solo retrocompatibilità dev
		this.obj.scale.set(1, 1, 1); //todo remove è solo retrocompatibilità dev
  }

  private cloneAnatomicalAxis(): void {
    this.obj = this._originalObj.cloneForEoC();
    this._pointsToUpdate = [this.obj.A, this.obj.A1, this.obj.A2, this.obj.Ac, this.obj.center, this.obj.B, this.obj.B1, this.obj.B2];
    this.obj.Bc && this._pointsToUpdate.push(this.obj.Bc);
  }

  public makeRotation(angle: number, rotationCenter: Vector3): void {
    for (const obj of this._pointsToUpdate) {
      obj.position.copy(obj.position.sub(rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(rotationCenter));
    }
  }

  public makeTranslation(pan: Vector3): void {
    for (const obj of this._pointsToUpdate) {
      obj.position.copy(obj.position.add(pan));
    }
  }

}
