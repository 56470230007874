<table aria-label="AP mechanical measures" class="wrapper-print-table">
	<thead>
		<tr>
			<th>{{ labels.PRINT_MA_AP_TABLE_PARAMETER_HEADER }} <br />
				({{ (isLeft ? sideEnum.Left : sideEnum.Right) | anatomicalSideLabel }})</th>
			<th>{{ labels.VALUE }}</th>
			<th>{{ labels.CONTROLATERAL }} <br />
				({{ (isLeft ? sideEnum.Right : sideEnum.Left) | anatomicalSideLabel }})</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngIf="isMAD">
			<td>{{ labels.PRINT_MAD_VAL }} {{ rangeMAD }}</td>
			<td>{{ defAPMeasures.def_ap_MAD }}</td>
			<td>{{ defCLMeasures.def_ap_MAD_ctrlateral }}</td>
		</tr>
		<tr *ngIf="isMNSA">
			<td>{{ labels.PRINT_MNSA_VAL }} {{ rangeMNSA }}</td>
			<td>{{ defAPMeasures.def_ap_MNSA }}</td>
			<td>{{ defCLMeasures.def_ap_MNSA_ctrlateral }}</td>
		</tr>
		<tr *ngIf="isLPFA">
			<td>{{ labels.PRINT_LPFA_VAL }} {{ rangeLPFA }}</td>
			<td>{{ defAPMeasures.def_ap_LPFA }}</td>
			<td>{{ defCLMeasures.def_ap_LPFA_ctrlateral }}</td>
		</tr>
		<tr *ngIf="isMPFA">
			<td>{{ labels.PRINT_MPFA_VAL }} {{ rangeMPFA }}</td>
			<td>{{ defAPMeasures.def_ap_MPFA }}</td>
			<td>{{ defCLMeasures.def_ap_MPFA_ctrlateral }}</td>
		</tr>
		<tr *ngIf="isMLDFA">
			<td>{{ labels.PRINT_MLDFA_VAL }} {{ rangeMLDFA }}</td>
			<td>{{ defAPMeasures.def_ap_mLDFA }}</td>
			<td>{{ defCLMeasures.def_ap_mLDFA_ctrlateral }}</td>
		</tr>
		<tr *ngIf="isJLCA">
			<td>{{ labels.PRINT_KNEE_JLCA_VAL }} {{ rangeJLCA }}</td>
			<td>{{ defAPMeasures.def_ap_JLCA }}</td>
			<td>{{ defCLMeasures.def_ap_JLCA_ctrlateral }}</td>
		</tr>
		<tr *ngIf="isKneeTransl">
			<td>{{ labels.PRINT_KNEE_TRANSL_VAL }} {{ rangeKneeTransl }}</td>
			<td>{{ defAPMeasures.def_ap_kneeTranslation }}</td>
			<td>{{ defCLMeasures.def_ap_kneeTranslation_ctrlateral }}</td>
		</tr>
		<tr *ngIf="isMPTA">
			<td>{{ labels.PRINT_MPTA_VAL }} {{ rangeMPTA }}</td>
			<td>{{ defAPMeasures.def_ap_MPTA }}</td>
			<td>{{ defCLMeasures.def_ap_MPTA_ctrlateral }}</td>
		</tr>
		<tr *ngIf="isLDTA">
			<td>{{ labels.PRINT_LDTA_VAL }} {{ rangeLDTA }}</td>
			<td>{{ defAPMeasures.def_ap_LDTA }}</td>
			<td>{{ defCLMeasures.def_ap_LDTA_ctrlateral }}</td>
		</tr>
	</tbody>
</table>