<div class="spacing-top">
  <a class="wrapper-back" href="#" (click)="back()" onclick="return false;">
    <span class="material-icons md-18 back">arrow_back</span>
    <span class="action">{{ labels.BONE_TYPE_SELECTION_COMPONENT_CANCEL_BTN }}</span>
  </a>
</div>

<div class="main-step">
  <div class="box-menu-settings active">
    <div class="row-box">
      <h4>{{ labels.BONE_TYPE_SELECTION_COMPONENT_TITLE }}</h4>
    </div>
    <div class="row-box">
      <div class="box-suggestion">
        <p>{{ labels.BONE_TYPE_SELECTION_COMPONENT_MESSAGE }}</p>
      </div>
    </div>
    <div class="row-box bones-area">


      <div class="bones-wrapper right" *ngIf="isLeft" [ngClass]="{'bones-wrapper-mobile': isMobile }" style="padding-top: 0;">

        <div class="right-side">

          <div class="custom-control custom-radio custom-control-inline"
               style="padding-right: 4.2rem;"
               [ngStyle]="{'margin-bottom': isMobile ? '3.2rem' : '5.2rem' }">
            <input type="radio" id="longleg" name="boneTypeLeft" class="custom-control-input"
                   [value]="boneTypeEnum.LongLeg" [(ngModel)]="boneType" />
            <label class="custom-control-label" for="longleg" style="font-size: 1.3rem;">{{ boneTypeEnum.LongLeg | boneTypeLabel | uppercase }}</label>
          </div>

          <div class="custom-control bones-control custom-radio">
            <input type="radio" id="femur" name="boneTypeLeft" class="custom-control-input"
                   [value]="boneTypeEnum.Femur" [(ngModel)]="boneType" />
            <label class="custom-control-label" for="femur"></label>
          </div>

          <div [ngStyle]="{'min-height': isMobile ? '5.8rem' : '11.8rem' }"></div>

          <div class="custom-control bones-control custom-radio">
            <input type="radio" id="tibia" name="boneTypeLeft" class="custom-control-input"
                   [value]="boneTypeEnum.Tibia" [(ngModel)]="boneType" />
            <label class="custom-control-label" for="tibia"></label>
          </div>

        </div>
        <label class="info-leg-bottom">{{ boneType | boneTypeLabel }}</label>
      </div>


      <div class="bones-wrapper left" *ngIf="isRight" [ngClass]="{'bones-wrapper-mobile': isMobile }" style="padding-top: 0;">
        <div class="left-side">

          <div class="custom-control custom-radio custom-control-inline"
               style="padding-left: 4.2rem;"
               [ngStyle]="{'margin-bottom': isMobile ? '3.2rem' : '5.2rem' }">
            <input type="radio" id="longleg" name="boneTypeRight" class="custom-control-input"
                   [value]="boneTypeEnum.LongLeg" [(ngModel)]="boneType" />
            <label class="custom-control-label" for="longleg" style="font-size: 1.3rem;">{{ boneTypeEnum.LongLeg | boneTypeLabel | uppercase }}</label>
          </div>

          <div class="custom-control bones-control custom-radio">
            <input type="radio" id="femur" name="boneTypeRight" class="custom-control-input"
                   [value]="boneTypeEnum.Femur" [(ngModel)]="boneType" />
            <label class="custom-control-label" for="femur"></label>
          </div>

          <div [ngStyle]="{'min-height': isMobile ? '5.8rem' : '11.8rem' }"></div>

          <div class="custom-control bones-control custom-radio">
            <input type="radio" id="tibia" name="boneTypeRight" class="custom-control-input"
                   [value]="boneTypeEnum.Tibia" [(ngModel)]="boneType" />
            <label class="custom-control-label" for="tibia"></label>
          </div>

        </div>
        <label class="info-leg-bottom">{{ boneType | boneTypeLabel }}</label>
      </div>


    </div>
    <div class="mt-auto">
      <div class="divider-box"></div>
      <div class="wrapper-btn-confirm">
        <button type="button" class="btn btn-primary pl-2 pr-2"
                [disabled]="!boneType"
                (click)="confirm()">
          {{ labels.BONE_TYPE_SELECTION_COMPONENT_CONFIRM_BTN }}
        </button>
      </div>
    </div>
  </div>
</div>
