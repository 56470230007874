import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
 * Table for bone length measures in deformity anatomical analysis.
 */
@Component({
	selector: 'def-bone-length-table',
	templateUrl: './def-bone-length-table.component.html'
})
export class DefBoneLengthTableComponent extends BaseComponent implements OnInit {

	isCollapsed: boolean = false;

	invalidSuggestedBoneLengthMsg: string;
	private readonly MIN_VALUE = 0;
	private readonly MAX_VALUE = 200;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit(): void {
		this.initInvalidMsgs();
	}

	private initInvalidMsgs(): void {
		const labelSBL: string = this.labels['DEF_BONE_LENGTH_TABLE_COMPONENT_SUGGESTED_BONE_LENGTH_VALIDATION'];
		this.invalidSuggestedBoneLengthMsg = labelSBL?.replace("%1", this.MIN_VALUE.toString())?.replace("%2", this.MAX_VALUE.toString());
	}

	/**
	 * Check if suggested bone length is valid.
	 */
	get isSuggestedBoneLengthValid(): boolean {
		return this.eocSuggestedBoneLength <= this.MAX_VALUE;
	}

}
