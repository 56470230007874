import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component of first vertical sidebar to handle the reference point insertion workflow.
*/
@Component({
	selector: 'reference-point-workflow',
	templateUrl: './reference-point-workflow.component.html'
})
export class ReferencePointWorkflowComponent extends BaseComponent implements OnInit {

	helpImg: string;
	isImgVisible: boolean;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.helpImg = 'assets/images/gif/reference_point.gif';
	}

	/**
	* Close reference point workflow.
	*/
	back(): void {
		this.canvasSrv.dispatch('cancelRefPoint');
	}

	/**
	* Confirm reference point and insert anatomical axis.
	*/
	confirm(): void {
		this.canvasSrv.dispatch('confirmRefPoint');
		this.canvasSrv.dispatch('anatomical-axis-btn');
	}

}
