import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JpsMenuComponent } from './jps-menu';
import { RpmMenuComponent } from './rpm-menu';
import { TemplateSelectionComponent } from './template-selection';
import { TlhexMenuComponent } from './tlhex-menu';

@NgModule({
	declarations: [
		TemplateSelectionComponent,
		RpmMenuComponent,
		JpsMenuComponent,
		TlhexMenuComponent
	],
	exports: [
		TemplateSelectionComponent,
		RpmMenuComponent,
		JpsMenuComponent,
		TlhexMenuComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule
	]
})
export class ProductModule { }
