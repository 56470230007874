<div class="spacing-top">
  <a class="wrapper-back" href="#" (click)="back()" onclick="return false;">
    <span class="material-icons md-18 back">arrow_back</span>
    <span class="action">{{ labels.AA_OST_WORKFLOW_COMPONENT_CANCEL_BTN }}</span>
  </a>
</div>

<div class="main-step">
  <div class="box-menu-settings active">
    <div class="row-box">
      <h4>{{ labels.AA_OST_WORKFLOW_COMPONENT_TITLE }}</h4>
    </div>
    <div class="row-box">
      <div class="box-suggestion">
        <p>
          <strong class="text-danger">DA RIVEDERE TESTO...</strong><br><br>
          {{ labels.AA_OST_WORKFLOW_COMPONENT_MESSAGE }}
        </p>
      </div>
    </div>
    <div class="row-box bones-area">


      <div class="bones-wrapper right generic-bone">

        <div class="right-side">
          <div class="custom-control bones-control custom-radio">
            <input type="radio" id="proximal" name="anatomicalSite" class="custom-control-input"
              [value]="apexSiteEnum.proximal" [(ngModel)]="site" />
            <label class="custom-control-label" for="proximal"></label>
          </div>
          <div [ngStyle]="{'min-height': '7.5rem' }"></div>
          <div class="custom-control bones-control custom-radio">
            <input type="radio" id="distal" name="anatomicalSite" class="custom-control-input"
              [value]="apexSiteEnum.distal" [(ngModel)]="site" />
            <label class="custom-control-label" for="distal"></label>
          </div>
        </div>
        <label class="info-leg-bottom">{{ apexSiteLabel }}</label>
      </div>


    </div>
    <div class="mt-auto">
      <div class="divider-box"></div>
      <div class="wrapper-btn-confirm">
        <button type="button" class="btn btn-primary pl-2 pr-2" [disabled]="!site" (click)="confirm()">
          {{ labels.AA_OST_WORKFLOW_COMPONENT_CONFIRM_BTN }}
        </button>
      </div>
    </div>
  </div>
</div>
