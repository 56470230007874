import { Mesh, MeshBasicMaterial, Shape, ShapeBufferGeometry } from "@ortho-next/three-base/three.js/build/three.module";
import { BindedModel } from "../../Models/BindedModel";
import { OsteotomyCut } from "../../States/State";
import { Osteotomy } from "../Osteotomy/Osteotomy";

export class RedShape extends Mesh {
  public geometry: ShapeBufferGeometry; // override
  public material: MeshBasicMaterial; // override

  constructor() {
    super(undefined, new MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 0.4 }));
    this.interceptedByRaycaster = false;
    this.name = "RedShape";
    this.position.z = 4;

    this.bindProperty('visible', (m: BindedModel) => {
      return m.cutType === OsteotomyCut.closing;
    }, ['cutType']);
  }

  public recalculateGeometry(osteotomy: Osteotomy, clonedOsteotomy: Osteotomy): void {
    if (this.visible) {
      this.geometry && this.geometry.dispose();

      const planeShape = new Shape().
        moveTo(osteotomy.A.position.x, osteotomy.A.position.y). // check
        lineTo(osteotomy.A.position.x, osteotomy.A.position.y).
        lineTo(osteotomy.B.position.x, osteotomy.B.position.y).
        lineTo(clonedOsteotomy.B.position.x, clonedOsteotomy.B.position.y).
        lineTo(clonedOsteotomy.A.position.x, clonedOsteotomy.A.position.y);

      this.geometry = new ShapeBufferGeometry(planeShape);
    }
  }

}
