import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CanvasTabsetComponent } from './canvas-tabset';
import { Canvas3DComponent } from './canvas3d.component';
import { LayersComponent } from './layers';
import { MagnifierComponent } from './magnifier';


@NgModule({
  declarations: [
    Canvas3DComponent,
    CanvasTabsetComponent,
    LayersComponent,
    MagnifierComponent
  ],
  exports: [
    Canvas3DComponent
  ],
  imports: [
    CommonModule,
    DragDropModule
  ],
  providers: []
})
export class Canvas3dModule { }
