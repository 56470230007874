import { Component } from '@angular/core';
import { SelectedApexMech } from '@ortho-next/nextray-core/States/State';
import { OsteotomyCut } from '../../../../nextray/States/State';
import { LanguageService } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
* Component of table of the recommended clinical measurements
*/
@Component({
	selector: 'prt-rec-clinic-measures',
	templateUrl: './prt-rec-clinic-measures.component.html'
})
export class PrtRecClinicMeasuresComponent extends BaseComponent {

	/**
	 * Get osteotomy site.
	 */
	osteotomySite: string;
	/**
	 * "Cortex Osteotomy level.
	 */
	osteotomyLevel: number;
	/**
	 * Cortex Wire insertion point.
	 */
	cortexWire: number;
	/**
	 * Wire Insertion Angle
	 */
	wireAngle: number;
	/**
	 * AP wedge angle.
	 */
	apWedgeAngle?: number;
	/**
	 * LT wedge angle.
	 */
	ltWedgeAngle?: number;
	/**
	 * AP/LT secondary translation.
	 */
	translation: string;
	/**
	 * Label AP Wedge angle.
	 */
	labelApWedgeAngle: string;
	/**
	 * Label LT Wedge angle.
	 */
	labelLtWedgeAngle: string;
	/**
	 * AP cortex gap.
	 */
	apCortexGap?: number;
	/**
	 * Lateral cortex gap.
	 */
	ltCortexGap?: number;

	/**
	 * AP secondary translation.
	 */
	private get translationAP(): string {
		const value = this.eocMeasures.eoc_ap_secondaryTransl;
		const label = value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_MEDIAL'] : this.labels['WEDGE_TABLE_COMPONENT_LATERAL'];
		return value || value === 0 ? `${Math.abs(value)} ${label}` : '';
	}
	/**
	 * LT secondary translation.
	 */
	private get translationLT(): string {
		const value = this.eocMeasures.eoc_lt_secondaryTransl;
		const label = value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_POSTERIOR'] : this.labels['WEDGE_TABLE_COMPONENT_ANTERIOR'];
		return value || value === 0 ? `${Math.abs(value)} ${label}` : '';
	}

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
	) {
		super(langSrv, canvasSrv);
		this.osteotomySite = SelectedApexMech[this.canvasSrv.selectedApex];
		this.osteotomyLevel = this.canvasSrv.print_osteotomyLevel;
		this.cortexWire = this.canvasSrv.print_cortexWire;
		this.wireAngle = this.canvasSrv.print_wireAngle;
		this.apWedgeAngle = this.eocMeasures.eoc_ap_angle;
		this.ltWedgeAngle = this.eocMeasures.eoc_lt_angle;

		if (this.canvasSrv.osteotomyCut === OsteotomyCut.opening) {
			this.labelApWedgeAngle = this.labels.PRINT_AP_OPENING_WEDGE_ANG;
			this.labelLtWedgeAngle = this.labels.PRINT_LT_OPENING_WEDGE_ANG;
		} else if (this.canvasSrv.osteotomyCut === OsteotomyCut.closing) {
			this.labelApWedgeAngle = this.labels.PRINT_AP_CLOSING_WEDGE_ANG;
			this.labelLtWedgeAngle = this.labels.PRINT_LT_CLOSING_WEDGE_ANG;
		}

		this.apCortexGap = this.eocMeasures.eoc_ap_cortexLength;
		this.ltCortexGap = this.eocMeasures.eoc_lt_cortexLength;
		this.translation = this.translationAP;
		const translationLT = this.translationLT;

		if (this.translation && translationLT) {
			this.translation += ', ';
		}

		this.translation += translationLT;
	}

}
