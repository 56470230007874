import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IModelRepository } from './Imodel.repository';
import { ApiResponse, RayModel } from './models';

@Injectable()
export class ModelRepository implements IModelRepository {
	constructor(private httpClient: HttpClient) { }

	getModel(caseId: string, userId: string): Observable<ApiResponse<RayModel>> {
		const options = {
			params: { caseId, userId }
		};
		return this.httpClient.get<ApiResponse<RayModel>>(environment.domainRayAPI + '/api/model/get', options);
	}

	saveModel(model: RayModel): Observable<ApiResponse<void>> {
		return this.httpClient.post<ApiResponse<void>>(environment.domainRayAPI + '/api/model/save', model);
	}
}
