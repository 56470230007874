import { AnatomicalSideEnum, BoneTypeEnum, BoneViewEnum } from "./plan";

export interface ImageCategorization {
	/**
	 * Patient GUID the image belongs to.
	 */
	patientId: string;

	/**
	 * Anatomical bone side (Left, Right).
	 */
	boneSide: AnatomicalSideEnum;

	/**
	 * Bone view (AP, LT).
	 */
	boneView: BoneViewEnum;

	/**
	 * Bone type.
	 */
	boneType: BoneTypeEnum;

	/**
	 * Image scale factor.
	 */
	scaleFactor: number;

	/**
	 * Orientation.
	 */
	orientation: ImageOrientationEnum;
}

export enum ImageOrientationEnum {
	Nothing = "Nothing",
	Horizontal = "Horizontal",
	Vertical = "Vertical",
	Rotate180 = "Rotate180"
}