import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectedApexMech } from '@ortho-next/nextray-core/States/State';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PlateOrientationType } from '../../../nextray/Models/AppModel';
import { LanguageService, Plate, PlateSearchFilters } from '../../core';
import { CanvasService, ProductService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component of first vertical sidebar to select the template.
*/
@Component({
	selector: 'template-selection',
	templateUrl: './template-selection.component.html',
	styles: []
})
export class TemplateSelectionComponent extends BaseComponent implements OnInit {

	filtersForm: FormGroup;

	siteFilterEnum = SiteFilterEnum;
	plateOrientationType = PlateOrientationType;

	$plateNameList: Observable<string[]>;
	plateList: Plate[];

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
		public prodSrv: ProductService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.$plateNameList = this.prodSrv.plateList.pipe(
			tap(list => this.plateList = list),
			map(list => list.map(l => this.plateNameMapper(l, this.filtersForm.value.site)))
		);
		this.initForm();
		this.initSite();
	}

	private initForm(): void {
		this.filtersForm = new FormGroup({
			'site': new FormControl(null),
			'bone': new FormControl(null),
			'screw': new FormControl(null),
			'segment': new FormControl(null),
			'angle': new FormControl(null),
			'offset': new FormControl(null),
			'flare': new FormControl(null),
			'holes': new FormControl(null),

			'side': new FormControl(null),

			'plateOrientation': new FormControl({ value: this.plateOrientation, disabled: !this.isPlateInserted })
		});
	}

	private initSite(): void {
		switch (this.selectedApex) {
			case SelectedApexMech.femurProximal: {
				this.filtersForm.controls.site.setValue(SiteFilterEnum.PROXIMAL_FEMUR);
				this.filtersForm.controls.bone.setValue(BoneFilterEnum.FEMUR);
				this.filtersForm.controls.segment.setValue(SegmentFilterEnum.PROXIMAL);
				this.filtersForm.controls.side.setValue(null);
				break;
			}
			case SelectedApexMech.femurDistal: {
				this.filtersForm.controls.site.setValue(SiteFilterEnum.DISTAL_FEMUR);
				this.filtersForm.controls.bone.setValue(BoneFilterEnum.FEMUR);
				this.filtersForm.controls.segment.setValue(SegmentFilterEnum.DISTAL);
				this.filtersForm.controls.side.setValue(null);
				break;
			}
			case SelectedApexMech.tibiaProximal:
			case SelectedApexMech.tibiaDistal: {
				this.filtersForm.controls.site.setValue(SiteFilterEnum.PROX_DIST_TIBIA);
				this.filtersForm.controls.bone.setValue(BoneFilterEnum.TIBIA);
				this.filtersForm.controls.segment.setValue(null);
				this.isLeft ? this.filtersForm.controls.side.setValue(SideFilterEnum.LEFT) : this.filtersForm.controls.side.setValue(SideFilterEnum.RIGHT);
				break;
			}
			default: {
				this.filtersForm.controls.bone.setValue(null);
				this.filtersForm.controls.segment.setValue(null);
				this.filtersForm.controls.side.setValue(null);
				break;
			}
		}
		const filter = this.searchFilter;
		this.prodSrv.updateDictionaryList(filter);
		this.prodSrv.updatePlateList(filter);
	}

	/**
	* Close the template selection workflow.
	*/
	back(): void {
		this.canvasSrv.dispatch('cancelPlate');
	}

	/**
	* Insert plate.
	*/
	insertPlate(index = 0): void {
		this.prodSrv.getScrewList(this.plateList[index].id, this.prodSrv.hasJPSExtended).subscribe(list => {
			const data = { plate: this.plateList[index], screwList: list, hasJPSExtended: this.prodSrv.hasJPSExtended };
			this.canvasSrv.dispatch('insertPlate', data);
			this.isPlateInserted && this.filtersForm.controls.plateOrientation.enable();
		});
	}

	/**
	* Confirm the selected template and close the workflow.
	*/
	confirmTemplate(): void {
		this.canvasSrv.dispatch('confirmPlate');
	}

	/**
	* Update dictionaries on screw selection.
	*/
	updateScrew(): void {
		if (this.filtersForm.value.site == SiteFilterEnum.PROX_DIST_TIBIA) this.filtersForm.controls.segment.reset();
		this.filtersForm.controls.angle.reset();
		this.filtersForm.controls.offset.reset();
		this.filtersForm.controls.flare.reset();
		this.filtersForm.controls.holes.reset();

		const filter = this.searchFilter;
		this.prodSrv.updateSegmentList(filter);
		this.prodSrv.updateAngleList(filter);
		this.prodSrv.updateOffsetList(filter);
		this.prodSrv.updateFlareList(filter);
		this.prodSrv.updateHolesList(filter);
		this.prodSrv.updatePlateList(filter);
	}

	/**
	* Update dictionaries on segment selection.
	*/
	updateSegment(): void {
		this.filtersForm.controls.angle.reset();
		this.filtersForm.controls.offset.reset();
		this.filtersForm.controls.flare.reset();
		this.filtersForm.controls.holes.reset();

		const filter = this.searchFilter;
		this.prodSrv.updateAngleList(filter);
		this.prodSrv.updateOffsetList(filter);
		this.prodSrv.updateFlareList(filter);
		this.prodSrv.updateHolesList(filter);
		this.prodSrv.updatePlateList(filter);
	}

	/**
	* Update dictionaries on angle selection.
	*/
	updateAngle(): void {
		this.filtersForm.controls.offset.reset();
		this.filtersForm.controls.flare.reset();
		this.filtersForm.controls.holes.reset();

		const filter = this.searchFilter;
		this.prodSrv.updateOffsetList(filter);
		this.prodSrv.updateFlareList(filter);
		this.prodSrv.updateHolesList(filter);
		this.prodSrv.updatePlateList(filter);
	}

	/**
	* Update dictionaries on offset selection.
	*/
	updateOffset(): void {
		this.filtersForm.controls.flare.reset();
		this.filtersForm.controls.holes.reset();

		const filter = this.searchFilter;
		this.prodSrv.updateHolesList(filter);
		this.prodSrv.updatePlateList(filter);
	}

	/**
	* Update dictionaries on flare selection.
	*/
	updateFlare(): void {
		this.filtersForm.controls.offset.reset();
		this.filtersForm.controls.holes.reset();

		const filter = this.searchFilter;
		this.prodSrv.updateHolesList(filter);
		this.prodSrv.updatePlateList(filter);
	}

	/**
	* Update dictionaries on holes selection.
	*/
	updateHoles(): void {
		const filter = this.searchFilter;
		this.prodSrv.updatePlateList(filter);
	}

	/**
	* Set plate orientation lateral.
	*/
	setLateral(): void {
		if (this.plateOrientation === PlateOrientationType.medial) {
			this.canvasSrv.dispatch('flipPlate', PlateOrientationType.lateral);
		}
	}

	/**
	* Set plate orientation medial.
	*/
	setMedial(): void {
		if (this.plateOrientation === PlateOrientationType.lateral) {
			this.canvasSrv.dispatch('flipPlate', PlateOrientationType.medial);
		}
	}


	private get searchFilter(): PlateSearchFilters {
		return {
			bone: this.filtersForm.value.bone,
			segment: this.filtersForm.value.segment,
			size: this.filtersForm.value.screw,
			angle: this.filtersForm.value.angle,
			offset: this.filtersForm.value.offset,
			flare: this.filtersForm.value.flare,
			holes: this.filtersForm.value.holes,
			site: this.filtersForm.value.side
		}
	}

	private plateNameMapper(plate: Plate, site: SiteFilterEnum): string {
		switch (this.filtersForm.value.site) {
			case SiteFilterEnum.PROXIMAL_FEMUR: return `${plate.angle}° - ${plate.offset ? plate.offset + 'mm offset  - ' : ''}${plate.holes} holes`
			case SiteFilterEnum.DISTAL_FEMUR: return `${plate.angle}° - ${plate.flare ? plate.flare + '° flare  - ' : ''}${plate.holes} holes`
			case SiteFilterEnum.PROX_DIST_TIBIA: return `${plate.segment.toLowerCase()} - ${plate.holes} holes`
			default: return null
		}
	}

	/**
	 * Get site filter label.
	 */
	siteFilterLabel(site: SiteFilterEnum | string): string {
		switch (site) {
			case SiteFilterEnum.PROXIMAL_FEMUR: return this.labels.JPS_SITE_PROXIMAL_FEMUR;
			case SiteFilterEnum.DISTAL_FEMUR: return this.labels.JPS_SITE_DISTAL_FEMUR;
			case SiteFilterEnum.PROX_DIST_TIBIA: return this.labels.JPS_SITE_PROX_DIST_TIBIA;
			default: return site;
		}
	}

}


export enum SiteFilterEnum {
	PROXIMAL_FEMUR = 'Proximal Femur',
	DISTAL_FEMUR = 'Distal Femur',
	PROX_DIST_TIBIA = 'Prox/Dist Tibia'
}

export enum BoneFilterEnum {
	FEMUR = 'FEMUR',
	TIBIA = 'TIBIA'
}

export enum SegmentFilterEnum {
	PROXIMAL = 'PROXIMAL',
	DISTAL = 'DISTAL'
}

export enum SideFilterEnum {
	RIGHT = 'RIGHT',
	LEFT = 'LEFT'
}
