import { ToolEvents } from "@ortho-next/nextray-core/Core/ITool";
import { Save, SaveChild } from "@ortho-next/nextray-core/Core/Save";
import { SelectedApexMech } from "@ortho-next/nextray-core/States/State";
import { MechanicalAxisFemurAP } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/FemurAnalyzerAP";
import { MechanicalAxisFemurLT } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/FemurAnalyzerLT";
import { MechanicalAxisTibiaAP } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/TibiaAnalyzerAP";
import { MechanicalAxisTibiaLT } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/TibiaAnalyzerLT";
import { DraggablePoint } from "@ortho-next/nextray-core/Tools/Primitive/DraggablePoint";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { Consts } from "../../Utils/Consts";
import { MechanicalAxisAP } from "../DeformityAnalyzer/FullAnalyzerAP";
import { MechanicalAxisLT } from "../DeformityAnalyzer/FullAnalyzerLT";
import { EoCPlane } from "./EoCPlane";

export class ClonedMechanicalAxisManager {
  @SaveChild() public obj: MechanicalAxisAP | MechanicalAxisLT;
  private _EoCPlane: EoCPlane;
  private _originalObj: MechanicalAxisAP | MechanicalAxisLT;
  private _pointsToUpdate: DraggablePoint[];
  @Save() private _pointsNameToUpdate: string[] = [];

  constructor(EoCPlane: EoCPlane, mechanicalAxis: MechanicalAxisAP | MechanicalAxisLT) {
    this._originalObj = mechanicalAxis;
    this._EoCPlane = EoCPlane;

    mechanicalAxis instanceof MechanicalAxisAP ? this.cloneMechanicalAxisAP() : this.cloneMechanicalAxisLT();

    this.obj.position.setZ(4);
    this.obj.name = "ClonedMechanicalAxis";
    this.obj.interceptedByRaycaster = false;

    this.obj.addEventListener(ToolEvents.updated, () => {
      this._EoCPlane.dispatchEvent({ type: ToolEvents.updated });
    });

    this._EoCPlane.dispatchEvent({ type: ToolEvents.updated });
  }

  public onAfterRestore = () => {
    this._pointsToUpdate = [];
    for (const pointName of this._pointsNameToUpdate) {
      const obj = this.obj.getObjectByName(pointName) as DraggablePoint;
      this._pointsToUpdate.push(obj);
    }
  }

  public reset(): void {
    this.obj instanceof MechanicalAxisAP ? this.resetAP() : this.resetLT();
  }

  private cloneMechanicalAxisAP(): void {
    this.obj = new MechanicalAxisAP(this._originalObj.isLeft, this._originalObj.boneType, false, true);
  }

  public resetAP(): void {
    const femur = this.obj.femur as MechanicalAxisFemurAP;
    const tibia = this.obj.tibia as MechanicalAxisTibiaAP;

    if (femur) {
      const oldFemur = this._originalObj.femur as MechanicalAxisFemurAP;
      femur.mechanical.FH.position.copy(oldFemur.mechanical.FH.position);
      femur.mechanical.GT.position.copy(oldFemur.mechanical.GT.position);
      femur.mechanical.LE.position.copy(oldFemur.mechanical.LE.position);
      femur.mechanical.ME.position.copy(oldFemur.mechanical.ME.position);
      femur.anatomical.NS_FH_A.position.copy(oldFemur.anatomical.NS_FH_A.position);
      femur.anatomical.NS_FH_B.position.copy(oldFemur.anatomical.NS_FH_B.position);
      femur.anatomical.NS_GT_lower_A.position.copy(oldFemur.anatomical.NS_GT_lower_A.position);
      femur.anatomical.NS_GT_lower_B.position.copy(oldFemur.anatomical.NS_GT_lower_B.position);
      femur.anatomical.NS_GT_upper_A.position.copy(oldFemur.anatomical.NS_GT_upper_A.position);
      femur.anatomical.NS_GT_upper_B.position.copy(oldFemur.anatomical.NS_GT_upper_B.position);
      femur.LPFAStandard = oldFemur.LPFAStandard;
      femur.mLDFAStandard = oldFemur.mLDFAStandard;
      femur.updateNSATool();
      femur.update();
    }
    if (tibia) {
      const oldTibia = this._originalObj.tibia as MechanicalAxisTibiaAP;
      tibia.LP.position.copy(oldTibia.LP.position);
      tibia.MP.position.copy(oldTibia.MP.position);
      tibia.LA.position.copy(oldTibia.LA.position);
      tibia.MA.position.copy(oldTibia.MA.position);
      tibia.LDTAStandard = oldTibia.LDTAStandard;
      tibia.MPTAStandard = oldTibia.MPTAStandard;
      tibia.update();
    }

    this.obj.setSelectedApex(this._EoCPlane.osteotomy.selectedApex as SelectedApexMech);
  }

  private cloneMechanicalAxisLT(): void { // todo remove measures
    this.obj = new MechanicalAxisLT(this._originalObj.isLeft, this._originalObj.boneType, true);
  }

  public resetLT(): void {
    const femur = this.obj.femur as MechanicalAxisFemurLT;
    const tibia = this.obj.tibia as MechanicalAxisTibiaLT;

    if (femur) {
      const oldFemur = this._originalObj.femur as MechanicalAxisFemurLT;
      femur.FH.position.copy(oldFemur.FH.position);
      femur.AE.position.copy(oldFemur.AE.position);
      femur.PE.position.copy(oldFemur.PE.position);
      femur.update();
    }
    if (tibia) {
      const oldTibia = this._originalObj.tibia as MechanicalAxisTibiaLT;
      tibia.AA.position.copy(oldTibia.AA.position);
      tibia.AP.position.copy(oldTibia.AP.position);
      tibia.PA.position.copy(oldTibia.PA.position);
      tibia.PP.position.copy(oldTibia.PP.position);
      tibia.ADTAStandard = oldTibia.ADTAStandard;
      tibia.PPTAStandard = oldTibia.PPTAStandard;
      tibia.update();
    }

    this.obj.setSelectedApex(this._EoCPlane.osteotomy.selectedApex as SelectedApexMech);
  }

  public updatePointsToUpdate(): void {
    this._pointsToUpdate = [];
    this._pointsNameToUpdate = [];
    const points = [];
    const selApex = this._EoCPlane.osteotomy.selectedApex;

    if (this.obj instanceof MechanicalAxisAP) {
      const femur = this.obj.femur;
      const tibia = this.obj.tibia;

      tibia && points.push(tibia.LA, tibia.MA);
      if (selApex === SelectedApexMech.femurProximal || selApex === SelectedApexMech.femurDistal) {
        points.push(femur.mechanical.LE, femur.mechanical.ME);
        tibia && points.push(tibia.LP, tibia.MP);
      }
      if (selApex === SelectedApexMech.femurProximal) {
        points.push(femur.anatomical.NS_GT_lower_A, femur.anatomical.NS_GT_lower_B, femur.anatomical.NS_GT_upper_A, femur.anatomical.NS_GT_upper_B);
      }
    } else {
      const femur = this.obj.femur;
      const tibia = this.obj.tibia;

      tibia && points.push(tibia.PA, tibia.AA);
      if (selApex === SelectedApexMech.femurProximal || selApex === SelectedApexMech.femurDistal) {
        points.push(femur.PE, femur.AE);
        tibia && points.push(tibia.PP, tibia.AP);
      }
    }

    for (const obj of points) {
      this._pointsToUpdate.push(obj);
      this._pointsNameToUpdate.push(obj.name);
    }
  }

  public makeRotation(angle: number, rotationCenter: Vector3): void {
    for (const obj of this._pointsToUpdate) {
      obj.position.copy(obj.position.sub(rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(rotationCenter));
    }
    this.obj.femur && this.obj.femur.update();
    this.obj.femur && (this.obj.femur as MechanicalAxisFemurAP).updateNSATool
      && this._EoCPlane.osteotomy.selectedApex == SelectedApexMech.femurProximal && (this.obj.femur as MechanicalAxisFemurAP).updateNSATool();
    this.obj.tibia && this.obj.tibia.update();
  }

  public makeTranslation(pan: Vector3): void {
    for (const obj of this._pointsToUpdate) {
      obj.position.copy(obj.position.add(pan));
    }
    this.obj.femur && this.obj.femur.update();
    this.obj.femur && (this.obj.femur as MechanicalAxisFemurAP).updateNSATool
      && this._EoCPlane.osteotomy.selectedApex == SelectedApexMech.femurProximal && (this.obj.femur as MechanicalAxisFemurAP).updateNSATool();
    this.obj.tibia && this.obj.tibia.update();
  }

}
