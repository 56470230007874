<prt-template [isDemo]="isDemo" [today]="today" [plan]="currentCase">
	<div class="row">
		<div class="col-4">
			<div class="wrapper-print-tab">
				<h4>{{labels.DRAWER_COMPONENT_TITLE_CORRECTION}}</h4>
			</div>

			<prt-aa-corr-tables *ngIf="isAnatomical; else mechanical"></prt-aa-corr-tables>
			<ng-template #mechanical>
				<prt-apex-table></prt-apex-table>
				<prt-lld-table *ngIf="hasAP"></prt-lld-table>
				<prt-mnsa-table *ngIf="isMNSA"></prt-mnsa-table>
				<prt-rec-clinic-measures *ngIf="plate"></prt-rec-clinic-measures>
			</ng-template>

		</div>
		<div class="col-8">
			<div class="wrapper-print-tab">
				<h4>{{labels.PRINT_XRAY_IMAGE_TITLE}}</h4>
			</div>
			<div class="wrapper-canvas">
				<img [src]="canvasSrc" alt="xray images" width="850" height="750" />
			</div>
		</div>
	</div>
</prt-template>