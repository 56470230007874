import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ITlhexRepository } from "./Itlhex.repository";
import { ApiResponse, DeformityParameters } from "./models";


@Injectable()
export class TlhexRepository implements ITlhexRepository {

	constructor(private httpClient: HttpClient) { }

	getDeformityParameters(caseId: string, userId: string): Observable<ApiResponse<DeformityParameters>> {
		return this.httpClient.get<ApiResponse<DeformityParameters>>(environment.domainTlhexAPI + `/api/TreatmentPlan/DeformityParameters?caseId=${caseId}&userId=${userId}`);
	}

	getEOCDeformityParameters(caseId: string, userId: string): Observable<ApiResponse<DeformityParameters>> {
		return this.httpClient.get<ApiResponse<DeformityParameters>>(environment.domainTlhexAPI + `/api/TreatmentPlan/EOCDeformityParameters?caseId=${caseId}&user=${userId}`);
	}

}