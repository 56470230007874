import { ViewType } from '@ortho-next/nextray-core/Models/AppModel';
import { Group, Object3D } from '@ortho-next/three-base/three.js/build/three.module';
import { MeshType } from '../../../app/core/repositories/models/plate';
import { LabelScrew } from './LabelScrew';
import { Screw } from './Screw';

export enum ScrewEvents {
	updated = 'ScrewUpdated'
}

export class ScrewManager extends Group {
	public screw: Screw;
	public label: LabelScrew;
	public indexBOM: number;
	public screwType: MeshType;
	private _viewType: ViewType;

	constructor(viewType: ViewType, lengths: number[], child: Object3D, screwType: MeshType, indexBOM: number) {
		super();
		this.name = 'ScrewManager';
		this.screwType = screwType;
		this.indexBOM = indexBOM;
		this._viewType = viewType;
		this.screw = new Screw(child, lengths, screwType);
		this.add(
			this.screw,
			this.label = new LabelScrew('label', viewType, this.screw)
		);

		// this.label.material.depthTest = false; // always on top

		this.screw.bindEvent('onAfterDragMove', () => {
			this.update();
		});

		//this.label.anchor = this.screw.direction.clone().setLength(3 * (this._viewType === ViewType.AP ? bindedModel.scaleFactorAP : bindedModel.scaleFactorLT) + this.screw.length / 2);
	}

	public update(): void {
		this.label.setParam('text', this.screw.length);
		this.label.update();
		//this.label.anchor = this.label.anchor.add(this.screw.direction.clone().setLength((this.screw.length - this.screw.oldLength) / 2));
		this.dispatchEvent({ type: ScrewEvents.updated });
	}

	public changeScrewList(lengths: number[]): void {
		this.screw.changeScrewList(lengths);
	}
}
