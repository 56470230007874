<!-- Box table -->
<div class="box-table">
	<span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
		{{ isForeFoot || isHindFoot ? labels.CORR_AA_AP_TABLE_COMPONENT_TITLE_DORS : labels.CORR_AA_AP_TABLE_COMPONENT_TITLE
		}}
		<div class="fake-material-icon icon-expand expand"></div>
	</span>
	<div [ngbCollapse]="isCollapsed">

		<!--TABLE-->
		<table role="none" class="table">
			<tbody>
				<tr [class.row-selected]="!isOverAngValid">
					<td>{{ labels.CORR_AA_AP_TABLE_COMPONENT_OVER_ANGULATION }}</td>
					<td class="pl-0" [highlightCell]="overAngulation">
						<form class="d-flex align-items-center" [formGroup]="overAngForm">
							<div class="spinner d-flex">
								<span class="material-icons md-18" [ngClass]="{'disabled': isOverAngDisabled}"
									(click)="decrementOverAng()">remove</span>
								<input type="text" maxlength="2" class="custom-control" style="max-width: 4rem" name="overAngValue"
									formControlName="overAngValue" (keyup.enter)="updateOverAng()" (blur)="updateOverAng()"
									[ngModel]="overAngulation" [ngClass]="{'is-invalid': overAngForm.controls.overAngValue.invalid }"
									[attr.disabled]="isOverAngDisabled ? true : null" />
								<span class="material-icons md-18" [ngClass]="{'disabled': isOverAngDisabled}"
									(click)="incrementOverAng()">add</span>
							</div>
							<div class="form-group pb-0">
								<div class="custom-control custom-radio">
									<input type="radio" id="overAng_valgus" name="overAngSign" class="custom-control-input"
										formControlName="overAngSign" (click)="updateOverAngSign(overAngEnum.valgus)"
										[ngModel]="overAngulationSign" [value]="overAngEnum.valgus"
										[attr.disabled]="isOverAngDisabled ? true : null" />
									<label class="custom-control-label" for="overAng_valgus">{{valgusLbl}}</label>
								</div>
								<div class="custom-control custom-radio">
									<input type="radio" id="overAng_varus" name="overAngSign" class="custom-control-input"
										formControlName="overAngSign" (click)="updateOverAngSign(overAngEnum.varus)"
										[ngModel]="overAngulationSign" [value]="overAngEnum.varus"
										[attr.disabled]="isOverAngDisabled ? true : null" />
									<label class="custom-control-label" for="overAng_varus">{{varusLbl}}</label>
								</div>
							</div>
						</form>
					</td>
				</tr>
				<tr [class.row-selected]="!isOverTranslValid">
					<td>{{ labels.CORR_AA_AP_TABLE_COMPONENT_OVER_TRANSLATION }}</td>
					<td class="pl-0" [highlightCell]="overTranslation">
						<form class="d-flex align-items-center" [formGroup]="overTranslForm">
							<div class="spinner d-flex">
								<span class="material-icons md-18" [ngClass]="{'disabled': isOverTranslDisabled}"
									(click)="decrementOverTransl()">remove</span>
								<input type="text" maxlength="3" class="custom-control" style="max-width: 4rem" name="overTranslValue"
									formControlName="overTranslValue" (keyup.enter)="updateOverTransl()" (blur)="updateOverTransl()"
									[ngModel]="overTranslation"
									[ngClass]="{'is-invalid': overTranslForm.controls.overTranslValue.invalid }"
									[attr.disabled]="isOverTranslDisabled ? true : null" />
								<span class="material-icons md-18" [ngClass]="{'disabled': isOverTranslDisabled}"
									(click)="incrementOverTransl()">add</span>
							</div>
							<div class="form-group pb-0">
								<div class="custom-control custom-radio">
									<input type="radio" id="overTransl_lateral" name="overTranslSign" class="custom-control-input"
										formControlName="overTranslSign" (click)="updateOverTranslSign(overTranslEnum.lateral)"
										[ngModel]="overTranslationSign" [value]="overTranslEnum.lateral"
										[attr.disabled]="isOverTranslDisabled ? true : null" />
									<label class="custom-control-label" for="overTransl_lateral">{{lateralLbl}}</label>
								</div>
								<div class="custom-control custom-radio">
									<input type="radio" id="overTransl_medial" name="overTranslSign" class="custom-control-input"
										formControlName="overTranslSign" (click)="updateOverTranslSign(overTranslEnum.medial)"
										[ngModel]="overTranslationSign" [value]="overTranslEnum.medial"
										[attr.disabled]="isOverTranslDisabled ? true : null" />
									<label class="custom-control-label" for="overTransl_medial">{{medialLbl}}</label>
								</div>
							</div>
						</form>
					</td>
				</tr>
			</tbody>
		</table>

		<div class="invalid-feedback d-block" *ngIf="!isOverAngValid">{{invalidOverAngMsg}}</div>
		<div class="invalid-feedback d-block" *ngIf="!isOverTranslValid">{{invalidOverTranslMsg}}</div>

	</div>
</div>