import { Component, OnInit } from '@angular/core';
import { BoneTypeEnum, ViewType } from '@ortho-next/nextray-core';
import { BoneLengthSign, OverAngulationSign, OverTranslSign } from '../../../../nextray/Models/Enums';
import { Consts } from '../../../../nextray/Utils/Consts';
import { LanguageService } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
 * Component for anatomical correction tables of preop plan print. 
 */
@Component({
	selector: 'prt-aa-corr-tables',
	templateUrl: './prt-aa-corr-tables.component.html'
})
export class PrtAaCorrTablesComponent extends BaseComponent implements OnInit {

	private _boneType: BoneTypeEnum = BoneTypeEnum.LongLeg;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit(): void {
		this._boneType = this.canvasSrv.currentCase.boneType;
	}

	/**
	* Get over angulation in AP image.
	*/
	get apOverAng(): string {
		return this.eocMeasuresAnat?.eoc_ap_overAng || this.eocMeasuresAnat.eoc_ap_overAng === 0 ? `${this.eocMeasuresAnat.eoc_ap_overAng} ${this.anatSignLabel(this.overAngSign(ViewType.AP))}` : '';
	}

	/**
	* Get over translation in AP image.
	*/
	get apOverTransl(): string {
		return this.eocMeasuresAnat?.eoc_ap_overTransl || this.eocMeasuresAnat.eoc_ap_overTransl === 0 ? `${this.eocMeasuresAnat.eoc_ap_overTransl} ${this.anatSignLabel(this.overTranslSign(ViewType.AP))}` : '';
	}

	/**
	* Get over angulation in LT image.
	*/
	get ltOverAng(): string {
		return this.eocMeasuresAnat?.eoc_lt_overAng || this.eocMeasuresAnat.eoc_lt_overAng === 0 ? `${this.eocMeasuresAnat.eoc_lt_overAng} ${this.anatSignLabel(this.overAngSign(ViewType.LT))}` : '';
	}

	/**
	* Get over translation in LT image.
	*/
	get ltOverTransl(): string {
		return this.eocMeasuresAnat?.eoc_lt_overTransl || this.eocMeasuresAnat.eoc_lt_overTransl === 0 ? `${this.eocMeasuresAnat.eoc_lt_overTransl} ${this.anatSignLabel(this.overTranslSign(ViewType.LT))}` : '';
	}

	/**
	* Get suggested bone length.
	*/
	get suggestedBoneLength(): string {
		return this.eocMeasuresAnat?.eoc_lt_overAng || this.eocMeasuresAnat.eoc_lt_overAng === 0 ? `${this.eocMeasuresAnat.eoc_lt_overAng} ${this.anatSignLabel(this.overAngSign(ViewType.LT))}` : '';
	}

	/**
	 * Get current bone length.
	 */
	get boneLength(): number {
		return this.eocMeasuresAnat?.eoc_boneLength ?? 0;
	}

	/**
	 * Get current bone length sign.
	 */
	get boneLengthSign(): string {
		return this.eocMeasuresAnat?.eoc_boneLengthSign && this.eocMeasuresAnat.eoc_boneLengthSign === BoneLengthSign[BoneLengthSign.short] ? this.labels.PRINT_AA_CORR_COMPONENT_SHORTENING : this.labels.PRINT_AA_CORR_COMPONENT_LENGTHENING;
	}

	private overAngSign(view: ViewType): string {
		const angSign = view === ViewType.AP ? this.eocMeasuresAnat.eoc_ap_overAngSign : this.eocMeasuresAnat.eoc_lt_overAngSign;
		return OverAngulationSign[angSign] === 1 ? this.rotationSign(view, 0) : this.rotationSign(view, 1);
	}

	private rotationSign(view: ViewType, index: number): string {
		return Consts.signLabels[this._boneType][ViewType[view]].rotation[index];
	}

	private overTranslSign(view: ViewType): string {
		const translSign = view === ViewType.AP ? this.eocMeasuresAnat.eoc_ap_overTranslSign : this.eocMeasuresAnat.eoc_lt_overTranslSign;
		return OverTranslSign[translSign] === 1 ? this.translationSign(view, 1) : this.translationSign(view, 0);
	}

	private translationSign(view: ViewType, index: number): string {
		return Consts.signLabels[this._boneType][ViewType[view]].translation[index];
	}

}
