<div class="spacing-top"></div>

<div class="main-step">


  <image-elaboration [view]="activeView"></image-elaboration>

  <!-- MENU DEFORMITY -->
  <deformity-menu></deformity-menu>

  <!-- RPM MENU -->
  <rpm-menu *ngIf="stateType === stateTypeEnum.RPM; else OtherBlock"></rpm-menu>


  <ng-template #OtherBlock>

    <!-- MENU CORRECTION -->
    <correction-menu></correction-menu>

    <!-- MENU JPS -->
    <jps-menu *ngIf="isJPSProduct && deformityAnalysisType !== defTypeEnum.anatomical"></jps-menu>

    <!-- MENU TLHEX -->
    <tlhex-menu *ngIf="isTlhexProduct && deformityAnalysisType === defTypeEnum.anatomical"></tlhex-menu>


    <!-- FOOTER MENU -->
    <div class="box-menu-settings active" *ngIf="isDeformityDone">

      <div class="row-box clickable mt-5" (click)="printOR()" *ngIf="isPrintVisible">
        <i class="material-icons">local_printshop</i>
        <span>{{ labels.TOOLS_MENU_COMPONENT_OR_PLAN }}</span>
      </div>
      <div class="row-box clickable" (click)="printBom()" *ngIf="stateType === stateTypeEnum.templating"
        [ngClass]="{'clickable': isPlateInserted, 'disabled': !isPlateInserted }">
        <i class="material-icons">receipt</i>
        <span>{{ labels.TOOLS_MENU_COMPONENT_BOM }}</span>
      </div>

    </div>

  </ng-template>


</div>
