<div class="spacing-top">
</div>

<div class="main-step">
	<div class="box-menu-settings active">
		<div class="row-box">
			<h4>{{ labels.CROPPING_WORKFLOW_COMPONENT_TITLE }}</h4>
		</div>
		<div class="row-box">
			<div class="box-suggestion">
				<p>
					{{ labels.CROPPING_WORKFLOW_COMPONENT_MESSAGE }}
				</p>
			</div>
		</div>
		<div class="row-box wrapper-img-axis justify-content-center">
			<div class="" [hidden]="!isImgVisible">
				<img [src]="helpImg" alt="" (load)='isImgVisible = true'
					[ngStyle]="{'max-height': isMobile ? '300px' : 'inherit'}">
			</div>
		</div>
		<div class="mt-auto">
			<div class="divider-box"></div>
			<div class="wrapper-btn-confirm">
				<button type="button" class="btn btn-primary pl-2 pr-2" (click)="confirm()">
					{{ labels.CROPPING_WORKFLOW_COMPONENT_CONFIRM_BTN }}
				</button>
			</div>
		</div>
	</div>
</div>