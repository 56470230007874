import { BoneTypeEnum, MechanicalAxisAP as MechanicalAxisAPBase, Save } from "@ortho-next/nextray-core";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { BindedModel } from "../../Models/BindedModel";
import { DeformityAnalysisType } from "../../Models/Enums";
import { PrintStateTypes, StateTypes } from "../../States/State";

export class MechanicalAxisAP extends MechanicalAxisAPBase {
	@Save() public isValid: boolean;

	constructor(isLeft: boolean, boneType: BoneTypeEnum, isContralateral: boolean, isEOC: boolean) {
		super(isLeft, boneType, isContralateral, isEOC);
		this.bindProperties(isContralateral, isEOC);
	}

	public handleRegisteredPoints(points: Vector3[]): boolean {
		this.isValid = super.handleRegisteredPoints(points);
		return this.isValid;
	}

	private bindProperties(isContralateral: boolean, isEOC: boolean): void {
		if (!isEOC) {
			if (isContralateral) {

				this.bindProperty('visible', (m: BindedModel) => {
					return m.layer_contralateral_all && m.isControlateralMechanicalAxisValid && !m.insertingControlateralMechanicalAxis && m.deformityAnalysisType === DeformityAnalysisType.mechanical && ((!m.EOCCropVisible && m.printState === PrintStateTypes.none) || m.printState === PrintStateTypes.deformityAnalysis);
				}, ['layer_contralateral_all', 'isControlateralMechanicalAxisValid', 'insertingControlateralMechanicalAxis', 'deformityAnalysisType', 'EOCCropVisible', 'printState']);

			} else {

				this.bindProperty('visible', (m: BindedModel) => {
					return m.isMechanicalAxisAPValid && !m.insertingMechanicalAxisAP && m.deformityAnalysisType === DeformityAnalysisType.mechanical && ((!m.EOCCropVisible && m.printState === PrintStateTypes.none) || m.printState === PrintStateTypes.deformityAnalysis);
				}, ['isMechanicalAxisAPValid', 'insertingMechanicalAxisAP', 'deformityAnalysisType', 'EOCCropVisible', 'printState']);

			}

			this.bindProperty('isEnabled', (m: BindedModel) => {
				return !m.readonly && !m.isRegisteringClickAP && m.appState === StateTypes.deformityAnalysis;
			}, ['readonly', 'isRegisteringClickAP', 'appState']);

		}

		this.weightBearing?.bindProperty('visible', (m: BindedModel) => {
			return m.layer_mechAxis_weightBearing;
		}, ["layer_mechAxis_weightBearing"]);

		if (!isContralateral) {
			this.tibia?.bindProperty('visible', (m: BindedModel) => {
				return m.layer_mechAxis_mechanical;
			}, ['layer_mechAxis_mechanical']);

			this.femur?.mechanical?.bindProperty('visible', (m: BindedModel) => {
				return m.layer_mechAxis_mechanical;
			}, ['layer_mechAxis_mechanical']);

			this.femur?.anatomical?.bindProperty('visible', (m: BindedModel) => {
				return m.layer_mechAxis_anatomical;
			}, ['layer_mechAxis_anatomical']);
		}
	}
}  
