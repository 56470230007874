<div class="wrapper-print-tab">
	<h4 [style.color]="titleColor" [style.background-color]="titleBackColor">{{title}}</h4>
</div>
<table aria-label="instruments" class="wrapper-print-table">
	<thead>
		<tr>
			<th style="min-width: 120px;">{{ labels.PART_NO }}</th>
			<th>{{ labels.DESC_INSTR }}</th>
			<th>{{ labels.QUANTITY }}</th>
		</tr>
	</thead>

	<tbody *ngIf="is3mm">
		<tr>
			<td>99-154182</td>
			<td>SS DRILL BIT D2.3MM QUICK CONNECT STERILE</td>
			<td>1</td>
		</tr>
	</tbody>

	<tbody *ngIf="is3dot5mm">
		<tr>
			<td>99-154183</td>
			<td>SS DRILL BIT D2.8MM QUICK CONNECT STERILE</td>
			<td>1</td>
		</tr>
	</tbody>

	<tbody *ngIf="is5mm">
		<tr>
			<td>99-154184</td>
			<td>SS DRILL BIT D3.4MM QUICK CONNECT STERILE</td>
			<td>1</td>
		</tr>
		<tr>
			<td>99-154185</td>
			<td>SS DRILL BIT D4.3MM QUICK CONNECT STERILE</td>
			<td>1</td>
		</tr>
	</tbody>

</table>