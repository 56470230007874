import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ViewType } from '@ortho-next/nextray-core/Models/AppModel';
import Slider from 'bootstrap-slider';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent, ConfirmationComponent } from '../../shared';


/**
* Component of image elaboration tools.
*/
@Component({
	selector: 'image-elaboration',
	templateUrl: './image-elaboration.component.html'
})
export class ImageElaborationComponent extends BaseComponent implements OnInit {

	@Input() set view(val: ViewType) {
		this.initBrightness();
		this.initContrast();
	}

	isCollapsed: boolean = true;

	brightnessSlider: any;
	contrastSlider: any;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
		private modalSrv: NgbModal
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.initSliders();
	}


	private initSliders(): void {
		const sliderOptions = { id: "slider12a", min: -1, max: 1, step: 0.1, value: 0, tooltip: 'hide' };
		this.brightnessSlider = new Slider("#brightnessSlider", sliderOptions).on('change', (val) => this.setBrightness(val));
		this.contrastSlider = new Slider("#contrastSlider", sliderOptions).on('change', (val) => this.setContrast(val));
		this.initBrightness();
		this.initContrast();
	}

	private initBrightness(): void {
		if (this.brightnessSlider) {
			this.activeView === this.activeViewEnum.AP ? this.brightnessSlider.setValue(this.brightnessAP) : this.brightnessSlider.setValue(this.brightnessLT);
		}
	}

	private initContrast(): void {
		if (this.contrastSlider) {
			this.activeView === this.activeViewEnum.AP ? this.contrastSlider.setValue(this.contrastAP) : this.contrastSlider.setValue(this.contrastLT);
		}
	}

	private setBrightness(result: { oldValue: number, newValue: number }): void {
		this.canvasSrv.dispatch('setBrightness', result.newValue);
	}

	private setContrast(result: { oldValue: number, newValue: number }): void {
		this.canvasSrv.dispatch('setContrast', result.newValue);
	}

	/**
	* Add line tool on active view.
	*/
	addLine(): void {
		this.canvasSrv.dispatch('addLine');
	}

	/**
	* Add goniometer tool on active view.
	*/
	addGoniometer(): void {
		this.canvasSrv.dispatch('addGoniometer');
	}

	/**
	* Indicate if measurement tool delete button is enabled in active view.
	*/
	get isDeleteEnabled() {
		return this.activeView === ViewType.AP ? this.isMeasurementToolSelectedAP : this.isMeasurementToolSelectedLT;
	}

	/**
	* Delete selected measurement tool on active view.
	*/
	deleteTool(): void {
		if (this.isDeleteEnabled) {
			this.canvasSrv.dispatch('deleteMeasurementTool');
		}
	}

	/**
	* Open rotation confirmation modal.
	*/
	openRotateModal(): void {
		const message: string = this.labels.IMAGE_ELABORATION_COMPONENT_WARNING_MODAL_MESSAGE;
		this.openWarningModal(message, this.startRotateWorkflow);
	}

	/**
	* Start rotation workflow.
	*/
	private startRotateWorkflow(): void {
		if (this.stateType !== this.stateTypeEnum.calibrated) {
			this.canvasSrv.dispatch('switchToCalibrated');
			this.canvasSrv.saveState();
		}
		this.canvasSrv.dispatch('startRotate');
	}

	/**
	* Open cropping confirmation modal.
	*/
	openCropModal(): void {
		const message: string = this.labels.IMAGE_ELABORATION_COMPONENT_WARNING_MODAL_MESSAGE;
		this.openWarningModal(message, this.startCropWorkflow);
	}

	/**
	* Start cropping workflow.
	*/
	private startCropWorkflow(): void {
		if (this.stateType !== this.stateTypeEnum.calibrated) {
			this.canvasSrv.dispatch('switchToCalibrated');
			this.canvasSrv.saveState();
		}
		this.canvasSrv.dispatch('startCrop');
	}

	private openWarningModal(message: string, confirmFunction: Function, cancelFunction?: Function): void {
		if (!this.modalSrv.hasOpenModals()) {
			const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
				centered: true, backdrop: 'static'
			});
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this.labels['IMAGE_ELABORATION_COMPONENT_WARNING_MODAL_TITLE'],
				message: message
			};
			modalRef.result.then(() => confirmFunction.call(this), () => cancelFunction ? cancelFunction.call(this) : null).catch(error => false);
		}
	}

}
