<div class="wrapper-print-tab">
	<h4 [style.color]="titleColor" [style.background-color]="titleBackColor">{{title}}</h4>
</div>
<table aria-label="instruments" *ngIf="is3mm" class="wrapper-print-table">
	<thead>
		<tr>
			<th style="min-width: 120px;">{{ labels.PART_NO }}</th>
			<th>{{ labels.DESC_INSTR }}</th>
			<th>{{ labels.QUANTITY }}</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>99-154282</td>
			<td>SS GUIDE WIRE D2.3MM L200MM PACK OF 2 STERILE</td>
			<td>2</td>
		</tr>
		<tr>
			<td>{{ isJPSExtension ? '99-154186' : '99-154182' }}</td>
			<td>
				{{ isJPSExtension ? 'JPS DRILL BIT LONG D2.3MM QC STERILE' : 'SS DRILL BIT D2.3MM QUICK CONNECT STERILE' }}
			</td>
			<td>1</td>
		</tr>
		<tr>
			<td>001-A-1502P</td>
			<td>GUIDE WIRE 1.5MM, 4.0/5.5MM BITE COMPRESSION SCREW (KIT OF 2)</td>
			<td>1</td>
		</tr>
	</tbody>
</table>

<table aria-label="instruments" *ngIf="is3dot5mm" class="wrapper-print-table">
	<thead>
		<tr>
			<th>{{ labels.PART_NO }}</th>
			<th>{{ labels.DESC_INSTR }}</th>
			<th>{{ labels.QUANTITY }}</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>99-154281</td>
			<td>SS GUIDE WIRE D2.0MM L200MM PACK OF 2 STERILE</td>
			<td>1</td>
		</tr>
		<tr>
			<td>99-154283</td>
			<td>SS GUIDE WIRE D2.8MM L200MM PACK OF 2 STERILE</td>
			<td>2</td>
		</tr>
		<tr>
			<td>{{ isJPSExtension ? '99-154187' : '99-154183' }}</td>
			<td>
				{{ isJPSExtension ? 'JPS DRILL BIT LONG D2.8MM QC STERILE' : 'SS DRILL BIT D2.8MM QUICK CONNECT STERILE' }}
			</td>
			<td>1</td>
		</tr>
	</tbody>
</table>

<table aria-label="instruments" *ngIf="is5mm" class="wrapper-print-table">
	<thead>
		<tr>
			<th>{{ labels.PART_NO }}</th>
			<th>{{ labels.DESC_INSTR }}</th>
			<th>{{ labels.QUANTITY }}</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>99-154281</td>
			<td>SS GUIDE WIRE D2.0MM L200MM PACK OF 2 STERILE</td>
			<td>1</td>
		</tr>
		<tr>
			<td>99-154283</td>
			<td>SS GUIDE WIRE D2.8MM L200MM PACK OF 2 STERILE</td>
			<td>2</td>
		</tr>
		<tr>
			<td>99-154284</td>
			<td>SS GUIDE WIRE D3.4MM L200MM PACK OF 2 STERILE</td>
			<td>1</td>
		</tr>
		<tr>
			<td>99-154285</td>
			<td>SS GUIDE WIRE D4.3MM L190MM PACK OF 2 STERILE</td>
			<td>1</td>
		</tr>
		<tr>
			<td>{{ isJPSExtension ? '99-154188' : '99-154184' }}</td>
			<td>
				{{ isJPSExtension ? 'JPS DRILL BIT LONG D3.4MM QC STERILE' : 'SS DRILL BIT D3.4MM QUICK CONNECT STERILE' }}
			</td>
			<td>1</td>
		</tr>
		<tr>
			<td>{{ isJPSExtension ? '99-154189' : '99-154185' }}</td>
			<td>
				{{ isJPSExtension ? 'JPS DRILL BIT LONG D4.3MM QC STERILE' : 'SS DRILL BIT D4.3MM QUICK CONNECT STERILE' }}
			</td>
			<td>1</td>
		</tr>
	</tbody>
</table>