import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ConstsBase } from '@ortho-next/nextray-core/Utils/Consts';
import { Utils } from '@ortho-next/nextray-core/Utils/Utils';
import { StateTypes } from '../../../nextray/States/State';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Table of deformity measures for Lateral image.
*/
@Component({
	selector: 'ma-lt-table',
	templateUrl: './ma-lt-table.component.html'
})
export class MaLtTableComponent extends BaseComponent implements OnInit {

	isCollapsed: boolean = true;

	ltAngles = { ...ConstsBase.paleyAngles.LT };

	lateralMeasuresForm: FormGroup;

	private readonly twoDigitsPattern: RegExp = /^\d$|^[1-9]\d{0,2}$/;


	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		const validators: ValidatorFn[] = [Validators.required, Validators.pattern(this.twoDigitsPattern), Validators.min(0), Validators.max(99)];
		this.lateralMeasuresForm = new FormGroup({
			'PPTA': new FormControl(this.def_standardAngles_PPTA, validators),
			'ADTA': new FormControl(this.def_standardAngles_ADTA, validators)
		});
	}


	// VISIBILITY

	/**
	* Check if PDFA is visible.
	*/
	get isPDFA(): boolean {
		return this.isLongLeg || this.isFemur;
	}

	/**
	* Check if PPTA is visible.
	*/
	get isPPTA(): boolean {
		return this.isLongLeg || this.isTibia;
	}

	/**
	* Check if ADTA is visible.
	*/
	get isADTA(): boolean {
		return this.isLongLeg || this.isTibia;
	}

	/**
	* Check if Knee Translation is visible.
	*/
	get isKneeTransl(): boolean {
		return this.isLongLeg;
	}



	// VALIDATION

	/**
	* Check if PDFA value is valid.
	*/
	get isValidPDFA(): boolean {
		return !this.defLTMeasures.def_lt_PDFA || Utils.isPaleyAngleValid(this.defLTMeasures.def_lt_PDFA, this.ltAngles.PDFA);
	}

	/**
	* Check if Knee Translation value is valid.
	*/
	get isValidKneeTransl(): boolean {
		return !this.defLTMeasures.def_lt_kneeTranslation || Utils.isPaleyAngleValid(this.defLTMeasures.def_lt_kneeTranslation, this.ltAngles.KneeTransl);
	}

	/**
	* Check if PPTA value is valid.
	*/
	get isValidPPTA(): boolean {
		return !this.defLTMeasures.def_lt_PPTA || Utils.isPaleyAngleValid(this.defLTMeasures.def_lt_PPTA, this.ltAngles.PPTA);
	}

	/**
	* Check if ADTA value is valid.
	*/
	get isValidADTA(): boolean {
		return !this.defLTMeasures.def_lt_ADTA || Utils.isPaleyAngleValid(this.defLTMeasures.def_lt_ADTA, this.ltAngles.ADTA);
	}

	/**
	* Check if a measure is out of range.
	*/
	get isMeasureOutOfRange(): boolean {
		return !this.isValidPDFA || !this.isValidKneeTransl || !this.isValidPPTA || !this.isValidADTA;
	}



	// ANGLES RANGE

	/**
	* Get PDFA range.
	*/
	get rangePDFA(): string {
		return `(${this.ltAngles.PDFA.min}° - ${this.ltAngles.PDFA.max}°)`;
	}

	/**
	* Get Knee Translation range.
	*/
	get rangeKneeTransl(): string {
		return `(${this.ltAngles.KneeTransl.min}mm - ${this.ltAngles.KneeTransl.max}mm)`;
	}

	/**
	* Get PPTA range.
	*/
	get rangePPTA(): string {
		return `(${this.ltAngles.PPTA.min}° - ${this.ltAngles.PPTA.max}°)`;
	}

	/**
	* Get ADTA range.
	*/
	get rangeADTA(): string {
		return `(${this.ltAngles.ADTA.min}° - ${this.ltAngles.ADTA.max}°)`;
	}



	// ANGLES STANDARD

	/**
	* Update PPTA target value.
	*/
	updateStandardPPTA(): void {
		if (this.defLTMeasures.def_lt_PPTA && this.stateType === StateTypes.deformityAnalysis) {
			if (this.lateralMeasuresForm.controls.PPTA.valid) {
				const target = parseInt(this.lateralMeasuresForm.controls.PPTA.value);
				this.canvasSrv.dispatch('setStandardAngle_PPTA', target);
			} else {
				this.lateralMeasuresForm.controls.PPTA.setValue(this.def_standardAngles_PPTA);
			}
		}
	}

	/**
	* Update ADTA target value.
	*/
	updateStandardADTA(): void {
		if (this.defLTMeasures.def_lt_ADTA && this.stateType === StateTypes.deformityAnalysis) {
			if (this.lateralMeasuresForm.controls.ADTA.valid) {
				const target = parseInt(this.lateralMeasuresForm.controls.ADTA.value);
				this.canvasSrv.dispatch('setStandardAngle_ADTA', target);
			} else {
				this.lateralMeasuresForm.controls.ADTA.setValue(this.def_standardAngles_ADTA);
			}
		}
	}

}
