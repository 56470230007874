<div class="spacing-top justify-content-between">
  <a class="wrapper-back" href="#" (click)="back()" onclick="return false;">
    <span class="material-icons md-18 back">arrow_back</span>
    <span class="action">{{ cancelBtnLabel }}</span>
  </a>
  <span class="material-icons md-18 back mr-4" (click)="cancel()" *ngIf="isCloseVisible">close</span>
</div>

<div class="main-step">
  <div class="box-menu-settings active">
    <div class="row-box">
      <h4 *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.insertMechanicalAxis">{{ labels.MECHANICAL_AXIS_COMPONENT_TITLE_MA }}</h4>
      <h4 *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.insertContralateral">{{ labels.MECHANICAL_AXIS_COMPONENT_TITLE_CTRL }}</h4>
    </div>
    <div class="row-box">
      <div class="box-suggestion">
        <p *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.insertMechanicalAxis">{{ labels.MECHANICAL_AXIS_COMPONENT_MESSAGE_MA }}</p>
        <p *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.insertContralateral">{{ labels.MECHANICAL_AXIS_COMPONENT_MESSAGE_CTRL }}</p>
      </div>
    </div>
    <div class="row-box wrapper-img-axis" style="justify-content: center">
      <div class="" [hidden]="!isImgVisible">
        <img [src]="helpImg" alt="" (load)='isImgVisible = true'
             style="height: 40rem;"
             [ngStyle]="{'max-height': isMobile ? '300px' : 'inherit'}">
      </div>
    </div>
    <div class="row-box align-items-center">
      <span class="material-icons ml-0 mr-2">sync</span>
      <a onclick="return false;" href="#" (click)="reset()">{{ labels.MECHANICAL_AXIS_COMPONENT_RESET_BTN }}</a>
    </div>
    <div class="mt-auto">
      <div class="divider-box"></div>
      <div class="wrapper-btn-confirm">
        <button type="button" class="btn btn-primary pl-2 pr-2"
                [disabled]="stateDescription?.isPointsPositioningRunning"
                (click)="save()">
          {{ labels.MECHANICAL_AXIS_COMPONENT_CONFIRM_BTN }}
        </button>
      </div>
    </div>
  </div>
</div>
