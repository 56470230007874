import { Component } from '@angular/core';
import { LanguageService } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
* Component of the MNSA table of PDF print
*/
@Component({
	selector: 'prt-mnsa-table',
	templateUrl: './prt-mnsa-table.component.html'
})
export class PrtMnsaTableComponent extends BaseComponent {

	/**
	 * MNSA value.
	 */
	mnsa: number;
	/**
	 * Correction MNSA value.
	 */
	desideredMNSA: number;
	/**
	 * Difference between MNSA and desired value.
	 */
	difference: number;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
		this.mnsa = this.canvasSrv.defAPMeasures.def_ap_MNSA;
		this.desideredMNSA = this.eocMeasures.eoc_ap_MNSA;
		this.difference = this.mnsa - this.desideredMNSA;
	}

}
