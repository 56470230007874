import { BoneTypeEnum, FormatUtilsBase, ViewType } from "@ortho-next/nextray-core";
import { BoneLengthSign, OverAngulationSign, OverTranslSign } from "../Models/Enums";
import { Consts } from "./Consts";

/**
 * Contains functions to format data.
 */
export class FormatUtils extends FormatUtilsBase {

  /**
   * 
   */
  public static angDefSign(value: number, boneType: BoneTypeEnum, viewType: ViewType): string {
    const index = value >= 0 ? 0 : 1;
    return Consts.signLabels[boneType][ViewType[viewType]].rotation[index];
  }

  /**
  * 
  */
  public static translSign(value: number, boneType: BoneTypeEnum, viewType: ViewType): string {
    const index = value <= 0 ? 0 : 1;
    return Consts.signLabels[boneType][ViewType[viewType]].translation[index];
  }

  /**
  * 
  */
   public static axTranslSign(value: number, boneType: BoneTypeEnum): string {
    const index = value <= 0 ? 0 : 1;
    return Consts.signLabels[boneType].axial.translation[index];
  }

  /**
   * 
   */
  public static overAngSign(value: number): string {
    const index = value <= 0 ? -1 : 1;
    return OverAngulationSign[index];
  }

  /**
  * 
  */
  public static overTranslSign(value: number): string {
    const index = value <= 0 ? -1 : 1;
    return OverTranslSign[index];
  }

  /**
  * 
  */
   public static boneLengthSign(value: number): string {
    const index = value <= 0 ? -1 : 1;
    return BoneLengthSign[index];
  }

}
