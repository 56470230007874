<prt-template [isDemo]="isDemo" [today]="today" [plan]="currentCase">
	<div class="row">
		<div class="col-4">
			<div class="wrapper-print-tab">
				<h4>{{labels.DRAWER_COMPONENT_TITLE_DEFORMITY}}</h4>
			</div>

			<prt-aa-def-tables *ngIf="isAnatomical; else mechanical"></prt-aa-def-tables>
			<ng-template #mechanical>
				<prt-ma-ap-table *ngIf="hasAP"></prt-ma-ap-table>
				<prt-ma-lt-table *ngIf="hasLT"></prt-ma-lt-table>
			</ng-template>

		</div>
		<div class="col-8">
			<div class="wrapper-print-tab">
				<h4>{{labels.PRINT_XRAY_IMAGE_TITLE}}</h4>
			</div>
			<div class="wrapper-canvas">
				<img [src]="printResult?.deformity" alt="deformity image" width="850" height="750" />
			</div>
		</div>
	</div>
</prt-template>