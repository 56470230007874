import { CaseModel, StateTransitionData, ViewType } from "@ortho-next/nextray-core";
import { Context } from "./Context";
import { Actions, State, StateTypes, WorkflowRunning } from "./State";

/**
 * State indicating that both views have been calibrated.
 */
export class Calibrated extends State {
	public stateDescription = {
		isWorkflowRunning: WorkflowRunning.none,
		isPointsPositioningRunning: false
	};

	constructor(context: Context, caseModel: CaseModel, restore?: object) {
		super(context, caseModel, StateTypes.calibrated, restore);
	}

	public initState(): void {
		if (this._restore) {
			if (this._caseModel.imgAP) {
				this.dispatchToMain("setView", ViewType.AP);
			} else {
				this.dispatchToMain("setView", ViewType.LT);
			}
		}
	}

	protected handleChangeStateEvent<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData {
		switch (event) {
			case "startCrop":
				this.stateDescription.isWorkflowRunning = WorkflowRunning.crop;
				this.dispatchToMain(event, args);
				return this;
			case "startRotate":
				this.stateDescription.isWorkflowRunning = WorkflowRunning.rotate;
				this.dispatchToMain(event, args);
				return this;
			case "confirmCrop":
			case "confirmRotate":
				this.stateDescription.isWorkflowRunning = WorkflowRunning.none;
				this.dispatchToMain(event, args);
				return this;
			case "switchToDeformity":
				this.dispatchToMain(event, args);
				return { type: StateTypes.deformityAnalysis, data: { caseModel: this._caseModel } };
		}
	}

}
