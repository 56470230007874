<!-- Magnifier -->
<div class="magnifier-window" cdkDrag cdkDragBoundary=".content">

	<div class="magnifier-header" cdkDragHandle>
		<h4>{{ labels.MAGNIFIER_COMPONENT_TITLE }}</h4>
		<i class="material-icons">open_with</i>
	</div>

	<div class="magnifier-content">

		<div id="magnifier-img" class="magnifier-img"></div>

		<i class="material-icons md-18">gps_fixed</i>

	</div>

</div>