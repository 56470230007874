import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { StateTypes } from '../../../nextray/States/State';
import { CanvasService, PrinterService, UserService } from '../../services';


/**
* Main component of OR Plan PDF print.
*/
@Component({
	selector: 'app-or-plan-print',
	templateUrl: './prt-or-plan.component.html'
})
export class PrintOrPlanComponent implements OnInit, AfterViewInit {
	/**
	 * Today's date time.
	 */
	today: number = Date.now();
	/**
	 * Flag indicating whether the page is available for printing.
	 */
	hasPageOne: boolean;
	/**
	 * Flag indicating whether the page is available for printing.
	 */
	hasPageTwo: boolean;
	/**
	 * Flag indicating whether the page is available for printing.
	 */
	hasPageThree: boolean;

	private _stateType: StateTypes;
	private _isPltDemo: boolean;
	private _isJPSDemo: boolean;

	/**
	 * Watermark Demo
	 */
	get isDemo(): boolean {
		return this._stateType === StateTypes.templating ? this._isJPSDemo : this._isPltDemo;
	}

	constructor(
		private prtSrv: PrinterService,
		private canvasSrv: CanvasService,
		private usrSrv: UserService,
		private element: ElementRef
	) { }

	ngOnInit(): void {
		this.usrSrv.isPlatformDemo().subscribe(res => this._isPltDemo = res);
		this.usrSrv.isJPSDemoProduct().subscribe(res => this._isJPSDemo = res);
		this._stateType = this.canvasSrv.stateType;
		this.hasPageOne = this._stateType >= StateTypes.deformityAnalysis;
		this.hasPageTwo = this.canvasSrv.isEocCut;
		this.hasPageThree = this._stateType === StateTypes.templating;
	}

	ngAfterViewInit(): void {
		const elem = this.element.nativeElement;
		this.prtSrv.onORPlanDataReady(elem);
	}

}
