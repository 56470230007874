<div class="page-print">
	<div *ngIf="isDemo"
		style="position:absolute; z-index:9999; height:952px; width:1348px; display: flex; align-items: center; justify-content: center">
		<p
			style="color:rgba(211, 211, 211, 0.6);font-size: 100px;transform: rotate(330deg); -webkit-transform:rotate(330deg);">
			{{labels.WATERMARK_DEMO}}
		</p>
	</div>
	<div class="menu-print">
		<div class="voice-menu">
			<span><strong>{{labels.PRINT_BOM_PATIENT_ID}}</strong> {{currentCase?.patient.number}}</span>
			<span><strong>{{labels.PRINT_BOM_PLAN_ID}}</strong> {{currentCase?.number}}</span>
			<span><strong>{{labels.PRINT_BOM_PLAN_SIDE}}</strong> {{currentCase?.side | anatomicalSideLabel}}</span>
			<span><strong>{{labels.PRINT_BOM_PLAN_BONE_TYPE}}</strong> {{currentCase?.boneType | boneTypeLabel}}</span>
			<span><strong><span style="font-size: 13pt">{{labels.PRINT_BOM_MAIN_TITLE}}</span>{{ today | date:'medium'
					}}</strong></span>
		</div>
		<span class="logo-text">{{labels.APP_LOGO_TEXT}}</span>
	</div>

	<div class="position-absolute text-center text-danger font-weight-bold" style="width: 1348px;"
		*ngIf="isJPSWarningVisible">
		{{ labels.PRINT_BOM_JPS_WARNING }}
	</div>

	<div class="wrapper-content">
		<div class="row">
			<div class="col-6">
				<list-material-table></list-material-table>
				<plat-instrument-consum-tables [isJPSExtension]="isJPS_Ext"></plat-instrument-consum-tables>
				<plat-instrument-drill-tables *ngIf="isJPS_Ext"></plat-instrument-drill-tables>
			</div>
			<div class="col-6">
				<plat-instrument-gen-table [isJPSExtension]="isJPS_Ext"></plat-instrument-gen-table>
				<plat-instrument-tray-tables></plat-instrument-tray-tables>
			</div>
		</div>
		<div class="row" style="justify-content: flex-end;">{{ labels.PRINT_BOTTOM_RIGHT_PAGE_TEXT }}</div>
	</div>
</div>