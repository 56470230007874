import { Component, Input } from '@angular/core';
import { LanguageService } from '../../../core';
import { BaseComponent } from '../../../shared';


/**
* Component of table of the generic instrument tray information
*/
@Component({
	selector: 'plat-instrument-gen-table',
	templateUrl: './plat-instrument-gen-table.component.html',
})
export class PlatInstrumentGenTableComponent extends BaseComponent {

	@Input() isJPSExtension: boolean;

	constructor(private langSrv: LanguageService) {
		super(langSrv);
	}

}
