<!-- Tabs -->
<div class="tab-views" *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.none">
  <span class="tab-view" [ngClass]="{'active': activeViewState == activeViewStateEnum.AP }" id="setViewAP"
    (click)="setAPView()"
    *ngIf="hasAP">{{ isForeFoot || isHindFoot ? labels.CANVAS_TABSET_COMPONENT_DORS_LABEL : labels.CANVAS_TABSET_COMPONENT_AP_LABEL }}</span>
  <span class="tab-view" [ngClass]="{'active': activeViewState == activeViewStateEnum.LT }" id="setViewLT"
    (click)="setLTView()" *ngIf="hasLT">{{ labels.CANVAS_TABSET_COMPONENT_LT_LABEL }}</span>
  <span class="tab-view" [ngClass]="{'active': activeViewState == activeViewStateEnum.multiple }" id="setViewMultiple"
    (click)="setMTView()" *ngIf="hasAP && hasLT">{{ labels.CANVAS_TABSET_COMPONENT_MULTIPLE_LABEL }}</span>
</div>
