import { AnatomicalSideEnum, BoneTypeEnum, ConvexityData, FullAnatomicalAxis as FullAnatomicalAxisBase, PlanTypeEnum, ReferenceTypeEnum, ViewType } from "@ortho-next/nextray-core";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { BindedModel } from "../../Models/BindedModel";
import { DeformityAnalysisType } from "../../Models/Enums";
import { PrintStateTypes, StateTypes } from "../../States/State";
import { AnatomicalAxis } from "./AnatomicalAxis";
import { ApexCalculator } from "./ApexCalculator";
import { ThirdLine } from "./ThirdLine";

export class FullAnatomicalAxis extends FullAnatomicalAxisBase {

	constructor(viewType: ViewType, convexities: ConvexityData, refType: ReferenceTypeEnum, side: AnatomicalSideEnum, boneType: BoneTypeEnum,
		type: PlanTypeEnum, angle: number, translHor: number, translVer: number) {
		super(viewType, convexities, refType, side, boneType, type, angle, translHor, translVer, ApexCalculator, ThirdLine, AnatomicalAxis);

		this.bindProperty('visible', (m: BindedModel) => {
			return m.layer_anatAxis && m.deformityAnalysisType === DeformityAnalysisType.anatomical && m.isAnatomicalAxisInserted;
		}, ['layer_anatAxis', 'deformityAnalysisType', 'isAnatomicalAxisInserted']);

		this.bindProperty('isEnabled', (m: BindedModel) => {
			return !m.readonly && (m.appState === StateTypes.deformityAnalysis || m.printState === PrintStateTypes.deformityAnalysis);
		}, ['readonly', 'appState', 'printState']);
	}

	public translateAllPoints(transl: Vector3): void { //move it to package
		this.moving.translateAllPoints(transl);
		this.reference.translateAllPoints(transl);
		this.apexCalculator.translateAllPoints(transl);
	}
}
