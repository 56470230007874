<!-- Box table -->
<div class="box-table">

	<span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
		{{ labels.MA_AP_RECAP_COMPONENT_TITLE }}
		<div class="fake-material-icon icon-expand expand"></div>
	</span>

	<table aria-label="AP mechanical correction measures" class="table" [ngbCollapse]="isCollapsed">
		<thead>
			<tr>
				<th></th>
				<th>{{ labels.MA_AP_RECAP_COMPONENT_VALUE_HEADER }} <br />
					({{ (isLeft ? sideEnum.Left : sideEnum.Right) | anatomicalSideLabel }})</th>
				<th>{{ labels.MA_AP_RECAP_COMPONENT_TARGET_HEADER }}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngIf="isMAD" [ngClass]="{'row-selected': !isValidMAD }">
				<td>{{ labels.MA_AP_RECAP_COMPONENT_MAD }} <br /> {{ rangeMAD }}</td>
				<td>{{ eocMeasures.eoc_ap_MAD }}</td>
				<td></td>
			</tr>
			<tr *ngIf="isMNSA" [ngClass]="{'row-selected': !isValidMNSA }">
				<td>{{ labels.MA_AP_RECAP_COMPONENT_MNSA }} <br /> {{ rangeMNSA }}</td>
				<td>{{ eocMeasures.eoc_ap_MNSA }}</td>
				<td></td>
			</tr>
			<tr *ngIf="isLPFA" [ngClass]="{'row-selected': !isValidLPFA }">
				<td>{{ labels.MA_AP_RECAP_COMPONENT_LPFA }} <br /> {{ rangeLPFA }}</td>
				<td>{{ eocMeasures.eoc_ap_LPFA }}</td>
				<td>{{ def_standardAngles_LPFA }}</td>
			</tr>
			<tr *ngIf="isMPFA" [ngClass]="{'row-selected': !isValidMPFA }">
				<td>{{ labels.MA_AP_RECAP_COMPONENT_MPFA }} <br /> {{ rangeMPFA }}</td>
				<td>{{ eocMeasures.eoc_ap_MPFA }}</td>
				<td></td>
			</tr>
			<tr *ngIf="isMLDFA" [ngClass]="{'row-selected': !isValidMLDFA }">
				<td>{{ labels.MA_AP_RECAP_COMPONENT_MLDFA }} <br /> {{ rangeMLDFA }}</td>
				<td>{{ eocMeasures.eoc_ap_mLDFA }}</td>
				<td>{{ def_standardAngles_mLDFA }}</td>
			</tr>
			<tr *ngIf="isJLCA" [ngClass]="{'row-selected': !isValidJLCA }">
				<td>{{ labels.MA_AP_RECAP_COMPONENT_KNEE_JLCA }} <br /> {{ rangeJLCA }}</td>
				<td>{{ eocMeasures.eoc_ap_JLCA }}</td>
				<td></td>
			</tr>
			<tr *ngIf="isKneeTransl" [ngClass]="{'row-selected': !isValidKneeTransl }">
				<td>{{ labels.MA_AP_RECAP_COMPONENT_KNEE_TRANSL }} <br /> {{ rangeKneeTransl }}</td>
				<td>{{ eocMeasures.eoc_ap_kneeTranslation }}</td>
				<td></td>
			</tr>
			<tr *ngIf="isMPTA" [ngClass]="{'row-selected': !isValidMPTA }">
				<td>{{ labels.MA_AP_RECAP_COMPONENT_MPTA }} <br /> {{ rangeMPTA }}</td>
				<td>{{ eocMeasures.eoc_ap_MPTA }}</td>
				<td>{{ def_standardAngles_MPTA }}</td>
			</tr>
			<tr *ngIf="isLDTA" [ngClass]="{'row-selected': !isValidLDTA }">
				<td>{{ labels.MA_AP_RECAP_COMPONENT_LDTA }} <br /> {{ rangeLDTA }}</td>
				<td>{{ eocMeasures.eoc_ap_LDTA }}</td>
				<td>{{ def_standardAngles_LDTA }}</td>
			</tr>
		</tbody>
	</table>

</div>