<!-- Box table -->
<div class="box-table">
	<span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
		{{ labels.APEX_TABLE_TITLE }}
		<div class="fake-material-icon icon-expand expand"></div>
	</span>
	<table aria-label="Apex measures" class="table" [ngbCollapse]="isCollapsed">
		<thead>
			<tr>
				<th></th>
				<th *ngIf="hasAP">{{ labels.APEX_TABLE_AP_HEADER }}</th>
				<th *ngIf="hasLT">{{ labels.APEX_TABLE_LT_HEADER }}</th>
			</tr>
		</thead>
		<tbody>
			<tr id="apexFemur" *ngIf="isFemur || isLongLeg">
				<td>{{ labels.APEX_TABLE_FEMUR }}</td>
				<td *ngIf="hasAP" [highlightCell]="apFemurMeasure">{{ apFemurMeasure }}</td>
				<td *ngIf="hasLT"></td>
			</tr>
			<tr id="apexTibia" *ngIf="isTibia || isLongLeg">
				<td>{{ labels.APEX_TABLE_TIBIA }}</td>
				<td *ngIf="hasAP" [highlightCell]="apTibiaMeasure">{{ apTibiaMeasure }}</td>
				<td *ngIf="hasLT" [highlightCell]="ltTibiaMeasure">{{ ltTibiaMeasure }}</td>
			</tr>
		</tbody>
	</table>


	<!--AXIAL ROTATION-->
	<form [formGroup]="axialRotForm">
		<table class="table" aria-label="Axial rotation measures" [ngbCollapse]="isCollapsed">
			<thead>
				<tr>
					<th colspan="2">{{ labels.APEX_TABLE_AXIAL_ROTATION_TITLE }}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngIf="isFemur || isLongLeg">
					<td>{{ labels.APEX_TABLE_FEMUR }}</td>
					<td>
						<span>{{labels.INS_CLINIC_ASSESS_VAL}}</span>
						<input type="text" maxlength="2" name="axialRotFemur" formControlName="axialRotFemur"
							[readonly]="stateType !== stateTypeEnum.deformityAnalysis" (keyup.enter)="updateAxialRotFemur()"
							(blur)="updateAxialRotFemur()" [ngClass]="{'is-invalid': axialRotForm.controls.axialRotFemur.invalid }" />
						<div class="form-group pb-0">
							<div class="custom-control custom-radio custom-control-inline" style="margin-right: 2rem;">
								<input type="radio" id="axialRotFemur_external" name="axialRotSignFemur" class="custom-control-input"
									formControlName="axialRotSignFemur" (click)="updateAxialRotSignFemur(axialRotEnum.external)"
									[value]="axialRotEnum.external"
									[attr.disabled]="stateType !== stateTypeEnum.deformityAnalysis ? true : null" />
								<label class="custom-control-label"
									for="axialRotFemur_external">{{labels.APEX_TABLE_AXIAL_ROTATION_EXTERNAL}}</label>
							</div>
							<div class="custom-control custom-radio custom-control-inline">
								<input type="radio" id="axialRotFemur_internal" name="axialRotSignFemur" class="custom-control-input"
									formControlName="axialRotSignFemur" (click)="updateAxialRotSignFemur(axialRotEnum.internal)"
									[value]="axialRotEnum.internal"
									[attr.disabled]="stateType !== stateTypeEnum.deformityAnalysis ? true : null" />
								<label class="custom-control-label"
									for="axialRotFemur_internal">{{labels.APEX_TABLE_AXIAL_ROTATION_INTERNAL}}</label>
							</div>
						</div>
					</td>
				</tr>
				<tr id="apexTibia" *ngIf="isTibia || isLongLeg">
					<td>{{ labels.APEX_TABLE_TIBIA }}</td>
					<td>
						<span>{{labels.INS_CLINIC_ASSESS_VAL}}</span>
						<input type="text" maxlength="2" name="axialRotTibia" formControlName="axialRotTibia"
							[readonly]="stateType !== stateTypeEnum.deformityAnalysis" (keyup.enter)="updateAxialRotTibia()"
							(blur)="updateAxialRotTibia()" [ngClass]="{'is-invalid': axialRotForm.controls.axialRotTibia.invalid }" />
						<div class="form-group pb-0">
							<div class="custom-control custom-radio custom-control-inline" style="margin-right: 2rem;">
								<input type="radio" id="axialRotTibia_external" name="axialRotSignTibia" class="custom-control-input"
									formControlName="axialRotSignTibia" (click)="updateAxialRotSignTibia(axialRotEnum.external)"
									[value]="axialRotEnum.external"
									[attr.disabled]="stateType !== stateTypeEnum.deformityAnalysis ? true : null" />
								<label class="custom-control-label"
									for="axialRotTibia_external">{{labels.APEX_TABLE_AXIAL_ROTATION_EXTERNAL}}</label>
							</div>
							<div class="custom-control custom-radio custom-control-inline">
								<input type="radio" id="axialRotTibia_internal" name="axialRotSignTibia" class="custom-control-input"
									formControlName="axialRotSignTibia" (click)="updateAxialRotSignTibia(axialRotEnum.internal)"
									[value]="axialRotEnum.internal"
									[attr.disabled]="stateType !== stateTypeEnum.deformityAnalysis ? true : null" />
								<label class="custom-control-label"
									for="axialRotTibia_internal">{{labels.APEX_TABLE_AXIAL_ROTATION_INTERNAL}}</label>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</form>
</div>