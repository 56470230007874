import { AnatomicalSideEnum } from "@ortho-next/nextray-core";
import { ToolToSave, ToolType } from "@ortho-next/nextray-core/Core/ITool";
import { Save, SaveChild } from "@ortho-next/nextray-core/Core/Save";
import { ViewType } from "@ortho-next/nextray-core/Models/AppModel";
import { DraggablePoint } from "@ortho-next/nextray-core/Tools/Primitive/DraggablePoint";
import { VectorUtils } from "@ortho-next/nextray-core/Utils/VectorUtils";
import { DragMoveEventArgs } from "@ortho-next/three-base/RaycasterHandler/ObjectEvents";
import { Group, Quaternion, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { MeshType, MyConstellationInstance } from "../../../app/core/repositories/models/plate";
import { ScrewList } from "../../../app/core/repositories/models/screw";
import { BOM } from "../../Models/AppModel";
import { BindedModel, bindedModel } from "../../Models/BindedModel";
import { PrintStateTypes, StateTypes } from "../../States/State";
import { Consts } from "../../Utils/Consts";
import { Plate } from "./Plate";
import { ScrewEvents } from "./ScrewManager";

export class PlateManager extends Group implements ToolToSave {
	public toolType = ToolType.plate;
	public BOM: BOM = {};
	@Save() public lengthsToRestore: number[] = [];
	@Save() public anchorsToRestore: Vector3[] = [];
	@SaveChild("position", "rotation") public plate: Plate;
	@Save("position") public rotationPointA: DraggablePoint;
	@Save("position") public rotationPointB: DraggablePoint;
	private _viewType: ViewType;
	private _eps = 30;
	private _startPoint: Vector3;
	private _lastAngleApplied = 0;
	private _isRestored: boolean;

	constructor(viewType: ViewType, side: AnatomicalSideEnum) {
		super();
		this.name = "PlateManager";
		this._viewType = viewType;
		this.position.z = 100;
		this.add(
			this.plate = new Plate(viewType, side),
			this.rotationPointA = new DraggablePoint("rotationPointA", viewType, 0xff0000),
			this.rotationPointB = new DraggablePoint("rotationPointB", viewType, 0xff0000)
		);

		this.bindProperty('isEnabled', (m: BindedModel) => {
			return !m.readonly;
		}, ['readonly']);

		this.bindProperty('visible', (m: BindedModel) => {
			return m.appState === StateTypes.templating && m.isPlateInserted && m.printState !== PrintStateTypes.deformityAnalysis && m.layer_plate;
		}, ['appState', 'isPlateInserted', 'printState', 'layer_plate']);

		this.rotationPointA.bindProperty('visible', (m: BindedModel) => {
			return m.printState === PrintStateTypes.none
		}, ['printState']);

		this.rotationPointB.bindProperty('visible', (m: BindedModel) => {
			return m.printState === PrintStateTypes.none
		}, ['printState']);

		// TODO: use onDragStartEvent to reuse same vector

		this.plate.bindEvent("onPositionComponentChange", () => {
			this.update();
		});

		this.rotationPointA.bindEvent("onDragEnd", () => {
			this._startPoint = undefined;
			this._lastAngleApplied = 0;
		});

		this.rotationPointB.bindEvent("onDragEnd", () => {
			this._startPoint = undefined;
			this._lastAngleApplied = 0;
		});

		this.rotationPointA.bindEvent("onDragMove", (args) => {
			this.handleRotationPoint(args);
		});

		this.rotationPointB.bindEvent("onDragMove", (args) => {
			this.handleRotationPoint(args);
		});
	}

	public loadSTL(fileName: string, meshList: MyConstellationInstance[], screwList: ScrewList, isTibiaDistal: boolean, onLoad: () => void): void {
		this.plate.loadSTL(fileName, meshList, screwList, isTibiaDistal, () => {
			this.update();
			this.BOM.screwLockingList = [];
			this.BOM.screwNoLockingList = [];
			this._isRestored && (this.lengthsToRestore = []);
			this._isRestored && (this.anchorsToRestore = []);
			this._isRestored = true;

			if (this._viewType === ViewType.AP) {
				for (let i = 0; i < this.plate.children.length; i++) {
					const screwManager = this.plate.children[i];
					const screwType = screwManager.screwType;

					screwManager.addEventListener(ScrewEvents.updated, () => {
						if (screwType === MeshType.nonlocking || screwType === MeshType.screw) {
							this.BOM[screwType == MeshType.nonlocking ? "screwNoLockingList" : "screwLockingList"][screwManager.indexBOM] = screwManager.screw.length;
						}
						this.lengthsToRestore[i] = screwManager.screw.length;
					});

					if (this.lengthsToRestore[i]) {
						screwManager.screw.length = this.lengthsToRestore[i];
					}

					screwManager.update();

					if (this.anchorsToRestore[i]) {
						screwManager.label.anchor = this.anchorsToRestore[i];
					}

					screwManager.label.bindEvent("onPositionComponentChange", () => {
						this.anchorsToRestore[i] = screwManager.label.anchor;
					});

				}
			}
			onLoad();
			this.update();
		});
	}

	public changeScrewList(screwList: ScrewList): void {
		this.plate.changeScrewList(screwList);
	}

	private update(): void {
		const translationVector = Consts.verDir.clone().setLength(this._eps * (this._viewType == ViewType.AP ? bindedModel.scaleFactorAP : bindedModel.scaleFactorLT)); //TODO cache
		this.rotationPointA.position.copy(this.plate.position.clone().add(translationVector).setZ(5));
		this.rotationPointB.position.copy(this.plate.position.clone().sub(translationVector).setZ(5));
	}

	private handleRotationPoint(args: DragMoveEventArgs): void {
		args.preventDefault = true;
		if (this._startPoint) {
			const angle = VectorUtils.linesAngle(args.position, this._startPoint, this.plate.position);
			this.plate.applyQuaternion(new Quaternion().setFromAxisAngle(Consts.planeNormal, this._lastAngleApplied - angle));
			this._lastAngleApplied = angle;
		} else {
			this._startPoint = args.position.clone();
		}
	}

	public reset(): void {
		this.BOM.screwLockingList = [];
		this.BOM.screwNoLockingList = [];
		this.plate.deleteScrewsAndGeometry();
	}
}
