import { AnatomicalSideEnum } from "@ortho-next/nextray-core";
import { ToolEvents } from "@ortho-next/nextray-core/Core/ITool";
import { Save } from "@ortho-next/nextray-core/Core/Save";
import { ViewType } from "@ortho-next/nextray-core/Models/AppModel";
import { SelectedApexMech } from "@ortho-next/nextray-core/States/State";
import { VectorUtils } from "@ortho-next/nextray-core/Utils/VectorUtils";
import { BufferGeometry, Euler, Group, Mesh, MeshPhongMaterial, Quaternion, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { AMFLoader } from "@ortho-next/three-base/three.js/examples/jsm/loaders/AMFLoader";
import { MeshType, MyConstellationInstance } from "../../../app/core/repositories/models/plate";
import { ScrewList } from "../../../app/core/repositories/models/screw";
import { environment } from "../../../environments/environment";
import { Consts } from "../../Utils/Consts";
import { MechanicalAxisAP } from "../DeformityAnalyzer/FullAnalyzerAP";
import { MechanicalAxisLT } from "../DeformityAnalyzer/FullAnalyzerLT";
import { ReferencePoint } from "../ReferencePoint";
import { ScrewManager } from "./ScrewManager";

export interface PlateState {
	geometry?: BufferGeometry;
	children?: ScrewManager[];
	meshList?: MyConstellationInstance[];
	screwList?: ScrewList;
	rotation?: Euler;
	position?: Vector3;
}

export class Plate extends Mesh {
	public geometry: BufferGeometry; // override
	public static material = new MeshPhongMaterial({ color: 0x888888, flatShading: true }); // override
	public children: ScrewManager[];
	@Save() private _axisPoint1: Vector3;
	@Save() private _axisPoint2: Vector3;
	@Save() private _axisPerp: Vector3;
	@Save() private _axisDir: Vector3;
	@Save() private _perpTranslation: Vector3;
	@Save() private _referencePoint: Vector3;
	private _viewType: ViewType;
	private _side: AnatomicalSideEnum;
	private _meshList: MyConstellationInstance[];
	private _screwList: ScrewList;
	private _stateToRestore: PlateState = {};
	private _isRestored: boolean;

	public onAfterRestore = () => {
		this.dispatchEvent({ type: ToolEvents.onAfterRestore });
	}

	public onBeforeRestore = () => {
		this._isRestored = true;
	}

	constructor(viewType: ViewType, side: AnatomicalSideEnum) {
		super(Consts.emptyGeometry, Plate.material);
		this._viewType = viewType;
		this._side = side;
		this.name = "Plate";
		this.isDraggable = true;

		this.bindEvent("onAfterDragMove", () => {
			this.update();
		});
	}

	public get wireOrigin(): Vector3 {
		const screwManagerWire = this.children.find(x => x.screwType == MeshType.wire);
		if (screwManagerWire) {
			const wireOrigin = screwManagerWire.screw.origin.clone();
			if (wireOrigin) {
				return this.localToWorld(wireOrigin);
			}
		}
	}

	public get distanceToReferencePoint(): number {
		const intersectionOsteotomyOnAxis = VectorUtils.lines2DIntersection(this._axisPoint1, this._axisPoint2, this.position, this.position.clone().add(this._axisPerp)); //todo project
		const sign = VectorUtils.computeSign(this.position, this._referencePoint, this._axisDir);
		return this._referencePoint.distanceTo(intersectionOsteotomyOnAxis) * sign;
	}

	public set distanceToReferencePoint(value: number) {
		const newCenter = this._referencePoint.clone().add(this._axisDir.clone().setLength(value)).add(this._perpTranslation);
		this.position.copy(newCenter);
	}

	public setPointsForSyncFromMechanicalAxis(mechanicalAxis: MechanicalAxisAP | MechanicalAxisLT, selectedApex: SelectedApexMech, referencePoint: ReferencePoint) {
		if (selectedApex === SelectedApexMech.femurProximal || selectedApex === SelectedApexMech.femurDistal) {
			if (mechanicalAxis instanceof MechanicalAxisAP) {
				this.setPointsForSync(mechanicalAxis.femur.mechanical.CE.position, mechanicalAxis.femur.mechanical.FH.position);
			} else {
				this.setPointsForSync(mechanicalAxis.femur.TE.position, mechanicalAxis.femur.FH.position);
			}
		} else {
			if (mechanicalAxis instanceof MechanicalAxisAP) {
				this.setPointsForSync(mechanicalAxis.tibia.CA.position, mechanicalAxis.tibia.CP.position);
			} else {
				this.setPointsForSync(mechanicalAxis.tibia.MA.position, mechanicalAxis.tibia.FP.position);
			}
		}
		this._referencePoint = VectorUtils.projectOnVector(referencePoint.position, this._axisPoint1, this._axisPoint2);
	}

	private setPointsForSync(p1: Vector3, p2: Vector3): void {
		this._axisPoint1 = p1.clone();
		this._axisPoint2 = p2.clone();
		this._axisDir = p1.clone().sub(p2).normalize();
		this._axisPerp = VectorUtils.getPerpendicular(this._axisDir);
		this.update();
	}

	public horizontalFlip() {
		this.applyQuaternion(new Quaternion().setFromAxisAngle(Consts.verDir, Math.PI));
	}

	public update(): void {
		const intersectionOnAxis = VectorUtils.projectOnVector(this.position, this._axisPoint1, this._axisPoint2); // TODO: project
		this._perpTranslation = this.position.clone().sub(intersectionOnAxis);
	}

	public loadSTL(fileName: string, meshList: MyConstellationInstance[], screwList: ScrewList, isTibiaDistal: boolean, onEndCallback: () => void): void {
		this._meshList = meshList;
		this._screwList = screwList;
		this.deleteScrewsAndGeometry();
		!this._isRestored && this.updateRotation(isTibiaDistal);
		this._isRestored = false;
		new AMFLoader().load(`${environment.cdnUrl}/templates/${fileName}`, (group: Group) => { // make AMFLoader static e evitare di farlo caricare due volte
			this.assignPlateGeometry(group);
			this.createScrews(group);
			onEndCallback();
		});
	}

	public changeScrewList(screwList: ScrewList): void {
		this._screwList = screwList;
		if (this._viewType === ViewType.AP) {
			const lockLengths = this._screwList.screwLockingList.map(x => x.length).sort((a, b) => a - b); //ascendent sorted
			const noLockLengths = this._screwList.screwNoLockingList.map(x => x.length).sort((a, b) => a - b); //ascendent sorted
			for (const child of this.children) {
				if (child instanceof ScrewManager) {
					const meshType = child.screwType;
					let lengths;
					if (meshType == MeshType.screw) {
						lengths = lockLengths;
					} else if (meshType == MeshType.nonlocking) {
						lengths = noLockLengths;
					}
					child.changeScrewList(lengths);
				}
			}
		}
	}

	private updateRotation(isTibiaDistal: boolean): void {
		this.position.set(0, 0, 0);
		this.rotation.set(Math.PI / -2, 0, Math.PI);
		this._viewType === ViewType.AP && this.rotateZ(Math.PI / (this._side === AnatomicalSideEnum.Left ? -2 : 2));
		isTibiaDistal && this.rotateY(Math.PI);
	}

	private assignPlateGeometry(group: Group): void {
		const countArrays = group.children.map(x => ((x.children[0] as Mesh).geometry as BufferGeometry).attributes.position.count);
		const plateGroup = group.children[countArrays.indexOf(Math.max(...countArrays))];
		this.geometry = (plateGroup.children[0] as Mesh).geometry as BufferGeometry;
		((plateGroup.children[0] as Mesh).material as MeshPhongMaterial).dispose();
		group.remove(plateGroup);
	}

	private createScrews(group: Group): void {
		if (this._viewType === ViewType.AP) {
			let iLock = 0;
			let iNoLock = 0;
			const lockLengths = this._screwList.screwLockingList.map(x => x.length).sort((a, b) => a - b); //ascendent sorted
			const noLockLengths = this._screwList.screwNoLockingList.map(x => x.length).sort((a, b) => a - b); //ascendent sorted
			for (const child of group.children) {
				const i = this._meshList.findIndex(x => x.objId == child.name);
				const meshType = this._meshList[i].type;
				let index: number;
				let lengths: number[];
				if (meshType == MeshType.screw) {
					index = iLock++;
					lengths = lockLengths;
				} else if (meshType == MeshType.nonlocking) {
					index = iNoLock++
					lengths = noLockLengths;
				}
				this.add(new ScrewManager(this._viewType, lengths, child, meshType, index));
			}
		}
	}

	public deleteScrewsAndGeometry(): void {
		if (this.geometry !== this._stateToRestore.geometry) {
			this.geometry.dispose();
			this.geometry = Consts.emptyGeometry;
			for (let i = this.children.length - 1; i >= 0; i--) {
				const screw = this.children[i];
				screw.dispose();
				screw.screw.dispose(); //override
				screw.label.dispose();
				screw.screw.geometry.dispose();
				screw.screw.material.dispose();
				screw.label.geometry.dispose();
				screw.label.material.dispose();
			}
		} else {
			this.children = [];
		}
	}

	public createStateToRestore(): void {
		this._stateToRestore = {
			geometry: this.geometry,
			children: [...this.children],
			meshList: this._meshList,
			screwList: this._screwList,
			rotation: this.rotation.clone(),
			position: this.position.clone()
		};
	}

	public confirmStateRestore(): void {
		this.deleteScrewsAndGeometry();
		if (this._stateToRestore.geometry) {
			this.geometry = this._stateToRestore.geometry;
			this.children = this._stateToRestore.children;
			this._meshList = this._stateToRestore.meshList;
			this._screwList = this._stateToRestore.screwList;
			this.setRotationFromEuler(this._stateToRestore.rotation);
			this.position.copy(this._stateToRestore.position);
		}
		this._stateToRestore = {};
	}

	public cancelStateRestore(): void {
		if (this._stateToRestore.geometry && this._stateToRestore.geometry !== this.geometry) {
			this._stateToRestore.geometry.dispose();
			this._stateToRestore.geometry = Consts.emptyGeometry;
			for (let i = this._stateToRestore.children.length - 1; i >= 0; i--) {
				const screw = this._stateToRestore.children[i];
				screw.dispose();
				screw.screw.dispose();
				screw.label.dispose();
				screw.screw.geometry.dispose();
				screw.screw.material.dispose();
				screw.label.geometry.dispose();
				screw.label.material.dispose();
			}
		}
		this._stateToRestore = {};
	}

}
