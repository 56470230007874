import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared';
import { ListMaterialTableComponent, PlatInstrumentConsumTablesComponent, PlatInstrumentDrillTablesComponent, PlatInstrumentGenTableComponent, PlatInstrumentTrayTablesComponent, PrintBomComponent } from './prt-bom';
import { PageThreeComponent, PrintOrPlanComponent, PrintPageOneComponent, PrintPageTwoComponent, PrtAaCorrTablesComponent, PrtAaDefTablesComponent, PrtApexTableComponent, PrtLldTableComponent, PrtMaApTableComponent, PrtMaLtTableComponent, PrtMnsaTableComponent, PrtRecClinicMeasuresComponent } from './prt-or-plan';
import { PrtTemplateComponent } from './prt-or-plan/prt-template/prt-template.component';


const routes: Routes = [
	{
		path: 'or-plan',
		outlet: 'print',
		component: PrintOrPlanComponent,
	},
	{
		path: 'bom',
		outlet: 'print',
		component: PrintBomComponent,
	},
];

@NgModule({
	declarations: [
		PrintOrPlanComponent,
		PrintBomComponent,
		PrtMaApTableComponent,
		PrtMaLtTableComponent,
		PrintPageOneComponent,
		PrintPageTwoComponent,
		PrtLldTableComponent,
		PrtApexTableComponent,
		PlatInstrumentGenTableComponent,
		PlatInstrumentTrayTablesComponent,
		ListMaterialTableComponent,
		PlatInstrumentConsumTablesComponent,
		PageThreeComponent,
		PrtMnsaTableComponent,
		PrtRecClinicMeasuresComponent,
		PlatInstrumentDrillTablesComponent,
		PrtAaDefTablesComponent,
		PrtAaCorrTablesComponent,
  PrtTemplateComponent
	],
	imports: [
		CommonModule,
		RouterModule.forRoot(routes),
		SharedModule
	]
})
export class PrintModule { }
