<div class="wrapper-print-tab">
	<h4 [style.color]="titleColor" [style.background-color]="titleBackColor">{{title}}</h4>
</div>
<table aria-label="instruments" *ngIf="is3mm" class="wrapper-print-table">
	<thead>
		<tr>
			<th>{{ labels.PART_NO }}</th>
			<th>{{ labels.DESC_INSTR }}</th>
			<th>{{ labels.QUANTITY }}</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>154200
			</td>
			<td>SS Drill Guide Locking D2.3mm
			</td>
			<td>4</td>
		</tr>
		<tr>
			<td>154211
			</td>
			<td>SS Drill Guide Non Locking D2.3mm
			</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154320
			</td>
			<td>T8 Retentive Screwdriver Quick Connect
			</td>
			<td>2</td>
		</tr>
		<tr>
			<td>154305
			</td>
			<td>AL Angled Wire Guide 3.0mm Plate
			</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154995
			</td>
			<td>JPS Modula Base 3.0mm Instrument
			</td>
			<td>1</td>
		</tr>
	</tbody>
</table>

<table aria-label="instruments" *ngIf="is3dot5mm" class="wrapper-print-table">
	<thead>
		<tr>
			<th>{{ labels.PART_NO }}</th>
			<th>{{ labels.DESC_INSTR }}</th>
			<th>{{ labels.QUANTITY }}</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>154201
			</td>
			<td>SS Drill Guide Locking D2.8mm
			</td>
			<td>4</td>
		</tr>
		<tr>
			<td>154212
			</td>
			<td>SS Drill Guide Non Locking D2.8mm
			</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154321
			</td>
			<td>SS T10 Retentive Screwdriver Quick Connect
			</td>
			<td>2</td>
		</tr>
		<tr>
			<td>154033
			</td>
			<td>SS Medialization Guide Body 3.5mm
			</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154031
			</td>
			<td>SS Medialization Guide Mechanism
			</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154304
			</td>
			<td>AL Angled Wire Guide 3.5mm and 5.0mm Plates
			</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154215
			</td>
			<td>SS Aiming Block - 3.5mm Plate
			</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154996
			</td>
			<td>JPS Modular Base 3.5mm Instrument
			</td>
			<td>1</td>
		</tr>
	</tbody>
</table>

<table aria-label="instruments" *ngIf="is5mm" class="wrapper-print-table">
	<thead>
		<tr>
			<th>{{ labels.PART_NO }}</th>
			<th>{{ labels.DESC_INSTR }}</th>
			<th>{{ labels.QUANTITY }}</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>154202
			</td>
			<td>SS Drill Guide Locking D4.3mm
			</td>
			<td>4</td>
		</tr>
		<tr>
			<td>154203
			</td>
			<td>SS Reduction Sleeve
			</td>
			<td>4</td>
		</tr>
		<tr>
			<td>154213
			</td>
			<td>SS Drill Guide Non Locking D3.4mm
			</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154322
			</td>
			<td>SS T15 Retentive Screwdriver Quick Connect
			</td>
			<td>2</td>
		</tr>
		<tr>
			<td>154034
			</td>
			<td>SS Medialization Guide Body 5.0mm
			</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154031
			</td>
			<td>SS Medialization Guide Mechanism
			</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154304
			</td>
			<td>AL Angled Wire Guide 3.5mm and 5.0mm Plates
			</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154214
			</td>
			<td>SS Aiming Block - 5.0mm Plate
			</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154997
			</td>
			<td>JPS Modular Base 5.0mm Instrument
			</td>
			<td>1</td>
		</tr>
	</tbody>
</table>