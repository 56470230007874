<table aria-label="LLD measures" class="wrapper-print-table">
	<thead>
		<tr>
			<th></th>
			<th>{{ labels.LLD_TABLE_LENGTH_HEADER }} <br />
				({{ (isLeft ? sideEnum.Left : sideEnum.Right) | anatomicalSideLabel }})</th>
			<th>{{ labels.LLD_TABLE_CL_LENGTH_HEADER }} <br />
				({{ (isLeft ? sideEnum.Right : sideEnum.Left) | anatomicalSideLabel }})</th>
			<th>{{ labels.LLD_TABLE_LLD_HEADER }}</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngIf="isLongLeg">
			<td>{{ labels.PRINT_LONGLEG_VAL }}</td>
			<td>{{ defAPMeasures?.def_ap_fullLength }}</td>
			<td>{{ defCLMeasures?.def_ap_fullLength_ctrlateral }}</td>
			<td>{{ longlegLLD }}</td>
		</tr>
		<tr *ngIf="isLongLeg || isFemur">
			<td>{{ labels.PRINT_FEMUR_VAL }}</td>
			<td>{{ defAPMeasures?.def_ap_femurLength }}</td>
			<td>{{ defCLMeasures?.def_ap_femurLength_ctrlateral }}</td>
			<td>{{ femurLLD }}</td>
		</tr>
		<tr *ngIf="isLongLeg || isTibia">
			<td>{{ labels.PRINT_TIBIA_VAL }}</td>
			<td>{{ defAPMeasures?.def_ap_tibiaLength }}</td>
			<td>{{ defCLMeasures?.def_ap_tibiaLength_ctrlateral }}</td>
			<td>{{ tibiaLLD }}</td>
		</tr>
		<tr *ngIf="isLongLeg">
			<td>{{ labels.PRINT_FEMUR_TIBIA_VAL }}</td>
			<td>{{ trueLength }}</td>
			<td>{{ trueLengthCL }}</td>
			<td>{{ trueLengthLLD }}</td>
		</tr>
	</tbody>
</table>