<div class="wrapper-print-tab">
	<h4 style="background-color: lightgray;">{{labels.JPS_GEN_INST_TRAY}}</h4>
</div>
<table aria-label="instruments" class="wrapper-print-table">
	<thead>
		<tr>
			<th>{{ labels.PART_NO }}</th>
			<th>{{ labels.DESC_INSTR }}</th>
			<th>{{ labels.QUANTITY }}</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>{{ isJPSExtension ? '154191' : '002-A-00009' }}</td>
			<td>{{ isJPSExtension ? 'JPS DEPTH GAUGE WITH HOOK L80MM' : 'Depth Gauge With Hook' }}</td>
			<td>1</td>
		</tr>
		<tr>
			<td>DH0455CE</td>
			<td>Micro Ratcheting Handle with AO Connector Cannulated</td>
			<td>1</td>
		</tr>
		<tr>
			<td>{{ isJPSExtension ? '154190' : '154276' }}</td>
			<td>{{ isJPSExtension ? 'JPS DOUBLE MEASURING DEVICE' : 'AL Double Measuring Device' }}</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154300</td>
			<td>SS Triangular Positioning Plate 60 Degrees 100 Degrees 20 Degrees</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154301</td>
			<td>SS Triangular Positioning Plate 70 Degrees 80 Degrees 30 Degrees</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154302</td>
			<td>SS Triangular Positioning Plate 90 Degrees 50 Degrees 40 Degrees</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154306</td>
			<td>SS Osteotomy Gauge</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154666</td>
			<td>SS JPS Template</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154780</td>
			<td>SS Small Bone Clamp</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154781</td>
			<td>SS Large Bone Clamp</td>
			<td>1</td>
		</tr>
		<tr>
			<td>{{ isJPSExtension ? '154999' : '154994' }}</td>
			<td>{{ isJPSExtension ? 'JPS MODULAR GENERAL INSTRUMENT BASE' : 'JPS Modular Base General Instrument' }}</td>
			<td>1</td>
		</tr>
		<tr>
			<td>154998</td>
			<td>JPS Modular Tray Lid</td>
			<td>1</td>
		</tr>
	</tbody>
</table>