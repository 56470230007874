import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ViewType } from '@ortho-next/nextray-core';
import { BoneLengthSign } from '../../../../nextray/Models/Enums';
import { SetAnatEoCMeasure } from '../../../../nextray/States/State';
import { LanguageService } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
 * Table for bone length measures in correction anatomical analysis.
 */
@Component({
	selector: 'corr-bone-length-table',
	templateUrl: './corr-bone-length-table.component.html'
})
export class CorrBoneLengthTableComponent extends BaseComponent implements OnInit {

	isCollapsed: boolean = false;

	invalidSuggestedBoneLengthMsg: string;
	invalidBoneLengthMsg: string;
	private readonly threeDigitsPattern: RegExp = /^\d$|^[1-9]\d{0,2}$/;
	private readonly MIN_VALUE = 0;
	private readonly MAX_VALUE = 200;

	boneLengthForm: FormGroup;
	boneLengthEnum = BoneLengthSign;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit(): void {
		this.initBoneLengthForm();
		this.initInvalidMsgs();
	}

	private initBoneLengthForm(): void {
		const validators: ValidatorFn[] = [Validators.pattern(this.threeDigitsPattern), Validators.min(this.MIN_VALUE), Validators.max(this.MAX_VALUE)];
		this.boneLengthForm = new FormGroup({
			'boneLengthValue': new FormControl(this.boneLength, validators),
			'boneLengthSign': new FormControl(this.boneLengthSign)
		});
	}

	private initInvalidMsgs(): void {
		const labelSBL: string = this.labels['CORR_BONE_LENGTH_TABLE_COMPONENT_SUGGESTED_BONE_LENGTH_VALIDATION'];
		this.invalidSuggestedBoneLengthMsg = labelSBL?.replace("%1", this.MIN_VALUE.toString())?.replace("%2", this.MAX_VALUE.toString());

		const labelBL: string = this.labels['CORR_BONE_LENGTH_TABLE_COMPONENT_BONE_LENGTH_VALIDATION'];
		this.invalidBoneLengthMsg = labelBL?.replace("%1", this.MIN_VALUE.toString())?.replace("%2", this.MAX_VALUE.toString());
	}

	// SUGGESTED BONE LENGTH

	/**
	 * Check if suggested bone length is valid.
	 */
	get isSuggestedBoneLengthValid(): boolean {
		return this.eocSuggestedBoneLength <= this.MAX_VALUE;
	}

	/**
	 * Check if osteotomy inclination warning is visible.
	 */
	get isOstInclinationWarningVisible(): boolean {
		return this.canvasSrv.boneLengthIsEqualsToSuggested && this.canvasSrv.ostBeyond15Degrees;
	}

	/**
	 * Check if bone length copy button is disabled.
	 */
	get isCopyDisabled(): boolean {
		return this.stateType !== this.stateTypeEnum.EOC || !this.isSuggestedBoneLengthValid || !this.isEocCut;
	}

	/**
	 * Set bone length with suggested value. 
	 */
	copyBoneLength(): void {
		if (!this.isCopyDisabled) {
			this.setBoneLength(this.eocSuggestedBoneLength, BoneLengthSign.long);
		}
	}

	// BONE LENGTH

	/**
	 * Get current bone length.
	 */
	get boneLength(): number {
		return this.eocMeasuresAnat?.eoc_boneLength ?? 0;
	}

	/**
	 * Get current bone length sign.
	 */
	get boneLengthSign(): BoneLengthSign {
		return this.eocMeasuresAnat?.eoc_boneLengthSign ? BoneLengthSign[this.eocMeasuresAnat.eoc_boneLengthSign] : BoneLengthSign.long;
	}

	/**
	 * Check if suggested bone length is valid.
	 */
	get isBoneLengthValid(): boolean {
		return this.boneLengthForm.controls.boneLengthValue.valid;
	}

	/**
	 * Check if bone length form is disabled.
	 */
	get isBoneLengthDisabled(): boolean {
		return this.stateType !== this.stateTypeEnum.EOC || !this.isEocCut;
	}

	/**
	 * Decrement bone length value. 
	 */
	decrementBoneLength(): void {
		if (!this.isBoneLengthDisabled) {
			const val = Number.parseInt(this.boneLengthForm.value.boneLengthValue);
			const sign = this.boneLengthForm.value.boneLengthSign as BoneLengthSign;
			if (!Number.isNaN(val) && val > this.MIN_VALUE) {
				this.setBoneLength(val - 1, sign);
			}
		}
	}

	/**
	 * Increment bone length value. 
	 */
	incrementBoneLength(): void {
		if (!this.isBoneLengthDisabled) {
			const val = Number.parseInt(this.boneLengthForm.value.boneLengthValue);
			const sign = this.boneLengthForm.value.boneLengthSign as BoneLengthSign;
			if (!Number.isNaN(val) && val < this.MAX_VALUE) {
				this.setBoneLength(val + 1, sign);
			}
		}
	}

	/**
	 * Update bone length value. 
	 */
	updateBoneLength(): void {
		if (!this.isBoneLengthDisabled) {
			const val = Number.parseInt(this.boneLengthForm.value.boneLengthValue);
			const sign = this.boneLengthForm.value.boneLengthSign as BoneLengthSign;
			if (!Number.isNaN(val)) {
				this.setBoneLength(val, sign);
			}
		}

	}

	/**
	* Update bone length sign.
	*/
	updateBoneLengthSign(sign: BoneLengthSign): void {
		if (!this.isBoneLengthDisabled) {
			this.setBoneLength(this.boneLength, sign);
		}
	}

	private setBoneLength(val: number, sign: BoneLengthSign): void {
		const data: SetAnatEoCMeasure<BoneLengthSign> = {
			view: ViewType.AP,
			value: val,
			sign: sign
		};
		this.canvasSrv.dispatch('setBoneLength', data);
	}

}
