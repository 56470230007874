<!-- Box table -->
<div class="box-table">
  <span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
    {{ labels.AXIAL_VIEW_TABLE_COMPONENT_TITLE }}
    <div class="fake-material-icon icon-expand expand"></div>
  </span>
  <div [ngbCollapse]="isCollapsed">

    <!--TABLE-->
    <table role="none" class="table">
      <tbody>
        <tr>
          <td>{{ labels.AXIAL_VIEW_TABLE_COMPONENT_ROTATION }}</td>
          <td>
            <form class="d-flex align-items-center" [formGroup]="axialRotForm">
              <input type="text" maxlength="2" name="axialRotValue" formControlName="axialRotValue"
                class="custom-control" (keyup.enter)="updateAxialRotValue()" (blur)="updateAxialRotValue()"
                [readonly]="stateType !== stateTypeEnum.deformityAnalysis"
                [ngClass]="{'is-invalid': axialRotForm.controls.axialRotValue.invalid }" />
              <div class="form-group pb-0 ml-4">
                <div class="custom-control custom-radio">
                  <input type="radio" id="axialRot_external" name="axialRotSign" class="custom-control-input"
                    formControlName="axialRotSign" (click)="updateAxialRotSign(axRotationExternalValue)"
                     [value]="axRotationExternalValue"
                    [attr.disabled]="stateType !== stateTypeEnum.deformityAnalysis ? true : null" />
                  <label class="custom-control-label" for="axialRot_external">{{externalLabel}}</label>
                </div>
                <div class="custom-control custom-radio">
                  <input type="radio" id="axialRot_internal" name="axialRotSign" class="custom-control-input"
                    formControlName="axialRotSign" (click)="updateAxialRotSign(axRotationInternalValue)"
                    [value]="axRotationInternalValue"
                    [attr.disabled]="stateType !== stateTypeEnum.deformityAnalysis ? true : null" />
                  <label class="custom-control-label" for="axialRot_internal">{{internalLabel}}</label>
                </div>
              </div>
            </form>
          </td>
        </tr>
        <tr *ngIf="isDeformity">
          <td>{{ labels.AXIAL_VIEW_TABLE_COMPONENT_TRANSLATION }}</td>
          <td>
            <form class="d-flex align-items-center" [formGroup]="axialTransForm">
              <input type="text" maxlength="3" name="axialTransValue" formControlName="axialTransValue"
                class="custom-control" (keyup.enter)="updateAxialTransValue()" (blur)="updateAxialTransValue()"
                [readonly]="stateType !== stateTypeEnum.deformityAnalysis"
                [ngClass]="{'is-invalid': axialTransForm.controls.axialTransValue.invalid }" />
              <div class="form-group pb-0 ml-4">
                <div class="custom-control custom-radio">
                  <input type="radio" id="axialTrans_short" name="axialTransSign" class="custom-control-input"
                    formControlName="axialTransSign" (click)="updateAxialTransSign(axialTransEnum.short)"
                    [value]="axialTransEnum.short"
                    [attr.disabled]="stateType !== stateTypeEnum.deformityAnalysis ? true : null" />
                  <label class="custom-control-label"
                    for="axialTrans_short">{{labels.AXIAL_VIEW_TABLE_COMPONENT_TRANSLATION_SHORT}}</label>
                </div>
                <div class="custom-control custom-radio">
                  <input type="radio" id="axialTrans_long" name="axialTransSign" class="custom-control-input"
                    formControlName="axialTransSign" (click)="updateAxialTransSign(axialTransEnum.long)"
                    [value]="axialTransEnum.long"
                    [attr.disabled]="stateType !== stateTypeEnum.deformityAnalysis ? true : null" />
                  <label class="custom-control-label"
                    for="axialTrans_long">{{labels.AXIAL_VIEW_TABLE_COMPONENT_TRANSLATION_LONG}}</label>
                </div>
              </div>
            </form>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="invalid-feedback d-block mb-3" *ngIf="!axialRotForm.valid">{{invalidRotMsg}}</div>
		<div class="invalid-feedback d-block mb-3" *ngIf="!axialTransForm?.valid">{{invalidTransMsg}}</div>

  </div>
</div>
