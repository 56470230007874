<!-- AP TABLE -->
<table aria-label="AP correction measures" class="wrapper-print-table">
	<thead>
		<tr>
			<th>
				{{isForeFoot || isHindFoot ? labels.PRINT_AA_CORR_COMPONENT_TITLE_DORS : labels.PRINT_AA_CORR_COMPONENT_TITLE_AP
				}}
				<br />
				({{ (isLeft ? sideEnum.Left : sideEnum.Right) | anatomicalSideLabel }})
			</th>
			<th>{{ labels.PRINT_AA_CORR_COMPONENT_VALUE }}</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>{{ labels.PRINT_AA_CORR_COMPONENT_OVER_ANG }}</td>
			<td>{{ apOverAng }}</td>
		</tr>
		<tr>
			<td>{{ labels.PRINT_AA_CORR_COMPONENT_OVER_TRANSL }}</td>
			<td>{{ apOverTransl }}</td>
		</tr>
	</tbody>
</table>
<!-- LT TABLE -->
<table aria-label="LT correciton measures" class="wrapper-print-table">
	<thead>
		<tr>
			<th>{{ labels.PRINT_AA_CORR_COMPONENT_TITLE_LT }} <br />
				({{ (isLeft ? sideEnum.Left : sideEnum.Right) | anatomicalSideLabel }})</th>
			<th>{{ labels.PRINT_AA_CORR_COMPONENT_VALUE }}</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>{{ labels.PRINT_AA_CORR_COMPONENT_OVER_ANG }}</td>
			<td>{{ ltOverAng }}</td>
		</tr>
		<tr>
			<td>{{ labels.PRINT_AA_CORR_COMPONENT_OVER_TRANSL }}</td>
			<td>{{ ltOverTransl }}</td>
		</tr>
	</tbody>
</table>
<!-- BONE LENGTH TABLE -->
<table aria-label="Bone length measures" class="wrapper-print-table">
	<thead>
		<tr>
			<th>{{ labels.PRINT_AA_CORR_COMPONENT_TITLE_BONE_LENGTH }} <br />
				({{ (isLeft ? sideEnum.Left : sideEnum.Right) | anatomicalSideLabel }})
			</th>
			<th>{{ labels.PRINT_AA_CORR_COMPONENT_VALUE }}</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngIf="isDeformity">
			<td>{{ labels.PRINT_AA_CORR_COMPONENT_SUGGESTED_BONE_LENGTH }}</td>
			<td>{{ eocSuggestedBoneLength }} {{ labels.PRINT_AA_CORR_COMPONENT_LENGTHENING }}</td>
		</tr>
		<tr>
			<td>{{ labels.PRINT_AA_CORR_COMPONENT_BONE_LENGTH }}</td>
			<td>{{ boneLength }} {{ boneLengthSign }}</td>
		</tr>
	</tbody>
</table>