import { AxialRotationEnum, BoneTypeEnum, BridgeActionsBase, BridgeBase, CaseModel, SelectedApexAnat, SelectedApexMech, ViewType } from "@ortho-next/nextray-core";
import { ScrewList } from "../../app/core";
import { PlateOrientationType } from "../Models/AppModel";
import { AxialTranslationEnum, BoneLengthSign, BoneTypeMinEnum, DeformityAnalysisType, OverAngulationSign, OverTranslSign } from "../Models/Enums";
import { PlateModel } from "../Models/PlateModel";
import { CustomStandardAngles, OsteotomyCut, SetAnatEoCMeasure, SetAxialRotation, SetAxialRotationSign, SetStandardAngleParams } from "../States/State";


/**
 * List of events that can be triggered by the angular part to interact with the three.js part.
 */
export interface BridgeActions extends BridgeActionsBase {
	/**
	 * Starts mechanical axis workflow in the active view.
	 */
	"mechanical-axes-btn": never;
	/**
	 * Starts anatomical axis workflow in the active view.
	 */
	"anatomical-axis-btn": never;
	/**
	* Starts contralateral mechanical axis workflow in the active view.
	*/
	"ma-to-ctrlateral": never;
	/**
	 * Sets femur proximal as osteotomy site during insert osteotomy worflow.
	 */
	apexFemurProximal: never;
	/**
	 * Sets femur distal as osteotomy site during insert osteotomy worflow.
	 */
	apexFemurDistal: never;
	/**
	 * Sets tibia proximal as osteotomy site during insert osteotomy worflow.
	 */
	apexTibiaProximal: never;
	/**
	 * Sets tibia distal as osteotomy site during insert osteotomy worflow.
	 */
	apexTibiaDistal: never;
	/**
	 * Sets osteotomy site during anatomical osteotomy worflow.
	 */
	setAnatApex: SelectedApexAnat;
	/**
	 * Ends insert osteotomy workflow.
	 */
	"place-osteotomy": never;
	/**
	 * Sets LPFA standard angle to calculate apex.
	 */
	setStandardAngle_LPFA: number;
	/**
	 * Sets mLDFA standard angle to calculate apex.
	 */
	setStandardAngle_mLDFA: number;
	/**
	 * Sets MPTA standard angle to calculate apex.
	 */
	setStandardAngle_MPTA: number;
	/**
	 * Sets LDTA standard angle to calculate apex.
	 */
	setStandardAngle_LDTA: number;
	/**
	 * Sets PPTA standard angle to calculate apex.
	 */
	setStandardAngle_PPTA: number;
	/**
	 * Sets ADTA standard angle to calculate apex.
	 */
	setStandardAngle_ADTA: number;
	/**
	 * Adds or removes the plane cut along the osteotomy.
	 */
	cutEoC: boolean;
	/**
	 * Sets the closing correction for the EOC.
	 */
	osteotomyCutClosing: never;
	/**
	 * Sets the opening correction for the EOC.
	 */
	osteotomyCutOpening: never;
	/**
	 * Inserts plate to the scenes.
	 */
	insertPlate: PlateModel;
	/**
	 * Sets the femur axial rotation angle degrees.
	 */
	setFemurAxialRotation: number;
	/**
	 * Sets the femur axial rotation angle sign.
	 */
	setFemurAxialRotationSign: AxialRotationEnum;
	/**
	 * Sets the tibia axial rotation angle degrees.
	 */
	setTibiaAxialRotation: number;
	/**
	 * Sets the tibia axial rotation angle sign.
	 */
	setTibiaAxialRotationSign: AxialRotationEnum;
	/**
	 * Starts selecting apex workflow.
	 */
	startSelectingApex: never;
	/**
	 * Ends selecting apex workflow and restore old selected apex value.
	 */
	cancelSelectingApex: never;
	/**
	 * Switches the current state to calibrated.
	 */
	switchToCalibrated: never;
	/**
	 * Switches the current state to deformity.
	 */
	switchToDeformity: never;
	/**
	 * Switches the current state to EOC.
	 */
	switchToEOC: DeformityAnalysisType;
	/**
	 * Switches the current state to templating.
	 */
	switchToMounting: never;
	/**
	 * Switches the current state to RPM.
	 */
	switchToRPM: never;
	/**
	 * Switches the current state to Tlhex.
	 */
	switchToTlhex: never;
	/**
	 * Starts the inserting plate workflow.
	 */
	startInsertingPlate: never;
	/**
	 * Ends inserting plate workflow.
	 */
	confirmPlate: never;
	/**
	 * Ends inserting plate workflow and restore old selected plate.
	 */
	cancelPlate: never;
	/**
	 * Flips the plate.
	 */
	flipPlate: PlateOrientationType;
	/**
	 * Generates images for print report.
	 */
	generateImagesToPrint: never;
	/**
	 * Updates measures available for print report only.
	 */
	updateMeausuresOnlyPrint: never;
	/**
	 * Starts the bone type selection workflow.
	 */
	startBoneTypeSelection: never;
	/**
	 * Ends bone type selection workflow without confirm.
	 */
	cancelBoneTypeSelection: never;
	/**
	 * Confirm bone type selection and ends workflow.
	 */
	confirmBoneTypeSelection: BoneTypeEnum;
	/**
	* Update screw list.
	*/
	updateScrews: ScrewList;
	/**
	 * Switch apex - third line on anatomical axis.
	 */
	switchApex: never;
	/**
	 * Switch proximal axis on anatomical axis.
	 */
	switchProximal: never;
	/**
	 * Switch distal axis on anatomical axis.
	 */
	switchDistal: never;

	/** LAYERS EVENTS **/

	/**
	 * Toggles the visibility of reference point layer TODO check
	 */
	setVisibility_referencePoint: boolean;

	/**
	 * Toggles the visibility of osteotomy layer
	 */
	setVisibility_osteotomy: boolean;
	/**
	 * Toggles the visibility of plate layer TODO check
	 */
	setVisibility_plate: boolean;
	/**
	 * Set anatomical axial rotation value in deformity analysis.
	 */
	setDefAnatAxialRot: number;
	/**
	 * Set anatomical axial rotation sign in deformity analysis.
	 */
	setDefAnatAxialRotSign: AxialRotationEnum;
	/**
	 * Set anatomical axial translation value in deformity analysis.
	 */
	setDefAnatAxialTrans: number;
	/**
	 * Set anatomical axial translation sign in deformity analysis.
	 */
	setDefAnatAxialTransSign: AxialTranslationEnum;
	/**
	 * Set anatomical axial translation reference.
	 */
	setAnatAxialTranslRef: ViewType;
	/**
	 * Starts the reference point workflow.
	 */
	startRefPointInsertion: never;
	/**
	 * Confirm reference point and ends the workflow.
	 */
	confirmRefPoint: never;
	/**
	 * Ends reference point workflow without confirm.
	 */
	cancelRefPoint: never;
	/**
	 * Set anatomical axial rotation value in correction analysis.
	 */
	setEocAnatAxialRot: number;
	/**
	 * Set anatomical axial rotation sign in correction analysis.
	 */
	setEocAnatAxialRotSign: AxialRotationEnum;
	/**
	 * Set anatomical over angulation in correction analysis.
	 */
	setOverAngulation: SetAnatEoCMeasure<OverAngulationSign>;
	/**
	 * Set anatomical over translation in correction analysis.
	 */
	setOverTransl: SetAnatEoCMeasure<OverTranslSign>;
	/**
	 * Set anatomical bone length in correction analysis.
	 */
	setBoneLength: SetAnatEoCMeasure<BoneLengthSign>;
	/**
	 * Starts the postop osteotomy workflow.
	 */
	startPostopOstInsertion: never;
	/**
	 * Confirm postop osteotomy and ends the workflow.
	 */
	confirmPostopOst: never;
	/**
	 * Ends postop osteotomy workflow without confirm.
	 */
	cancelPostopOst: never;
	/**
	 * Reset checkup EOC initial data.
	 */
	resetCheckupEOC: never;
}

/**
 * Messages sent from three.js part to angular part.
 */
export enum BridgeResultMessages {
	/**
	 * Error during registering mechanical axis points.
	 */
	mechanicalAxisInsertError = "mechanicalAxisInsertError",
	/**
	 * Error during registering contralateral mechanical axis points.
	 */
	contralateralAxisInsertError = "contralateralAxisInsertError",
	/**
	 * Error: osteotomy line (mechanical) is too long.
	 */
	osteotomyLengthError = "osteotomyLengthError",
	/**
	 * Error: one of the two osteotomy doens't intersect the plane. Cannot cut the plane along osteotomy.
	 */
	cannotCut = "cannotCut"
}

/**
 * Manages the communications between three.js and angular part.
 */
export class Bridge extends BridgeBase {

	//override
	public mapEvent<K extends keyof BridgeActions>(type: K, args?: BridgeActions[K]): void {
		switch (type) {
			case "setModel":
				return this._context.handle("setModel", args as CaseModel);
			case "mechanical-axes-btn":
				return this._context.handle("insertMechanicalAxis", this._model.activeView);
			case "anatomical-axis-btn":
				return this._context.handle("insertAnatomicalAxis", this._model.activeView);
			case "ma-to-ctrlateral":
				return this._context.handle("insertControlaterMechanicalAxis");
			case "apexFemurProximal":
				return this._context.handle("selectApex", SelectedApexMech.femurProximal);
			case "apexFemurDistal":
				return this._context.handle("selectApex", SelectedApexMech.femurDistal);
			case "apexTibiaProximal":
				return this._context.handle("selectApex", SelectedApexMech.tibiaProximal);
			case "apexTibiaDistal":
				return this._context.handle("selectApex", SelectedApexMech.tibiaDistal);
			case "setAnatApex":
				return this._context.handle("selectApex", args as SelectedApexAnat);
			case "place-osteotomy":
				return this._context.handle("confirmApex");
			case "setStandardAngle_LPFA":
				return this._context.handle("setStandardAngle", { angle: CustomStandardAngles.LPFA, value: args as number } as SetStandardAngleParams);
			case "setStandardAngle_mLDFA":
				return this._context.handle("setStandardAngle", { angle: CustomStandardAngles.mLDFA, value: args as number } as SetStandardAngleParams);
			case "setStandardAngle_MPTA":
				return this._context.handle("setStandardAngle", { angle: CustomStandardAngles.MPTA, value: args as number } as SetStandardAngleParams);
			case "setStandardAngle_LDTA":
				return this._context.handle("setStandardAngle", { angle: CustomStandardAngles.LDTA, value: args as number } as SetStandardAngleParams);
			case "setStandardAngle_PPTA":
				return this._context.handle("setStandardAngle", { angle: CustomStandardAngles.PPTA, value: args as number } as SetStandardAngleParams);
			case "setStandardAngle_ADTA":
				return this._context.handle("setStandardAngle", { angle: CustomStandardAngles.ADTA, value: args as number } as SetStandardAngleParams);
			case "setVisibility_referencePoint":
				return this._context.handle("setVisibility", { key: "referencePoint", value: args as boolean });
			case "setVisibility_osteotomy":
				return this._context.handle("setVisibility", { key: "osteotomy", value: args as boolean });
			case "setVisibility_plate":
				return this._context.handle("setVisibility", { key: "plate", value: args as boolean });
			case "osteotomyCutClosing":
				return this._context.handle("osteotomyCut", OsteotomyCut.closing);
			case "osteotomyCutOpening":
				return this._context.handle("osteotomyCut", OsteotomyCut.opening);
			case "cutEoC":
				return this._context.handle("cutEoC", args as boolean);
			case "insertPlate":
				return this._context.handle("insertPlate", args as PlateModel);
			case "setFemurAxialRotation":
				return this._context.handle("setAxialRotation", { boneType: BoneTypeMinEnum.Femur, value: args as number } as SetAxialRotation); //todo BoneTypeMinEnum move
			case "setFemurAxialRotationSign":
				return this._context.handle("setAxialRotationSign", { boneType: BoneTypeMinEnum.Femur, value: args as AxialRotationEnum } as SetAxialRotationSign);
			case "setTibiaAxialRotation":
				return this._context.handle("setAxialRotation", { boneType: BoneTypeMinEnum.Tibia, value: args as number } as SetAxialRotation);
			case "setTibiaAxialRotationSign":
				return this._context.handle("setAxialRotationSign", { boneType: BoneTypeMinEnum.Tibia, value: args as AxialRotationEnum } as SetAxialRotationSign);
			case "startSelectingApex":
				return this._context.handle("startSelectingApex");
			case "cancelSelectingApex":
				return this._context.handle("cancelSelectingApex");
			case "switchToCalibrated":
				return this._context.handle("switchToCalibrated");
			case "switchToDeformity":
				return this._context.handle("switchToDeformity");
			case "switchToEOC":
				return this._context.handle("switchToEOC", args as DeformityAnalysisType);
			case "switchToMounting":
				return this._context.handle("switchToMounting");
			case "switchToRPM":
				return this._context.handle("switchToRPM");
			case "switchToTlhex":
				return this._context.handle("switchToTlhex");
			case "startInsertingPlate":
				return this._context.handle("startInsertingPlate");
			case "confirmPlate":
				return this._context.handle("confirmPlate");
			case "cancelPlate":
				return this._context.handle("cancelPlate");
			case "flipPlate":
				return this._context.handle("flipPlate", args as PlateOrientationType);
			case "updateScrews":
				return this._context.handle("updateScrews", args as ScrewList);
			case "generateImagesToPrint":
				return this._context.handle("generateImagesToPrint");
			case "updateMeausuresOnlyPrint":
				return this._context.handle("updateMeausuresOnlyPrint");
			case "startBoneTypeSelection":
				return this._context.handle("startBoneTypeSelection");
			case "cancelBoneTypeSelection":
				return this._context.handle("cancelBoneTypeSelection");
			case "confirmBoneTypeSelection":
				return this._context.handle("confirmBoneTypeSelection", args as BoneTypeEnum);
			case "switchApex":
				return this._context.handle("switchApex", this._model.activeView);
			case "switchProximal":
				return this._context.handle("switchProximal", this._model.activeView);
			case "switchDistal":
				return this._context.handle("switchDistal", this._model.activeView);
			case "setDefAnatAxialRot":
				return this._context.handle("setDefAnatAxialRot", args as number);
			case "setDefAnatAxialRotSign":
				return this._context.handle("setDefAnatAxialRotSign", args as AxialRotationEnum);
			case "setDefAnatAxialTrans":
				return this._context.handle("setDefAnatAxialTrans", args as number);
			case "setDefAnatAxialTransSign":
				return this._context.handle("setDefAnatAxialTransSign", args as AxialTranslationEnum);
			case "setAnatAxialTranslRef":
				return this._context.handle("setAnatAxialTranslRef", args as ViewType);
			case "startRefPointInsertion":
				return this._context.handle("startRefPointInsertion");
			case "confirmRefPoint":
				return this._context.handle("confirmRefPoint");
			case "cancelRefPoint":
				return this._context.handle("cancelRefPoint");
			case "startPostopOstInsertion":
				return this._context.handle("startPostopOstInsertion");
			case "confirmPostopOst":
				return this._context.handle("confirmPostopOst");
			case "cancelPostopOst":
				return this._context.handle("cancelPostopOst");
			case "setEocAnatAxialRot":
				return this._context.handle("setEocAnatAxialRot", args as number);
			case "setEocAnatAxialRotSign":
				return this._context.handle("setEocAnatAxialRotSign", args as AxialRotationEnum);
			case "setOverAngulation":
				return this._context.handle("setOverAngulation", args as SetAnatEoCMeasure<OverAngulationSign>);
			case "setOverTransl":
				return this._context.handle("setOverTransl", args as SetAnatEoCMeasure<OverTranslSign>);
			case "setBoneLength":
				return this._context.handle("setBoneLength", args as SetAnatEoCMeasure<BoneLengthSign>);
			case "resetCheckupEOC":
				return this._context.handle("resetCheckupEOC");
		}


		return super.mapEvent(type, args);
	}
}
