<div class="spacing-top justify-content-between">
  <a class="wrapper-back" href="#" (click)="back()" onclick="return false;">
    <span class="material-icons md-18 back">arrow_back</span>
    <span class="action">{{ labels.POSTOP_OST_WORKFLOW_COMPONENT_CANCEL_BTN }}</span>
  </a>
</div>

<div class="main-step">
  <div class="box-menu-settings active">
    <div class="row-box">
      <h4>{{ labels.POSTOP_OST_WORKFLOW_COMPONENT_TITLE }}</h4>
    </div>
    <div class="row-box">
      <div class="box-suggestion">
        <p>
          {{ labels.POSTOP_OST_WORKFLOW_COMPONENT_MESSAGE }}
        </p>
      </div>
    </div>
    <div class="row-box wrapper-img-axis justify-content-center">
      <div [hidden]="!isImgVisible">
        <img [src]="helpImg" alt="" (load)='isImgVisible = true' style="height: 25rem;">
      </div>
    </div>
    <div class="mt-auto">
      <div class="divider-box"></div>
      <div class="wrapper-btn-confirm">
        <button type="button" class="btn btn-primary pl-2 pr-2" (click)="confirm()">
          {{ labels.POSTOP_OST_WORKFLOW_COMPONENT_CONFIRM_BTN }}
        </button>
      </div>
    </div>
  </div>
</div>
