export interface DeformityParameters {
	ap: DeformityViewData;
	ml: DeformityViewData;
	ax: AxialDeformity;
	footLikeStick: boolean;
}

export interface DeformityViewData {
	angle: number;
	angulation: AngularDeformityTypeEnum;
	displacement: number;
	translation: TranslationTypeEnum;
}

export interface AxialDeformity {
	angle: number;
	rotation: RotationTypeEnum;
	displacement: number;
	translation: TranslationTypeEnum;
}

export enum RotationTypeEnum {
	Undefined = "Undefined",
	Internal = "Internal",
	Zero = "Zero",
	External = 'External'
}

export enum AngularDeformityTypeEnum {
	Undefined = "Undefined",
	Varus = "Varus",
	Valgus = "Valgus",
	ApexPosterior = 'ApexPosterior',
	ApexAnterior = 'ApexAnterior',
	Zero = 'Zero'
}

export enum TranslationTypeEnum {
	Undefined = "Undefined",
	Anterior = "Anterior",
	Posterior = "Posterior",
	Medial = 'Medial',
	Lateral = 'Lateral',
	Short = 'Short',
	Long = 'Long',
	Zero = 'Zero'
}