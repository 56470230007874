import { DOCUMENT } from "@angular/common";
import { ApplicationRef, ChangeDetectorRef, Directive, ElementRef, Inject, Injector, Input, NgZone, OnInit, Renderer2, TemplateRef, ViewContainerRef } from "@angular/core";
import { NgbTooltip, NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";
import { Subscription, of } from "rxjs";
import { delay } from "rxjs/operators";


/**
* Custom tooltip component
 * @param {string} valueTooltip text of tooltip
 * @param {boolean} activeTooltip when true tooltip is opened for some time
*/
@Directive({
	selector: "[customTooltip]",
	exportAs: "customTooltip"
})
export class CustomTooltipDirective extends NgbTooltip implements OnInit {

	@Input() valueTooltip: string | TemplateRef<any>;
	@Input() set activeTooltip(val: boolean) {
		if (val !== this._active) {
			this._active = val;
			this._active ? this.open() : this.close();
		}
	}

	private delaySubscription$: Subscription;
	private _active: boolean = false;
	private readonly OPEN_DELAY: number = 5000;

	constructor(
		private _elRef: ElementRef,
		private _render: Renderer2,
		injector: Injector,
		private viewContainerRef: ViewContainerRef,
		config: NgbTooltipConfig,
		ngZone: NgZone,
		private changeRef: ChangeDetectorRef,
		@Inject(DOCUMENT) _document: any,
		applicationRef: ApplicationRef
	) {
		super(
			_elRef,
			_render,
			injector,
			viewContainerRef,
			config,
			ngZone,
			_document,
			changeRef,
			applicationRef
		);

		this.triggers = "manual";
		this.autoClose = false;
		this.placement = "right";
		this.tooltipClass = "white-tooltip";
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.ngbTooltip = this.valueTooltip;
		this.open();
	}

	/**
	* Open tooltip
	*/
	open(): void {
		if (this._active) {
			super.open();
			this.delaySubscription$ = of(true).pipe(delay(this.OPEN_DELAY)).subscribe(() => this.close());
		}
	}

	/**
	* Close tooltip
	*/
	close(): void {
		super.close();
		this.delaySubscription$?.unsubscribe();
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
		this.delaySubscription$?.unsubscribe();
	}

}
