import { Component } from '@angular/core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';



/**
* Component of first vertical sidebar tool menu to JPS templating.
*/
@Component({
	selector: 'jps-menu',
	templateUrl: './jps-menu.component.html'
})
export class JpsMenuComponent extends BaseComponent {

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}


	/**
	* Display JPS template menu tools.
	*/
	enableTemplate(): void {
		if (this.isEocCut) {
			this.goToTemplate();
		}
	}

	private goToTemplate(): void {
		this.canvasSrv.dispatch('switchToMounting');
		this.canvasSrv.saveState();
	}

	/**
	* Start template selection workflow.
	*/
	openTemplateSelection(): void {
		this.canvasSrv.dispatch('startInsertingPlate');
	}

}
