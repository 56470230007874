import { IAnatomicalAxis, ViewType, ThirdLine as ThirdLineBase, ReferenceTypeEnum, AnatomicalSideEnum, PlanTypeEnum } from "@ortho-next/nextray-core";
import { StateTypes } from "../../States/State";
import { BindedModel } from "../../Models/BindedModel";

export class ThirdLine extends ThirdLineBase {

	constructor(viewType: ViewType, reference: IAnatomicalAxis, moving: IAnatomicalAxis, side: AnatomicalSideEnum, refType: ReferenceTypeEnum, planeType: PlanTypeEnum) {
		super(viewType, reference, moving, side, refType, planeType);
		this.bindProperty('visible', (m: BindedModel) => { return m.isThirdLineInserted }, ['isThirdLineInserted']);
		this.line1.bindProperty('visible', (m: BindedModel) => { return m.appState === StateTypes.deformityAnalysis }, ['appState']);
		this.line2.bindProperty('visible', (m: BindedModel) => { return m.appState === StateTypes.deformityAnalysis }, ['appState']);
	}
}
