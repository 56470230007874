import { AxialRotationEnum, BoneTypeEnum, PlanTypeEnum, RotateCameraType, SelectedApexAnat, SelectedApexMech, ToolEvents, ViewType } from "@ortho-next/nextray-core";
import { MathUtils, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { PlateOrientationType } from "../Models/AppModel";
import { BindedModel, bindedModel } from "../Models/BindedModel";
import { AxialTranslationEnum, BoneTypeMinEnum, DeformityAnalysisType } from "../Models/Enums";
import { PlateModel } from "../Models/PlateModel";
import { Context } from "../States/Context";
import { CustomStandardAngles, OsteotomyCut, RegisteringType, StateTypes } from "../States/State";
import { AnatomicalAxis } from "../Tools/AnatomicalAxis/AnatomicalAxis";
import { EoCPlaneAnatomical } from "../Tools/EoCPlane/EoCPlaneAnatomical";
import { OsteotomyAP } from "../Tools/Osteotomy/OsteotomyAP";
import { OsteotomyAnatomical } from "../Tools/Osteotomy/OsteotomyAnatomical";
import { Consts } from "../Utils/Consts";
import { FormatUtils } from "../Utils/FormatUtils";
import { MeasuresUtils } from "../Utils/MeasuresUtils";
import { BridgeResultMessages } from "./Bridge";
import { Main } from "./Main";

/**
 * Handle events logic.
 */
export class ContextInit {
	private static _previousPlateState = false;
	private static _previousPlateOrientation: PlateOrientationType;
	private static _previousPlateModel: PlateModel;
	private static _previousBoneTypeLT: BoneTypeEnum;
	private static _previousMechAxisLT: Vector3[]; //todo move in class instance or do a specific class
	private static _previousMechAxisLTValid: boolean;
	private static _previousDefAnalysisType: DeformityAnalysisType;

	/**
	 * Init all events logic.
	 */
	public static create(main: Main, context: Context): Context {

		context.addEventListener("setModel", (event) => {
			main.loadScenes(event.args);
		});

		context.addEventListener("stopRegistering", () => {
			main.model.magnifier = undefined;
			// restore previous bone type LT
			if (this._previousBoneTypeLT) { //todo move it
				bindedModel.boneTypeLT = this._previousBoneTypeLT;
				main.toolsLT.mechanicalAxis && (main.toolsLT.mechanicalAxis.boneType = bindedModel.boneTypeLT);
				main.toolsLT.mechanicalAxis && (main.toolsLT.mechanicalAxis.isValid = this._previousMechAxisLTValid);
				bindedModel.isMechanicalAxisLTValid = this._previousMechAxisLTValid;
				main.toolsLT.clonedMechanicalAxis && (main.toolsLT.clonedMechanicalAxis.boneType = bindedModel.boneTypeLT);
				this._previousBoneTypeLT = null;
				this._previousMechAxisLT = null;
				this._previousMechAxisLTValid = null;
			}
		});

		context.addEventListener("completeRegistering", (event) => {
			switch (event.args) {
				case RegisteringType.mechanicalAxisAP:
					main.model.isAPMechanicalAxisInserted = true;
					break;
				case RegisteringType.mechanicalAxisLT:
					main.model.isLTMechanicalAxisInserted = true;
					this._previousBoneTypeLT = null;
					this._previousMechAxisLT = null;
					break;
				case RegisteringType.contralateral:
					main.model.isContralateralAxisInserted = true;
					break;
			}
		});

		context.addEventListener("generateImagesToPrint", () => {
			main.model.printResult = main.printUtils.generateImages(main.model.stateType);
		});

		context.addEventListener("updateMeausuresOnlyPrint", () => {
			if (main.hasAP) {
				const mecAxis = main.toolsAP.mechanicalAxis;
				const osteotomy = main.toolsAP.osteotomy as OsteotomyAP;
				const EOCplane = main.toolsAP.EoCPlane;
				if (main.model.stateType === StateTypes.EOC || main.model.stateType === StateTypes.templating) {
					main.model.print_osteotomyLevel = FormatUtils.distance(MeasuresUtils.getOsteotomyLevel(mecAxis, osteotomy, main.model.selectedApex as SelectedApexMech));
				}
				if (main.model.stateType === StateTypes.templating) {
					const plate = main.toolsAP.plate;
					if (plate && main.model.plate) {
						const plateAngle = main.model.plate.plate.angle;
						main.model.print_cortexWire = FormatUtils.distance(MeasuresUtils.getCortexWire(mecAxis, osteotomy, main.model.selectedApex as SelectedApexMech, plate.wireOrigin));
						main.model.print_wireAngle = FormatUtils.angleRadiants(MeasuresUtils.getWireInsertionAngle(mecAxis, EOCplane, plateAngle, main.model.selectedApex as SelectedApexMech));
					}
				}
			}
		});

		context.addEventListener("insertMechanicalAxis", (event) => {
			main.toolsInit.initMechanicalAxis(event.args);
			this._previousDefAnalysisType = bindedModel.deformityAnalysisType;
			bindedModel.deformityAnalysisType = main.model.deformityAnalysisType = DeformityAnalysisType.mechanical;
			if (!main.getTools(event.args).mechanicalAxis.isValid) {
				event.args === ViewType.LT && (main.toolsLT.mechanicalAxis.boneType = bindedModel.boneTypeLT);
				main.setView(event.args);
				if (event.args === ViewType.LT && this._previousBoneTypeLT) {
					this.registerMechanicalAxis(main, context, event.args, { oldState: this._previousMechAxisLT, oldType: this._previousBoneTypeLT });
				} else {
					this.registerMechanicalAxis(main, context, event.args);
				}
				main.model.magnifier = this.generateMagnifierImage(main);
			} else {
				bindedModel.deformityAnalysisType = main.model.deformityAnalysisType = DeformityAnalysisType.mechanical;
			}
		});

		context.addEventListener("insertAnatomicalAxis", (event) => {
			main.toolsInit.initAnatomicalAxis();
			bindedModel.deformityAnalysisType = main.model.deformityAnalysisType = DeformityAnalysisType.anatomical;
			bindedModel.isAnatomicalAxisInserted = true;
			main.model.isAnatomicalAxisInserted = true;
		});

		context.addEventListener("switchApex", (event) => {
			main.hasAP && main.toolsAP.anatomicalAxis.switchApex();
			main.hasLT && main.toolsLT.anatomicalAxis.switchApex();
			bindedModel.isThirdLineInserted = !bindedModel.isThirdLineInserted;
			main.model.isThirdLineInserted = bindedModel.isThirdLineInserted;
		});

		context.addEventListener("switchProximal", (event) => {
			const anatAxis = main.getTools(event.args).anatomicalAxis;
			anatAxis.switchProximal();
		});

		context.addEventListener("switchDistal", (event) => {
			const anatAxis = main.getTools(event.args).anatomicalAxis;
			anatAxis.switchDistal();
		});

		context.addEventListener("insertControlaterMechanicalAxis", () => {
			main.toolsInit.initControlaterMechanicalAxis();
			this._previousDefAnalysisType = bindedModel.deformityAnalysisType;
			bindedModel.deformityAnalysisType = main.model.deformityAnalysisType = DeformityAnalysisType.mechanical;
			if (!main.toolsAP.contralateralMechanicalAxis.isValid) {
				main.setView(ViewType.AP);
				this.registerControlaterMechanicalAxis(main, context);
				main.model.magnifier = this.generateMagnifierImage(main);
			} else {
				bindedModel.deformityAnalysisType = main.model.deformityAnalysisType = DeformityAnalysisType.mechanical;
			}
		});

		context.addEventListener("startBoneTypeSelection", (event) => {
			main.setView(ViewType.LT);
			bindedModel.insertingMechanicalAxisLT = true;
			this._previousDefAnalysisType = bindedModel.deformityAnalysisType;
			bindedModel.deformityAnalysisType = main.model.deformityAnalysisType = DeformityAnalysisType.mechanical;
		});

		context.addEventListener("cancelBoneTypeSelection", (event) => {
			bindedModel.insertingMechanicalAxisLT = false;
			bindedModel.deformityAnalysisType = main.model.deformityAnalysisType = this._previousDefAnalysisType;
		});

		context.addEventListener("confirmBoneTypeSelection", (event) => {
			if (event.args !== bindedModel.boneTypeLT) {
				this._previousBoneTypeLT = bindedModel.boneTypeLT;
				main.toolsLT.mechanicalAxis && (this._previousMechAxisLT = main.toolsLT.mechanicalAxis.getPoints());
				main.toolsLT.mechanicalAxis && (this._previousMechAxisLTValid = main.toolsLT.mechanicalAxis.isValid);
				bindedModel.boneTypeLT = event.args;
				main.toolsLT.mechanicalAxis && (main.toolsLT.mechanicalAxis.boneType = bindedModel.boneTypeLT);
				main.toolsLT.clonedMechanicalAxis && (main.toolsLT.clonedMechanicalAxis.boneType = bindedModel.boneTypeLT);

				main.toolsLT.mechanicalAxis && (main.toolsLT.mechanicalAxis.isValid = false);
				bindedModel.isMechanicalAxisLTValid = false;
			}
			bindedModel.insertingMechanicalAxisLT = false;
		});

		context.addEventListener("startRefPointInsertion", () => {
			context.handle("setViewMultiple");
			this._previousDefAnalysisType = bindedModel.deformityAnalysisType;
			bindedModel.deformityAnalysisType = main.model.deformityAnalysisType = DeformityAnalysisType.anatomical;
			main.toolsInit.initReferencePoints();
			bindedModel.isReferencePointInserted = true;
			bindedModel.layer_referencePoint = true;
		});

		context.addEventListener("cancelRefPoint", () => {
			bindedModel.deformityAnalysisType = main.model.deformityAnalysisType = this._previousDefAnalysisType;
			bindedModel.isReferencePointInserted = false;
		});

		context.addEventListener("confirmRefPoint", () => {
			bindedModel.layer_referencePoint = false;
		});

		context.addEventListener("startPostopOstInsertion", () => {
			context.handle("setViewMultiple");
			bindedModel.deformityAnalysisType = main.model.deformityAnalysisType = DeformityAnalysisType.anatomical; //todo link prop
			main.toolsInit.initAnatomicalAxis();
			main.toolsInit.initReferencePoints();
			main.toolsInit.initOsteotomies();
			bindedModel.isOsteotomyValid = true;
			main.toolsInit.osteotomiesManager.setPosition(SelectedApexAnat.none, main.isPostOp);
			if (main.caseModel.type === PlanTypeEnum.Fracture) {
				bindedModel.isAnatomicalAxisInserted = main.model.isAnatomicalAxisInserted = true;
			}
		});

		context.addEventListener("cancelPostopOst", () => {
			bindedModel.isOsteotomyValid = false;
		});

		context.addEventListener("confirmPostopOst", () => {
			if (main.caseModel.checkUpDeformity) {
				const translAP = main.toolsAP.osteotomy.C.position.clone().sub((main.toolsAP.osteotomy as OsteotomyAnatomical).apexPos);
				main.toolsAP.anatomicalAxis.translateAllPoints(translAP);
				main.toolsAP.osteotomy.dispatchEvent({ type: ToolEvents.updated });
				const translLT = main.toolsLT.osteotomy.C.position.clone().sub((main.toolsLT.osteotomy as OsteotomyAnatomical).apexPos);
				main.toolsLT.anatomicalAxis.translateAllPoints(translLT);
				main.toolsLT.osteotomy.dispatchEvent({ type: ToolEvents.updated });
			} else {
				main.toolsAP.anatomicalAxis.translateAllPoints(main.toolsAP.osteotomy.C.position);
				main.toolsLT.anatomicalAxis.translateAllPoints(main.toolsLT.osteotomy.C.position);
			}
			bindedModel.isAnatomicalAxisInserted = main.model.isAnatomicalAxisInserted = true;
		});

		// TODO: unire select apex con confirm apex
		context.addEventListener("selectApex", (event) => {
			main.model.selectedApex = event.args;
		});

		context.addEventListener("confirmApex", () => {
			main.toolsInit.initReferencePoints();
			main.toolsInit.initOsteotomies();
			main.toolsInit.osteotomiesManager.setPosition(main.model.selectedApex, main.isPostOp);
			bindedModel.isOsteotomyValid = true;
		});

		context.addEventListener("insertPlate", (event) => {
			main.model.plate = event.args;
			main.toolsInit.initPlates();
			bindedModel.isPlateInserted = true;
			main.model.isPlateInserted = true;
			bindedModel.layer_plate = true;
		});

		context.addEventListener("startInsertingPlate", () => {
			this._previousPlateState = main.model.isPlateInserted;
			this._previousPlateOrientation = main.model.plateOrientation;
			this._previousPlateModel = main.model.plate;
			main.toolsAP.plate && main.toolsAP.plate.createStateToRestore();
			main.toolsLT.plate && main.toolsLT.plate.createStateToRestore();
		});

		context.addEventListener("cancelPlate", () => {
			main.toolsAP.plate && main.toolsAP.plate.confirmStateRestore();
			main.toolsLT.plate && main.toolsLT.plate.confirmStateRestore();
			bindedModel.isPlateInserted = this._previousPlateState;
			main.model.isPlateInserted = this._previousPlateState;
			main.model.plateOrientation = this._previousPlateOrientation;
			main.model.plate = this._previousPlateModel;
		});

		context.addEventListener("confirmPlate", () => {
			main.toolsAP.plate && main.toolsAP.plate.cancelStateRestore();
			main.toolsLT.plate && main.toolsLT.plate.cancelStateRestore();
		});

		context.addEventListener("flipPlate", (event) => {
			main.model.plateOrientation = event.args;
			main.toolsAP.plate && main.toolsAP.plate.horizontalFlip();
			main.toolsLT.plate && main.toolsLT.plate.horizontalFlip();
		});

		context.addEventListener("updateScrews", (event) => {
			main.model.plate.screwList = event.args;
			main.model.plate.hasJPSExtended = true;
			main.toolsAP.plate && main.toolsAP.plate.changeScrewList(event.args);
			main.toolsLT.plate && main.toolsLT.plate.changeScrewList(event.args);
		});

		context.addEventListener("setStandardAngle", (event) => {
			switch (event.args.angle) {
				case CustomStandardAngles.LDTA:
					main.model.def_standardAngles_LDTA = event.args.value;
					return main.toolsAP.mechanicalAxis.tibia.LDTAStandardDegrees = event.args.value;
				case CustomStandardAngles.LPFA:
					main.model.def_standardAngles_LPFA = event.args.value;
					return main.toolsAP.mechanicalAxis.femur.LPFAStandardDegrees = event.args.value;
				case CustomStandardAngles.mLDFA:
					main.model.def_standardAngles_mLDFA = event.args.value;
					return main.toolsAP.mechanicalAxis.femur.mLDFAStandardDegrees = event.args.value;
				case CustomStandardAngles.MPTA:
					main.model.def_standardAngles_MPTA = event.args.value;
					return main.toolsAP.mechanicalAxis.tibia.MPTAStandardDegrees = event.args.value;
				case CustomStandardAngles.PPTA:
					main.model.def_standardAngles_PPTA = event.args.value;
					return main.toolsLT.mechanicalAxis.tibia.PPTAStandardDegrees = event.args.value;
				case CustomStandardAngles.ADTA:
					main.model.def_standardAngles_ADTA = event.args.value;
					return main.toolsLT.mechanicalAxis.tibia.ADTAStandardDegrees = event.args.value;
			}
		});

		context.addEventListener("setAxialRotation", (event) => {
			if (event.args.boneType == BoneTypeMinEnum.Femur) {
				main.model.def_femur_axialRotation = event.args.value;
			} else {
				main.model.def_tibia_axialRotation = event.args.value;
			}
		});

		context.addEventListener("setAxialRotationSign", (event) => {
			if (event.args.boneType === BoneTypeMinEnum.Femur) {
				main.model.def_femur_axialRotationSign = event.args.value;
			} else {
				main.model.def_tibia_axialRotationSign = event.args.value;
			}
		});

		context.addEventListener("setDefAnatAxialRot", (event) => {
			main.model.def_anatAxialRot = event.args;
		});

		context.addEventListener("setDefAnatAxialRotSign", (event) => {
			main.model.def_anatAxialRotSign = event.args;
		});

		context.addEventListener("setDefAnatAxialTrans", (event) => {
			main.model.def_anatAxialTrans = event.args;
		});

		context.addEventListener("setDefAnatAxialTransSign", (event) => {
			main.model.def_anatAxialTransSign = event.args;
		});

		context.addEventListener("setAnatAxialTranslRef", (event) => {
			main.model.anatAxialTranslRef = event.args;
		});

		context.addEventListener("setEocAnatAxialRot", (event) => {
			main.model.eoc_anatAxialRot = event.args;
		});

		context.addEventListener("setEocAnatAxialRotSign", (event) => {
			main.model.eoc_anatAxialRotSign = event.args;
		});

		context.addEventListener("osteotomyCut", (event) => {
			main.model.osteotomyCut = event.args;
			bindedModel.cutType = event.args;
		});

		context.addEventListener("setOverAngulation", (event) => {
			const tools = main.getTools(event.args.view);
			tools.EoCPlane.angle = MathUtils.degToRad(event.args.value) * -event.args.sign;
			tools.EoCPlane.triggerEvent("onAfterDragMove");
		});

		context.addEventListener("setOverTransl", (event) => {
			const tools = main.getTools(event.args.view);
			(tools.EoCPlane as EoCPlaneAnatomical).horizontalTranslation = event.args.value * event.args.sign;
		});

		context.addEventListener("setBoneLength", (event) => {
			main.toolsAP.EoCPlane.verticalTranslation = event.args.value * event.args.sign;
			main.toolsLT.EoCPlane.verticalTranslation = event.args.value * event.args.sign;
		});

		context.addEventListener("cutEoC", (event) => {
			if (event.args) {
				let APValid = true, LTValid = true;
				const verticalTransl = bindedModel.deformityAnalysisType === DeformityAnalysisType.mechanical ?
					MeasuresUtils.getEoCVerticalTranslationBeforeCut(main.toolsAP, main.toolsLT, main.model.osteotomyCut) :
					(main.caseModel.type === PlanTypeEnum.Deformity ? main.model.measures.def_suggestedBoneLength : 0);
				main.toolsInit.initEoCPlanes();
				if (main.hasAP) {
					const cutPoint = bindedModel.deformityAnalysisType === DeformityAnalysisType.mechanical ?
						main.toolsAP.mechanicalAxis.cutPoint : main.toolsAP.anatomicalAxis.moving.A.position;
					APValid = main.toolsAP.EoCPlane.cutPlane(cutPoint, verticalTransl, main.model.osteotomyCut);
				}
				if (main.hasLT && APValid) {
					const cutPoint = bindedModel.deformityAnalysisType === DeformityAnalysisType.mechanical ?
						main.toolsLT.mechanicalAxis.cutPoint : main.toolsLT.anatomicalAxis.moving.A.position;
					LTValid = main.toolsLT.EoCPlane.cutPlane(cutPoint, verticalTransl, main.model.osteotomyCut);
				}
				if (APValid && LTValid) {
					// move this in EoCPlaneManager
					bindedModel.EOCCropVisible = true;
					main.model.eocCut = true;
					main.toolsAP.EoCPlane?.dispatchEvent({ type: ToolEvents.updated });
					main.toolsLT.EoCPlane?.dispatchEvent({ type: ToolEvents.updated });
					main.toolsAP?.anatomicalAxis?.position.setZ(5); //todo move it
					main.toolsLT?.anatomicalAxis?.position.setZ(5);
				} else {
					main.bridge.dispatchEvent({ type: "onResult", args: BridgeResultMessages.cannotCut });
				}
			} else {
				main.model.eocCut = false;
				bindedModel.EOCCropVisible = false;
				bindedModel.boneLengthisEqualsToSuggested = true;
				main.toolsAP?.anatomicalAxis?.position.setZ(0); //todo move it
				main.toolsLT?.anatomicalAxis?.position.setZ(0);
			}
		});

		context.addEventListener("switchToCalibrated", (event) => {
			this.reset(StateTypes.calibrated, main);
		});

		context.addEventListener("switchToDeformity", (event) => {
			this.reset(StateTypes.deformityAnalysis, main);
		});

		context.addEventListener("switchToEOC", (event) => {
			if (context.state.type === StateTypes.tlhex) return;
			
			if (event.args) {

				if (main.caseModel.type === PlanTypeEnum.Fracture) {
					const axTranslAP = main.toolsAP.anatomicalAxis.apexCalculator.apexes[0].axialTranslation.value;
					const axTranslLT = main.toolsLT.anatomicalAxis.apexCalculator.apexes[0].axialTranslation.value;
					bindedModel.anatAxialTranslDifAP = main.model.anatAxialTranslRef === ViewType.AP ? 0 : axTranslAP - axTranslLT;
					bindedModel.anatAxialTranslDifLT = main.model.anatAxialTranslRef === ViewType.LT ? 0 : axTranslLT - axTranslAP;
				}

				bindedModel.deformityAnalysisType = main.model.deformityAnalysisType = event.args;
				if (bindedModel.deformityAnalysisType === DeformityAnalysisType.anatomical && !bindedModel.isThirdLineInserted && !main.isPostOp) {
					main.toolsInit.initReferencePoints();
					if (main.caseModel.type === PlanTypeEnum.Fracture) {
						main.toolsAP.referencePoint.position.copy((main.toolsAP.anatomicalAxis.moving as AnatomicalAxis).B.position);
						main.toolsLT.referencePoint.position.copy((main.toolsLT.anatomicalAxis.moving as AnatomicalAxis).B.position);
					}
					main.toolsInit.initOsteotomies();
					main.toolsInit.osteotomiesManager.setPosition(SelectedApexAnat.none, main.isPostOp);
					bindedModel.isOsteotomyValid = true;
				}

				if (main.isPostOp && main.caseModel.type === PlanTypeEnum.Fracture) {
					main.toolsInit.initReferencePoints();
					main.toolsInit.initOsteotomies();
					main.toolsInit.osteotomiesManager.setPosition(SelectedApexAnat.none, main.isPostOp);
				}
			}

			this.reset(StateTypes.EOC, main);
		});

		context.addEventListener("rotateCamera", () => {
			if (main.model.stateType === StateTypes.templating) {
				main.rotateCamera(RotateCameraType.templating);
			} else if (main.model.stateType !== StateTypes.loading) {
				main.rotateCamera(RotateCameraType.none);
			}
		});

		context.addEventListener("resetCheckupEOC", () => {
			if (main.caseModel.checkUpEOC) {
				main.toolsAP.EoCPlane && (main.toolsAP.EoCPlane as EoCPlaneAnatomical).resetDefaults();
				main.toolsLT.EoCPlane && (main.toolsLT.EoCPlane as EoCPlaneAnatomical).resetDefaults();
				main.model.eoc_anatAxialRot = 0;
				main.model.eoc_anatAxialRotSign = null;
				main.caseModel.checkUpEOC = null;
			}
		});

		return context;
	}

	private static generateMagnifierImage(main: Main): string {
		main.multiviewRenderer.render();
		return main.renderer.domElement.toDataURL();
	}

	private static registerMechanicalAxis(main: Main, context: Context, viewType: ViewType, oldMechAxisLT?: { oldState: Vector3[], oldType: BoneTypeEnum }): void {
		const tools = main.getTools(viewType);
		const insertingMechanicalAxis: keyof BindedModel = viewType === ViewType.AP ? "insertingMechanicalAxisAP" : "insertingMechanicalAxisLT";
		const isMechanicalAxisValid: keyof BindedModel = viewType === ViewType.AP ? "isMechanicalAxisAPValid" : "isMechanicalAxisLTValid";
		const oldValidState = bindedModel[isMechanicalAxisValid];
		const oldStateTool = tools.mechanicalAxis.getPoints();

		bindedModel[insertingMechanicalAxis] = true;
		context.handle("fitToView");
		main.activeCameraControl.enabled = false;

		tools.registerHitPlane.startRegisteringForObj(tools.mechanicalAxis, main.activeCamera, {
			onRegError: () => {
				main.bridge.dispatchEvent({ type: "onResult", args: BridgeResultMessages.mechanicalAxisInsertError })
			},
			onRegEnd: (args) => {
				bindedModel[insertingMechanicalAxis] = false;
				bindedModel[isMechanicalAxisValid] = args || oldValidState;
				tools.mechanicalAxis.isValid = bindedModel[isMechanicalAxisValid]; //todo refactor
				main.activeCameraControl.enabled = true;
				context.handle("endRegistering");
			},
			onRegSuccess: () => {
				bindedModel.layer_mechAxis_mechanical = true;
				bindedModel.layer_mechAxis_anatomical = true;
				bindedModel.layer_mechAxis_weightBearing = true;
			},
			onRegCancel: () => {
				if (oldMechAxisLT) {
					tools.mechanicalAxis.boneType = oldMechAxisLT.oldType;
					oldMechAxisLT.oldState && tools.mechanicalAxis.restorePoints(oldMechAxisLT.oldState);
				} else {
					tools.mechanicalAxis.restorePoints(oldStateTool);
				}
				bindedModel.deformityAnalysisType = main.model.deformityAnalysisType = this._previousDefAnalysisType;
			},
			onRegReset: () => {
				bindedModel[insertingMechanicalAxis] = true;
				context.handle("fitToView");
				main.activeCameraControl.enabled = false;
			}
		}, true);
	}

	private static registerControlaterMechanicalAxis(main: Main, context: Context): void {
		const tools = main.toolsAP;
		const oldValidState = bindedModel.isControlateralMechanicalAxisValid;
		const oldStateTool = tools.contralateralMechanicalAxis.getPoints();

		bindedModel.insertingControlateralMechanicalAxis = true;
		context.handle("fitToView");
		main.activeCameraControl.enabled = false;

		tools.registerHitPlane.startRegisteringForObj(tools.contralateralMechanicalAxis, main.activeCamera, {
			onRegError: () => {
				main.bridge.dispatchEvent({ type: "onResult", args: BridgeResultMessages.contralateralAxisInsertError })
			},
			onRegEnd: (args) => {
				bindedModel['insertingControlateralMechanicalAxis'] = false;
				bindedModel['isControlateralMechanicalAxisValid'] = args || oldValidState;
				tools.contralateralMechanicalAxis.isValid = args || oldValidState; //todo refactor
				main.activeCameraControl.enabled = true;
				context.handle("endRegistering");
			},
			onRegSuccess: () => {
				bindedModel.layer_contralateral_all = true;
			},
			onRegCancel: () => {
				tools.contralateralMechanicalAxis.restorePoints(oldStateTool);
				bindedModel.deformityAnalysisType = main.model.deformityAnalysisType = this._previousDefAnalysisType;
			},
			onRegReset: () => {
				bindedModel.insertingControlateralMechanicalAxis = true;
				context.handle("fitToView");
				main.activeCameraControl.enabled = false;
			}
		}, true);
	}

	private static reset(landingState: StateTypes, main: Main): void {
		if (landingState <= StateTypes.calibrated) {
			this.resetLines(main);
			this.resetGoniometers(main);
		}
		if (landingState <= StateTypes.calibrated) {
			this.resetDeformity(main);
		}
		if (landingState <= StateTypes.deformityAnalysis) {
			this.resetEOC(main);
		}
		if (landingState <= StateTypes.EOC) {
			this.resetTemplating(main);
		}
	}

	private static resetDeformity(main: Main): void {
		bindedModel.deformityAnalysisType = DeformityAnalysisType.none;
		main.model.deformityAnalysisType = DeformityAnalysisType.none;

		// Mechanical analysis
		bindedModel.isMechanicalAxisAPValid = false;
		main.toolsAP.mechanicalAxis && (main.toolsAP.mechanicalAxis.isValid = false);
		bindedModel.isMechanicalAxisLTValid = false;
		main.toolsLT.mechanicalAxis && (main.toolsLT.mechanicalAxis.isValid = false);
		bindedModel.isControlateralMechanicalAxisValid = false;
		main.toolsAP.contralateralMechanicalAxis && (main.toolsAP.contralateralMechanicalAxis.isValid = false);
		main.model.isAPMechanicalAxisInserted = false;
		main.model.isLTMechanicalAxisInserted = false;
		main.model.isContralateralAxisInserted = false;

		main.model.def_femur_axialRotation = 0;
		main.model.def_femur_axialRotationSign = AxialRotationEnum.external;
		main.model.def_tibia_axialRotation = 0;
		main.model.def_tibia_axialRotationSign = AxialRotationEnum.external;
		main.model.def_standardAngles_LPFA = Consts.paleyAngles.AP.mLPFA.standard;
		main.model.def_standardAngles_mLDFA = Consts.paleyAngles.AP.mLDFA.standard;
		main.model.def_standardAngles_MPTA = Consts.paleyAngles.AP.MPTA.standard;
		main.model.def_standardAngles_LDTA = Consts.paleyAngles.AP.LDTA.standard;
		main.model.def_standardAngles_PPTA = Consts.paleyAngles.LT.PPTA.standard;
		main.model.def_standardAngles_ADTA = Consts.paleyAngles.LT.ADTA.standard;

		bindedModel.boneTypeLT = main.caseModel.boneType;
		main.toolsLT.mechanicalAxis && (main.toolsLT.mechanicalAxis.boneType = bindedModel.boneTypeLT);

		// Anatomical analysis

		bindedModel.isReferencePointInserted = false;
		bindedModel.isAnatomicalAxisInserted = false;
		main.model.isAnatomicalAxisInserted = false;
		bindedModel.isThirdLineInserted = false;
		main.model.isThirdLineInserted = false;

		main.toolsAP?.referencePoint?.reset();
		main.toolsLT?.referencePoint?.reset();
		main.toolsAP?.anatomicalAxis?.reset();
		main.toolsLT?.anatomicalAxis?.reset();

		bindedModel.apexTooFarAP = false;
		bindedModel.apexTooFarLT = false;

		main.model.def_anatAxialRot = 0;
		main.model.def_anatAxialRotSign = AxialRotationEnum.external;
		main.model.def_anatAxialTrans = 0;
		main.model.def_anatAxialTransSign = AxialTranslationEnum.short;
		main.model.anatAxialTranslRef = undefined;
		bindedModel.anatAxialTranslDifAP = 0;
		bindedModel.anatAxialTranslDifLT = 0;

		if (main.isPostOp) {
			bindedModel.isOsteotomyValid = false;
		}
	}

	private static resetLines(main: Main): void {
		for (const line of [...main.toolsAP.lines, ...main.toolsLT.lines]) {
			line.dispose();
		}
		main.toolsAP.lines = [];
		main.toolsLT.lines = [];
		bindedModel.hasLines = false;
	}

	private static resetGoniometers(main: Main): void {
		for (const goniometer of [...main.toolsAP.goniometers, ...main.toolsLT.goniometers]) {
			goniometer.dispose();
		}
		main.toolsAP.goniometers = [];
		main.toolsLT.goniometers = [];
		bindedModel.hasGoniometers = false;
	}

	private static resetEOC(main: Main): void {
		main.model.selectedApex = SelectedApexMech.none;
		if (!main.isPostOp) {
			bindedModel.isOsteotomyValid = false;
		}
		bindedModel.ostTooFarAP = false;
		bindedModel.ostTooFarLT = false;
		main.model.osteotomyCut = OsteotomyCut.none;
		main.model.eocCut = false;
		bindedModel.EOCCropVisible = false;

		main.toolsLT.clonedMechanicalAxis && (main.toolsLT.clonedMechanicalAxis.boneType = bindedModel.boneTypeLT);

		// Anatomical analysis
		main.model.eoc_anatAxialRot = 0;
		main.model.eoc_anatAxialRotSign = AxialRotationEnum.external;
		main.toolsAP?.anatomicalAxis?.position.setZ(0); //todo move it
		main.toolsLT?.anatomicalAxis?.position.setZ(0);
	}

	private static resetTemplating(main: Main): void {
		main.hasAP && main.toolsAP.plateManager && main.toolsAP.plateManager.reset();
		main.hasLT && main.toolsLT.plateManager && main.toolsLT.plateManager.reset();
		main.model.plate = undefined;
		main.model.plateOrientation = PlateOrientationType.lateral;
		main.model.isPlateInserted = false;
		bindedModel.isPlateInserted = false;
	}
}
