<!-- MENU DEFORMITY -->
<div class="box-menu-settings active">

	<div class="row-box">
		<h4>{{ labels.TOOLS_MENU_COMPONENT_DEFORMITY }}</h4>
		<div class="check-success ml-auto" *ngIf="isDeformityDone"></div>
	</div>

	<div class="divider-box"></div>

	<div class="row-box clickable" (click)="enableDeformity()"
		*ngIf="stateType !== stateTypeEnum.deformityAnalysis; else DeformityBlock">
		<i class="material-icons">more_horiz</i>
		<span>{{ labels.TOOLS_MENU_COMPONENT_ACTIVATE }}</span>
	</div>

	<ng-template #DeformityBlock>

		<div class="row-box clickable" *ngIf="isMechToolVisible" (click)="setMechanicalAxis()">
			<i class="material-icons">linear_scale</i>
			<span>{{ labels.TOOLS_MENU_COMPONENT_MECHANICAL_AXIS }}</span>
			<div class="check-success ml-auto" *ngIf="isMechDeformityDone"></div>
		</div>
		<div class="row-box clickable" *ngIf="isAnatAxisVisible" (click)="setAnatomicalAxis()">
			<i class="material-icons">align_horizontal_center</i>
			<span>{{ labels.TOOLS_MENU_COMPONENT_ANATOMICAL_AXIS }}</span>
			<div class="check-success ml-auto" *ngIf="isAnatDeformityDone"></div>
		</div>
		<div class="row-box" *ngIf="isMechToolVisible" (click)="setContralateralAxis()"
			[ngClass]="{'clickable': isCtrlEnabled, 'disabled': !isCtrlEnabled }">
			<i class="material-icons">add_circle</i>
			<span>{{ labels.TOOLS_MENU_COMPONENT_CONTRALATERAL_AXIS }}</span>
		</div>

	</ng-template>

</div>