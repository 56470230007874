import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { ImageCompression, jsPDF } from 'jspdf';
import { take, takeWhile, timer } from 'rxjs';
import { LoaderService } from '../core';
import { CanvasService } from './canvas.service';
import { ProductService } from './product.service';


/**
* This service handles the PDF print of current ray analysis.
*/
@Injectable()
export class PrinterService {

	/**
	* PDF permissions accessible by any user.
	*/
	private readonly pdfUserPermissions = ['print' as const];

	private readonly pdfCompression: ImageCompression = 'MEDIUM';

	constructor(
		private router: Router,
		private canvasSrv: CanvasService,
		private location: Location,
		private loadSrv: LoaderService,
		private prodSrv: ProductService
	) { }

	/**
	* Print OR Plan PDF.
	*/
	printORPlan(): void {
		this.loadSrv.show();
		this.canvasSrv.saveState();
		this.canvasSrv.dispatch('generateImagesToPrint');
		this.canvasSrv.dispatch('updateMeausuresOnlyPrint');
		this.router.navigate(['/',
			{
				outlets: {
					'print': ['or-plan']
				}
			}]);
	}

	/**
	* Print BOM PDF.
	*/
	printBom(): void {
		this.loadSrv.show();
		this.canvasSrv.saveState();
		this.prodSrv.getScrewList(this.canvasSrv.plate.id, this.canvasSrv.hasJPSExtended)
			.subscribe(screwList => {
				this.prodSrv.bomScrewList = screwList;
				this.router.navigate(['/',
					{
						outlets: {
							'print': ['bom']
						}
					}]);
			});
	}

	/**
	* Generate PDF when OR Plan data is ready.
	*/
	async onORPlanDataReady(page_print: HTMLElement) {
		const pdfOwnerPassword = this.genPdfPassword();
		const pdf = new jsPDF({
			compress: true,
			format: 'a4',
			orientation: 'l',
			unit: 'mm',
			encryption: { userPermissions: this.pdfUserPermissions, ownerPassword: pdfOwnerPassword }
		});
		const imgWidth = 297;
		page_print.hidden = false;
		const printPagePromises = [];
		const printPageOne = page_print.querySelector('print-page-one');
		if (printPageOne) {
			const printPagePromise = html2canvas(printPageOne as HTMLElement, {
				allowTaint: true,
				scale: 2,
				width: 1348,
				height: 952,
				useCORS: true,
				//onclone: docClone => {
				//  const xray_canvas = docClone.querySelector('canvas');
				//  const canvas_wrapper = docClone.querySelector('div.wrapper-canvas');
				//  canvas_wrapper.appendChild(xray_canvas);
				//}
			}).then(canvas => {
				const imgHeight = 210;
				const contentDataURL = canvas.toDataURL('image/png');
				return {
					imgHeight,
					content: contentDataURL
				};
			});
			printPagePromises.push(printPagePromise);
		}
		const prtPageTwo = page_print.querySelector('print-page-two');
		if (prtPageTwo) {
			const printPagePromise = html2canvas(prtPageTwo as HTMLElement, {
				allowTaint: true,
				scale: 2,
				width: 1348,
				height: 952,
				useCORS: true,
				/*onclone: docClone => {
					const xray_canvas = docClone.querySelector('canvas');
					const canvas_wrapper = docClone.querySelector('div.wrapper-canvas');
					canvas_wrapper.appendChild(xray_canvas);
				}*/
			}).then(canvas => {
				const imgHeight = 210;
				const contentDataURL = canvas.toDataURL('image/png');
				return {
					imgHeight,
					content: contentDataURL
				};
			});
			printPagePromises.push(printPagePromise);
		}
		const prtPageThree = page_print.querySelector('print-page-three');
		if (prtPageThree) {
			const printPagePromise = html2canvas(prtPageThree as HTMLElement, {
				allowTaint: true,
				scale: 2,
				width: 1348,
				height: 952,
				useCORS: true,
				/*onclone: docClone => {
					const xray_canvas = docClone.querySelector('canvas');
					const canvas_wrapper = docClone.querySelector('div.wrapper-canvas');
					canvas_wrapper.appendChild(xray_canvas);
				}*/
			}).then(canvas => {
				const imgHeight = 210;
				const contentDataURL = canvas.toDataURL('image/png');
				return {
					imgHeight,
					content: contentDataURL
				};
			});
			printPagePromises.push(printPagePromise);
		}
		page_print.hidden = true;
		await Promise.all(printPagePromises)
			.then((values) => {
				if (printPageOne) {
					const result = values[0];
					pdf
						.setPage(1)
						.addImage(result.content, 'PNG', 0, 0, imgWidth, result.imgHeight, undefined, this.pdfCompression);
				}
				if (prtPageTwo) {
					const result = values[1];
					pdf
						.addPage()
						.setPage(2)
						.addImage(result.content, 'PNG', 0, 0, imgWidth, result.imgHeight, undefined, this.pdfCompression);
				}
				if (prtPageThree) {
					const result = values[2];
					pdf
						.addPage()
						.setPage(3)
						.addImage(result.content, 'PNG', 0, 0, imgWidth, result.imgHeight, undefined, this.pdfCompression);
				}
				this.loadSrv.hide();
			});
		const fileName = `${this.canvasSrv.currentCase.number} - Preoperative Planning Report.pdf`;
		pdf.save(fileName); // Generated PDF
		this.location.replaceState('?caseGuid=' + this.canvasSrv.currentCase.id);
		timer(0).pipe(
			takeWhile(() => !this.location.getState()),
			take(10)
		).subscribe(() => this.location.back());
	}

	/**
	 * Generate PDF when BoM data is ready.
	 */
	async onBomDataReady(page_print: HTMLElement) {
		const pdfOwnerPassword = this.genPdfPassword();
		const pdf = new jsPDF({
			compress: true,
			format: 'a4',
			orientation: 'l',
			unit: 'mm',
			encryption: { userPermissions: this.pdfUserPermissions, ownerPassword: pdfOwnerPassword }
		});
		const imgWidth = 297;
		page_print.hidden = false;
		const element = page_print.querySelector('div.page-print');
		const printPromise = html2canvas(element as HTMLElement, {
			allowTaint: true,
			scale: 2,
			width: 1348,
			height: 952,
			useCORS: true,
		}).then(canvas => {
			const imgHeight = 210;
			const contentDataURL = canvas.toDataURL('image/png');
			pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight, undefined, this.pdfCompression);
		});
		page_print.hidden = true;
		await printPromise.then(() => this.loadSrv.hide());
		const fileName = `${this.canvasSrv.currentCase.number} - List of Materials.pdf`;
		pdf.save(fileName); // Generated PDF
		this.location.replaceState('?caseGuid=' + this.canvasSrv.currentCase.id);
		timer(0).pipe(
			takeWhile(() => !this.location.getState()),
			take(10)
		).subscribe(() => this.location.back());
	}

	/**
	 * Generate a random password.
	 */
	private genPdfPassword(): string {
		return Buffer.from(crypto.getRandomValues(new Uint16Array(16))).toString('base64');
	}

}
