import { LayersBase } from "@ortho-next/nextray-core/Core/Layers";
import { BoneTypeEnum, PlanTypeEnum } from "@ortho-next/nextray-core/Models/CaseModel";
import { LayerElements } from "../Models/AppModel";
import { BindedModel, bindedModel } from "../Models/BindedModel";
import { DeformityAnalysisType } from "../Models/Enums";
import { Main } from "./Main";

/**
 * Binds layers logic and visibility
 */
export class Layers extends LayersBase {
	protected _main: Main;

	//override
	protected createBinding(name: string, getCallback: (model: any) => boolean, setCallback: (value: boolean) => void, propertiesBound: (keyof BindedModel)[]): void {
		super.createBinding(name, getCallback, setCallback, propertiesBound as any);
	}
	protected createBindingValue(name: string, bindedModelKey: keyof BindedModel, appModelKey: keyof LayerElements): void {
		super.createBindingValue(name, bindedModelKey as any, appModelKey as any);
	}

	constructor(main: Main) {
		super(main);
		this._main = main; //todo needed?
	}

	/**
	 * Creates all bindings.
	 */
	public createAllBinding(): void {
		super.createAllBinding();
		this.createContralateralBinding();
		this.createMechanicalAxisBinding();
		this.createReferencePointBinding();
		this.createAnatomicalAxisBinding();
		this._main.isPostOp && this._main.caseModel.type === PlanTypeEnum.Deformity && this.createOsteotomyBinding();
		this._main.caseModel.boneType !== BoneTypeEnum.Tibia && this.createMechanicalAnatomicalAxisBinding();
		this._main.caseModel.boneType == BoneTypeEnum.LongLeg && this.createWeightbearingBinding();
		this.createPlateBinding();
	}

	/**
	 * Set on/off value to a specific layer.
	 */
	public setLayersVisibility(key: keyof LayerElements, value: boolean): boolean {
		super.setLayersVisibility(key as any, value);
		switch (key) {
			case "mechanicalAxis_anatomical":
				return bindedModel.layer_mechAxis_anatomical = value;
			case "mechanicalAxis_mechanical":
				return bindedModel.layer_mechAxis_mechanical = value;
			case "referencePoint":
				return bindedModel.layer_referencePoint = value;
			case "osteotomy":
				return bindedModel.layer_osteotomy = value;
			case "anatomicalAxis":
				return bindedModel.layer_anatAxis = value;
			case "mechanicalAxis_weightBearing":
				return bindedModel.layer_mechAxis_weightBearing = value;
			case "contralateral_all":
				return bindedModel.layer_contralateral_all = value;
			case "plate":
				return bindedModel.layer_plate = value;
		}
	}

	protected createContralateralBinding(): void {
		this.createBinding("layerContralateralAxis", (m: BindedModel) => m.isControlateralMechanicalAxisValid && m.deformityAnalysisType === DeformityAnalysisType.mechanical && !m.EOCCropVisible, (value) => {
			this._main.model.layers.contralateral_all = value;
		}, ["isControlateralMechanicalAxisValid", "EOCCropVisible", "deformityAnalysisType"]);

		this.createBindingValue("layerContralateralAxis", "layer_contralateral_all", "contralateral_all");
	}

	protected createMechanicalAxisBinding(): void {
		this.createBinding("layerMechanicalAxis", (m: BindedModel) => (m.isMechanicalAxisAPValid || m.isMechanicalAxisLTValid) && m.deformityAnalysisType === DeformityAnalysisType.mechanical, (value) => {
			this._main.model.layers.mechanicalAxis_mechanical = value;
		}, ["isMechanicalAxisAPValid", "isMechanicalAxisLTValid", "deformityAnalysisType"]);

		this.createBindingValue("layerMechanicalAxis", "layer_mechAxis_mechanical", "mechanicalAxis_mechanical");
	}

	protected createMechanicalAnatomicalAxisBinding(): void {
		this.createBinding("layerMechanicalAnatomicalAxis", (m: BindedModel) => m.isMechanicalAxisAPValid && m.deformityAnalysisType === DeformityAnalysisType.mechanical, (value) => {
			this._main.model.layers.mechanicalAxis_anatomical = value;
		}, ["isMechanicalAxisAPValid", "deformityAnalysisType"]);

		this.createBindingValue("layerMechanicalAnatomicalAxis", "layer_mechAxis_anatomical", "mechanicalAxis_anatomical");
	}

	protected createReferencePointBinding(): void {
		this.createBinding("layerReferencePoint", (m: BindedModel) => m.isReferencePointInserted && m.deformityAnalysisType === DeformityAnalysisType.anatomical, (value) => {
			this._main.model.layers.referencePoint = value;
		}, ["isReferencePointInserted", "deformityAnalysisType"]);

		this.createBindingValue("layerReferencePoint", "layer_referencePoint", "referencePoint");
	}

	protected createAnatomicalAxisBinding(): void {
		this.createBinding("layerAnatomicalAxis", (m: BindedModel) => m.isAnatomicalAxisInserted && m.deformityAnalysisType === DeformityAnalysisType.anatomical, (value) => {
			this._main.model.layers.anatomicalAxis = value;
		}, ["isAnatomicalAxisInserted", "deformityAnalysisType"]);

		this.createBindingValue("layerAnatomicalAxis", "layer_anatAxis", "anatomicalAxis");
	}

	protected createOsteotomyBinding(): void {
		this.createBinding("layerOsteotomy", (m: BindedModel) => m.isOsteotomyValid, (value) => {
			this._main.model.layers.osteotomy = value;
		}, ["isOsteotomyValid"]);

		this.createBindingValue("layerOsteotomy", "layer_osteotomy", "osteotomy");
	}

	protected createWeightbearingBinding(): void {
		this.createBinding("layerWeightbearing", (m: BindedModel) => (m.isMechanicalAxisAPValid || m.isMechanicalAxisLTValid) && m.deformityAnalysisType === DeformityAnalysisType.mechanical, (value) => {
			this._main.model.layers.mechanicalAxis_weightBearing = value;
		}, ["isMechanicalAxisAPValid", "isMechanicalAxisLTValid", "deformityAnalysisType"]);

		this.createBindingValue("layerWeightbearing", "layer_mechAxis_weightBearing", "mechanicalAxis_weightBearing");
	}

	protected createPlateBinding(): void {
		this.createBinding("layerPlate", (m: BindedModel) => m.isPlateInserted, (value) => {
			this._main.model.layers.plate = value;
		}, ["isPlateInserted"]);

		this.createBindingValue("layerPlate", "layer_plate", "plate");
	}

}
