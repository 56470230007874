import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Magnifier component.
*/
@Component({
	selector: 'magnifier',
	templateUrl: './magnifier.component.html'
})
export class MagnifierComponent extends BaseComponent implements OnInit, OnDestroy {

	private canvas: HTMLElement;
	private magnifier: HTMLElement;
	private imageWidth: number;

	private readonly zoom = 2;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.initMagnifier();
	}

	private initMagnifier() {
		let img = new Image();
		this.canvas = document.getElementById('canvas-container');
		this.magnifier = document.getElementById('magnifier-img');

		img.onload = () => {
			const scale = this.canvas ? this.canvas.offsetHeight / img.height : 0;
			this.imageWidth = img.width * scale;
			const height = img.height * scale;

			if (this.magnifier) {
				/* Set background properties for the magnifier */
				this.magnifier.style.backgroundImage = "url('" + img.src + "')";
				this.magnifier.style.backgroundRepeat = "no-repeat";
				this.magnifier.style.backgroundSize = (this.imageWidth * this.zoom) + "px " + (height * this.zoom) + "px";
			}

			/* Execute a function when someone moves the mouse over the image */
			this.canvas.addEventListener("mousemove", this.moveMagnifier);
			this.canvas.addEventListener("touchstart", this.moveMagnifierTouch);
			this.canvas.addEventListener("touchmove", this.moveMagnifierTouch);
			this.canvas.addEventListener("touchend", this.cancelMagnifier);
			this.canvas.addEventListener("touchcancel", this.cancelMagnifier);
		};

		img.src = this.canvasSrv.magnifier;
	}

	private cancelMagnifier = (event: TouchEvent): void => {
		this.magnifier.style.backgroundPosition = "0px 0px";
	}

	moveMagnifierTouch = (event: TouchEvent) => {
		let pos, x, y;
		let w = this.magnifier.offsetWidth / 2;
		let h = this.magnifier.offsetHeight / 2;
		/* Prevent any other actions that may occur when moving over the image */
		event.preventDefault();
		/* Get the cursor's x and y positions: */
		pos = this.getTouchPos(event);
		x = pos.x;
		y = pos.y;

		x = x - (this.canvas.offsetWidth / 2) + (this.imageWidth / 2);

		/* Set the magnifier image position */
		this.magnifier.style.backgroundPosition = (-1) * ((x * this.zoom) - w) + "px " + (-1) * ((y * this.zoom) - h) + "px";
	}

	moveMagnifier = (event: MouseEvent) => {
		let pos, x, y;
		let w = this.magnifier.offsetWidth / 2;
		let h = this.magnifier.offsetHeight / 2;
		/* Prevent any other actions that may occur when moving over the image */
		event.preventDefault();
		/* Get the cursor's x and y positions: */
		pos = this.getCursorPos(event);
		x = pos.x;
		y = pos.y;

		x = x - (this.canvas.offsetWidth / 2) + (this.imageWidth / 2);

		/* Set the magnifier image position */
		this.magnifier.style.backgroundPosition = (-1) * ((x * this.zoom) - w) + "px " + (-1) * ((y * this.zoom) - h) + "px";
	}

	getTouchPos = (e: TouchEvent) => {
		let a, x = 0, y = 0;
		/* Get the x and y positions of the image: */
		a = this.canvas.getBoundingClientRect();
		/* Calculate the cursor's x and y coordinates, relative to the image */
		x = e.touches[0].pageX - a.left;
		y = e.touches[0].pageY - a.top;
		/* Consider any page scrolling */
		x = x - window.scrollX;
		y = y - window.scrollY;

		return { x: x, y: y };
	};

	getCursorPos = (e) => {
		let a, x = 0, y = 0;
		/* Get the x and y positions of the image: */
		a = this.canvas.getBoundingClientRect();
		/* Calculate the cursor's x and y coordinates, relative to the image */
		x = e.pageX - a.left;
		y = e.pageY - a.top;
		/* Consider any page scrolling */
		x = x - window.scrollX;
		y = y - window.scrollY;

		return { x: x, y: y };
	};


	ngOnDestroy() {
		this.canvas.removeEventListener("mousemove", this.moveMagnifier);
		this.canvas.removeEventListener("touchstart", this.moveMagnifierTouch);
		this.canvas.removeEventListener("touchmove", this.moveMagnifierTouch);
		this.canvas.removeEventListener("touchend", this.cancelMagnifier);
		this.canvas.removeEventListener("touchcancel", this.cancelMagnifier);
		this.canvas = null;
		this.magnifier = null;
		this.imageWidth = null;
	}

}
