import { ToolEvents, ViewType } from "@ortho-next/nextray-core";
import { Main } from "src/nextray/Core/Main";
import { bindedModel } from "../../Models/BindedModel";
import { DeformityAnalysisType } from "../../Models/Enums";
import { EoCPlane } from "./EoCPlane";
import { EoCPlaneAnatomical } from "./EoCPlaneAnatomical";
import { EoCPlaneMechanical } from "./EoCPlaneMechanical";
import { MathUtils } from "@ortho-next/three-base/three.js/build/three.module";
import { MeasuresUtils } from "src/nextray/Utils/MeasuresUtils";

export class EoCPlaneManager {
	public synced = false;
	public EoCPlaneAP: EoCPlane;
	public EoCPlaneLT: EoCPlane;

	public initBothViews(main: Main): void {
		const eocPlaneAP = main.toolsAP.EoCPlane;
		const eocPlaneLT = main.toolsLT.EoCPlane;

		if ((main.hasAP && eocPlaneAP && bindedModel.deformityAnalysisType != eocPlaneAP.deformityAnalysisType) || (main.hasLT && eocPlaneLT && bindedModel.deformityAnalysisType != eocPlaneLT.deformityAnalysisType)) {
			this.unregister();
			main.toolsAP.EoCPlane = undefined;
			main.toolsLT.EoCPlane = undefined;
		}

		main.hasAP && this.initView(main, ViewType.AP);
		main.hasLT && this.initView(main, ViewType.LT);
	}

	public initView(main: Main, viewType: ViewType): EoCPlane {
		const tools = main.getTools(viewType);

		if (!tools.EoCPlane) {
			if (bindedModel.deformityAnalysisType === DeformityAnalysisType.mechanical) {
				tools.add(tools.EoCPlane = new EoCPlaneMechanical(viewType, main.caseModel.side, tools.plane, tools.osteotomy, tools.mechanicalAxis));
			} else {
				const model = main.caseModel.checkUpEOC;
				let angle = 0;
				let horTransl = 0;
				let verTransl = 0;
				if (model) {
					const angleSign = MeasuresUtils.getAngleSignEoC(viewType === ViewType.AP ? model.ap.angulation : model.ml.angulation);
					angle = angleSign * MathUtils.degToRad(viewType === ViewType.AP ? model.ap.angle : model.ml.angle);
					const horTranslSign = MeasuresUtils.getTranslSignEoC(viewType === ViewType.AP ? model.ap.translation : model.ml.translation);
					horTransl = horTranslSign * (viewType === ViewType.AP ? model.ap.displacement : model.ml.displacement);
					const verTranslSign = MeasuresUtils.getTranslSignEoC(model.ax.translation);
					verTransl = verTranslSign * model.ax.displacement;
				}
				tools.add(tools.EoCPlane = new EoCPlaneAnatomical(viewType, main.caseModel.side, main.caseModel.referenceType, main.caseModel.type, tools.plane, tools.osteotomy, tools.anatomicalAxis, angle, horTransl, verTransl));
			}
			main.measuresController.bindEoC(viewType);
			viewType === ViewType.AP ? (this.EoCPlaneAP = tools.EoCPlane) : (this.EoCPlaneLT = tools.EoCPlane);
			this.bindSync(main);
		}
		return tools.EoCPlane;
	}

	public unregister(): void {
		this.EoCPlaneAP?.dispose();
		this.EoCPlaneLT?.dispose();
		this.EoCPlaneAP = undefined;
		this.EoCPlaneLT = undefined;
		this.synced = false;
	}

	private bindSync(main: Main): void {
		if (this.EoCPlaneAP && this.EoCPlaneLT && !this.synced) {

			if (bindedModel.deformityAnalysisType === DeformityAnalysisType.anatomical) {
				const suggestedBoneLength = main.model.measures.def_suggestedBoneLength;		
				
				this.EoCPlaneAP.addEventListener(ToolEvents.updated, () => {
					bindedModel.boneLengthisEqualsToSuggested = suggestedBoneLength === Math.round(this.EoCPlaneAP.verticalTranslation);
				});

				this.EoCPlaneLT.addEventListener(ToolEvents.updated, () => {
					bindedModel.boneLengthisEqualsToSuggested = suggestedBoneLength === Math.round(this.EoCPlaneLT.verticalTranslation);
				});
			}

			this.EoCPlaneAP.bindEvent("onAfterDragMove", () => { // TODO: history will not work
				this.EoCPlaneLT.verticalTranslation = this.EoCPlaneAP.verticalTranslation;
			});

			this.EoCPlaneLT.bindEvent("onAfterDragMove", () => {
				this.EoCPlaneAP.verticalTranslation = this.EoCPlaneLT.verticalTranslation;
			});

			this.synced = true;
		}
	}
}
