import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ViewType } from '@ortho-next/nextray-core';
import { Subscription } from 'rxjs';
import { LanguageService, ProductTypeEnum } from '../../core';
import { CanvasService, CaseService } from '../../services';
import { BaseComponent, ConfirmationComponent } from '../../shared';



/**
* Component of first vertical sidebar tool menu to handle deformity analysis.
*/
@Component({
	selector: 'deformity-menu',
	templateUrl: './deformity-menu.component.html'
})
export class DeformityMenuComponent extends BaseComponent implements OnInit, OnDestroy {

	private _products: ProductTypeEnum[] = [];
	private _prodSub: Subscription;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
		private modalSrv: NgbModal,
		private planSrv: CaseService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit(): void {
		this._prodSub = this.planSrv.getPlanProducts().subscribe(list => this._products = list);
	}

	private openWarningModal(message: string, confirmFunction: Function, cancelFunction?: Function): void {
		if (!this.modalSrv.hasOpenModals()) {
			const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
				centered: true, backdrop: 'static'
			});
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this.labels['TOOLS_MENU_COMPONENT_WARNING_MODAL_TITLE'],
				message: message
			};
			modalRef.result.then(() => confirmFunction.call(this), () => cancelFunction ? cancelFunction.call(this) : null).catch(error => false);
		}
	}

	/**
	* Display Deformity analysis menu tools.
	*/
	enableDeformity(): void {
		if (this.stateType !== this.stateTypeEnum.calibrated) {
			const message: string = this.labels['TOOLS_MENU_COMPONENT_DEFORMITY_MODAL_MESSAGE'];
			this.openWarningModal(message, this.goToDeformity);
		} else {
			this.goToDeformity();
		}
	}

	private goToDeformity(): void {
		this.canvasSrv.dispatch('switchToDeformity');
		this.canvasSrv.saveState();
	}

	/**
	* Check if mechanical tool is visible.
	*/
	get isMechToolVisible(): boolean {
		return this._products.includes(ProductTypeEnum.JPS) || this._products.includes(ProductTypeEnum.Fitbone);
	}

	/**
	* Check if anatomical axis is visible.
	*/
	get isAnatAxisVisible(): boolean {
		return this._products.includes(ProductTypeEnum.TLHex);
	}

	/**
	* Check if third line is visible.
	*/
	// get isThirdLineVisible(): boolean {
	// 	return this.isAnatAxisVisible && this.isDeformity && this.isLegBone && !this.isPostOp;
	// }

	/**
	* Check if third line button is enabled.
	*/
	get isThirdLineEnabled(): boolean {
		return this.isAnatomicalAxisInserted && this.deformityAnalysisType === this.defTypeEnum.anatomical;
	}

	/**
	* Check if contralateral button is enabled.
	*/
	get isCtrlEnabled(): boolean {
		return this.activeView === this.activeViewEnum.AP && this.deformityAnalysisType !== this.defTypeEnum.anatomical;
	}

	/**
	* Start mechanical axis insertion workflow.
	*/
	setMechanicalAxis(): void {
		this.startMechanicalAxisWorkflow();
	}

	/**
	 * Insert anatomical axis.
	 */
	setAnatomicalAxis(): void {
		this.startAnatomicalAxisWorkflow();
	}

	/**
	 * Insert third line axis.
	 */
	// setThirdLineAxis(): void {
	// 	if (this.isThirdLineEnabled) {
	// 		this.canvasSrv.dispatch("switchApex");
	// 	}
	// }

	/**
	* Start contralateral axis insertion workflow.
	*/
	setContralateralAxis(): void {
		if (this.isCtrlEnabled) {
			this.startContralateralAxisWorkflow();
		}
	}

	private startMechanicalAxisWorkflow(): void {
		if (this.activeView === ViewType.LT && this.isLongLeg) {
			this.canvasSrv.dispatch("startBoneTypeSelection");
		} else {
			this.canvasSrv.dispatch("mechanical-axes-btn");
		}
	}

	private startContralateralAxisWorkflow(): void {
		this.canvasSrv.dispatch("ma-to-ctrlateral");
	}

	private startAnatomicalAxisWorkflow(): void {
		if (this.isFracture) { //fracture
			this.canvasSrv.dispatch("anatomical-axis-btn");
		} else { //deformity
			if (this.isPostOp) { //postop
				if (!this.isAnatomicalAxisInserted) {
					this.canvasSrv.dispatch("startPostopOstInsertion");
				} else {
					this.canvasSrv.dispatch("anatomical-axis-btn");
				}
			} else { //preop
				if (!this.isReferencePointInserted) {
					this.canvasSrv.dispatch("startRefPointInsertion");
				} else {
					this.canvasSrv.dispatch("anatomical-axis-btn");
				}
			}
		}
	}


	ngOnDestroy(): void {
		this._prodSub?.unsubscribe();
	}

}
