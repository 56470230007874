import { Component, Input } from '@angular/core';
import { LanguageService, Plan } from '../../../core';
import { BaseComponent } from '../../../shared';


/**
 * Component for template of preop plan.
 */
@Component({
	selector: 'prt-template',
	templateUrl: './prt-template.component.html'
})
export class PrtTemplateComponent extends BaseComponent {

	@Input() isDemo: boolean;
	@Input() today: number;
	@Input() plan: Plan;

	constructor(private langSrv: LanguageService) {
		super(langSrv);
	}

}
