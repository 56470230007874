import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@ortho-next/auth-core';
import { SseService } from '@ortho-next/sse';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LanguageService, Plan, ToastService } from '../../core';
import { CanvasService, CaseService, Guide, GuideService, ModelService, UserService } from '../../services';
import { BaseComponent, ClosureModalComponent, ConfirmationComponent, HelpComponent } from '../../shared';


/**
 * Horizontal bar on top
 */
@Component({
	selector: 'navbar',
	templateUrl: './navbar.component.html'
})
export class NavbarComponent extends BaseComponent implements OnInit, OnDestroy {

	buildVersion: string = environment.buildVer;
	envName: string = environment.name;

	firstName: string;
	lastName: string;
	currentCase: Plan;
	isReadOnly: boolean;
	resetKey: string;

	guideList: Guide[] = [];

	private caseSubscription$: Subscription;

	readonly eifuSiteUrl = environment.eifuSite;

	constructor(
		private langSrv: LanguageService,
		private userSrv: UserService,
		private caseSrv: CaseService,
		private modelSrv: ModelService,
		private canvasSrv: CanvasService,
		private authSrv: AuthService,
		private modalSrv: NgbModal,
		private toastSrv: ToastService,
		private guideSrv: GuideService,
		private sse: SseService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.userSrv.getUserName().subscribe(name => { this.firstName = name.firstName; this.lastName = name.lastName });
		this.caseSrv.isReadOnly().subscribe(res => this.isReadOnly = res);
		this.caseSubscription$ = this.caseSrv.getCurrentCase().subscribe(c => {
			this.currentCase = c;
			this.handlePlanResetting();
		});
	}

	/**
	 * Check if save button is disabled.
	 */
	get isSaveDisabled(): boolean {
		return this.isReadOnly || this.isMechAxisWorkflow;
	}

	/**
	* Check if window width is strict
	*/
	get isStrict(): boolean {
		return window.innerWidth < 1260;
	}

	/**
	* Get plan step label
	*/
	get planStepLabel(): string {
		if (!this.currentCase) return null;
		return this.currentCase.isPostOperative ? this.labels['NAVBAR_COMPONENT_PLAN_STEP_POSTOP'] : this.labels['NAVBAR_COMPONENT_PLAN_STEP_PREOP'];
	}

	/**
	* Open about page
	*/
	openAboutPage(): void {
		const lang = this.langSrv.currentLang;
		const aboutSite = environment.aboutSite?.replace('about-en.html', `about-${lang}.html`);
		window.open(aboutSite, '_blank');
	}


	/**
	* Open legal terms page
	*/
	openLegalTerms(): void {
		this.userSrv.getUserCountry().subscribe(code => {
			window.open(`${environment.legalTermsSite}?countryCode=${code}`);
		});
	}

	/**
	* Logout
	*/
	logout(): void {
		this.authSrv.singleSignOut();
	}

	/**
	 * Persist model scene.
	 */
	save(): void {
		if (!this.isSaveDisabled) {
			const currentCase = this.canvasSrv.currentCase;
			this.modelSrv.persistModel(currentCase.id, currentCase.userGuid).subscribe({
				next: () => this.toastSrv.showSuccess(this.labels['NAVBAR_COMPONENT_PLAN_SAVED_SUCCESS']),
				error: () => this.toastSrv.showError(this.labels['NAVBAR_COMPONENT_PLAN_SAVED_ERROR'])
			});
		}
	}

	/**
	 * Get Guide list when open dropdown the first time.
	 */
	getGuideList(open: boolean): void {
		if (!!open && (!this.guideList || this.guideList.length === 0)) {
			this.guideSrv.getGuideList().subscribe(list => this.guideList = list);
		}
	}

	/**
	 * Open guide page.
	 */
	openGuide(path: string): void {
		const guideUrl = this.guideSrv.getGuideUrl(path);
		window.open(guideUrl, '_blank');
	}

	/**
	* Open modal of help info
	*/
	openHelpModal(): void {
		if (!this.modalSrv.hasOpenModals()) {
			this.modalSrv.open(HelpComponent, { backdropClass: 'wrapper-modal-help', windowClass: 'wrapper-modal-help' });
		}
	}

	/**
	* Open modal of confirmation before exit
	*/
	openClosingModal(): void {
		if (!this.modalSrv.hasOpenModals()) {
			this.modalSrv.open(ClosureModalComponent, { centered: true, backdrop: 'static' });
		}
	}

	/**
	 * Subscribe to analysis resetting event.
	 */
	private handlePlanResetting(): void {
		if (!this.resetKey) {
			const caseGuid = this.currentCase?.id;
			this.resetKey = `OrthoNext.Ray.Reset.${caseGuid}`;
			setTimeout(() => {
				this.sse.subscribe(this.resetKey, (success: boolean) => {
					if (success) {
						this.modalSrv.dismissAll();
						this.openPlanChangedWarning(caseGuid);
					}
				});
			}, 5000);
		}
	}

	private openPlanChangedWarning(caseGuid: string): void {
		const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
			centered: true, backdrop: 'static'
		});
		(modalRef.componentInstance as ConfirmationComponent).config = {
			title: this.langSrv.labels.INVALID_PLAN_MODAL_TITLE,
			message: this.langSrv.labels.PLAN_CHANGED_WARNING_MESSAGE,
			confirmBtn: this.langSrv.labels.INVALID_PLAN_MODAL_CONFIRM_BTN,
			isCancelBtn: false
		};
		modalRef.result.then(() => window.location.href = `${environment.planDetailsSite}${caseGuid}`).catch(err => false);
	}

	ngOnDestroy() {
		if (this.caseSubscription$) {
			this.caseSubscription$.unsubscribe();
		}
	}

}
