<div class="wrapper-print-tab">
	<h4>{{labels.PLATE_AND_SCREWS}}</h4>
</div>
<table aria-label="list material" class="wrapper-print-table">
	<thead>
		<tr>
			<th style="min-width: 120px;">{{ labels.PART_NO }}</th>
			<th>{{ labels.DESC_INSTR }}</th>
			<th>{{ labels.QUANTITY }}</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>99-{{ plate?.id }}</td>
			<td>{{ plate?.description }}</td>
			<td>1</td>
		</tr>
		<tr *ngFor="let screw of screwPlateList">
			<td>{{screw.code}}</td>
			<td>{{screw.description}}</td>
			<td>{{screw.count}}</td>
		</tr>
	</tbody>
</table>