import { VectorUtils } from "@ortho-next/nextray-core/Utils/VectorUtils";
import { BufferGeometry, Color, CylinderBufferGeometry, Material, MathUtils, Mesh, MeshLambertMaterial, Object3D, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { MeshType } from "../../../app/core/repositories/models/plate";

export class Screw extends Mesh {
	public material: MeshLambertMaterial;
	public direction: Vector3;
	public origin: Vector3;
	public oldLength: number;
	private _radius: number;
	private _minSize: number;
	private _maxSize: number;
	private _startLength = 40;
	private _startLengthDrag: number;
	private _EPSStretch = 1;
	private _startDragPoint: Vector3;
	private _lengths: number[];

	constructor(child: Object3D, lengths: number[], screwType: MeshType) {
		super(undefined, new MeshLambertMaterial()); // fix geometry in constructor
		this.material.color = this.getMaterialColor(screwType);
		this.isDraggable = true;
		this.name = child.name;
		this._lengths = lengths;
		this.oldLength = this.length; //this._startLength;

		this.getSizes(lengths);
		this.assignScrewData(child);
		this.geometry = new CylinderBufferGeometry(this._radius, this._radius, 1);
		this.lookAt(this.position.clone().add(new Vector3(this.direction.x, -this.direction.z, this.direction.y)));
		this.length = this._startLength;

		this.bindEvent("onDragMove", (args) => { // implement mousemove
			args.preventDefault = true;
			this.oldLength = this.length;
			args.position.copy(VectorUtils.projectOnVector(args.position, this.origin, this.origin.clone().sub(this.direction)));
			if (this._startDragPoint) {
				const sign = VectorUtils.computeSign(args.position, this._startDragPoint, this.direction);
				let distance = args.position.distanceTo(this._startDragPoint) * sign;
				if (this._lengths) {
					this.length = this.getLengthInRange(this._startLengthDrag + distance);
				} else {
					distance -= distance % this._EPSStretch;
					this.length = MathUtils.clamp(this._startLengthDrag + distance, this._minSize, this._maxSize);
				}
			} else {
				this._startLengthDrag = this.length;
				this._startDragPoint = VectorUtils.projectOnVector(args.position, this.origin, this.origin.clone().sub(this.direction)); //todo cache value
			}
		});

		this.bindEvent("onDragEnd", () => {
			this._startDragPoint = undefined;
		});
	}

	private getLengthInRange(length: number): number {
		if (length >= this._maxSize) {
			return this._maxSize;
		}
		if (length <= this._minSize) {
			return this._minSize;
		}
		for (let i = 1; i < this._lengths.length; i++) {
			if (length <= this._lengths[i]) {
				return this._lengths[i];
			}
		}
	}

	public get length(): number {
		return this.scale.y;
	}
	public set length(value: number) {
		this.position.copy(this.origin).add(this.direction.clone().setLength(value / 2));
		this.scale.y = value;
	}

	private assignScrewData(child: Object3D): void {
		const geometry = (child.children[0] as Mesh).geometry as BufferGeometry;
		const position = geometry.attributes.position;
		const positionArray = position.array;
		const indexes = geometry.index;
		const indexesArray = indexes.array;
		const occ: { count: number; vertexIndex: number; arrayIndex: number }[] = [];

		for (let i = 0; i < position.count; i++) {
			occ[i] = { count: 0, vertexIndex: i, arrayIndex: -1 };
		}

		for (let i = 0; i < indexes.count; i += 3) {
			occ[indexesArray[i]].count++;
			occ[indexesArray[i]].arrayIndex = i;
		}

		occ.sort((a, b) => b.count - a.count);

		if (occ[0].vertexIndex > occ[1].vertexIndex) { //important to avoid problem with direction calculation
			const temp = occ[0];
			occ[0] = occ[1];
			occ[1] = temp;
		}

		const center1PosIndex = occ[0].vertexIndex * 3;
		const center1 = new Vector3(positionArray[center1PosIndex], positionArray[center1PosIndex + 1], positionArray[center1PosIndex + 2]);
		const center2PosIndex = occ[1].vertexIndex * 3;
		const center2 = new Vector3(positionArray[center2PosIndex], positionArray[center2PosIndex + 1], positionArray[center2PosIndex + 2]);

		const center1Index = occ[0].arrayIndex;
		const center1IndexModulo = center1Index % 3;
		const secondVertexPosIndex = indexesArray[center1IndexModulo === 0 ? center1Index + 1 : center1Index - center1IndexModulo] * 3;
		const secondVertex = new Vector3(positionArray[secondVertexPosIndex], positionArray[secondVertexPosIndex + 1], positionArray[secondVertexPosIndex + 2]);

		geometry.dispose();
		((child.children[0] as Mesh).material as Material).dispose();

		this.direction = center2.clone().sub(center1);
		this.origin = center1.clone();
		this._radius = center1.distanceTo(secondVertex);
	}

	private getMaterialColor(screwType: MeshType): Color {
		switch (screwType) {
			case MeshType.nonlocking:
				return new Color(0xaaaaaa); //todo put in config
			case MeshType.screw:
				return new Color(0x71b634);
			case MeshType.wire:
				return new Color(0xa800a8);
		}
	}

	public changeScrewList(lengths: number[]): void {
		this._lengths = lengths;
		this.getSizes(lengths);
	}
	private getSizes(lengths: number[]): void {
		if (lengths) { // array already sorted
			this._minSize = lengths[0];
			this._maxSize = lengths[lengths.length - 1];
		} else {
			this._minSize = 10;
			this._maxSize = 100;
		}
	}

}
