import { Component } from '@angular/core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
 * Tabset component that handles canvas views
 */
@Component({
	selector: 'canvas-tabset',
	templateUrl: './canvas-tabset.component.html'
})
export class CanvasTabsetComponent extends BaseComponent {

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	/**
	* Display AP view
	*/
	setAPView() {
		this.canvasSrv.dispatch("setViewAP");
	}

	/**
	* Display Lateral view
	*/
	setLTView() {
		this.canvasSrv.dispatch("setViewLT");
	}

	/**
	* Display Multiple view
	*/
	setMTView() {
		this.canvasSrv.dispatch("setViewMultiple");
	}

}
