import { Component, Input, OnInit } from '@angular/core';
import { PrintResult } from '../../../../nextray/Utils/PrintUtils';
import { LanguageService, Plan } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
* First page of PDF print.
*/
@Component({
	selector: 'print-page-one',
	templateUrl: './page-one.component.html'
})
export class PrintPageOneComponent extends BaseComponent implements OnInit {

	@Input() isDemo: boolean;
	@Input() today: number;

	currentCase: Plan;
	printResult: PrintResult;
	isAnatomical: boolean;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit(): void {
		this.currentCase = this.canvasSrv.currentCase;
		this.printResult = this.canvasSrv.printResult;
		this.isAnatomical = this.canvasSrv.deformityAnalysisType === this.defTypeEnum.anatomical;
	}

}
