import { PrintUtilsBase, TempDataToRestore } from "@ortho-next/nextray-core/Utils/PrintUtils";
import { Main } from "../Core/Main";
import { Context } from "../States/Context";
import { PrintStateTypes, StateTypes } from "../States/State";

/**
 * Print phase used to generate custom image.
 */
export interface PrintResult {
  [name: string]: string;
  deformity?: string;
  templating?: string;
}

/**
 * Generates images for use in the print report.
 */
export class PrintUtils extends PrintUtilsBase {
  protected _main: Main;
  protected _context: Context;

  constructor(main: Main, context: Context) {
    super(main, context);
  }

  /**
   * Generates two images (EOC and Templating).
   */
  public generateImages(stateType: StateTypes): PrintResult {
    const printResult: PrintResult = {};
    const tempData: TempDataToRestore = {};

    this.getRestoreData(tempData);
    if (this._main.hasAP && this._main.hasLT) {
      this._context.handle("setViewMultiple");
    }
    this.applyResize(850, 750);


    stateType >= StateTypes.deformityAnalysis && (printResult.deformity = this.generateImage(PrintStateTypes.deformityAnalysis));
    stateType >= StateTypes.EOC && (printResult.templating = this.generateImage(PrintStateTypes.defAnalysisNext));

    this.restoreData(tempData);

    return printResult;
  }
}
