/**
* Plate model
*/
export interface Plate {

  id: string;

  fileName: string;

  description: string;

  segment: string;

  bone: string;

  site: string;

  angle?: number;

  size: number;

  offset: number;

  flare?: number;

  holes: number;

  additionalInfos?: InfoWrapper;
}


interface InfoWrapper {

  meshList: MyConstellationInstance[];

  itemID: string;
}

export interface MyConstellationInstance {

  objId: string;

  verticesNum: number;

  position: number;

  type: MeshType;
}



export enum MeshType {
  plate,
  screw,
  nonlocking,
  wire
}
