import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService, ProductTypeEnum } from '../../core';
import { BaseComponent } from '../../shared';


/**
* Modal component to select correction method
*/
@Component({
	selector: 'app-eoc-modal',
	templateUrl: './eoc-modal.component.html'
})
export class EocModalComponent extends BaseComponent {

	@Input()
	set productList(list: ProductTypeEnum[]) {
		this.isMechVisible = list?.includes(ProductTypeEnum.JPS);
		this.isAnatVisible = list?.includes(ProductTypeEnum.TLHex);
		this.isRPMVisible = list?.includes(ProductTypeEnum.Fitbone)
	};

	isMechValid: boolean;
	isAnatValid: boolean;
	isMechVisible: boolean;
	isAnatVisible: boolean;
	isRPMVisible: boolean;
	prodTypeEnum = ProductTypeEnum;

	constructor(
		private langSrv: LanguageService,
		public activeModal: NgbActiveModal
	) {
		super(langSrv);
	}

}
