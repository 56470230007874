import { Component } from '@angular/core';
import { ViewType } from '@ortho-next/nextray-core';
import { LanguageService } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
 * Table for axial translation measures in anatomical analysis. 
 */
@Component({
	selector: 'axial-transl-table',
	templateUrl: './axial-transl-table.component.html'
})
export class AxialTranslTableComponent extends BaseComponent {

	isCollapsed: boolean = false;

	readonly viewEnum = ViewType;
	private readonly MAX_TRANSL = 200;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}


	/**
	* Get axial translation in AP image.
	*/
	get apAxialTranslMeasure(): string {
		return this.anatApexProxAP.def_ax_transl || this.anatApexProxAP.def_ax_transl === 0 ? `${this.anatApexProxAP.def_ax_transl} ${this.anatSignLabel(this.anatApexProxAP.def_ax_translSign)}` : '';
	}

	/**
	* Get axial translation in LT image.
	*/
	get ltAxialTranslMeasure(): string {
		return this.anatApexProxLT.def_ax_transl || this.anatApexProxLT.def_ax_transl === 0 ? `${this.anatApexProxLT.def_ax_transl} ${this.anatSignLabel(this.anatApexProxLT.def_ax_translSign)}` : '';
	}

	// VALIDATION

	/**
	* Check if axial translation in AP image is valid.
	*/
	get isValidAPAxialTransl(): boolean {
		return !this.anatApexProxAP?.def_ax_transl || this.anatApexProxAP.def_ax_transl <= this.MAX_TRANSL;
	}

	/**
	* Check if axial translation in LT image is valid.
	*/
	get isValidLTAxialTransl(): boolean {
		return !this.anatApexProxLT?.def_ax_transl || this.anatApexProxLT.def_ax_transl <= this.MAX_TRANSL;
	}

	/**
	* Update axial translation reference.
	*/
	updateRef(value: ViewType): void {
		if (this.stateType === this.stateTypeEnum.deformityAnalysis) {
			this.canvasSrv.dispatch('setAnatAxialTranslRef', value);
		}
	}

}
