import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';

@Component({
	selector: 'plat-instrument-drill-tables',
	templateUrl: './plat-instrument-drill-tables.component.html',
	styles: [
	]
})
export class PlatInstrumentDrillTablesComponent extends BaseComponent implements OnInit {

	is3mm: boolean;
	is3dot5mm: boolean;
	is5mm: boolean;
	title: string;
	titleColor: string;
	titleBackColor: string;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.initTable();
	}

	initTable(): void {
		const screwDiameter = this.canvasSrv.plate.size;
		switch (screwDiameter) {
			case 3:
				this.title = this.labels['JPS_3.0MM_SHORT_DRILL'];
				this.is3mm = true;
				this.titleColor = 'white';
				this.titleBackColor = '#196C3D';
				break;
			case 3.5:
				this.title = this.labels['JPS_3.5MM_SHORT_DRILL'];
				this.is3dot5mm = true;
				this.titleColor = 'black';
				this.titleBackColor = '#FCC026';
				break;
			case 5:
				this.title = this.labels['JPS_5.0MM_SHORT_DRILL'];
				this.is5mm = true;
				this.titleColor = 'white';
				this.titleBackColor = '#1D3D7A';
				break;
		}
	}

}
