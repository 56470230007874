<table aria-label="MNSA measures" class="wrapper-print-table">
	<thead>
		<tr>
			<th>{{ labels.MA_AP_TABLE_COMPONENT_MNSA }}</th>
			<th>{{ labels.DESIRED_MNSA }}</th>
			<th>{{ labels.DIFFERENCE }}</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>{{ mnsa }}</td>
			<td>{{ desideredMNSA }}</td>
			<td>{{ difference }}</td>
		</tr>
	</tbody>
</table>