import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ConstsBase } from '@ortho-next/nextray-core/Utils/Consts';
import { Utils } from '@ortho-next/nextray-core/Utils/Utils';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Table of deformity measures for AP image.
*/
@Component({
	selector: 'ma-ap-table',
	templateUrl: './ma-ap-table.component.html'
})
export class MaApTableComponent extends BaseComponent implements OnInit {

	isCollapsed: boolean = true;

	apAngles = { ...ConstsBase.paleyAngles.AP };

	apMeasuresForm: FormGroup;

	private readonly twoDigitsPattern: RegExp = /^\d$|^[1-9]\d{0,2}$/;
	readonly MIN_VALUE: number = 0;
	readonly MAX_VALUE: number = 99;


	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		const validators: ValidatorFn[] = [Validators.required, Validators.pattern(this.twoDigitsPattern), Validators.min(this.MIN_VALUE), Validators.max(this.MAX_VALUE)];
		this.apMeasuresForm = new FormGroup({
			'LPFA': new FormControl(this.def_standardAngles_LPFA, validators),
			'MLDFA': new FormControl(this.def_standardAngles_mLDFA, validators),
			'MPTA': new FormControl(this.def_standardAngles_MPTA, validators),
			'LDTA': new FormControl(this.def_standardAngles_LDTA, validators)
		});
	}


	// VISIBILITY

	/**
	* Check if MAD is visible.
	*/
	get isMAD(): boolean {
		return this.isLongLeg;
	}

	/**
	* Check if MNSA is visible.
	*/
	get isMNSA(): boolean {
		return this.isLongLeg || this.isFemur;
	}

	/**
	* Check if LPFA is visible.
	*/
	get isLPFA(): boolean {
		return this.isLongLeg || this.isFemur;
	}

	/**
	* Check if MPFA is visible.
	*/
	get isMPFA(): boolean {
		return this.isLongLeg || this.isFemur;
	}

	/**
	* Check if MLDFA is visible.
	*/
	get isMLDFA(): boolean {
		return this.isLongLeg || this.isFemur;
	}

	/**
	* Check if JLCA is visible.
	*/
	get isJLCA(): boolean {
		return this.isLongLeg;
	}

	/**
	* Check if Knee Translation is visible.
	*/
	get isKneeTransl(): boolean {
		return this.isLongLeg;
	}

	/**
	* Check if MPTA is visible.
	*/
	get isMPTA(): boolean {
		return this.isLongLeg || this.isTibia;
	}

	/**
	* Check if LDTA is visible.
	*/
	get isLDTA(): boolean {
		return this.isLongLeg || this.isTibia;
	}




	// VALIDATION

	/**
	* Check if MAD value is valid.
	*/
	get isValidMAD(): boolean {
		return !this.defAPMeasures.def_ap_MAD || Utils.isPaleyAngleValid(this.defAPMeasures.def_ap_MAD, this.apAngles.MAD);
	}

	/**
	* Check if MNSA value is valid.
	*/
	get isValidMNSA(): boolean {
		return !this.defAPMeasures.def_ap_MNSA || Utils.isPaleyAngleValid(this.defAPMeasures.def_ap_MNSA, this.apAngles.MNSA);
	}

	/**
	* Check if LPFA value is valid.
	*/
	get isValidLPFA(): boolean {
		return !this.defAPMeasures.def_ap_LPFA || Utils.isPaleyAngleValid(this.defAPMeasures.def_ap_LPFA, this.apAngles.mLPFA);
	}

	/**
	* Check if MPFA value is valid.
	*/
	get isValidMPFA(): boolean {
		return !this.defAPMeasures.def_ap_MPFA || Utils.isPaleyAngleValid(this.defAPMeasures.def_ap_MPFA, this.apAngles.MPFA);
	}

	/**
	* Check if MLDFA value is valid.
	*/
	get isValidMLDFA(): boolean {
		return !this.defAPMeasures.def_ap_mLDFA || Utils.isPaleyAngleValid(this.defAPMeasures.def_ap_mLDFA, this.apAngles.mLDFA);
	}

	/**
	* Check if JLCA value is valid.
	*/
	get isValidJLCA(): boolean {
		return !this.defAPMeasures.def_ap_JLCA || Utils.isPaleyAngleValid(this.defAPMeasures.def_ap_JLCA, this.apAngles.JLCA);
	}

	/**
	* Check if Knee Translation value is valid.
	*/
	get isValidKneeTransl(): boolean {
		return !this.defAPMeasures.def_ap_kneeTranslation || Utils.isPaleyAngleValid(this.defAPMeasures.def_ap_kneeTranslation, this.apAngles.KneeTransl);
	}

	/**
	* Check if MPTA value is valid.
	*/
	get isValidMPTA(): boolean {
		return !this.defAPMeasures.def_ap_MPTA || Utils.isPaleyAngleValid(this.defAPMeasures.def_ap_MPTA, this.apAngles.MPTA);
	}

	/**
	* Check if LDTA value is valid.
	*/
	get isValidLDTA(): boolean {
		return !this.defAPMeasures.def_ap_LDTA || Utils.isPaleyAngleValid(this.defAPMeasures.def_ap_LDTA, this.apAngles.LDTA);
	}

	/**
	* Check if a measure is out of range.
	*/
	get isMeasureOutOfRange(): boolean {
		return !this.isValidMAD || !this.isValidMNSA || !this.isValidLPFA || !this.isValidMPFA || !this.isValidMLDFA || !this.isValidJLCA || !this.isValidKneeTransl || !this.isValidMPTA || !this.isValidLDTA;
	}

	/**
	* Check if Contralateral LPFA value is valid.
	*/
	get isValidLPFA_CTRL(): boolean {
		return !!this.defCLMeasures.def_ap_LPFA_ctrlateral && this.defCLMeasures.def_ap_LPFA_ctrlateral <= this.MAX_VALUE;
	}

	/**
	* Check if Contralateral MLDFA value is valid.
	*/
	get isValidMLDFA_CTRL(): boolean {
		return !!this.defCLMeasures.def_ap_mLDFA_ctrlateral && this.defCLMeasures.def_ap_mLDFA_ctrlateral <= this.MAX_VALUE;
	}

	/**
	* Check if Contralateral MPTA value is valid.
	*/
	get isValidMPTA_CTRL(): boolean {
		return !!this.defCLMeasures.def_ap_MPTA_ctrlateral && this.defCLMeasures.def_ap_MPTA_ctrlateral <= this.MAX_VALUE;
	}

	/**
	* Check if Contralateral LDTA value is valid.
	*/
	get isValidLDTA_CTRL(): boolean {
		return !!this.defCLMeasures.def_ap_LDTA_ctrlateral && this.defCLMeasures.def_ap_LDTA_ctrlateral <= this.MAX_VALUE;
	}



	// ANGLES RANGE

	/**
	* Get MAD range.
	*/
	get rangeMAD(): string {
		return `(${this.apAngles.MAD.min}mm - ${this.apAngles.MAD.max}mm)`;
	}

	/**
	* Get MNSA range.
	*/
	get rangeMNSA(): string {
		return `(${this.apAngles.MNSA.min}° - ${this.apAngles.MNSA.max}°)`;
	}

	/**
	* Get LPFA range.
	*/
	get rangeLPFA(): string {
		return `(${this.apAngles.mLPFA.min}° - ${this.apAngles.mLPFA.max}°)`;
	}

	/**
	* Get MPFA range.
	*/
	get rangeMPFA(): string {
		return `(${this.apAngles.MPFA.min}° - ${this.apAngles.MPFA.max}°)`;
	}

	/**
	* Get MLDFA range.
	*/
	get rangeMLDFA(): string {
		return `(${this.apAngles.mLDFA.min}° - ${this.apAngles.mLDFA.max}°)`;
	}

	/**
	* Get JLCA range.
	*/
	get rangeJLCA(): string {
		return `(${this.apAngles.JLCA.min}° - ${this.apAngles.JLCA.max}°)`;
	}

	/**
	* Get Knee Translation range.
	*/
	get rangeKneeTransl(): string {
		return `(${this.apAngles.KneeTransl.min}mm - ${this.apAngles.KneeTransl.max}mm)`;
	}

	/**
	* Get MPTA range.
	*/
	get rangeMPTA(): string {
		return `(${this.apAngles.MPTA.min}° - ${this.apAngles.MPTA.max}°)`;
	}

	/**
	* Get LDTA range.
	*/
	get rangeLDTA(): string {
		return `(${this.apAngles.LDTA.min}° - ${this.apAngles.LDTA.max}°)`;
	}




	// ANGLES STANDARD

	/**
	* Check if LPFA target is disabled.
	*/
	get isDisableStandardLPFA(): boolean {
		return !this.defAPMeasures.def_ap_LPFA || this.stateType !== this.stateTypeEnum.deformityAnalysis;
	}

	/**
	* Check if MLDFA target is disabled.
	*/
	get isDisableStandardMLDFA(): boolean {
		return !this.defAPMeasures.def_ap_mLDFA || this.stateType !== this.stateTypeEnum.deformityAnalysis;
	}

	/**
	* Check if MPTA target is disabled.
	*/
	get isDisableStandardMPTA(): boolean {
		return !this.defAPMeasures.def_ap_MPTA || this.stateType !== this.stateTypeEnum.deformityAnalysis;
	}

	/**
	* Check if LDTA target is disabled.
	*/
	get isDisableStandardLDTA(): boolean {
		return !this.defAPMeasures.def_ap_LDTA || this.stateType !== this.stateTypeEnum.deformityAnalysis;
	}

	/**
* Check if LPFA copy is disabled.
*/
	get isDisableCopyLPFA(): boolean {
		return this.isDisableStandardLPFA || !this.isValidLPFA_CTRL;
	}

	/**
	* Check if MLDFA copy is disabled.
	*/
	get isDisableCopyMLDFA(): boolean {
		return this.isDisableStandardMLDFA || !this.isValidMLDFA_CTRL;
	}

	/**
	* Check if MPTA copy is disabled.
	*/
	get isDisableCopyMPTA(): boolean {
		return this.isDisableStandardMPTA || !this.isValidMPTA_CTRL;
	}

	/**
	* Check if LDTA copy is disabled.
	*/
	get isDisableCopyLDTA(): boolean {
		return this.isDisableStandardLDTA || !this.isValidLDTA_CTRL;
	}

	/**
	* Update LPFA target value.
	*/
	updateStandardLPFA(): void {
		if (!this.isDisableStandardLPFA) {
			if (this.apMeasuresForm.controls.LPFA.valid) {
				const target = parseInt(this.apMeasuresForm.controls.LPFA.value);
				this.canvasSrv.dispatch('setStandardAngle_LPFA', target);
			} else {
				this.apMeasuresForm.controls.LPFA.setValue(this.def_standardAngles_LPFA);
			}
		}
	}

	/**
	* Copy LPFA target value from Contralateral value.
	*/
	copyStandardLPFA(): void {
		if (!this.isDisableCopyLPFA) {
			this.apMeasuresForm.controls.LPFA.setValue(this.defCLMeasures.def_ap_LPFA_ctrlateral);
			this.updateStandardLPFA();
		}
	}

	/**
	* Update MLDFA target value.
	*/
	updateStandardMLDFA(): void {
		if (!this.isDisableStandardMLDFA) {
			if (this.apMeasuresForm.controls.MLDFA.valid) {
				const target = parseInt(this.apMeasuresForm.controls.MLDFA.value);
				this.canvasSrv.dispatch('setStandardAngle_mLDFA', target);
			} else {
				this.apMeasuresForm.controls.MLDFA.setValue(this.def_standardAngles_mLDFA);
			}
		}
	}

	/**
	* Copy MLDFA target value from Contralateral value.
	*/
	copyStandardMLDFA(): void {
		if (!this.isDisableCopyMLDFA) {
			this.apMeasuresForm.controls.MLDFA.setValue(this.defCLMeasures.def_ap_mLDFA_ctrlateral);
			this.updateStandardMLDFA();
		}
	}

	/**
	* Update MPTA target value.
	*/
	updateStandardMPTA(): void {
		if (!this.isDisableStandardMPTA) {
			if (this.apMeasuresForm.controls.MPTA.valid) {
				const target = parseInt(this.apMeasuresForm.controls.MPTA.value);
				this.canvasSrv.dispatch('setStandardAngle_MPTA', target);
			} else {
				this.apMeasuresForm.controls.MPTA.setValue(this.def_standardAngles_MPTA);
			}
		}
	}

	/**
	* Copy MPTA target value from Contralateral value.
	*/
	copyStandardMPTA(): void {
		if (!this.isDisableCopyMPTA) {
			this.apMeasuresForm.controls.MPTA.setValue(this.defCLMeasures.def_ap_MPTA_ctrlateral);
			this.updateStandardMPTA();
		}
	}

	/**
	* Update LDTA target value.
	*/
	updateStandardLDTA(): void {
		if (!this.isDisableStandardLDTA) {
			if (this.apMeasuresForm.controls.LDTA.valid) {
				const target = parseInt(this.apMeasuresForm.controls.LDTA.value);
				this.canvasSrv.dispatch('setStandardAngle_LDTA', target);
			} else {
				this.apMeasuresForm.controls.LDTA.setValue(this.def_standardAngles_LDTA);
			}
		}
	}

	/**
	* Copy LDTA target value from Contralateral value.
	*/
	copyStandardLDTA(): void {
		if (!this.isDisableCopyLDTA) {
			this.apMeasuresForm.controls.LDTA.setValue(this.defCLMeasures.def_ap_LDTA_ctrlateral);
			this.updateStandardLDTA();
		}
	}

}
