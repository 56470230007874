import { Component } from '@angular/core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';



/**
 * Component for anatomical correction analysis tables.
 */
@Component({
	selector: 'correction-anatomical-table',
	templateUrl: './correction-anatomical-table.component.html'
})
export class CorrectionAnatomicalTableComponent extends BaseComponent {

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

}
