
import { PlanTypeEnum, SelectedApexAnat, SelectedApexMech, ToolEvents, ViewType } from "@ortho-next/nextray-core";
import { BridgeResultMessages } from "../../../nextray/Core/Bridge";
import { Main } from "../../../nextray/Core/Main";
import { bindedModel } from "../../Models/BindedModel";
import { DeformityAnalysisType } from "../../Models/Enums";
import { Osteotomy } from "./Osteotomy";
import { OsteotomyAnatomical } from "./OsteotomyAnatomical";
import { OsteotomyAP } from "./OsteotomyAP";
import { OsteotomyLT } from "./OsteotomyLT";

export class OsteotomiesManager {
	public synced = false;
	public osteotomyAP: Osteotomy;
	public osteotomyLT: Osteotomy;
	private _isPostOp: boolean;

	public initBothViews(main: Main): void {
		this._isPostOp = main.isPostOp;
		const ostAP = main.toolsAP.osteotomy;
		const ostLT = main.toolsLT.osteotomy;

		if ((main.hasAP && ostAP && bindedModel.deformityAnalysisType != ostAP.deformityAnalysisType) || (main.hasLT && ostLT && bindedModel.deformityAnalysisType != ostLT.deformityAnalysisType)) {
			this.unregister();
			main.toolsAP.osteotomy = undefined;
			main.toolsLT.osteotomy = undefined;
		}

		main.hasAP && this.initAP(main);
		main.hasLT && this.initLT(main);
	}

	public initAP(main: Main): Osteotomy {
		this._isPostOp = main.isPostOp;
		const tools = main.toolsAP;
		if (!tools.osteotomy) {
			if (bindedModel.deformityAnalysisType === DeformityAnalysisType.mechanical) {
				tools.add(tools.osteotomy = new OsteotomyAP(tools.mechanicalAxis, tools.referencePoint));
				tools.osteotomy.addEventListener(ToolEvents.onError, () => {
					main.bridge.dispatchEvent({ type: "onResult", args: BridgeResultMessages.osteotomyLengthError });
				});
			} else {
				tools.add(tools.osteotomy = new OsteotomyAnatomical(ViewType.AP, tools.anatomicalAxis, tools.referencePoint, main.isPostOp, main.caseModel.type));
				main.measuresController.bindOsteotomy(ViewType.AP);
			}
			this.registerAP(tools.osteotomy);
		}
		return tools.osteotomy;
	}

	public initLT(main: Main): Osteotomy {
		this._isPostOp = main.isPostOp;
		const tools = main.toolsLT;
		if (!tools.osteotomy) {
			if (bindedModel.deformityAnalysisType === DeformityAnalysisType.mechanical) {
				tools.add(tools.osteotomy = new OsteotomyLT(tools.mechanicalAxis, tools.referencePoint));
				tools.osteotomy.addEventListener(ToolEvents.onError, (event) => {
					main.bridge.dispatchEvent({ type: "onResult", args: BridgeResultMessages.osteotomyLengthError });
				});
			} else {
				tools.add(tools.osteotomy = new OsteotomyAnatomical(ViewType.LT, tools.anatomicalAxis, tools.referencePoint, main.isPostOp, main.caseModel.type));
				main.measuresController.bindOsteotomy(ViewType.LT);
			}
			this.registerLT(tools.osteotomy);
		}
		return tools.osteotomy;
	}

	public registerAP(osteotomy: Osteotomy): void {
		this.osteotomyAP = osteotomy;
		this.bindSync();
	}

	public registerLT(osteotomy: Osteotomy): void {
		this.osteotomyLT = osteotomy;
		this.bindSync();
	}

	public unregister(): void {
		this.osteotomyAP?.dispose();
		this.osteotomyLT?.dispose();
		this.osteotomyAP = undefined;
		this.osteotomyLT = undefined;
		this.synced = false;
	}

	public bindSync(): void {
		if (this.osteotomyAP && this.osteotomyLT && !this.synced) {

			if (!this._isPostOp) {
				this.osteotomyAP.bindEvent("onAfterDragMove", () => { // TODO: history will not work
					this.osteotomyLT.distanceToReferencePoint = this.osteotomyAP.distanceToReferencePoint;
				});

				this.osteotomyLT.bindEvent("onAfterDragMove", () => {
					this.osteotomyAP.distanceToReferencePoint = this.osteotomyLT.distanceToReferencePoint;
				});
			}

			if (bindedModel.deformityAnalysisType === DeformityAnalysisType.anatomical) {

				this.osteotomyAP.bindEvent("onAfterDragMove", () => {
					this.updateWarning15degrees();
				});

				this.osteotomyLT.bindEvent("onAfterDragMove", () => {
					this.updateWarning15degrees();
				});
			}

			this.synced = true;
		}
	}

	public setPosition(selectedApex: SelectedApexMech | SelectedApexAnat, isPostOp: boolean): void {
		if (bindedModel.deformityAnalysisType === DeformityAnalysisType.mechanical) {
			this.osteotomyAP?.referencePoint.calculate(selectedApex as SelectedApexMech, (this.osteotomyAP as OsteotomyAP).mechanicalAxis);
			this.osteotomyLT?.referencePoint.calculate(selectedApex as SelectedApexMech, (this.osteotomyLT as OsteotomyAP).mechanicalAxis);
			this.osteotomyAP?.setPositionByApex(selectedApex);
			const osteotomyDistanceToReferencePointAP = (this.osteotomyAP as OsteotomyAP)?.mechanicalAxis.getApexAngle(selectedApex as SelectedApexMech) ? this.osteotomyAP.distanceToReferencePoint : undefined;
			this.osteotomyLT?.setPositionByApex(selectedApex, osteotomyDistanceToReferencePointAP);
			if (osteotomyDistanceToReferencePointAP === undefined && this.osteotomyAP && this.osteotomyLT) {
				this.osteotomyAP.distanceToReferencePoint = this.osteotomyLT.distanceToReferencePoint;
			}
		} else if (bindedModel.deformityAnalysisType === DeformityAnalysisType.anatomical) {
			if (!isPostOp && (this.osteotomyAP as OsteotomyAnatomical).planeType === PlanTypeEnum.Deformity) {
				this.osteotomyAP.setPositionByApex(selectedApex);
				const ostDistanceToRefPointAP = (this.osteotomyAP as OsteotomyAnatomical).isApexValid ? this.osteotomyAP.distanceToReferencePoint : undefined;
				this.osteotomyLT.setPositionByApex(selectedApex, ostDistanceToRefPointAP);
				if (ostDistanceToRefPointAP === undefined) {
					if ((this.osteotomyLT as OsteotomyAnatomical).isApexValid) {
						this.osteotomyAP.distanceToReferencePoint = this.osteotomyLT.distanceToReferencePoint;
					} else {
						this.osteotomyLT.distanceToReferencePoint = this.osteotomyAP.distanceToReferencePoint
					}
				}
			} else {
				this.osteotomyAP.setPositionByApex(SelectedApexAnat.none);
				this.osteotomyLT.setPositionByApex(SelectedApexAnat.none);
			}
			this.updateWarning15degrees();
		}
	}

	private updateWarning15degrees() {
		bindedModel.ostBeyond15Degrees =
			(this.osteotomyAP as OsteotomyAnatomical).isOsteotomyBeyond15Degrees() || (this.osteotomyLT as OsteotomyAnatomical).isOsteotomyBeyond15Degrees();
	}
}
