import { Directive, ElementRef, Input, OnChanges, OnDestroy } from "@angular/core";



/**
 * Directive to hightlight cell content changes.
 */
@Directive({
	selector: "[highlightCell]"
})
export class HighlightCellDirective implements OnChanges, OnDestroy {

	@Input() highlightCell: any;

	private interval: NodeJS.Timer;
	private readonly DELAY = 500;
	private readonly HIGHLIGHTED_CLASS = 'highlighted';

	constructor(private el: ElementRef) { }

	ngOnChanges(): void {
		const cell = this.el.nativeElement as HTMLElement;
		cell.classList.add(this.HIGHLIGHTED_CLASS);
		clearInterval(this.interval);
		this.interval = setInterval(() => {
			cell.classList.remove(this.HIGHLIGHTED_CLASS);
		}, this.DELAY);
	}

	ngOnDestroy(): void {
		clearInterval(this.interval);
	}
}