import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse, BoneTypeEnum, CaseBoneData, DeformityParameters, IntegrationRepository, ModelRepository, Plan, RayModel, RayModelTlHexDeformity } from '../core';
import { BaseService } from './base.service';
import { CaseService } from './case.service';


/**
* This service handles the ray analysis model.
*/
@Injectable()
export class ModelService extends BaseService {

	private _isReadOnly: boolean;

	constructor(
		private modelRepo: ModelRepository,
		private caseSrv: CaseService,
		private integrationRepo: IntegrationRepository
	) {
		super();
		this.caseSrv.isReadOnly().subscribe(res => this._isReadOnly = res);
	}

	/**
	* Save current model.
	*/
	persistModel(caseId: string, userGuid: string): Observable<void> {
		if (!this._isReadOnly) {
			const rayModel = this.rayModelMapper(caseId, userGuid);
			return this.modelRepo.saveModel(rayModel).pipe(map((res: ApiResponse<void>) => this.handleApiResponse(res)));
		}
		return of();
	}

	/**
	* Restore current model.
	*/
	restoreModel(caseId: string, userGuid: string): Observable<RayModel> {
		return this.modelRepo.getModel(caseId, userGuid).pipe(
			map((res: ApiResponse<RayModel>) => this.handleApiResponse(res))
		);
	}

	/**
	 * Save Next-Ray 3D model and deformity data.
	 */
	saveRayModelAndTlHexDeformity(plan: Plan, defData: DeformityParameters, eocData?: DeformityParameters): Observable<void> {
		if (!this._isReadOnly) {
			const rayModel = this.rayModelMapper(plan.id, plan.userGuid);
			const rayTlhexModel: RayModelTlHexDeformity = Object.assign({} as RayModelTlHexDeformity, rayModel);
			rayTlhexModel.userId = plan.userGuid;
			rayTlhexModel.bone = this.boneReq(plan);
			rayTlhexModel.deformity = defData;
			rayTlhexModel.deformityEOC = eocData;
			return this.integrationRepo.saveRayModelAndTlHexDeformity(rayTlhexModel).pipe(map((res: ApiResponse<void>) => this.handleApiResponse(res)));
		}
		return of();
	}

	private rayModelMapper = (caseId: string, userGuid: string): RayModel => {
		const model = (window as any).main._saveHandler.getUpdatedData(); // TODO: refactor
		return {
			version: environment.rayModelVersion,
			caseId: caseId,
			userGuid: userGuid,
			model
		};
	}

	private boneReq = (plan: Plan): CaseBoneData => ({
		type: plan.boneType == BoneTypeEnum.LongLeg ? "LongLeg" as BoneTypeEnum : plan.boneType,
		side: plan.side,
		reference: plan.referenceType
	});

}
