<table aria-label="LT mechanical measures" class="wrapper-print-table">
	<thead>
		<tr>
			<th>{{ labels.PRINT_MA_LT_TABLE_PARAMETER_HEADER }} <br />
				({{ (isLeft ? sideEnum.Left : sideEnum.Right) | anatomicalSideLabel }})</th>
			<th>{{ labels.VALUE }}</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngIf="isPDFA">
			<td>{{ labels.PRINT_PDFA_VAL }} {{ rangePDFA }}</td>
			<td>{{ defLTMeasures.def_lt_PDFA }}</td>
		</tr>
		<tr *ngIf="isKneeTransl">
			<td>{{ labels.PRINT_KNEE_TRANSL_VAL }} {{ rangeKneeTransl }}</td>
			<td>{{ defLTMeasures.def_lt_kneeTranslation }}</td>
		</tr>
		<tr *ngIf="isPPTA">
			<td>{{ labels.PRINT_PPTA_VAL }} {{ rangePPTA }}</td>
			<td>{{ defLTMeasures.def_lt_PPTA}}</td>
		</tr>
		<tr *ngIf="isADTA">
			<td>{{ labels.PRINT_ADTA_VAL }} {{ rangeADTA }}</td>
			<td>{{ defLTMeasures.def_lt_ADTA }}</td>
		</tr>
	</tbody>
</table>