import { Component } from '@angular/core';


/**
 * Main container component of application
 */
@Component({
	selector: 'container-template',
	templateUrl: './container-template.component.html'
})
export class ContainerTemplateComponent { }
