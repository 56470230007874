import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AuthCoreModule, AuthService } from '@ortho-next/auth-core';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom, map, of, switchMap } from 'rxjs';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AUTH_CONFIG } from './app.config';
import { APP_ROUTES } from './app.routes';
import { CaseRepository, ErrorsComponent, FallbackComponent, HttpErrorInterceptor, ImageRepository, IntegrationRepository, isGuid, LanguageService, LoaderService, ModelRepository, MonitoringErrorHandler, MonitoringService, ProductRepository, TlhexRepository, UserRepository } from './core';
import { PrintModule } from './print';
import { CanvasService, CaseService, HelpService, HexrayMapperService, ImageService, MainCanvasLoaderService, ModelService, PrinterService, ProductService, TlhexService, UserService } from './services';
import { TemplateModule } from './template';


export function initAuth(authSrv: AuthService) {
	return (): Promise<any> => {
		return authSrv.runInitialLoginSequence().then(() => {
			const urlParams = new URLSearchParams(window.location.search);
			const caseGuid = urlParams.get('caseGuid');
			if (!isGuid(caseGuid)) {
				window.location.href = environment.patplanSite;
			}
		});
	};
}

export function initApp(authSrv: AuthService, usrSrv: UserService, langSrv: LanguageService) {
	return (): Promise<any> => {
		return firstValueFrom(authSrv.isDoneLoading$.pipe(
			map(() => !!authSrv.accessToken),
			switchMap(isAuth => isAuth ? usrSrv.loadUser() : of(null)),
			switchMap(usr => usr ? langSrv.loadLabels(usr.language) : of(null))
		));
	};
}


@NgModule({
	declarations: [
		AppComponent,
		ErrorsComponent,
		FallbackComponent
	],
	imports: [
		FormsModule,
		BrowserModule,
		AuthCoreModule.forRoot(AUTH_CONFIG),
		RouterModule.forRoot(APP_ROUTES),
		CommonModule,
		ReactiveFormsModule,
		TemplateModule,
		HttpClientModule,
		PrintModule
	],
	providers: [
		CaseRepository,
		ImageRepository,
		CaseService,
		ImageService,
		MainCanvasLoaderService,
		CanvasService,
		ProductRepository,
		ProductService,
		CookieService,
		MonitoringService,
		PrinterService,
		LoaderService,
		UserService,
		UserRepository,
		ModelService,
		ModelRepository,
		HelpService,
		TlhexRepository,
		TlhexService,
		IntegrationRepository,
		HexrayMapperService,
		{ provide: ErrorHandler, useClass: MonitoringErrorHandler },
		LanguageService,
		{ provide: APP_INITIALIZER, useFactory: initAuth, deps: [AuthService], multi: true },
		{ provide: APP_INITIALIZER, useFactory: initApp, deps: [AuthService, UserService, LanguageService], multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
