import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectedApexAnat } from '@ortho-next/nextray-core';
import { Subscription } from 'rxjs';
import { DeformityAnalysisType } from '../../../nextray/Models/Enums';
import { LanguageService } from '../../core';
import { MaApRecapComponent, MaLtRecapComponent } from '../../correction';
import { MaApTableComponent, MaLtTableComponent } from '../../deformity';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
 * Second left vertical bar 
 */
@Component({
	selector: 'drawer',
	templateUrl: './drawer.component.html'
})
export class DrawerComponent extends BaseComponent implements OnInit, OnDestroy {

	isDeformityDrawerOpen: boolean;
	isCorrectionDrawerOpen: boolean;

	private _drawerClosureSub$: Subscription;

	private _maApTable: MaApTableComponent;
	@ViewChild(MaApTableComponent) set maApTable(table: MaApTableComponent) {
		setTimeout(() => {
			this._maApTable = table;
		}, 0);
	};

	private _maLtTable: MaLtTableComponent;
	@ViewChild(MaLtTableComponent) set maLtTable(table: MaLtTableComponent) {
		setTimeout(() => {
			this._maLtTable = table;
		}, 0);
	};

	private _maApRecap: MaApRecapComponent;
	@ViewChild(MaApRecapComponent) set maApRecap(table: MaApRecapComponent) {
		setTimeout(() => {
			this._maApRecap = table;
		}, 0);
	};

	private _maLtRecap: MaLtRecapComponent;
	@ViewChild(MaLtRecapComponent) set maLtRecap(table: MaLtRecapComponent) {
		setTimeout(() => {
			this._maLtRecap = table;
		}, 0);
	};

	constructor(
		private langSrv: LanguageService,
		public canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}


	ngOnInit(): void {
		this._drawerClosureSub$ = this.canvasSrv.drawerClosure().subscribe(() => this.isDeformityDrawerOpen = false);
	}

	/**
	* Check if deformity measures are out of range.
	*/
	get isDeformityOutOfRange(): boolean {
		switch (this.deformityAnalysisType) {
			case DeformityAnalysisType.mechanical: return this._maApTable?.isMeasureOutOfRange || this._maLtTable?.isMeasureOutOfRange;
			case DeformityAnalysisType.anatomical: return this.isInvalidAnatDefMeasure;
			default: return false;
		}
	}

	/**
	* Check if correction drawer is visible.
	*/
	get isCorrectionDrawerVisible(): boolean {
		if (this.stateType === this.stateTypeEnum.EOC) {
			switch (this.deformityAnalysisType) {
				case DeformityAnalysisType.mechanical: return true;
				case DeformityAnalysisType.anatomical: return !this.isThirdLineInserted || this.selectedApex !== SelectedApexAnat.none;
				default: return false;
			}
		}
		return (this.stateType === this.stateTypeEnum.templating) || (this.stateType === this.stateTypeEnum.tlhex);
	}

	/**
	* Check if correcttion measures are out of range.
	*/
	get isCorrectionOutOfRange(): boolean {
		switch (this.deformityAnalysisType) {
			case DeformityAnalysisType.mechanical: return this._maApRecap?.isMeasureOutOfRange || this._maLtRecap?.isMeasureOutOfRange;
			case DeformityAnalysisType.anatomical: return this.isInvalidAnatCorrMeasure;
			default: return false;
		}
	}

	/**
	 * Check if tooltip for fracture axial translation is visible.
	 */
	get isFractureTooltipVisible(): boolean {
		return this.isFracture && this.isAnatomicalAxisInserted && this.anatAxialTranslRef == null && !this.isDeformityDrawerOpen;
	}



	ngOnDestroy(): void {
		this._drawerClosureSub$?.unsubscribe();
	}

}
