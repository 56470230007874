<!-- Box table -->
<div class="box-table">
	<span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
		{{ labels.AXIAL_TRANSL_TABLE_COMPONENT_TITLE }}
		<div class="fake-material-icon icon-expand expand"></div>
	</span>
	<div [ngbCollapse]="isCollapsed">

		<!--TABLE-->
		<table aria-label="Axial translation mesures" class="table">
			<thead>
				<tr>
					<th></th>
					<th>
						{{ isForeFoot || isHindFoot ? labels.AXIAL_TRANSL_TABLE_COMPONENT_DORS_HEADER :
						labels.AXIAL_TRANSL_TABLE_COMPONENT_AP_HEADER }}
					</th>
					<th>{{ labels.AXIAL_TRANSL_TABLE_COMPONENT_LT_HEADER }}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td [class.cell-selected]="!isValidAPAxialTransl || !isValidLTAxialTransl">
						{{ labels.AXIAL_TRANSL_TABLE_COMPONENT_AXIAL_TRANSLATION }}
					</td>
					<td [class.cell-selected]="!isValidAPAxialTransl" [highlightCell]="apAxialTranslMeasure">
						{{ apAxialTranslMeasure }}
					</td>
					<td [class.cell-selected]="!isValidLTAxialTransl" [highlightCell]="ltAxialTranslMeasure">
						{{ ltAxialTranslMeasure }}
					</td>
				</tr>
				<tr [ngClass]="anatAxialTranslRef == null ? 'bg-error' : 'bg-tertiary'">
					<td>{{ labels.AXIAL_TRANSL_TABLE_COMPONENT_REFERENCE_MEASURE }}</td>
					<td>
						<div class="custom-control custom-radio d-flex justify-content-center align-items-start">
							<input type="radio" name="reference-measure" class="custom-control-input" [value]="viewEnum.AP"
								[ngModel]="anatAxialTranslRef" (ngModelChange)="updateRef($event)"
								[attr.disabled]="stateType !== stateTypeEnum.deformityAnalysis ? true : null" />
							<label class="custom-control-label"></label>
						</div>
					</td>
					<td>
						<div class="custom-control custom-radio d-flex justify-content-center align-items-start">
							<input type="radio" name="reference-measure" class="custom-control-input" [value]="viewEnum.LT"
								[ngModel]="anatAxialTranslRef" (ngModelChange)="updateRef($event)"
								[attr.disabled]="stateType !== stateTypeEnum.deformityAnalysis ? true : null" />
							<label class="custom-control-label"></label>
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<div class="invalid-feedback d-block" *ngIf="!isValidAPAxialTransl || !isValidLTAxialTransl">
			{{ labels.AXIAL_TRANSL_TABLE_COMPONENT_AXIAL_TRANSL_WARNING }}
		</div>
		<div class="invalid-feedback d-block" *ngIf="anatAxialTranslRef == null">
			{{ labels.AXIAL_TRANSL_TABLE_COMPONENT_REFERENCE_MEASURE_WARNING }}
		</div>

	</div>
</div>