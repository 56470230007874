import { Component } from '@angular/core';
import { ConstsBase } from '@ortho-next/nextray-core/Utils/Consts';
import { LanguageService } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
* Recap table of Lateral image of PDF print.
*/
@Component({
	selector: 'prt-ma-lt-table',
	templateUrl: './prt-ma-lt-table.component.html'
})
export class PrtMaLtTableComponent extends BaseComponent {

	ltAngles = { ...ConstsBase.paleyAngles.LT };

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	/**
	* Check if PDFA is visible.
	*/
	get isPDFA(): boolean {
		return this.isLongLeg || this.isFemur;
	}

	/**
	* Check if PPTA is visible.
	*/
	get isPPTA(): boolean {
		return this.isLongLeg || this.isTibia;
	}

	/**
	* Check if ADTA is visible.
	*/
	get isADTA(): boolean {
		return this.isLongLeg || this.isTibia;
	}

	/**
	* Check if Knee Translation is visible.
	*/
	get isKneeTransl(): boolean {
		return this.isLongLeg;
	}


	// ANGLES RANGE

	/**
	* Get PDFA range.
	*/
	get rangePDFA(): string {
		return `(${this.ltAngles.PDFA.min}° - ${this.ltAngles.PDFA.max}°)`;
	}

	/**
	* Get Knee Translation range.
	*/
	get rangeKneeTransl(): string {
		return `(${this.ltAngles.KneeTransl.min}mm - ${this.ltAngles.KneeTransl.max}mm)`;
	}

	/**
	* Get PPTA range.
	*/
	get rangePPTA(): string {
		return `(${this.ltAngles.PPTA.min}° - ${this.ltAngles.PPTA.max}°)`;
	}

	/**
	* Get ADTA range.
	*/
	get rangeADTA(): string {
		return `(${this.ltAngles.ADTA.min}° - ${this.ltAngles.ADTA.max}°)`;
	}

}
