import { bindedModelBase, BindedModelBase, BoneTypeEnum } from '@ortho-next/nextray-core';
import { OsteotomyCut, PrintStateTypes, StateTypes } from '../States/State';
import { DeformityAnalysisType } from './Enums';

/**
 * Model used by three.js part to create 3d objects binding.
 */
export class BindedModel extends BindedModelBase { // don't create obj or array properties //always initialize property
	/**
	 * Gets current state type.
	 */
	public appState: StateTypes = 0;
	/**
	 * Indicates if the AP registering mode is waiting that user set calibration mode before starts.
	 */
	public calibrationWaitingSelectionAP = false;
	/**
	 * Indicates if the lateral registering mode is waiting that user set calibration mode before starts.
	 */
	public calibrationWaitingSelectionLT = false;
	/**
	 * Indicates if user is inserting mechanical axis in AP view.
	 */
	public insertingMechanicalAxisAP = false;
	/**
	 * Indicates if user is inserting mechanical axis in lateral view.
	 */
	public insertingMechanicalAxisLT = false;
	/**
	 * Indicates if user is inserting contralateral mechanical axis in AP view.
	 */
	public insertingControlateralMechanicalAxis = false;
	/**
	 * Indicates deformity analysis type.
	 */
	public deformityAnalysisType = DeformityAnalysisType.none;
	/**
	 * Indicates if last AP mechanical axis inserted is valid.
	 */
	public isMechanicalAxisAPValid = false;
	/**
	 * Indicates if last lateral mechanical axis inserted is valid.
	 */
	public isMechanicalAxisLTValid = false;
	/**
	 * Indicates if reference point is inserted.
	 */
	public isReferencePointInserted = false;
	/**
	 * Indicates if anatomical axis is inserted.
	 */
	public isAnatomicalAxisInserted = false;
	/**
	 * Indicates if third line is inserted.
	 */
	public isThirdLineInserted = false;
	/**
	 * Indicates if last contralateral mechanical axis inserted is valid.
	 */
	public isControlateralMechanicalAxisValid = false;
	/**
	 * Indicates if EOC crop is vibile (switch is on).
	 */
	public EOCCropVisible = false;
	/**
	 * Binded variable used to change mesh visibility to generate images for print report.
	 */
	public printState: PrintStateTypes = 0;
	/**
	 * Indicates if osteotomy must be visible in the current state.
	 */
	public isOsteotomyValid = false;
	/**
	 * Indicates if the plate must be visible in the current state.
	 */
	public isPlateInserted = false;
	/**
	 * Gets the selected osteotomy cut to perform.
	 */
	public cutType: OsteotomyCut = 0;
	/**
	 * Gets bone type of Lateral view.
	 */
	public boneTypeLT: BoneTypeEnum = null;
	/**
	 * Indicates if osteotomy is more inclinated than 15 degrees.
	 */
	public ostBeyond15Degrees = false;
	/**
	 * Indicates if bone length is equals to suggested bone length.
	 */
	public boneLengthisEqualsToSuggested = true;
	/**
	 * Anatomical axial translation difference to apply for fracture case.
	 */
	public anatAxialTranslDifAP = 0;
	/**
	* Anatomical axial translation difference to apply for fracture case.
	*/
	public anatAxialTranslDifLT = 0;

	/**
	 * Indicates if mechAxis_anatomical layer is enabled.
	 */
	public layer_mechAxis_anatomical = true;
	/**
	 * Indicates if mechAxis_mechanical layer is enabled.
	 */
	public layer_mechAxis_mechanical = true;
	/**
	 * Indicates if mechAxis_weightBearing layer is enabled.
	 */
	public layer_mechAxis_weightBearing = true;
	/**
	 * Indicates if Reference Point layer is enabled.
	 */
	public layer_referencePoint = true;
	/**
	 * Indicates if Anatomical Axis layer is enabled.
	 */
	public layer_anatAxis = true;
	/**
	 * Indicates if Osteotomy layer is enabled.
	 */
	public layer_osteotomy = true;
	/**
	 * Indicates if contralateral_all layer is enabled.
	 */
	public layer_contralateral_all = true;
	/**
	 * Indicates if plate layer is enabled.
	 */
	public layer_plate = true;
}

export const bindedModel: BindedModel = bindedModelBase as BindedModel;
