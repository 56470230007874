<!-- Box table -->
<div class="box-table">
	<span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
		{{ isForeFoot || isHindFoot ? labels.AA_AP_TABLE_COMPONENT_TITLE_DORS : labels.AA_AP_TABLE_COMPONENT_TITLE }}
		<div class="fake-material-icon icon-expand expand"></div>
	</span>
	<div [ngbCollapse]="isCollapsed">

		<!--TABLE-->
		<table aria-label="AP anatomical deformity measures for proximal" class="table" *ngIf="isProxTabVisible">
			<thead *ngIf="isThirdLineInserted">
				<tr>
					<th colspan="2">{{ labels.AA_AP_TABLE_COMPONENT_APEX_PROX }}</th>
				</tr>
			</thead>
			<tbody>
				<tr [class.row-selected]="!isValidAPProxAngDef">
					<td>{{ labels.AA_AP_TABLE_COMPONENT_ANGULAR_DEFORMITY }}</td>
					<td [highlightCell]="apProxAngDef">{{ apProxAngDef }}</td>
				</tr>
				<tr [class.row-selected]="!isValidAPProxLatTransl">
					<td>{{ labels.AA_AP_TABLE_COMPONENT_LATERAL_TRANSLATION }}</td>
					<td [highlightCell]="apProxLatTransl">{{ apProxLatTransl }}</td>
				</tr>
			</tbody>
		</table>

		<!--TABLE-->
		<table aria-label="AP anatomical deformity measures for distal" class="table" *ngIf="isDistTabVisible">
			<thead>
				<tr>
					<th colspan="2">{{ labels.AA_AP_TABLE_COMPONENT_APEX_DIST }}</th>
				</tr>
			</thead>
			<tbody>
				<tr [class.row-selected]="!isValidAPDistAngDef">
					<td>{{ labels.AA_AP_TABLE_COMPONENT_ANGULAR_DEFORMITY }}</td>
					<td [highlightCell]="apDistAngDef">{{ apDistAngDef }}</td>
				</tr>
				<tr [class.row-selected]="!isValidAPDistLatTransl">
					<td>{{ labels.AA_AP_TABLE_COMPONENT_LATERAL_TRANSLATION }}</td>
					<td [highlightCell]="apDistLatTransl">{{ apDistLatTransl }}</td>
				</tr>
			</tbody>
		</table>

		<div class="invalid-feedback d-block" *ngIf="!isValidAPProxAngDef || !isValidAPDistAngDef">
			{{ labels.AA_AP_TABLE_COMPONENT_ANG_DEF_WARNING }}
		</div>
		<div class="invalid-feedback d-block" *ngIf="!isValidAPProxLatTransl || !isValidAPDistLatTransl">
			{{ labels.AA_AP_TABLE_COMPONENT_LAT_TRANSL_WARNING }}
		</div>

	</div>
</div>