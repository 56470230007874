import { Apex, CaseModel, ViewType } from "@ortho-next/nextray-core";
import { MeasuresCalculator as MeasuresCalculatorBase } from "@ortho-next/nextray-core/Core/MeasuresCalculator";
import { NextRayMeasures, NextrayMeasuresAnatomicalApex } from "../Models/AppModel";
import { bindedModel } from "../Models/BindedModel";
import { EoCPlaneAnatomical } from "../Tools/EoCPlane/EoCPlaneAnatomical";
import { EoCPlaneMechanical } from "../Tools/EoCPlane/EoCPlaneMechanical";
import { OsteotomyAnatomical } from "../Tools/Osteotomy/OsteotomyAnatomical";
import { FormatUtils } from "../Utils/FormatUtils";
import { MeasuresUtils } from "../Utils/MeasuresUtils";
import { Main } from "./Main";

/**
 * Calculates measures.
 */
export class MeasuresCalculator extends MeasuresCalculatorBase implements NextRayMeasures {
	protected _main: Main;

	constructor(main: Main) {
		super(main);
	}

	public get eoc_ap_LPFA(): number {
		return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.femur.LPFA.value) : 0;
	}

	public get eoc_ap_mLDFA(): number {
		return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.femur.mLDFA.value) : 0;
	}

	public get eoc_ap_MPTA(): number {
		return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.tibia.MPTA.value) : 0;
	}

	public get eoc_ap_LDTA(): number {
		return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.tibia.LDTA.value) : 0;
	}

	public get eoc_ap_JLCA(): number {
		return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.JLCA.value) : 0;
	}

	public get eoc_ap_MAD(): number {
		return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.distance(this._main.toolsAP.clonedMechanicalAxis.MAD.value) : 0;
	}

	public get eoc_ap_MNSA(): number {
		return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.femur.MNSA.value) : 0;
	}

	public get eoc_ap_MPFA(): number {
		return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.femur.MPFA.value) : 0;
	}

	public get eoc_ap_aLDFA(): number {
		return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.angleRadiants(this._main.toolsAP.clonedMechanicalAxis.femur.aLDFA.value) : 0;
	}

	public get eoc_ap_kneeTranslation(): number {
		return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.distance(this._main.toolsAP.clonedMechanicalAxis.kneeTranslation.value) : 0;
	}

	public get eoc_ap_fullLength(): number {
		return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.distance(this._main.toolsAP.clonedMechanicalAxis.length.value) : 0;
	}

	public get eoc_ap_femurLength(): number {
		return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.distance(this._main.toolsAP.clonedMechanicalAxis.femur.length.value) : 0;
	}

	public get eoc_ap_tibiaLength(): number {
		return this._main.toolsAP.clonedMechanicalAxis ? FormatUtils.distance(this._main.toolsAP.clonedMechanicalAxis.tibia.length.value) : 0;
	}

	public get eoc_lt_PDFA(): number {
		const clonedMA = this._main.toolsLT.clonedMechanicalAxis;
		return clonedMA && clonedMA.femur ? FormatUtils.angleRadiants(clonedMA.femur.PDFA.value) : null;
	}

	public get eoc_lt_PPTA(): number {
		const clonedMA = this._main.toolsLT.clonedMechanicalAxis;
		return clonedMA && clonedMA.tibia ? FormatUtils.angleRadiants(clonedMA.tibia.PPTA.value) : null;
	}

	public get eoc_lt_ADTA(): number {
		const clonedMA = this._main.toolsLT.clonedMechanicalAxis;
		return clonedMA && clonedMA.tibia ? FormatUtils.angleRadiants(clonedMA.tibia.ADTA.value) : null;
	}

	public get eoc_lt_kneeTranslation(): number {
		const clonedMA = this._main.toolsLT.clonedMechanicalAxis;
		return clonedMA && clonedMA.kneeTranslation && clonedMA.kneeTranslation.value ? FormatUtils.distance(clonedMA.kneeTranslation.value) : null;
	}

	public get eoc_ap_axialLength(): number {
		if (!bindedModel.EOCCropVisible) return null;
		return this._main.toolsAP.EoCPlane ? FormatUtils.distance(this._main.toolsAP.EoCPlane.verticalTranslation) : 0;
	}

	public get eoc_ap_cortexLength(): number {
		if (!bindedModel.EOCCropVisible) return null;
		return FormatUtils.distance((this._main.toolsAP.EoCPlane as EoCPlaneMechanical)?.cortexLength) ?? 0;
	}

	public get eoc_ap_angle(): number {
		return this._main.toolsAP.EoCPlane ? FormatUtils.angleRadiants(this._main.toolsAP.EoCPlane.angle) : 0;
	}

	public get eoc_ap_secondaryTransl(): number {
		if (!bindedModel.EOCCropVisible) return null;
		return this._main.toolsAP.EoCPlane ? FormatUtils.distance(this._main.toolsAP.EoCPlane.horizontalTranslation) : 0;
	}

	public get eoc_lt_axialLength(): number {
		if (!bindedModel.EOCCropVisible) return null;
		return this._main.toolsLT.EoCPlane ? FormatUtils.distance(this._main.toolsLT.EoCPlane.verticalTranslation) : 0;
	}

	public get eoc_lt_cortexLength(): number {
		if (!bindedModel.EOCCropVisible) return null;
		return FormatUtils.distance((this._main.toolsLT.EoCPlane as EoCPlaneMechanical)?.cortexLength) ?? 0;
	}

	public get eoc_lt_angle(): number {
		return this._main.toolsLT.EoCPlane ? FormatUtils.angleRadiants(this._main.toolsLT.EoCPlane.angle) : 0
	}

	public get eoc_lt_secondaryTransl(): number {
		if (!bindedModel.EOCCropVisible) return null;
		return this._main.toolsLT.EoCPlane ? FormatUtils.distance(this._main.toolsLT.EoCPlane.horizontalTranslation) : 0;
	}

	public get def_ap_apex1(): NextrayMeasuresAnatomicalApex {
		return ApexMeasuresCalculator.getMeasures(this._main.caseModel, this._main.toolsAP.anatomicalAxis?.apexCalculator.apexes[0],
			this._main.toolsAP.osteotomy as OsteotomyAnatomical, ViewType.AP);
	}

	public get def_lt_apex1(): NextrayMeasuresAnatomicalApex {
		return ApexMeasuresCalculator.getMeasures(this._main.caseModel, this._main.toolsLT.anatomicalAxis?.apexCalculator.apexes[0],
			this._main.toolsLT.osteotomy as OsteotomyAnatomical, ViewType.LT);
	}

	public get def_ap_apex2(): NextrayMeasuresAnatomicalApex {
		return ApexMeasuresCalculator.getMeasures(this._main.caseModel, this._main.toolsAP.anatomicalAxis?.apexCalculator.apexes[1],
			this._main.toolsAP.osteotomy as OsteotomyAnatomical, ViewType.AP);
	}

	public get def_lt_apex2(): NextrayMeasuresAnatomicalApex {
		return ApexMeasuresCalculator.getMeasures(this._main.caseModel, this._main.toolsLT.anatomicalAxis?.apexCalculator.apexes[1],
			this._main.toolsLT.osteotomy as OsteotomyAnatomical, ViewType.LT);
	}

	public get def_suggestedBoneLength(): number {
		return Math.max(
			MeasuresUtils.calculateSuggestedBoneLength(this._main.caseModel.type, this._main.caseModel.referenceType, this._main.toolsAP.osteotomy as OsteotomyAnatomical),
			MeasuresUtils.calculateSuggestedBoneLength(this._main.caseModel.type, this._main.caseModel.referenceType, this._main.toolsLT.osteotomy as OsteotomyAnatomical)
		);
	}

	public get eoc_ap_overAng(): number {
		return FormatUtils.angleRadiants(Math.abs(this._main.toolsAP.EoCPlane.angle));
	}

	public get eoc_ap_overAngSign(): string {
		return FormatUtils.overAngSign(this._main.toolsAP.EoCPlane.angle);
	}

	public get eoc_ap_overTransl(): number {
		return FormatUtils.distance(Math.abs((this._main.toolsAP.EoCPlane as EoCPlaneAnatomical).horizontalTranslation));
	}

	public get eoc_ap_overTranslSign(): string {
		return FormatUtils.overTranslSign((this._main.toolsAP.EoCPlane as EoCPlaneAnatomical).horizontalTranslation);
	}

	public get eoc_boneLength(): number {
		return FormatUtils.distance(Math.abs(this._main.toolsAP.EoCPlane.verticalTranslation));
	}

	public get eoc_boneLengthSign(): string {
		return FormatUtils.boneLengthSign(this._main.toolsAP.EoCPlane.verticalTranslation);
	}

	public get eoc_lt_overAng(): number {
		return FormatUtils.angleRadiants(Math.abs(this._main.toolsLT.EoCPlane.angle));
	}

	public get eoc_lt_overAngSign(): string {
		return FormatUtils.overAngSign(this._main.toolsLT.EoCPlane.angle);
	}

	public get eoc_lt_overTransl(): number {
		return FormatUtils.distance(Math.abs((this._main.toolsLT.EoCPlane as EoCPlaneAnatomical).horizontalTranslation));
	}

	public get eoc_lt_overTranslSign(): string {
		return FormatUtils.overTranslSign((this._main.toolsLT.EoCPlane as EoCPlaneAnatomical).horizontalTranslation);
	}
}

export class ApexMeasuresCalculator {
	public static getMeasures(model: CaseModel, apex: Apex, osteotomy: OsteotomyAnatomical, viewType: ViewType): NextrayMeasuresAnatomicalApex {
		return apex ? {
			def_angDef: FormatUtils.angleRadiants(Math.abs(apex.angularDeformity.value)),
			def_angDefSign: FormatUtils.angDefSign(apex.angularDeformity.value, model.boneType, viewType),
			def_transl: FormatUtils.distance(
				Math.abs(MeasuresUtils.getAnatomicalDefTranslation(apex, osteotomy, model.side, model.type, model.boneType, viewType, model.isPostOperative))
			),
			def_translSign: FormatUtils.translSign(
				MeasuresUtils.getAnatomicalDefTranslation(apex, osteotomy, model.side, model.type, model.boneType, viewType, model.isPostOperative), model.boneType, viewType
			),
			def_ax_transl: FormatUtils.distance(Math.abs(apex.axialTranslation.value)),
			def_ax_translSign: FormatUtils.axTranslSign(apex.axialTranslation.value, model.boneType)
		} : undefined;
	}
}
