import { Save } from "@ortho-next/nextray-core/Core/Save";
import { BufferGeometry, Mesh, MeshBasicMaterial, ShapeBufferGeometry } from "@ortho-next/three-base/three.js/build/three.module";

export class BlackPlane extends Mesh {
  @Save("attributes.position", "attributes.normal", "attributes.uv", "index")
  public geometry: ShapeBufferGeometry; //override
  public material: MeshBasicMaterial; //override

  public onAfterRestore = () => {
    this.geometry.computeBoundingSphere();
  }

  constructor() {
    super(new BufferGeometry(), new MeshBasicMaterial({ color: 0x000000 }));
    this.interceptedByRaycaster = false;
    this.name = "BlackPlane";
  }

  public setGeometry(geometry: ShapeBufferGeometry) {
    this.geometry.dispose();
    this.geometry = geometry;
  }

}
