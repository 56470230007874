import { CaseModel, PlanTypeEnum, StateTransitionData, ViewType } from "@ortho-next/nextray-core";
import { bindedModel } from "../Models/BindedModel";
import { Context } from "./Context";
import { Actions, RegisteringType, State, StateTypes, WorkflowRunning } from "./State";

/**
 * State indicating that both mechanical axis have been placed.
 */
export class DeformityAnalysis extends State {
	public stateDescription = {
		isWorkflowRunning: WorkflowRunning.none,
		isPointsPositioningRunning: false
	};

	private _isAPMechanicalAxisInserting: boolean;
	private _isLTMechanicalAxisInserting: boolean;
	private _isControlateralMechanicalAxisInserting: boolean;

	constructor(context: Context, caseModel: CaseModel, restore?: object) {
		super(context, caseModel, StateTypes.deformityAnalysis, restore);
	}

	public initState(): void {
		if (this._restore) {
			if (this._caseModel.imgAP) {
				this.dispatchToMain("setView", ViewType.AP);
			} else {
				this.dispatchToMain("setView", ViewType.LT);
			}
		}
	}

	protected handleChangeStateEvent<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData {
		switch (event) {
			case "insertMechanicalAxis":
				const isValid = bindedModel[args === ViewType.AP ? "isMechanicalAxisAPValid" : "isMechanicalAxisLTValid"];
				if (!isValid) {
					this[args === ViewType.AP ? "_isAPMechanicalAxisInserting" : "_isLTMechanicalAxisInserting"] = true;
					this.stateDescription.isWorkflowRunning = WorkflowRunning.insertMechanicalAxis;
					this.stateDescription.isPointsPositioningRunning = true;
				}
				return this.dispatchToMain(event, args);
			case "insertControlaterMechanicalAxis":
				if (!bindedModel.isControlateralMechanicalAxisValid) {
					this._isControlateralMechanicalAxisInserting = true;
					this.stateDescription.isWorkflowRunning = WorkflowRunning.insertContralateral;
					this.stateDescription.isPointsPositioningRunning = true;
				}
				return this.dispatchToMain(event, args);
			case "startBoneTypeSelection":
				this.stateDescription.isWorkflowRunning = WorkflowRunning.boneTypeSelection;
				return this.dispatchToMain(event, args);
			case "cancelBoneTypeSelection":
			case "confirmBoneTypeSelection":
				this.stateDescription.isWorkflowRunning = WorkflowRunning.none;
				return this.dispatchToMain(event, args);
			case "insertAnatomicalAxis":
			case "setStandardAngle":
			case "setAxialRotation":
			case "setAxialRotationSign":
			case "setDefAnatAxialRot":
			case "setDefAnatAxialRotSign":
			case "setDefAnatAxialTrans":
			case "setDefAnatAxialTransSign":
			case "setAnatAxialTranslRef":
			case "generateImagesToPrint":
			case "updateMeausuresOnlyPrint":
			case "switchApex":
			case "switchProximal":
			case "switchDistal":
				return this.dispatchToMain(event, args);
			case "completeRegistering": {
				this.stateDescription.isWorkflowRunning = WorkflowRunning.none;
				if (this._isControlateralMechanicalAxisInserting) {
					this._isControlateralMechanicalAxisInserting = false;
					return this.dispatchToMain("completeRegistering", RegisteringType.contralateral);
				} else if (this._isAPMechanicalAxisInserting || this._isLTMechanicalAxisInserting) {
					const data = this.dispatchToMain("completeRegistering", this._isAPMechanicalAxisInserting ? RegisteringType.mechanicalAxisAP : RegisteringType.mechanicalAxisLT);
					this[this._isAPMechanicalAxisInserting ? "_isAPMechanicalAxisInserting" : "_isLTMechanicalAxisInserting"] = false;
					return data;
				}
				return { type: this.type };
			}
			case "endRegistering":
				this.stateDescription.isPointsPositioningRunning = false;
				return this.dispatchToMain(event, args);
			case "stopRegistering":
				this._isAPMechanicalAxisInserting = false;
				this._isLTMechanicalAxisInserting = false;
				this._isControlateralMechanicalAxisInserting = false;
				this.stateDescription.isWorkflowRunning = WorkflowRunning.none;
				return this.dispatchToMain(event, args);
			case "resetRegistering":
				this.stateDescription.isPointsPositioningRunning = true;
				return this.dispatchToMain(event, args);
			case "switchToCalibrated":
				this.dispatchToMain(event, args);
				return { type: StateTypes.calibrated, data: { caseModel: this._caseModel } };
			case "switchToRPM":
				this.dispatchToMain(event, args);
				return { type: StateTypes.RPM, data: { caseModel: this._caseModel } };
			case "switchToEOC":
				this.dispatchToMain(event, args);
				return { type: StateTypes.EOC, data: { caseModel: this._caseModel } };
			case "startRefPointInsertion":
				this.stateDescription.isWorkflowRunning = WorkflowRunning.insertRefPoint;
				return this.dispatchToMain(event, args);
			case "cancelRefPoint":
			case "confirmRefPoint":
				this.stateDescription.isWorkflowRunning = WorkflowRunning.none;
				return this.dispatchToMain(event, args);
			case "startPostopOstInsertion":
				this._caseModel.type === PlanTypeEnum.Deformity && (this.stateDescription.isWorkflowRunning = WorkflowRunning.insertPostopOst);
				return this.dispatchToMain(event, args);
			case "cancelPostopOst":
			case "confirmPostopOst":
				this.stateDescription.isWorkflowRunning = WorkflowRunning.none;
				return this.dispatchToMain(event, args);
			case "resetCheckupEOC":
				return this.dispatchToMain(event, args);
		}
	}

}
