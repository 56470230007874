import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';



/**
* Component of first vertical sidebar tool menu to reverse planning method.
*/
@Component({
	selector: 'rpm-menu',
	templateUrl: './rpm-menu.component.html'
})
export class RpmMenuComponent extends BaseComponent {

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}


	goToRPM(): void {
		const currentCase = this.canvasSrv.currentCase;
		window.location.href = environment.templatesSite + '?caseGuid=' + currentCase.id;
	}

}
