import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared';
import { AaOsteotomyWorkflowComponent } from './aa-osteotomy-workflow';
import { CorrAaApTableComponent, CorrAaLtTableComponent, CorrAxialViewTableComponent, CorrBoneLengthTableComponent, CorrectionAnatomicalTableComponent } from './correction-anatomical-table';
import { CorrectionMenuComponent } from './correction-menu';
import { EocModalComponent } from './eoc-modal/eoc-modal.component';
import { LldRecapComponent } from './lld-recap';
import { MaApRecapComponent } from './ma-ap-recap';
import { MaLtRecapComponent } from './ma-lt-recap';
import { OsteotomyWorkflowComponent } from './osteotomy-workflow';
import { WedgeTableComponent } from './wedge-table';

@NgModule({
	declarations: [
		OsteotomyWorkflowComponent,
		WedgeTableComponent,
		MaApRecapComponent,
		MaLtRecapComponent,
		LldRecapComponent,
		EocModalComponent,
		AaOsteotomyWorkflowComponent,
		CorrectionAnatomicalTableComponent,
		CorrAaApTableComponent,
		CorrAaLtTableComponent,
		CorrAxialViewTableComponent,
		CorrectionMenuComponent,
		CorrBoneLengthTableComponent
	],
	exports: [
		OsteotomyWorkflowComponent,
		WedgeTableComponent,
		MaApRecapComponent,
		MaLtRecapComponent,
		LldRecapComponent,
		AaOsteotomyWorkflowComponent,
		CorrectionAnatomicalTableComponent,
		CorrectionMenuComponent
	],
	entryComponents: [
		EocModalComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgbCollapseModule,
		SharedModule
	]
})
export class CorrectionModule { }
