import { IAnatomicalAxis, ViewType, ApexCalculator as ApexCalculatorBase, ReferenceTypeEnum, AnatomicalSideEnum, PlanTypeEnum } from "@ortho-next/nextray-core";
import { StateTypes } from "../../States/State";
import { BindedModel } from "../../Models/BindedModel";

export class ApexCalculator extends ApexCalculatorBase {

	constructor(viewType: ViewType, axis1: IAnatomicalAxis, axis2: IAnatomicalAxis, planType: PlanTypeEnum, side: AnatomicalSideEnum, refType: ReferenceTypeEnum) {
		super(viewType, axis1, axis2, planType, side, refType);

		this.bindProperty('visible', (m: BindedModel) => { return !m.isThirdLineInserted }, ['isThirdLineInserted']);

		if (planType === PlanTypeEnum.Deformity) {
			this.line1.bindProperty('visible', (m: BindedModel) => { return m.appState === StateTypes.deformityAnalysis }, ['appState']);
			this.line2.bindProperty('visible', (m: BindedModel) => { return m.appState === StateTypes.deformityAnalysis }, ['appState']);
		}
	}
}
