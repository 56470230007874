<!-- Box table -->
<div class="box-table">
  <span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
    {{ labels.CORR_BONE_LENGTH_TABLE_COMPONENT_TITLE }}
    <div class="fake-material-icon icon-expand expand"></div>
  </span>
  <div [ngbCollapse]="isCollapsed">

    <!--TABLE-->
    <table role="none" class="table" *ngIf="isDeformity">
      <tbody>
        <tr [class.row-selected]="!isSuggestedBoneLengthValid">
          <td>{{ labels.CORR_BONE_LENGTH_TABLE_COMPONENT_SUGGESTED_BONE_LENGTH }}</td>
          <td [highlightCell]="eocSuggestedBoneLength">{{eocSuggestedBoneLength}}
            {{labels.CORR_BONE_LENGTH_TABLE_COMPONENT_LENGTHENING}}</td>
          <td><span class="material-icons copy-value" [ngClass]="{'disabled': isCopyDisabled }"
              (click)="copyBoneLength()">file_copy</span>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="invalid-feedback d-block" *ngIf="!isSuggestedBoneLengthValid">{{invalidSuggestedBoneLengthMsg}}</div>

    <div class="invalid-feedback d-block text-warning mt-4 mb-4" *ngIf="isOstInclinationWarningVisible">
      {{labels.CORR_BONE_LENGTH_TABLE_COMPONENT_OST_INCLINATION_WARNING}}
    </div>

    <!--TABLE-->
    <table role="none" class="table">
      <tbody>
        <tr [class.row-selected]="!isBoneLengthValid">
          <td>{{ labels.CORR_BONE_LENGTH_TABLE_COMPONENT_BONE_LENGTH }}</td>
          <td class="pl-0" [highlightCell]="boneLength">
            <form class="d-flex align-items-center" [formGroup]="boneLengthForm">
              <div class="spinner d-flex">
                <span class="material-icons md-18" [ngClass]="{'disabled': isBoneLengthDisabled}"
                  (click)="decrementBoneLength()">remove</span>
                <input type="text" maxlength="3" class="custom-control" style="max-width: 4rem" name="boneLengthValue"
                  formControlName="boneLengthValue" (keyup.enter)="updateBoneLength()" (blur)="updateBoneLength()"
                  [ngModel]="boneLength" [ngClass]="{'is-invalid': boneLengthForm.controls.boneLengthValue.invalid }"
                  [attr.disabled]="isBoneLengthDisabled ? true : null" />
                <span class="material-icons md-18" [ngClass]="{'disabled': isBoneLengthDisabled}"
                  (click)="incrementBoneLength()">add</span>
              </div>
              <div class="form-group pb-0">
                <div class="custom-control custom-radio">
                  <input type="radio" id="boneLength_shortening" name="boneLengthSign" class="custom-control-input"
                    formControlName="boneLengthSign" (click)="updateBoneLengthSign(boneLengthEnum.short)"
                    [ngModel]="boneLengthSign" [value]="boneLengthEnum.short"
                    [attr.disabled]="isBoneLengthDisabled ? true : null" />
                  <label class="custom-control-label"
                    for="boneLength_shortening">{{labels.CORR_BONE_LENGTH_TABLE_COMPONENT_SHORTENING}}</label>
                </div>
                <div class="custom-control custom-radio">
                  <input type="radio" id="boneLength_lengthening" name="boneLengthSign" class="custom-control-input"
                    formControlName="boneLengthSign" (click)="updateBoneLengthSign(boneLengthEnum.long)"
                    [ngModel]="boneLengthSign" [value]="boneLengthEnum.long"
                    [attr.disabled]="isBoneLengthDisabled ? true : null" />
                  <label class="custom-control-label"
                    for="boneLength_lengthening">{{labels.CORR_BONE_LENGTH_TABLE_COMPONENT_LENGTHENING}}</label>
                </div>
              </div>
            </form>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="invalid-feedback d-block" *ngIf="!isBoneLengthValid">{{invalidBoneLengthMsg}}</div>

  </div>
</div>
