import { AnatomicalAxis, AnatomicalSideEnum, DraggablePoint, PlanTypeEnum, ReferenceTypeEnum, SaveChild, VectorUtils } from "@ortho-next/nextray-core";
import { ToolEvents } from "@ortho-next/nextray-core/Core/ITool";
import { ViewType } from "@ortho-next/nextray-core/Models/AppModel";
import { Plane } from "@ortho-next/nextray-core/Tools/Plane";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { DeformityAnalysisType } from "src/nextray/Models/Enums";
import { BindedModel, bindedModel } from "../../Models/BindedModel";
import { Consts } from "../../Utils/Consts";
import { FullAnatomicalAxis } from "../AnatomicalAxis/FullAnatomicalAxis";
import { Osteotomy } from "../Osteotomy/Osteotomy";
import { OsteotomyAnatomical } from "../Osteotomy/OsteotomyAnatomical";
import { ClonedAnatomicalAxisManager } from "./ClonedAnatomicalAxisManager";
import { EoCPlane } from "./EoCPlane";

export class EoCPlaneAnatomical extends EoCPlane {
	public deformityAnalysisType = DeformityAnalysisType.anatomical;
	public osteotomy: OsteotomyAnatomical;
	protected _originalAxis: FullAnatomicalAxis;
	private _referenceType: ReferenceTypeEnum;
	private _planType: PlanTypeEnum;
	private _defaultAngle: number;
	private _defaultHorTransl: number;
	private _defaultVerTransl: number;
	@SaveChild() private _clonedAnatomicalAxisManager: ClonedAnatomicalAxisManager;

	public onAfterRestore(): void {
		super.onAfterRestore();
		// this.dispatchEvent({ type: ToolEvents.updated });
	}

	constructor(viewType: ViewType, side: AnatomicalSideEnum, refType: ReferenceTypeEnum, planType: PlanTypeEnum, plane: Plane,
		osteotomy: Osteotomy, anatomicalAxis: FullAnatomicalAxis, angle: number, horTransl: number, verTransl: number) {
		super(viewType, side, plane, osteotomy, anatomicalAxis);
		this._referenceType = refType;
		this._planType = planType;
		this._defaultAngle = angle;
		this._defaultHorTransl = horTransl;
		this._defaultVerTransl = verTransl;
		this._clonedAnatomicalAxisManager = new ClonedAnatomicalAxisManager(this, this._originalAxis.moving as AnatomicalAxis)
		this.add(this.clonedAxis);

		this.clonedAxis.bindProperty('visible', (m: BindedModel) => {
			return m.layer_anatAxis;
		}, ['layer_anatAxis']);
	}

	public get angle(): number {
		const sign = (this._side === AnatomicalSideEnum.Left ? 1 : -1) * (this._referenceType === ReferenceTypeEnum.Proximal ? 1 : -1);
		const refABDir = (this._originalAxis.reference as AnatomicalAxis).B.position.clone().sub((this._originalAxis.reference as AnatomicalAxis).A.position);
		const movBADir = this.clonedAxis.AB;
		const angle = VectorUtils.linesAngleFromOrigin(refABDir, movBADir);
		return angle * sign;
	}
	public set angle(value: number) {
		const sign = (this._side === AnatomicalSideEnum.Left ? 1 : -1) * (this._referenceType === ReferenceTypeEnum.Proximal ? 1 : -1) * -1;
		const angleToApply = (value + this.angle) * sign;
		this.rotationByPoint(this.ost1.position.clone().sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, angleToApply).add(this._rotationCenter), this.ost1);
		this.dispatchEvent({ type: ToolEvents.updated });
	}

	public get verticalTranslation(): number {
		const reference = this._originalAxis.reference as AnatomicalAxis;
		const moving = this._originalAxis.moving as AnatomicalAxis;
		let distance: number, sign: number;
		const refPerp = VectorUtils.getPerpendicular(reference.AB);

		if (this._planType === PlanTypeEnum.Deformity) {
			//can be refactored
			if (this.osteotomy.isOnReferenceSide) {
				const intRefOst = VectorUtils.lines2DIntersection(reference.A.position, reference.B.position, this.osteotomy.A.position, this.osteotomy.B.position);
				if (intRefOst == null) return undefined;
				const intMovOstNormRef = VectorUtils.lines2DIntersection(moving.A.position, moving.B.position, intRefOst, intRefOst.clone().add(refPerp));
				if (intMovOstNormRef == null) return undefined;
				//find the point where is positioned the green stick extremity
				const movTranslOrigin = intMovOstNormRef.clone().sub(moving.B.position);
				const movTranslOriginSign = -Math.sign(moving.B.position.clone().sub(moving.A.position).dot(movTranslOrigin));
				//the new point is where is positioned the the green stick extremity after rototraslation of the cropped plane
				const newPoint = this.clonedAxis.B.position.clone().sub(
					this.clonedAxis.B.position.clone().sub(this.clonedAxis.A.position).setLength(movTranslOriginSign * movTranslOrigin.length()));
				distance = intRefOst.clone().sub(newPoint).projectOnVector(reference.AB).length(); //use new proj func?
				sign = VectorUtils.computeSign(newPoint, intRefOst, reference.AB) < 0 ? 1 : -1;
			} else {
				const intMovOst = VectorUtils.lines2DIntersection(moving.A.position, moving.B.position, this.osteotomy.A.position, this.osteotomy.B.position);
				if (intMovOst == null) return undefined;
				const intersectionMoving_NormalReference = VectorUtils.lines2DIntersection(reference.A.position, reference.B.position, intMovOst, intMovOst.clone().add(refPerp));
				if (intersectionMoving_NormalReference == null) return undefined;
				const intersectionEocMovingOsteotomy = VectorUtils.lines2DIntersection(this.clonedAxis.A.position, this.clonedAxis.B.position, this.clonedOsteotomy.A.position, this.clonedOsteotomy.B.position);
				if (intersectionEocMovingOsteotomy == null) return undefined;
				distance = intersectionEocMovingOsteotomy.clone().sub(intersectionMoving_NormalReference).projectOnVector(reference.AB).length(); //use new proj func?
				sign = VectorUtils.computeSign(intersectionEocMovingOsteotomy, intersectionMoving_NormalReference, reference.AB) < 0 ? 1 : -1;
			}
		} else {
			const point = VectorUtils.projectOnVector(this.clonedAxis.B.position, reference.A.position, reference.B.position);
			distance = point.distanceTo(reference.B.position);
			sign = VectorUtils.computeSign(point, reference.B.position, reference.AB) < 0 ? 1 : -1;
		}
		const diffAxTransl = this._viewType === ViewType.AP ? bindedModel.anatAxialTranslDifAP : bindedModel.anatAxialTranslDifLT;
		return (distance * sign) - diffAxTransl;
	}
	public set verticalTranslation(value: number) {
		const pan = (this._originalAxis.reference as AnatomicalAxis).AB.setLength(this.verticalTranslation - value);
		this.translationByPoint(this.croppedPlane.position.clone().add(pan));
		this.dispatchEvent({ type: ToolEvents.updated });
	}

	public set horizontalTranslation(value: number) {
		const sign = (this._side === AnatomicalSideEnum.Left ? 1 : -1) * (this._referenceType === ReferenceTypeEnum.Proximal ? 1 : -1);
		const pan = VectorUtils.getPerpendicular((this._originalAxis.reference as AnatomicalAxis).AB).setLength((value - this.horizontalTranslation) * sign);
		this.translationByPoint(this.croppedPlane.position.clone().add(pan));
		this.dispatchEvent({ type: ToolEvents.updated });
	}
	public get horizontalTranslation(): number {
		return this._planType === PlanTypeEnum.Fracture ? this.horizontalTranslationFracture() : this.horizontalTranslationDeformity();
	}

	public get clonedAxis(): AnatomicalAxis {
		return this._clonedAnatomicalAxisManager.obj;
	}

	private horizontalTranslationFracture(): number {
		const ref = this._originalAxis.reference as AnatomicalAxis;
		const intersectionMoving = VectorUtils.lines2DIntersection(this.ost1.position, this.ost2.position, this.clonedAxis.A.position, this.clonedAxis.B.position);
		const intersectionReference = VectorUtils.lines2DIntersection(this.osteotomy.A.position, this.osteotomy.B.position, ref.A.position, ref.B.position);
		if (!intersectionMoving || !intersectionReference) return 0; // or undefined?
		const angle = VectorUtils.linesAngleFromOrigin(ref.A.position.clone().sub(ref.B.position), intersectionReference.clone().sub(intersectionMoving));
		const translation = intersectionMoving.distanceTo(intersectionReference) * Math.sin(angle);
		const sign = (this._side === AnatomicalSideEnum.Left ? 1 : -1) * (this._referenceType === ReferenceTypeEnum.Proximal ? 1 : -1);
		return translation * sign;
	}

	private horizontalTranslationDeformity(): number {
		const ost = this.osteotomy;
		const ref = ost.reference;
		const moving = ost.moving;
		const refAB = ref.AB;
		const originForSignCalculation = ost.apexPos;
		const refABPerp = VectorUtils.getPerpendicular(refAB);
		let sign = (this._side === AnatomicalSideEnum.Left ? 1 : -1) * (this._referenceType === ReferenceTypeEnum.Proximal ? 1 : -1);
		let distance: number;

		if (ost.isOnReferenceSide) {
			const intRefOst = VectorUtils.lines2DIntersection(ref.A.position, ref.B.position, ost.A.position, ost.B.position);
			if (!intRefOst) return 0;
			const intPointRefPerp = VectorUtils.lines2DIntersection(moving.A.position, moving.B.position, intRefOst, intRefOst.clone().add(refABPerp));
			if (!intPointRefPerp) return 0;

			//find the point where is positioned the green stick extremity
			const AMoving_TranslationOrigin = intPointRefPerp.clone().sub(moving.B.position);
			const AMoving_TranslationOriginLength = AMoving_TranslationOrigin.length();
			const AMoving_TranslationOriginSign = -Math.sign(moving.A.position.clone().sub(moving.B.position).dot(AMoving_TranslationOrigin));
			//the new point is where is positioned the the green stick extremity after rototraslation of the cropped plane
			const newPoint = this.clonedAxis.B.position.clone().sub(this.clonedAxis.B.position.clone().sub(this.clonedAxis.A.position)
				.setLength(AMoving_TranslationOriginSign * AMoving_TranslationOriginLength));
			distance = intRefOst.clone().sub(newPoint).projectOnVector(refABPerp).length();
			sign *= -Math.sign(refABPerp.dot(intRefOst.sub(newPoint)));
		} else {
			//intersect with moving
			const intMovOst = VectorUtils.lines2DIntersection(this.clonedAxis.A.position, this.clonedAxis.B.position, this.ost1.position, this.ost2.position);
			if (!intMovOst) return 0;
			const intPointRefPerp = VectorUtils.lines2DIntersection(ref.A.position, ref.B.position, intMovOst, intMovOst.clone().add(refABPerp));
			if (!intPointRefPerp) return 0;
			distance = intMovOst.distanceTo(intPointRefPerp);
			sign *= Math.sign(refABPerp.dot(intMovOst.clone().sub(originForSignCalculation)));
		}

		return distance * sign;
	}

	public cutPlane(planePoint: Vector3, verticalTransl: number): boolean {
		if (this.executeCutPlane(planePoint)) {
			this._clonedAnatomicalAxisManager.reset();
			this._rotationCenter = this._planType === PlanTypeEnum.Deformity ? this.osteotomy.apexPos.clone() :
				(this._originalAxis.reference as AnatomicalAxis).B.position.clone();
			this.angle = this._defaultAngle;
			this.verticalTranslation = this._defaultVerTransl || verticalTransl;
			this._defaultVerTransl = 0; //for reset
			this.horizontalTranslation = this._defaultHorTransl;
			return true;
		}
		return false;
	}

	protected translationByPoint(vector: Vector3): void {
		const pan = vector.clone().sub(this.croppedPlane.position);
		super.translationByPoint(vector);
		this._clonedAnatomicalAxisManager.makeTranslation(pan);
	}

	protected rotationByPoint(vector: Vector3, point: DraggablePoint): void { // apex funge solo se ruoti
		const angle = VectorUtils.linesAngle(point.position, vector, this._rotationCenter);
		super.rotationByPoint(vector, point);
		this._clonedAnatomicalAxisManager.makeRotation(angle, this._rotationCenter);
	}

	public resetDefaults(): void {
		this._defaultAngle = 0;
		this._defaultHorTransl = 0;
		this._defaultVerTransl = 0;
	}

}
