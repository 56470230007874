  <!-- RPM MENU -->
  <div class="box-menu-settings active">

    <div class="row-box">
      <h4>{{ labels.TOOLS_MENU_COMPONENT_RPM }}</h4>
    </div>

    <div class="divider-box"></div>

    <div class="row-box clickable" (click)="goToRPM()">
      <i class="material-icons">double_arrow</i>
      <span>{{ labels.TOOLS_MENU_COMPONENT_GO_TO_RPM }}</span>
    </div>

  </div>
