<!-- Draggable Window -->
<div id="draggable" class="wrapper-draggable-window" cdkDrag cdkDragBoundary=".content" *ngIf="layersVisible"
	[cdkDragFreeDragPosition]="dragPosition" [ngClass]="{'minimize': isMinimized }">
	<div id="draggableheader" class="header" cdkDragHandle>
		<h4>{{ labels.LAYERS_COMPONENT_HEADER }}</h4>
		<!--NOTA: cliccando l'icona "reduce-expand" bisogna aggiungere la classe "minimize" a "wrapper-draggable-window" -->
		<i class="material-icons md-18 reduce-expand" (click)="toggle()">minimize</i>
	</div>
	<div class="content-layers" [hidden]="isMinimized">

		<!--Labels-->
		<div class="row-layer">
			<span style="color: #FFFFFF; font-weight: bold;">{{ labels.LAYERS_COMPONENT_LABELS }}</span>
			<div class="wrapper-icons-actions">
				<i class="material-icons md-18" (click)="resetLB()">sync</i>
				<i class="material-icons-outlined md-18" (click)="setVisibilityLB(true)"
					*ngIf="!layersValue.labels">visibility_off</i>
				<i class="material-icons md-18" (click)="setVisibilityLB(false)" *ngIf="layersValue.labels">visibility</i>
			</div>
		</div>

		<!--Mechanical Axis-->
		<div class="row-layer" *ngIf="layers.mechanicalAxis_mechanical">
			<span style="color: #0C64C0; font-weight: bold;">{{ labels.LAYERS_COMPONENT_MECHANICAL_AXIS }}</span>
			<div class="wrapper-icons-actions">
				<i class="material-icons-outlined md-18" (click)="setVisibilityMA(true)"
					*ngIf="!layersValue.mechanicalAxis_mechanical">visibility_off</i>
				<i class="material-icons md-18" (click)="setVisibilityMA(false)"
					*ngIf="layersValue.mechanicalAxis_mechanical">visibility</i>
			</div>
		</div>

		<!--Mechanical Anatomical Axis-->
		<div class="row-layer" *ngIf="layers.mechanicalAxis_anatomical">
			<span style="color: #ED7D31; font-weight: bold;">{{ labels.LAYERS_COMPONENT_MNSA_TOOL }}</span>
			<div class="wrapper-icons-actions">
				<i class="material-icons-outlined md-18" (click)="setVisibilityMNSA(true)"
					*ngIf="!layersValue.mechanicalAxis_anatomical">visibility_off</i>
				<i class="material-icons md-18" (click)="setVisibilityMNSA(false)"
					*ngIf="layersValue.mechanicalAxis_anatomical">visibility</i>
			</div>
		</div>

		<!--Weightbearing-->
		<div class="row-layer" *ngIf="layers.mechanicalAxis_weightBearing">
			<span style="color: #00FF00; font-weight: bold;">{{ labels.LAYERS_COMPONENT_WEIGHTBEARING }}</span>
			<div class="wrapper-icons-actions">
				<i class="material-icons-outlined md-18" (click)="setVisibilityWB(true)"
					*ngIf="!layersValue.mechanicalAxis_weightBearing">visibility_off</i>
				<i class="material-icons md-18" (click)="setVisibilityWB(false)"
					*ngIf="layersValue.mechanicalAxis_weightBearing">visibility</i>
			</div>
		</div>

		<!--Contralateral-->
		<div class="row-layer" *ngIf="layers.contralateral_all">
			<span style="color: deepskyblue; font-weight: bold;">{{ labels.LAYERS_COMPONENT_CONTRALATERAL }}</span>
			<div class="wrapper-icons-actions">
				<i class="material-icons-outlined md-18" (click)="setVisibilityCL(true)"
					*ngIf="!layersValue.contralateral_all">visibility_off</i>
				<i class="material-icons md-18" (click)="setVisibilityCL(false)"
					*ngIf="layersValue.contralateral_all">visibility</i>
			</div>
		</div>

		<!--Reference point-->
		<div class="row-layer" *ngIf="layers.referencePoint">
			<span style="color: #FFFF00; font-weight: bold;">{{ labels.LAYERS_COMPONENT_REFERENCE_POINT }}</span>
			<div class="wrapper-icons-actions">
				<i class="material-icons-outlined md-18" (click)="setVisibilityRP(true)"
					*ngIf="!layersValue.referencePoint">visibility_off</i>
				<i class="material-icons md-18" (click)="setVisibilityRP(false)"
					*ngIf="layersValue.referencePoint">visibility</i>
			</div>
		</div>

		<!--Osteotomy-->
		<div class="row-layer" *ngIf="layers.osteotomy">
			<span style="color: #FF0000; font-weight: bold;">{{ labels.LAYERS_COMPONENT_OSTEOTOMY }}</span>
			<div class="wrapper-icons-actions">
				<i class="material-icons-outlined md-18" (click)="setVisibilityOS(true)"
					*ngIf="!layersValue.osteotomy">visibility_off</i>
				<i class="material-icons md-18" (click)="setVisibilityOS(false)" *ngIf="layersValue.osteotomy">visibility</i>
			</div>
		</div>

		<!--Anatomical Axis-->
		<div class="row-layer" *ngIf="layers.anatomicalAxis">
			<span style="color: #ED7D31; font-weight: bold;">{{ labels.LAYERS_COMPONENT_ANATOMICAL_AXIS }}</span>
			<div class="wrapper-icons-actions">
				<i class="material-icons-outlined md-18" (click)="setVisibilityAX(true)"
					*ngIf="!layersValue.anatomicalAxis">visibility_off</i>
				<i class="material-icons md-18" (click)="setVisibilityAX(false)"
					*ngIf="layersValue.anatomicalAxis">visibility</i>
			</div>
		</div>

		<!--Lines-->
		<div class="row-layer" *ngIf="layers.lines">
			<span style="color: #EC44CC; font-weight: bold;">{{ labels.LAYERS_COMPONENT_LINE }}</span>
			<div class="wrapper-icons-actions">
				<i class="material-icons-outlined md-18" (click)="setVisibilityLN(true)"
					*ngIf="!layersValue.lines">visibility_off</i>
				<i class="material-icons md-18" (click)="setVisibilityLN(false)" *ngIf="layersValue.lines">visibility</i>
			</div>
		</div>

		<!--Goniometers-->
		<div class="row-layer" *ngIf="layers.goniometers">
			<span style="color: #EC44CC; font-weight: bold;">{{ labels.LAYERS_COMPONENT_GONIOMETER }}</span>
			<div class="wrapper-icons-actions">
				<i class="material-icons-outlined md-18" (click)="setVisibilityGM(true)"
					*ngIf="!layersValue.goniometers">visibility_off</i>
				<i class="material-icons md-18" (click)="setVisibilityGM(false)" *ngIf="layersValue.goniometers">visibility</i>
			</div>
		</div>

		<!--Plate-->
		<div class="row-layer" *ngIf="layers.plate">
			<span style="color: lightgrey; font-weight: bold;">{{ labels.LAYERS_COMPONENT_PLATE }}</span>
			<div class="wrapper-icons-actions">
				<i class="material-icons-outlined md-18" (click)="setVisibilityPL(true)"
					*ngIf="!layersValue.plate">visibility_off</i>
				<i class="material-icons md-18" (click)="setVisibilityPL(false)" *ngIf="layersValue.plate">visibility</i>
			</div>
		</div>
	</div>
	<div class="btn-selected delete d-none" [hidden]="isMinimized">
		<span>Selected Ruler</span>
		<i class="material-icons md-18">delete_outline</i>
	</div>
	<div class="btn-selected screw d-none" [hidden]="isMinimized">
		<div class="wrapper-screw">
			<span>Selected Screw <br> Lenght</span>
			<div class="spinner">
				<span class="material-icons md-18">
					remove
				</span>
				<input type="text" />
				<span class="material-icons md-18">
					add
				</span>
			</div>
		</div>
		<div class="switch-custom-container">
			<label class="switch">
				<input type="checkbox" />
				<span></span>
			</label>
		</div>
	</div>
</div>