import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { LanguageService, Plan } from '../../core';
import { CanvasService, PrinterService, ProductService, UserService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Main component of BOM PDF print.
*/
@Component({
	selector: 'app-bom-print',
	templateUrl: './prt-bom.component.html'
})
export class PrintBomComponent extends BaseComponent implements OnInit, AfterViewInit {

	private _isDemo: boolean;

	isJPS_Ext: boolean;
	isJPSWarningVisible: boolean;

	today: number = Date.now();
	currentCase: Plan;

	/**
	 * Watermark Demo
	 */
	get isDemo(): boolean {
		return this._isDemo;
	}

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
		private usrSrv: UserService,
		private prtSrv: PrinterService,
		private element: ElementRef,
		private prodSrv: ProductService
	) {
		super(langSrv);
		this.currentCase = this.canvasSrv.currentCase;
		this.usrSrv.isJPSDemoProduct().subscribe(res => this._isDemo = res);
	}

	ngOnInit() {
		this.isJPS_Ext = this.prodSrv.jpsExtension;
		this.isJPSWarningVisible = this.prodSrv.isJPSToUpgrade;
	}

	ngAfterViewInit() {
		const elem = this.element.nativeElement;
		this.prtSrv.onBomDataReady(elem);
	}

}
