import { CaseModel, StateTransitionData, ViewType } from "@ortho-next/nextray-core";
import { Context } from "./Context";
import { Actions, State, StateTypes, WorkflowRunning } from "./State";

/**
 * State indicating that user can execute end of correction.
 */
export class EOC extends State {
	public stateDescription = {
		isWorkflowRunning: WorkflowRunning.none,
		isPointsPositioningRunning: false
	};

	constructor(context: Context, caseModel: CaseModel, restore?: object) {
		super(context, caseModel, StateTypes.EOC, restore);
	}

	public initState(): void {
		if (this._restore) {
			if (this._caseModel.imgAP) {
				this.dispatchToMain("setView", ViewType.AP);
			} else {
				this.dispatchToMain("setView", ViewType.LT);
			}
		}
	}

	protected handleChangeStateEvent<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData {
		switch (event) {
			case "selectApex":
			case "setEocAnatAxialRot":
			case "setEocAnatAxialRotSign":
			case "setBoneLength":
			case "setOverAngulation":
			case "setOverTransl":
			case "generateImagesToPrint":
			case "updateMeausuresOnlyPrint":
			case "osteotomyCut":
			case "cutEoC":
				return this.dispatchToMain(event, args);
			case "startSelectingApex":
				this.stateDescription.isWorkflowRunning = WorkflowRunning.insertOsteotomy;
				return this.dispatchToMain(event, args);
			case "cancelSelectingApex":
				this.stateDescription.isWorkflowRunning = WorkflowRunning.none;
				return this.dispatchToMain(event, args);
			case "confirmApex":
				this.stateDescription.isWorkflowRunning = WorkflowRunning.none;
				return this.dispatchToMain(event, args);
			case "switchToCalibrated":
				this.dispatchToMain(event, args);
				return { type: StateTypes.calibrated, data: { caseModel: this._caseModel } };
			case "switchToDeformity":
				this.dispatchToMain(event, args);
				return { type: StateTypes.deformityAnalysis, data: { caseModel: this._caseModel } };
			case "switchToMounting":
				this.dispatchToMain(event, args);
				return { type: StateTypes.templating, data: { caseModel: this._caseModel } };
			case "switchToTlhex":
				this.dispatchToMain(event, args);
				return { type: StateTypes.tlhex, data: { caseModel: this._caseModel } };
		}
	}
}
