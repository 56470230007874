import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component of first vertical sidebar to handle the postoperative osteotomy insertion workflow.
*/
@Component({
	selector: 'postop-osteotomy-workflow',
	templateUrl: './postop-osteotomy-workflow.component.html'
})
export class PostopOsteotomyWorkflowComponent extends BaseComponent implements OnInit {

	helpImg: string;
	isImgVisible: boolean;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.helpImg = 'assets/images/gif/osteotomy.gif';
	}

	/**
	* Close postop osteotomy workflow.
	*/
	back(): void {
		this.canvasSrv.dispatch('cancelPostopOst');
	}

	/**
	* Confirm postop osteotomy and insert anatomical axis.
	*/
	confirm(): void {
		this.canvasSrv.dispatch('confirmPostopOst');
	}

}
