<!-- AP TABLE -->
<table aria-label="AP deformity measures" class="wrapper-print-table">
	<thead>
		<tr>
			<th>
				{{isForeFoot || isHindFoot ? labels.PRINT_AA_DEF_COMPONENT_TITLE_DORS : labels.PRINT_AA_DEF_COMPONENT_TITLE_AP
				}}
				<br />
				({{ (isLeft ? sideEnum.Left : sideEnum.Right) | anatomicalSideLabel }})
			</th>
			<th>{{ labels.PRINT_AA_DEF_COMPONENT_VALUE }}</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>{{ labels.PRINT_AA_DEF_COMPONENT_ANG_DEF }}</td>
			<td>{{ apProxAngDef }}</td>
		</tr>
		<tr>
			<td>{{ labels.PRINT_AA_DEF_COMPONENT_LAT_TRANSL }}</td>
			<td>{{ apProxLatTransl }}</td>
		</tr>
	</tbody>
</table>
<!-- LT TABLE -->
<table aria-label="LT deformity measures" class="wrapper-print-table">
	<thead>
		<tr>
			<th>{{ labels.PRINT_AA_DEF_COMPONENT_TITLE_LT }} <br />
				({{ (isLeft ? sideEnum.Left : sideEnum.Right) | anatomicalSideLabel }})</th>
			<th>{{ labels.PRINT_AA_DEF_COMPONENT_VALUE }}</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>{{ labels.PRINT_AA_DEF_COMPONENT_ANG_DEF }}</td>
			<td>{{ ltProxAngDef }}</td>
		</tr>
		<tr>
			<td>{{ labels.PRINT_AA_DEF_COMPONENT_LAT_TRANSL }}</td>
			<td>{{ ltProxLatTransl }}</td>
		</tr>
	</tbody>
</table>
<!-- AXIAL TABLE -->
<table aria-label="Axial measures" class="wrapper-print-table" *ngIf="isFracture">
	<thead>
		<tr>
			<th>{{ labels.PRINT_AA_DEF_COMPONENT_TITLE_AXIAL }} <br />
				({{ (isLeft ? sideEnum.Left : sideEnum.Right) | anatomicalSideLabel }})</th>
			<th>{{ isForeFoot || isHindFoot ? labels.PRINT_AA_DEF_COMPONENT_DORS : activeViewEnum[0] }}</th>
			<th>{{ activeViewEnum[1] }}</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>{{ labels.PRINT_AA_DEF_COMPONENT_AXIAL_TRANSL }}</td>
			<td>{{ apAxialTranslMeasure }}</td>
			<td>{{ ltAxialTranslMeasure }}</td>
		</tr>
		<tr>
			<td>{{ labels.PRINT_AA_DEF_COMPONENT_REF_MEASURE }}</td>
			<td>{{ anatAxialTranslRef === activeViewEnum.AP ? '&check;' : null }}</td>
			<td>{{ anatAxialTranslRef === activeViewEnum.LT ? '&check;' : null }}</td>
		</tr>
	</tbody>
</table>