import { SelectedApexMech } from "@ortho-next/nextray-core";
import { RotateCameraType } from "@ortho-next/nextray-core/Controls/CustomCameraControls";
import { MainBase } from "@ortho-next/nextray-core/Core/Main";
import { AxialRotationEnum, ViewType } from "@ortho-next/nextray-core/Models/AppModel";
import { BoneTypeEnum } from "@ortho-next/nextray-core/Models/CaseModel";
import { Binding } from "@ortho-next/three-base/Binding/Binding";
import { RotationTypeEnum, TranslationTypeEnum } from "../../app/core/repositories/models/deformity-parameters";
import { MultiViewsElementCustom } from "../Controls/MultiViewsElementCustom";
import { AppModel } from "../Models/AppModel";
import { BindedModel, bindedModel } from "../Models/BindedModel";
import { CaseModel } from "../Models/CaseModel";
import { AxialTranslationEnum } from "../Models/Enums";
import { Context } from "../States/Context";
import { PrintUtils } from "../Utils/PrintUtils";
import { Bridge } from "./Bridge";
import { ContextInit } from "./ContextInit";
import { Layers } from "./Layers";
import { MeasuresController } from "./MeasuresController";
import { SaveHandler } from "./Save";
import { Scene } from "./Scene";
import { Tools, ToolsAP, ToolsLT } from "./Tools";
import { ToolsInit } from "./ToolsInit";

/**
 * Manages rendering and all logics.
 */
export class Main extends MainBase {
	public scenes: Scene[]; // override
	public model: AppModel; // override
	public bridge: Bridge;
	public override caseModel: CaseModel;
	public toolsInit: ToolsInit;
	public printUtils: PrintUtils;
	public layers: Layers;
	public measuresController: MeasuresController;
	protected _context: Context;
	protected _saveHandler: SaveHandler;

	//overridden getter
	public get activeScene(): Scene { return super.activeScene as Scene }
	public get toolsAP(): ToolsAP { return super.toolsAP as ToolsAP }
	public get toolsLT(): ToolsLT { return super.toolsLT as ToolsLT }
	public get activeTools(): Tools { return super.activeTools as Tools }

	public get isPostOp(): boolean {
		return this.caseModel.isPostOperative;
	}

	constructor(canvasContainer: HTMLDivElement) {
		super(canvasContainer, {
			appModel: AppModel,
			bindedModel: new BindedModel(),
			bridge: Bridge,
			contextProto: Context,
			measuresController: MeasuresController,
			printUtilsProto: PrintUtils,
			saveHandlerProto: SaveHandler,
			sceneProto: Scene,
			toolsAPProto: ToolsAP,
			toolsLTProto: ToolsLT
		});
		ContextInit.create(this, this._context);
		this.showStats = false; //todo remove it from package
	}

	/**
	 * Rotates camera based on app state.
	 */
	public rotateCamera(type: RotateCameraType): void { //move RotateCameraType out of pacchetto
		for (const scene of this.scenes) {
			// TODO: refactor
			if ((scene.meshes.viewType === ViewType.AP && this.hasAP) || (scene.meshes.viewType === ViewType.LT && this.hasLT)) {
				const value = type === RotateCameraType.none ? 0 : -scene.meshes.clonedMechanicalAxis.getAngleForTemplating(this.model.selectedApex as SelectedApexMech);
				scene.cameraControls.rotateCamera(value);
			}
		}
		for (const viewport of this.multiviewRenderer.viewsports as MultiViewsElementCustom[]) {
			if ((viewport.scene.meshes.viewType === ViewType.AP && this.hasAP) || (viewport.scene.meshes.viewType === ViewType.LT && this.hasLT)) {
				const value = type === RotateCameraType.none ? 0 : -viewport.scene.meshes.clonedMechanicalAxis.getAngleForTemplating(this.model.selectedApex as SelectedApexMech);
				viewport.cameraControls.rotateCamera(value);
			}
		}
	}

	public loadScenes(caseModel: CaseModel): void {
		if (!this.caseModel) {
			this.initBoneTypeLT(caseModel);
			super.loadScenes(caseModel, ToolsInit, Layers);
			this._context.handle("rotateCamera");
			this.setView(this.hasAP ? ViewType.AP : ViewType.LT);
			this.applyCheckupAxialRotation();
		}
	}

	private applyCheckupAxialRotation(): void {
		if (!this.caseModel.model && this.caseModel.checkUpDeformity) {
			this.model.def_anatAxialTrans = this.caseModel.checkUpDeformity.ax.displacement;
			this.model.def_anatAxialTransSign = this.caseModel.checkUpDeformity.ax.translation === TranslationTypeEnum.Short ? AxialTranslationEnum.short : AxialTranslationEnum.long;
			this.model.def_anatAxialRot = this.caseModel.checkUpDeformity.ax.angle;
			this.model.def_anatAxialRotSign = this.caseModel.checkUpDeformity.ax.rotation === RotationTypeEnum.External ? AxialRotationEnum.external : AxialRotationEnum.internal;
			this.model.eoc_anatAxialRot = this.caseModel.checkUpEOC.ax.angle;
			this.model.eoc_anatAxialRotSign = this.caseModel.checkUpEOC.ax.rotation === RotationTypeEnum.External ? AxialRotationEnum.external : AxialRotationEnum.internal;
		}
	}

	private initBoneTypeLT(caseModel: CaseModel): void {
		if (!!caseModel.imgLT) {
			Binding.createCustom("boneTypeLT", (m: BindedModel) => m.boneTypeLT, (value: BoneTypeEnum) => {
				this.model.boneTypeLT = value;
			}, ["boneTypeLT"]);
			bindedModel.boneTypeLT = caseModel.boneType;
		}
	}

	//override
	public getTools(viewType: ViewType): Tools {
		return super.getTools(viewType) as Tools;
	}
}
