import { Utils } from "@ortho-next/nextray-core";
import { ToolEvents } from "@ortho-next/nextray-core/Core/ITool";
import { ToolsInitBase } from "@ortho-next/nextray-core/Core/ToolsInit";
import { ViewType } from "@ortho-next/nextray-core/Models/AppModel";
import { SelectedApexMech } from "@ortho-next/nextray-core/States/State";
import { MathUtils } from "@ortho-next/three-base/three.js/build/three.module";
import { AppModel, PlateOrientationType } from "../Models/AppModel";
import { FullAnatomicalAxis } from "../Tools/AnatomicalAxis/FullAnatomicalAxis";
import { MechanicalAxisAP } from "../Tools/DeformityAnalyzer/FullAnalyzerAP";
import { MechanicalAxisLT } from "../Tools/DeformityAnalyzer/FullAnalyzerLT";
import { EoCPlane } from "../Tools/EoCPlane/EoCPlane";
import { EoCPlaneManager } from "../Tools/EoCPlane/EoCPlaneManager";
import { OsteotomiesManager } from "../Tools/Osteotomy/OsteotomiesManager";
import { Osteotomy } from "../Tools/Osteotomy/Osteotomy";
import { PlateManager } from "../Tools/Plate/PlateManager";
import { ReferencePoint } from "../Tools/ReferencePoint";
import { MeasuresUtils } from "../Utils/MeasuresUtils";
import { Main } from "./Main";

/**
 * Manages the initialization of the tools.
 */
export class ToolsInit extends ToolsInitBase {
	public osteotomiesManager = new OsteotomiesManager();
	public eocPlaneManager = new EoCPlaneManager();
	protected _main: Main; //override
	private _plateSync: boolean;

	constructor(main: Main) {
		super(main, FullAnatomicalAxis, MechanicalAxisAP, MechanicalAxisLT);
	}

	/**
	 * Inits EoCPlane if necessary and returns it.
	 */
	public initEoCPlane(viewType: ViewType): EoCPlane {
		return this.eocPlaneManager.initView(this._main, viewType);
	}

	/**
	 * Inits both EoCPlanes if necessary.
	 */
	public initEoCPlanes(): void {
		return this.eocPlaneManager.initBothViews(this._main);
	}

	/**
	 * Inits both osteotomies if necessary.
	 */
	public initOsteotomies(): void {
		this.osteotomiesManager.initBothViews(this._main);
	}

	/**
	 * Inits AP osteotomy if necessary and returns it.
	 */
	public initOsteotomyAP(): Osteotomy {
		return this.osteotomiesManager.initAP(this._main);
	}

	/**
	 * Inits LT osteotomy if necessary and returns it.
	 */
	public initOsteotomyLT(): Osteotomy {
		return this.osteotomiesManager.initLT(this._main);
	}

	/**
	 * Inits both reference points if necessary.
	 */
	public initReferencePoints(): void {
		this._main.hasAP && this.initReferencePoint(ViewType.AP);
		this._main.hasLT && this.initReferencePoint(ViewType.LT);
	}

	/**
	 * Inits reference point if necessary and returns it.
	 */
	public initReferencePoint(viewType: ViewType): ReferencePoint {
		const tools = this._main.getTools(viewType);
		if (!tools.referencePoint) {
			tools.add(tools.referencePoint = new ReferencePoint(viewType));
		}
		return tools.referencePoint;
	}

	/**
	 * Inits both plates manager if necessary.
	 */
	public initPlates(): void {
		this._main.hasAP && this.initPlate(ViewType.AP) && this.initPlateSTL(ViewType.AP);
		this._main.hasLT && this.initPlate(ViewType.LT) && this.initPlateSTL(ViewType.LT);
	}

	/**
	 * Inits plate manager if necessary and returns it.
	 */
	public initPlate(viewType: ViewType): PlateManager {
		const plateModel = this._main.model.plate;
		const tools = this._main.getTools(viewType);

		if (plateModel) {
			if (!tools.plateManager) {
				tools.add(tools.plateManager = new PlateManager(viewType, this._main.caseModel.side));
				tools.plate.addEventListener(ToolEvents.onAfterRestore, () => this.initPlateSTL(viewType, true));
			}
		}
		return tools.plateManager;
	}

	private initPlateSTL(viewType: ViewType, isRestored = false): void {
		const plateModel = this._main.model.plate;
		const tools = this._main.getTools(viewType);
		if (plateModel) {
			const fileName = plateModel.plate.fileName.toUpperCase();
			const meshList = plateModel.plate.additionalInfos.meshList;
			if (tools.plateManager) {
				const isPlateLoading: keyof AppModel = viewType === ViewType.AP ? 'isAPPlateLoading' : 'isLTPlateLoading';
				this._main.model[isPlateLoading] = true;
				const isTibiaDistal = this._main.model.selectedApex === SelectedApexMech.tibiaDistal;
				tools.plateManager.loadSTL(fileName, meshList, plateModel.screwList, isTibiaDistal, () => {
					this._main.model[isPlateLoading] = false;
					if (!isRestored) {
						// Flip plate if orientation is medial
						const orientation = this._main.model.plateOrientation;
						orientation === PlateOrientationType.medial && tools.plate.horizontalFlip();
						// Move plate on osteotomy center
						const startPosition = tools.osteotomy.C.position.clone();
						const pan = startPosition.clone().sub(tools.plate.position);
						tools.plate.position.copy(startPosition);
						tools.plateManager.rotationPointA.position.copy(tools.plateManager.rotationPointA.position.clone().add(pan));
						tools.plateManager.rotationPointB.position.copy(tools.plateManager.rotationPointB.position.clone().add(pan));
						tools.plate.update();
					}
				});
				!isRestored && tools.plate.setPointsForSyncFromMechanicalAxis(tools.clonedMechanicalAxis, this._main.model.selectedApex as SelectedApexMech, tools.referencePoint);
				this.bindPlateSync();
				if (viewType === ViewType.AP) {
					this._main.model.screwsBom = tools.plateManager.BOM;
				}
			}
		}
	}

	private bindPlateSync(): void {
		const plateAP = this._main.toolsAP.plate;
		const plateLT = this._main.toolsLT.plate;

		if (plateAP && plateLT && !this._plateSync) {
			plateAP.bindEvent("onAfterDragMove", () => { // TODO: history will not work
				plateLT.distanceToReferencePoint = plateAP.distanceToReferencePoint;
			});

			plateLT.bindEvent("onAfterDragMove", () => {
				plateAP.distanceToReferencePoint = plateLT.distanceToReferencePoint;
			});

			this._plateSync = true;
		}
	}

	public initAnatomicalAxisView(viewType: ViewType): FullAnatomicalAxis {
		const tools = this._main.getTools(viewType);
		if (!tools.anatomicalAxis) {
			const model = this._main.caseModel;
			const checkUpDeformity = this._main.caseModel.checkUpDeformity;
			const convexity = Utils.getConvexity(viewType, model.boneType, model.side, model.referenceType);
			let angle = 0;
			let translHor = 0;
			let translVer = 0;
			if (checkUpDeformity) {
				const angleSign = MeasuresUtils.getAngleSign(viewType === ViewType.AP ? checkUpDeformity.ap.angulation : checkUpDeformity.ml.angulation, model.side, model.referenceType);
				angle = angleSign * MathUtils.degToRad(viewType === ViewType.AP ? checkUpDeformity.ap.angle : checkUpDeformity.ml.angle);
				const translHorSign = MeasuresUtils.getTranslSign(viewType === ViewType.AP ? checkUpDeformity.ap.translation : checkUpDeformity.ml.translation, model.side);
				translHor = translHorSign * (viewType === ViewType.AP ? checkUpDeformity.ap.displacement : checkUpDeformity.ml.displacement);
				const translVerSign = MeasuresUtils.getAxTranslSign(checkUpDeformity.ax.translation);
				translVer = translVerSign * checkUpDeformity.ax.displacement;
			}
			tools.add(tools.anatomicalAxis = new FullAnatomicalAxis(viewType, convexity, model.referenceType, model.side, model.boneType, model.type, angle, translHor, translVer));
			this._main.measuresController.bindAnatomicalAxis(viewType);
		}
		return tools.anatomicalAxis;
	}

}
