import { Component, OnDestroy, OnInit } from '@angular/core';
import { ViewType } from '@ortho-next/nextray-core';
import { BridgeResultMessages } from '../../../nextray/Core/Bridge';
import { BoneTypeEnum, LanguageService, ToastService } from '../../core';
import { BoneImageEnum, CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component of first vertical sidebar to handle the mechanical axis insertion workflow.
*/
@Component({
	selector: 'mechanical-axis',
	templateUrl: './mechanical-axis.component.html',
	styles: []
})
export class MechanicalAxisComponent extends BaseComponent implements OnInit, OnDestroy {

	helpImg: string;
	isImgVisible: boolean;
	isCloseVisible: boolean;
	cancelBtnLabel: string;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
		private toastSrv: ToastService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.isCloseVisible = this.isSecondPage;
		this.cancelBtnLabel = this.isSecondPage ? this.labels['MECHANICAL_AXIS_COMPONENT_BACK_BTN'] : this.labels['MECHANICAL_AXIS_COMPONENT_CANCEL_BTN'];
		this.helpImg = this.stateDescription.isWorkflowRunning == this.workflowEnum.insertContralateral ? this._helpImgCTRL : this._helpImgMA;
		this.canvasSrv.addEventListener('onResult', this.mechanicalAxisInsertError);
	}


	private get isSecondPage(): boolean {
		// component appears after bone type selection
		return this.activeView === this.activeViewEnum.LT && this.isLongLeg;
	}

	/**
	* Event listener of mechanical axis and contralateral axis insertion error.
	*/
	mechanicalAxisInsertError = (event) => {
		if (event.args === BridgeResultMessages.mechanicalAxisInsertError || event.args === BridgeResultMessages.contralateralAxisInsertError) {
			this.toastSrv.showWarning(this.labels['MECHANICAL_AXIS_COMPONENT_POINTS_ERROR']);
		}
	}

	private get _helpImgMA(): string {
		if (this.isRight) {
			return this._helpImgMA_Right;
		} else {
			return this._helpImgMA_Left;
		};
	}

	private get _helpImgMA_Right(): string {
		if (this.activeView === ViewType.AP) {
			switch (this.canvasSrv.currentCase.boneType) {
				case BoneTypeEnum.LongLeg: return BoneImageEnum.Right_LongLeg_AP;
				case BoneTypeEnum.Femur: return BoneImageEnum.Right_Femur_AP;
				case BoneTypeEnum.Tibia: return BoneImageEnum.Right_Tibia_AP;
				default: return null;
			}
		} else {
			switch (this.boneTypeLT) {
				case BoneTypeEnum.LongLeg: return BoneImageEnum.Right_LongLeg_LT;
				case BoneTypeEnum.Femur: return BoneImageEnum.Right_Femur_LT;
				case BoneTypeEnum.Tibia: return BoneImageEnum.Right_Tibia_LT;
				default: return null;
			}
		}
	}

	private get _helpImgMA_Left(): string {
		if (this.activeView === ViewType.AP) {
			switch (this.canvasSrv.currentCase.boneType) {
				case BoneTypeEnum.LongLeg: return BoneImageEnum.Left_LongLeg_AP;
				case BoneTypeEnum.Femur: return BoneImageEnum.Left_Femur_AP;
				case BoneTypeEnum.Tibia: return BoneImageEnum.Left_Tibia_AP;
				default: return null;
			}
		} else {
			switch (this.boneTypeLT) {
				case BoneTypeEnum.LongLeg: return BoneImageEnum.Left_LongLeg_LT;
				case BoneTypeEnum.Femur: return BoneImageEnum.Left_Femur_LT;
				case BoneTypeEnum.Tibia: return BoneImageEnum.Left_Tibia_LT;
				default: return null;
			}
		}
	}

	private get _helpImgCTRL(): string {
		let fileName: BoneImageEnum = null;
		if (this.isRight) {
			if (this.isLongLeg) fileName = BoneImageEnum.Left_LongLeg_AP;
			if (this.isFemur) fileName = BoneImageEnum.Left_Femur_AP;
			if (this.isTibia) fileName = BoneImageEnum.Left_Tibia_AP;
		} else {
			if (this.isLongLeg) fileName = BoneImageEnum.Right_LongLeg_AP;
			if (this.isFemur) fileName = BoneImageEnum.Right_Femur_AP;
			if (this.isTibia) fileName = BoneImageEnum.Right_Tibia_AP;
		}
		return fileName;
	}

	/**
	* Confirm mechanical axis insertion.
	*/
	save(): void {
		this.canvasSrv.dispatch("completeRegistering");
	}

	/**
	* Close mechanical axis insertion workflow and return to previous step in second page mode.
	*/
	back(): void {
		this.canvasSrv.dispatch("stopRegistering");
		if (this.isSecondPage) {
			this.canvasSrv.dispatch("startBoneTypeSelection");
		}
	}

	/**
	* Close mechanical axis insertion workflow in second page mode.
	*/
	cancel(): void {
		if (this.isSecondPage) {
			this.canvasSrv.dispatch("stopRegistering");
		}
	}

	/**
	* Reset mechanical axis insertion.
	*/
	reset(): void {
		this.canvasSrv.dispatch("resetRegistering");
	}


	ngOnDestroy() {
		this.canvasSrv.removeEventListener('onResult', this.mechanicalAxisInsertError);
	}

}
