import { ViewType } from '@ortho-next/nextray-core/Models/AppModel';
import { DraggableLabel } from '@ortho-next/nextray-core/Tools/Primitive/Label/DraggableLabel';
import { Screw } from './Screw';

export class LabelScrew extends DraggableLabel {
  public screw: Screw;

  _originalScrewColor: number;

  constructor(name: string, viewType: ViewType, screw: Screw) {
    super(name, viewType, screw);

    this.name = name;
    this.screw = screw;
    this._originalScrewColor = screw.material.emissive.getHex();
    this.defaultAnchor = this.anchor.add(this.screw.direction.clone().setLength(this.screw.length / 10)).clone();

    this.bindEvent('onHover', () => {
      this.screw.material.emissive.setHex(0xff0000);
    });

    this.bindEvent('onLeave', () => {
      this.screw.material.emissive.setHex(this._originalScrewColor);
    });
  }

  update(): void {
    this.anchor = this.anchor.add(this.screw.direction.clone().setLength((this.screw.length - this.screw.oldLength) / 2));
  }

  resetPosition = () => {
    this.anchor = this.defaultAnchor.clone()
      .add(this.screw.direction.clone().setLength(this.screw.length / 2));
  }
}
