import { DraggablePoint, Label, MechanicalAxisAP, MechanicalAxisFemurAP, MechanicalAxisLT, Save, SelectedApexMech, ToolToSave, ToolType, ViewType } from "@ortho-next/nextray-core";
import { MechanicalAxisFemurLT } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/FemurAnalyzerLT";
import { MechanicalAxisTibiaAP } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/TibiaAnalyzerAP";
import { MechanicalAxisTibiaLT } from "@ortho-next/nextray-core/Tools/DeformityAnalyzer/TibiaAnalyzerLT";
import { SingleLine } from "@ortho-next/nextray-core/Tools/Primitive/SingleLine";
import { Default } from "@ortho-next/nextray-core/Utils/Default";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { BindedModel } from "../Models/BindedModel";
import { DeformityAnalysisType } from "../Models/Enums";
import { StateTypes } from "../States/State";

/**
 * Reference point used to sync osteotomies.
 */
export class ReferencePoint extends DraggablePoint implements ToolToSave {
	public toolType = ToolType.referencePoint;
	@Save() public position: Vector3; // override
	private _verLine: SingleLine;
	private _horLine: SingleLine;
	private _label: Label;

	constructor(viewType: ViewType) {
		super("Reference point", viewType, 0xffff00, undefined, false);

		this.add(
			this._verLine = new SingleLine("Vertical line", 0xffff00, new Vector3(0, 2.5), new Vector3(0, -2.5)),
			this._horLine = new SingleLine("Horizontal line", 0xffff00, new Vector3(2.5), new Vector3(-2.5)),
			this._label = new Label("Label", viewType, undefined, new Vector3(0, 4), {
				text: "Ref.Point",
				backgroundColor: "rgba(255, 255, 0, 0.6)",
				borderColor: "#ff0",
				fontColor: "#000"
			}, 1, false)
		);

		this.bindProperties();
	}

	private bindProperties(): void {
		Default.bindOnEnabledChange_NoRaycastAndHiddenLabel(this);

		this.bindProperty("visible", (m: BindedModel) => {
			return m.layer_referencePoint && m.isReferencePointInserted && m.deformityAnalysisType === DeformityAnalysisType.anatomical
		}, ['layer_referencePoint', 'isReferencePointInserted', 'deformityAnalysisType']);

		this.bindProperty("isEnabled", (m: BindedModel) => {
			return !m.readonly && m.appState === StateTypes.deformityAnalysis
		}, ['readonly', 'appState']);
	}

	/**
	 * Gets position according to selected apex and mechanical axis points.
	 */
	public calculate(selectedApex: SelectedApexMech, mechanicalAxis: MechanicalAxisAP | MechanicalAxisLT) {
		switch (selectedApex) {
			case SelectedApexMech.femurProximal:
			case SelectedApexMech.femurDistal:
				return this.handleFemurApex(mechanicalAxis.femur);
			case SelectedApexMech.tibiaProximal:
			case SelectedApexMech.tibiaDistal:
				return this.handleTibiaApex(mechanicalAxis.tibia);
			case SelectedApexMech.none:
				return this.handleNoSelectedApex();
		}
	}

	private handleNoSelectedApex(): void { //todo
		this.position.set(0, 0, 0);
	}

	private handleFemurApex(femur: MechanicalAxisFemurAP | MechanicalAxisFemurLT): void {
		this.position.copy(femur.FH.position);
	}

	private handleTibiaApex(tibia: MechanicalAxisTibiaAP | MechanicalAxisTibiaLT): void {
		if (tibia instanceof MechanicalAxisTibiaAP) {
			this.position.copy(tibia.CP.position).lerp(tibia.CA.position, 0.5);
		} else {
			this.position.copy(tibia.FP.position).lerp(tibia.MA.position, 0.5);
		}
	}

	public reset(): void {
		this.position.set(0, 0, 0);
	}

}
