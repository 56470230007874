import { LabelTextConfig } from '@ortho-next/nextray-core/Config/LabeltextConfig';
import { ToolToSave, ToolType } from '@ortho-next/nextray-core/Core/ITool';
import { Save, SaveChild } from '@ortho-next/nextray-core/Core/Save';
import { ViewType } from '@ortho-next/nextray-core/Models/AppModel';
import { SelectedApexAnat, SelectedApexMech } from '@ortho-next/nextray-core/States/State';
import { DraggablePoint } from '@ortho-next/nextray-core/Tools/Primitive/DraggablePoint';
import { LabelText } from '@ortho-next/nextray-core/Tools/Primitive/Label/LabelText';
import { LinePointToPoint2 } from '@ortho-next/nextray-core/Tools/Primitive/LinePointToPoint2';
import { Default } from '@ortho-next/nextray-core/Utils/Default';
import { LimitUtils } from '@ortho-next/nextray-core/Utils/LimitUtils';
import { Group, Vector3 } from '@ortho-next/three-base/three.js/build/three.module';
import { DeformityAnalysisType } from '../../../nextray/Models/Enums';
import { Config } from '../../Config/Config';
import { ReferencePoint } from '../ReferencePoint';

export abstract class Osteotomy extends Group implements ToolToSave {
	public toolType = ToolType.osteotomy;
	@Save('position') public A: DraggablePoint;
	@Save('position') public B: DraggablePoint;
	@Save('position') public C: DraggablePoint;
	public ABLine: LinePointToPoint2;
	@SaveChild() public label: LabelText;
	@Save() public selectedApex: SelectedApexMech | SelectedApexAnat;
	public referencePoint: ReferencePoint;
	@Save() protected _axisPoint1: Vector3;
	@Save() protected _axisPoint2: Vector3;
	@Save() protected _axisPerp: Vector3;
	@Save() protected _axisDir: Vector3;
	@Save() protected _axisValidPoint: Vector3;
	protected _initialLength = 30;
	protected _perpTranslToSync: Vector3;
	protected _enableLimits: boolean;
	protected _errorLength: boolean;

	public abstract deformityAnalysisType: DeformityAnalysisType;
	public abstract get distanceToReferencePoint(): number;
	public abstract set distanceToReferencePoint(value: number);
	public abstract setPositionByApex(selectedApex: SelectedApexMech | SelectedApexAnat, distanceToReferenceePoint?: number): void;
	protected abstract updatePerpTranslation(): void;

	public get length(): number {
		return this.A.position.distanceTo(this.B.position);
	}

	constructor(viewType: ViewType, referencePoint: ReferencePoint, enableLimits: boolean) {
		super();
		this.name = 'Osteotomy';
		this.referencePoint = referencePoint;
		this._enableLimits = enableLimits;

		this.add(
			this.A = new DraggablePoint('A', viewType, Config.osteotomy_colorHex, new Vector3(this._initialLength / 2, 0, 0)),
			this.B = new DraggablePoint('B', viewType, Config.osteotomy_colorHex, new Vector3(this._initialLength / -2, 0, 0)),
			this.C = new DraggablePoint('C', viewType, Config.osteotomy_colorHex, this.A.position.clone().lerp(this.B.position, 0.5)),
			this.ABLine = new LinePointToPoint2('ABLine', viewType, Config.osteotomy_colorHex, this.A, this.B),
			this.label = new LabelText({
				name: 'label',
				color: LabelTextConfig.color, //todo refactor
				viewType,
				objToAnchor: this.C,
				anchor: new Vector3(0, 5, 0),
				labelParams: { text: 'Osteotomy', backgroundColor: Config.osteotomy_colorCssAlpha, fontColor: Config.osteotomy_fontColor, borderColor: Config.osteotomy_colorCss }
			})
		);

		this.bindEvents();
	}

	protected bindEvents(): void {
		Default.bindOnEnabledChange_NoRaycastAndHiddenLabel(this);

		this.A.bindEvent('onDragMove', (args) => {
			this._enableLimits && LimitUtils.applyMultiLimits(args.position, this.A.position, [
				{ a: this._axisPoint1, b: this._axisPoint1.clone().add(this._axisPerp), v: this._axisValidPoint },
				{ a: this._axisPoint2, b: this._axisPoint2.clone().add(this._axisPerp), v: this._axisValidPoint }
			]);
			this.C.position.copy(args.position.clone().lerp(this.B.position, 0.5));
		});

		this.B.bindEvent('onDragMove', (args) => {
			this._enableLimits && LimitUtils.applyMultiLimits(args.position, this.A.position, [
				{ a: this._axisPoint1, b: this._axisPoint1.clone().add(this._axisPerp), v: this._axisValidPoint },
				{ a: this._axisPoint2, b: this._axisPoint2.clone().add(this._axisPerp), v: this._axisValidPoint }
			]);
			this.C.position.copy(args.position.clone().lerp(this.A.position, 0.5));
		});

		this.C.bindEvent('onDragMove', (args) => {
			this._enableLimits && LimitUtils.applyLimitAndProjectVectorCenterLinePoint(this._axisPoint1, this._axisPoint1.clone().add(this._axisPerp), this.C.position, args.position, this.A.position, this.B.position, this._axisValidPoint);
			this._enableLimits && LimitUtils.applyLimitAndProjectVectorCenterLinePoint(this._axisPoint2, this._axisPoint2.clone().add(this._axisPerp), this.C.position, args.position, this.A.position, this.B.position, this._axisValidPoint);
			this.translateAB(args.position);
		});
	}


	protected updateCenter(newCenter: Vector3): void {
		this.translateAB(newCenter);
		this.C.position.copy(newCenter);
	}

	protected translateAB(newCenter: Vector3): void {
		const pan = this.C.position.clone().sub(newCenter);
		this.A.position.sub(pan);
		this.B.position.sub(pan);
	}

	protected placeOsteotomyByApex(origin: Vector3, direction: Vector3): void {
		const dir = direction.clone().setLength(this._initialLength / 2);
		this.C.position.copy(origin);
		this.A.position.copy(origin).add(dir);
		this.B.position.copy(origin).sub(dir);
	}
}
