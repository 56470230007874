import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BridgeResultMessagesBase } from '@ortho-next/nextray-core';
import { SseService } from '@ortho-next/sse';
import { Subscription, take, takeWhile, timer } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BridgeResultMessages } from '../../../nextray/Core/Bridge';
import { DeformityAnalysisType } from '../../../nextray/Models/Enums';
import { OsteotomyCut } from '../../../nextray/States/State';
import { DeformityParameters, LanguageService, LoaderService, Plan, PlanTypeEnum, ProductTypeEnum, ToastService } from '../../core';
import { CanvasService, CaseService, HexrayMapperService, ModelService, TlhexService, UserService } from '../../services';
import { BaseComponent, ConfirmationComponent } from '../../shared';
import { EocModalComponent } from '../eoc-modal';



/**
* Component of first vertical sidebar tool menu to handle correction.
*/
@Component({
	selector: 'correction-menu',
	templateUrl: './correction-menu.component.html'
})
export class CorrectionMenuComponent extends BaseComponent implements OnInit, OnDestroy {

	isFitboneProduct: boolean;
	cutType = OsteotomyCut;
	cutForm: FormGroup;

	private _products: ProductTypeEnum[] = [];
	private _prodSub: Subscription;
	private _resetKey: string;

	private _isReadOnly: boolean;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService,
		private modalSrv: NgbModal,
		private userSrv: UserService,
		private toastSrv: ToastService,
		private modelSrv: ModelService,
		private planSrv: CaseService,
		private loaderSrv: LoaderService,
		private sseSrv: SseService,
		private hexrayMapperSrv: HexrayMapperService,
		private tlhexSrv: TlhexService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.userSrv.isFitboneProduct().pipe(first()).subscribe(res => this.isFitboneProduct = res);
		this._prodSub = this.planSrv.getPlanProducts().subscribe(list => this._products = list);
		this.planSrv.isReadOnly().pipe(first(res => res != null)).subscribe(res => this._isReadOnly = res);
		this.canvasSrv.addEventListener('onResult', this.cutError);
		this.canvasSrv.addEventListener('onResult', this.cutInit);
		this.initCutForm();
	}


	private openWarningModal(message: string, confirmFunction: Function, cancelFunction?: Function): void {
		if (!this.modalSrv.hasOpenModals()) {
			const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
				centered: true, backdrop: 'static'
			});
			(modalRef.componentInstance as ConfirmationComponent).config = {
				title: this.labels['TOOLS_MENU_COMPONENT_WARNING_MODAL_TITLE'],
				message: message
			};
			modalRef.result.then(() => confirmFunction.call(this), () => cancelFunction ? cancelFunction.call(this) : null).catch(error => false);
		}
	}

	/**
	* Display Correction menu tools.
	*/
	enableCorrection(): void {
		if (this.isDeformityDone) {
			if (this.stateType !== this.stateTypeEnum.deformityAnalysis) {
				const message: string = this.labels['TOOLS_MENU_COMPONENT_CORRECTION_MODAL_MESSAGE'];
				this.openWarningModal(message, this.goToEOC);
			} else {
				this._enableCorrection();
			}
		}
	}

	private _enableCorrection(): void {
		if (this.canvasSrv.currentCase.isCheckUp) {
			this.checkCheckupDeformity();
			if (!this._isReadOnly) {
				this.saveCheckUpDeformity();
			} else {
				this.goToEOC(DeformityAnalysisType.anatomical);
			}
		} else {
			const methods = this.correctionMethods;
			methods.length > 1 ? this.openEOCModal(methods) : this.goToCorrection(methods[0]);
		}
	}

	private checkCheckupDeformity(): void {
		const planType = this.canvasSrv.currentCase.type;
		const checkup = this.tlhexSrv.checkupDeformity;
		const deformity = this.hexrayMapperSrv.deformityRequestMapper();
		if (checkup && deformity && this.isCheckupDeformityChanged(checkup, deformity, planType)) {
			this.canvasSrv.dispatch("resetCheckupEOC");
		}
	}

	private isCheckupDeformityChanged(checkup: DeformityParameters, deformity: DeformityParameters, planType: PlanTypeEnum): boolean {
		return (checkup.ap.angle !== deformity.ap.angle || checkup.ap.angulation !== deformity.ap.angulation) ||
			(planType === PlanTypeEnum.Deformity && deformity.ap.displacement !== 0) ||
			(planType === PlanTypeEnum.Fracture && (checkup.ap.displacement !== deformity.ap.displacement || checkup.ap.translation !== deformity.ap.translation)) ||
			(checkup.ml.angle !== deformity.ml.angle || checkup.ml.angulation !== deformity.ml.angulation) ||
			(planType === PlanTypeEnum.Deformity && deformity.ml.displacement !== 0) ||
			(planType === PlanTypeEnum.Fracture && (checkup.ml.displacement !== deformity.ml.displacement || checkup.ml.translation !== deformity.ml.translation)) ||
			(checkup.ax.angle !== deformity.ax.angle || checkup.ax.rotation !== deformity.ax.rotation || checkup.ax.displacement !== deformity.ax.displacement || checkup.ax.translation !== deformity.ax.translation);
	}

	private saveCheckUpDeformity(): void {
		const defData = this.hexrayMapperSrv.deformityRequestMapper();
		this.loaderSrv.show();
		this.modelSrv.saveRayModelAndTlHexDeformity(this.canvasSrv.currentCase, defData).pipe(
			finalize(() => this.loaderSrv.hide())
		).subscribe({
			next: () => this.goToEOC(DeformityAnalysisType.anatomical),
			error: () => this.toastSrv.showError(this.labels['TOOLS_MENU_COMPONENT_PLAN_SAVED_ERROR'])
		});
	}

	private get correctionMethods(): ProductTypeEnum[] {
		const prodsList = [ProductTypeEnum.JPS, ProductTypeEnum.Fitbone, ProductTypeEnum.TLHex];
		return this._products.filter(p => prodsList.includes(p) && (this.isFitboneProduct || p !== ProductTypeEnum.Fitbone));
	}

	private openEOCModal(corrMethods: ProductTypeEnum[]): void {
		if (!this.modalSrv.hasOpenModals()) {
			const size = corrMethods.length > 2 ? 'lg' : null;
			const modalRef: NgbModalRef = this.modalSrv.open(EocModalComponent, {
				centered: true, backdrop: 'static', size: size
			});
			(modalRef.componentInstance as EocModalComponent).productList = corrMethods;
			(modalRef.componentInstance as EocModalComponent).isMechValid = this.isMechDeformityDone;
			(modalRef.componentInstance as EocModalComponent).isAnatValid = this.isAnatDeformityDone;
			modalRef.result.then((method: ProductTypeEnum) => this.goToCorrection(method)).catch(error => false);
		}
	}

	private goToCorrection(method: ProductTypeEnum): void {
		switch (method) {
			case ProductTypeEnum.JPS: this.goToEOC(DeformityAnalysisType.mechanical); break;
			case ProductTypeEnum.Fitbone: this.goToReversePlanning(); break;
			case ProductTypeEnum.TLHex: this.goToEOC(DeformityAnalysisType.anatomical); break;
		}
	}

	private goToEOC(defType?: DeformityAnalysisType): void {
		this.canvasSrv.closeDrawer();
		this.canvasSrv.dispatch('switchToEOC', defType);
		if (defType == DeformityAnalysisType.anatomical && (!this.isDeformity || this.isPostOp) && !this.isEocCut) {
			this.setCut();
		}
		this.canvasSrv.saveState();
		this.initCutForm();
	}

	private goToReversePlanning(): void {
		const currentCase = this.canvasSrv.currentCase;
		this.canvasSrv.dispatch('switchToRPM');
		if (!this._isReadOnly) {
			this.loaderSrv.show();
			this.handleModelReset(currentCase);
			this.modelSrv.persistModel(currentCase.id, currentCase.userGuid).subscribe({
				error: () => {
					this.backToDeformity();
					this._clearResetEvents();
				}
			});
		}
	}

	private backToDeformity(): void {
		this.toastSrv.showWarning(this.labels['TOOLS_MENU_COMPONENT_PLAN_SAVED_ERROR']);
		this.canvasSrv.dispatch('switchToDeformity');
		this.loaderSrv.hide();
	}

	private _clearResetEvents(): void {
		if (this._resetKey) {
			this.sseSrv.unsubscribe(this._resetKey);
			this._resetKey = null;
		}
	}

	private handleModelReset(currentCase: Plan): void {
		this._resetKey = `OrthoNext.Fibone.Reset.${currentCase.id}`;
		console.log('Resetting... ' + this._resetKey);
		this.sseSrv.subscribe(this._resetKey, (success: boolean) => {
			console.log('Resetted: ' + success);
			if (success) {
				this.redirectToFitbone(currentCase);
			} else {
				this.backToDeformity();
				this._clearResetEvents();
			}
		});
		// timeout reset subscription
		timer(0, environment.resetSubscriptionTimeout / 10).pipe(
			takeWhile(() => !!this._resetKey),
			take(10),
		).subscribe({
			complete: () => {
				if (this._resetKey) {
					console.log('Not Resetted');
					this.backToDeformity();
					this._clearResetEvents();
				}
			}
		});
	}

	/**
	 * Redirect to fitbone site.
	 */
	private redirectToFitbone(currentCase: Plan): void {
		window.location.href = environment.templatesSite + '?caseGuid=' + currentCase.id;
	}

	/**
	* Start osteotomy site workflow.
	*/
	openOsteotomyWorkflow(): void {
		if (!this.isEocCut) {
			if (this.osteotomyCut) {
				const message: string = this.labels['TOOLS_MENU_COMPONENT_OSTEOTOMY_MODAL_MESSAGE'];
				this.openWarningModal(message, this.startApexSelection);
			} else {
				this.startApexSelection();
			}
		}
	}

	private startApexSelection(): void {
		this.canvasSrv.dispatch('startSelectingApex');
	}

	private initCutForm(): void {
		if (this.deformityAnalysisType === DeformityAnalysisType.mechanical) {
			this.cutForm = new FormGroup({
				'cutType': new FormControl({ value: this.osteotomyCut, disabled: !this.selectedApex }),
				'cut': new FormControl({ value: this.isEocCut, disabled: !this.osteotomyCut })
			});
		}
		else if (this.deformityAnalysisType === DeformityAnalysisType.anatomical) {
			this.cutForm = new FormGroup({
				'cut': new FormControl({ value: this.isEocCut, disabled: this.isAnatCutDisable })
			});
		}
	}

	/**
	* Event listener for cut error.
	*/
	cutError = (event) => {
		if (event.args == BridgeResultMessages.cannotCut) {
			this.toastSrv.showWarning(this.labels['TOOLS_MENU_COMPONENT_CUT_ERROR']);
			this.cutForm?.controls.cut.setValue(this.isEocCut);
		}
	};

	/**
	* Event listener for cut initialization.
	*/
	cutInit = (event) => {
		if (event.args == BridgeResultMessagesBase.restore) {
			this.initCutForm();
		}
	};

	// MECHANICAL CORRECTION

	/**
	* Set osteotomy opening cut.
	*/
	setOpening(): void {
		if (this.isEocCut && this.osteotomyCut === this.cutType.closing) {
			const message: string = this.labels['TOOLS_MENU_COMPONENT_CUT_MODAL_MESSAGE'];
			this.openWarningModal(message, this.setCutOpeningAndReset, this.setCutClosing);
		} else {
			this.setCutOpening(false);
		}
	}

	private setCutOpening(resetEoc: boolean = true): void {
		this.canvasSrv.dispatch('osteotomyCutOpening');
		this.cutForm.controls.cutType.setValue(this.cutType.opening);
		this.cutForm.controls.cut.enable();
	}

	private setCutOpeningAndReset(): void {
		this.setCutOpening();
		if (this.isEocCut) {
			this.setCut();
			this.cutForm.controls.cut.setValue(this.isEocCut);
		}
	}

	/**
	* Set osteotomy closing cut.
	*/
	setClosing(): void {
		if (this.isEocCut && this.osteotomyCut === this.cutType.opening) {
			const message: string = this.labels['TOOLS_MENU_COMPONENT_CUT_MODAL_MESSAGE'];
			this.openWarningModal(message, this.setCutClosingAndReset, this.setCutOpening);
		} else {
			this.setCutClosing();
		}
	}

	private setCutClosing(): void {
		this.canvasSrv.dispatch('osteotomyCutClosing');
		this.cutForm.controls.cutType.setValue(this.cutType.closing);
		this.cutForm.controls.cut.enable();
	}

	private setCutClosingAndReset(): void {
		this.setCutClosing();
		if (this.isEocCut) {
			this.setCut();
			this.cutForm.controls.cut.setValue(this.isEocCut);
		}
	}

	/**
	* Set cut.
	*/
	setCut(): void {
		const val = !this.isEocCut;
		this.canvasSrv.dispatch('cutEoC', val);
	}

	/**
	* Reset cut.
	*/
	resetCut(): void {
		if (this.isEocCut) {
			this.setCut();
			this.setCut();
		}
	}

	// ANATOMICAL CORRECTION

	get isAnatCutDisable(): boolean {
		return (this.isThirdLineInserted && !this.selectedApex) ||
			(this.anatApexProxAP?.def_transl > 100 || this.anatApexProxLT?.def_transl > 100) ||
			(this.anatApexDistAP?.def_transl > 100 || this.anatApexDistLT?.def_transl > 100) ||
			this.eocSuggestedBoneLength > 200;
	}

	/**
	* Start osteotomy site workflow.
	*/
	openAnatOsteotomyWorkflow(): void {
		if (this.isEocCut) {
			const message: string = this.labels['TOOLS_MENU_COMPONENT_OSTEOTOMY_MODAL_MESSAGE'];
			this.openWarningModal(message, this.startAnatApexSelection);
		} else {
			this.startAnatApexSelection();
		}
	}

	private startAnatApexSelection(): void {
		this.canvasSrv.dispatch('cutEoC', false);
		this.canvasSrv.dispatch('startSelectingApex');
	}

	/**
	* Set cut in anatomical analysis.
	*/
	setAnatCut(): void {
		if (!this.isAnatCutDisable) {
			this.setCut();
		}
	}


	ngOnDestroy() {
		this.canvasSrv.removeEventListener('onResult', this.cutError);
		this.canvasSrv.removeEventListener('onResult', this.cutInit);
		this._prodSub?.unsubscribe();
	}

}