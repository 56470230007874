<div class="page-print">
	<div *ngIf="isDemo"
		style="position:absolute; z-index:9999; height:952px; width:1348px; display: flex; align-items: center; justify-content: center">
		<p
			style="color:rgba(211, 211, 211, 0.6);font-size: 100px;transform: rotate(330deg); -webkit-transform:rotate(330deg);">
			{{labels.WATERMARK_DEMO}}
		</p>
	</div>
	<div class="menu-print">
		<div class="voice-menu">
			<span><strong>{{labels.NAVBAR_COMPONENT_PATIENT_ID}}</strong> {{plan?.patient.number}}</span>
			<span><strong>{{labels.NAVBAR_COMPONENT_PLAN_ID}}</strong> {{plan?.number}}</span>
			<span><strong>{{labels.NAVBAR_COMPONENT_PLAN_SIDE}}</strong> {{plan?.side | anatomicalSideLabel}}</span>
			<span><strong>{{labels.NAVBAR_COMPONENT_PLAN_BONE_TYPE}}</strong> {{plan?.boneType | boneTypeLabel}}</span>
			<span><strong><span style="font-size: 13pt">{{labels.PRINT_OR_PLAN_MAIN_TITLE}}</span>
					{{ today | date:'medium' }}</strong></span>
		</div>
		<span class="logo-text">{{labels.APP_LOGO_TEXT}}</span>
	</div>
	<div class="wrapper-content">

		<ng-content></ng-content>

		<div class="row" style="justify-content: flex-end;">{{ labels.PRINT_BOTTOM_RIGHT_PAGE_TEXT }}</div>
	</div>
</div>