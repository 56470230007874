import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LanguageService, Plan } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
 * Third page of PDF print.
 */
@Component({
	selector: 'print-page-three',
	templateUrl: './page-three.component.html'
})
export class PageThreeComponent extends BaseComponent implements OnInit {

	@Input() isDemo: boolean;
	@Input() today: number;

	currentCase: Plan;
	readonly cdnBaseUrl: string = environment.cdnUrl;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this.currentCase = this.canvasSrv.currentCase;
	}

}
