import { Component } from '@angular/core';
import { LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Table of wedge dimension in correction panel.
*/
@Component({
	selector: 'wedge-table',
	templateUrl: './wedge-table.component.html'
})
export class WedgeTableComponent extends BaseComponent {

	isCollapsed: boolean = true;

	private readonly twoDigitsPattern: RegExp = /^\d$|^[1-9]\d{0,2}$/;
	private readonly MIN_VALUE = 0;
	private readonly MAX_VALUE = 90;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	get ap_wedgeAngle(): string {
		return this.eocMeasures.eoc_ap_angle || this.eocMeasures.eoc_ap_angle === 0 ? this.eocMeasures.eoc_ap_angle.toString() : '';
	}

	get lt_wedgeAngle(): string {
		return this.eocMeasures.eoc_lt_angle || this.eocMeasures.eoc_lt_angle === 0 ? this.eocMeasures.eoc_lt_angle.toString() : '';
	}

	get ap_cortexLenght(): string {
		const value = this.eocMeasures.eoc_ap_cortexLength;
		const label = value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_LENGTHENING'] : this.labels['WEDGE_TABLE_COMPONENT_SHORTENING'];
		return value || value === 0 ? `${Math.abs(value)} ${label}` : '';
	}

	get lt_cortexLength(): string {
		const value = this.eocMeasures.eoc_lt_cortexLength;
		const label = value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_LENGTHENING'] : this.labels['WEDGE_TABLE_COMPONENT_SHORTENING'];
		return value || value === 0 ? `${Math.abs(value)} ${label}` : '';
	}

	get ap_axialLength(): string {
		const value = this.eocMeasures.eoc_ap_axialLength;
		const label = value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_LENGTHENING'] : this.labels['WEDGE_TABLE_COMPONENT_SHORTENING'];
		return value || value === 0 ? `${Math.abs(value)} ${label}` : '';
	}

	get lt_axialLength(): string {
		const value = this.eocMeasures.eoc_lt_axialLength;
		const label = value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_LENGTHENING'] : this.labels['WEDGE_TABLE_COMPONENT_SHORTENING'];
		return value || value === 0 ? `${Math.abs(value)} ${label}` : '';
	}

	get ap_secondaryTransl(): string {
		const value = this.eocMeasures.eoc_ap_secondaryTransl;
		const label = value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_MEDIAL'] : this.labels['WEDGE_TABLE_COMPONENT_LATERAL'];
		return value || value === 0 ? `${Math.abs(value)} ${label}` : '';
	}

	get lt_secondaryTransl(): string {
		const value = this.eocMeasures.eoc_lt_secondaryTransl;
		const label = value > 0 ? this.labels['WEDGE_TABLE_COMPONENT_POSTERIOR'] : this.labels['WEDGE_TABLE_COMPONENT_ANTERIOR'];
		return value || value === 0 ? `${Math.abs(value)} ${label}` : '';
	}

}
