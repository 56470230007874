import { BoneTypeEnum, MechanicalAxisLT as MechanicalAxisLTBase, Save } from "@ortho-next/nextray-core";
import { Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { bindedModel, BindedModel } from "../../Models/BindedModel";
import { DeformityAnalysisType } from "../../Models/Enums";
import { PrintStateTypes, StateTypes } from "../../States/State";

export class MechanicalAxisLT extends MechanicalAxisLTBase {
	@Save() public isValid: boolean;

	public onAfterRestore(): void {
		this.boneType = bindedModel.boneTypeLT;
		super.onAfterRestore();
	}

	constructor(isLeft: boolean, boneType: BoneTypeEnum, isEOC: boolean) {
		super(isLeft, boneType, isEOC);
		this.bindProperties(isEOC);
	}

	public handleRegisteredPoints(points: Vector3[]): boolean {
		this.isValid = super.handleRegisteredPoints(points);
		return this.isValid;
	}

	private bindProperties(isEOC: boolean): void {
		if (!isEOC) {
			this.bindProperty('visible', (m: BindedModel) => {
				return m.isMechanicalAxisLTValid && !m.insertingMechanicalAxisLT && m.deformityAnalysisType === DeformityAnalysisType.mechanical && ((!m.EOCCropVisible && m.printState === PrintStateTypes.none) || m.printState === PrintStateTypes.deformityAnalysis);
			}, ['isMechanicalAxisLTValid', 'insertingMechanicalAxisLT', 'deformityAnalysisType', 'EOCCropVisible', 'printState']);

			this.bindProperty('isEnabled', (m: BindedModel) => {
				return !m.readonly && !m.isRegisteringClickLT && m.appState === StateTypes.deformityAnalysis;
			}, ['readonly', 'isRegisteringClickLT', 'appState']);
		}

		this.weightBearing?.bindProperty('visible', (m: BindedModel) => {
			return m.layer_mechAxis_weightBearing && m.boneTypeLT === BoneTypeEnum.LongLeg;
		}, ["layer_mechAxis_weightBearing", "boneTypeLT"]);

		this.femur?.bindProperty('visible', (m: BindedModel) => {
			return m.layer_mechAxis_mechanical && (m.boneTypeLT === BoneTypeEnum.LongLeg || m.boneTypeLT === BoneTypeEnum.Femur);
		}, ["layer_mechAxis_mechanical", "boneTypeLT"]);

		this.tibia?.bindProperty('visible', (m: BindedModel) => {
			return m.layer_mechAxis_mechanical && (m.boneTypeLT === BoneTypeEnum.LongLeg || m.boneTypeLT === BoneTypeEnum.Tibia);
		}, ["layer_mechAxis_mechanical", "boneTypeLT"]);
	}
}  
