<!-- Menu Settings -->
<div class="menu-settings-tlhex">


  <!--LEFT MENU COMPONENTS-->

  <tools-menu *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.none"></tools-menu>

  <mechanical-axis *ngIf="isMechAxisWorkflow"></mechanical-axis>

  <bone-type-selection *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.boneTypeSelection"></bone-type-selection>

  <rotation-workflow *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.rotate"></rotation-workflow>

  <cropping-workflow *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.crop"></cropping-workflow>

  <reference-point-workflow *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.insertRefPoint"></reference-point-workflow>

  <osteotomy-workflow *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.insertOsteotomy && deformityAnalysisType == defTypeEnum.mechanical"></osteotomy-workflow>
  <aa-osteotomy-workflow *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.insertOsteotomy && deformityAnalysisType == defTypeEnum.anatomical"></aa-osteotomy-workflow>

  <postop-osteotomy-workflow *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.insertPostopOst"></postop-osteotomy-workflow>

  <template-selection *ngIf="stateDescription?.isWorkflowRunning == workflowEnum.insertPlate"></template-selection>


  <!--RIGHT MENU - DRAWER-->
  <drawer></drawer>

</div>
