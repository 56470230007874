import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';
import { CroppingWorkflowComponent } from './cropping-workflow';
import { RotationWorkflowComponent } from './rotation-workflow';

@NgModule({
	declarations: [
		RotationWorkflowComponent,
		CroppingWorkflowComponent,
	],
	exports: [
		RotationWorkflowComponent,
		CroppingWorkflowComponent
	],
	imports: [
		CommonModule,
		SharedModule
	]
})
export class ImageSetupModule { }
