<!-- Box table -->
<div class="box-table">
	<span class="title-accordion" [ngClass]="{'active': !isCollapsed }" (click)="isCollapsed = !isCollapsed">
		{{ labels.AA_LT_TABLE_COMPONENT_TITLE }}
		<div class="fake-material-icon icon-expand expand"></div>
	</span>
	<div [ngbCollapse]="isCollapsed">

		<!--TABLE-->
		<table aria-label="LT anatomical deformity measures for proximal" class="table" *ngIf="isProxTabVisible">
			<thead *ngIf="isThirdLineInserted">
				<tr>
					<th colspan="2">{{ labels.AA_LT_TABLE_COMPONENT_APEX_PROX }}</th>
				</tr>
			</thead>
			<tbody>
				<tr [class.row-selected]="!isValidLTProxAngDef">
					<td>{{ labels.AA_LT_TABLE_COMPONENT_ANGULAR_DEFORMITY }}</td>
					<td [highlightCell]="ltProxAngDef">{{ ltProxAngDef }}</td>
				</tr>
				<tr [class.row-selected]="!isValidLTProxLatTransl">
					<td>{{ labels.AA_LT_TABLE_COMPONENT_LATERAL_TRANSLATION }}</td>
					<td [highlightCell]="ltProxLatTransl">{{ ltProxLatTransl }}</td>
				</tr>
			</tbody>
		</table>

		<!--TABLE-->
		<table aria-label="LT anatomical deformity measures for distal" class="table" *ngIf="isDistTabVisible">
			<thead>
				<tr>
					<th colspan="2">{{ labels.AA_LT_TABLE_COMPONENT_APEX_DIST }}</th>
				</tr>
			</thead>
			<tbody>
				<tr [class.row-selected]="!isValidLTDistAngDef">
					<td>{{ labels.AA_LT_TABLE_COMPONENT_ANGULAR_DEFORMITY }}</td>
					<td [highlightCell]="ltDistAngDef">{{ ltDistAngDef }}</td>
				</tr>
				<tr [class.row-selected]="!isValidLTDistLatTransl">
					<td>{{ labels.AA_LT_TABLE_COMPONENT_LATERAL_TRANSLATION }}</td>
					<td [highlightCell]="ltDistLatTransl">{{ ltDistLatTransl }}</td>
				</tr>
			</tbody>
		</table>

		<div class="invalid-feedback d-block" *ngIf="!isValidLTProxAngDef || !isValidLTDistAngDef">
			{{ labels.AA_LT_TABLE_COMPONENT_ANG_DEF_WARNING }}
		</div>
		<div class="invalid-feedback d-block" *ngIf="!isValidLTProxLatTransl || !isValidLTDistLatTransl">
			{{ labels.AA_LT_TABLE_COMPONENT_LAT_TRANSL_WARNING }}
		</div>

	</div>
</div>