import { Component } from '@angular/core';
import { ConstsBase } from '@ortho-next/nextray-core/Utils/Consts';
import { LanguageService } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
* Recap table of AP image of PDF print.
*/
@Component({
	selector: 'prt-ma-ap-table',
	templateUrl: './prt-ma-ap-table.component.html'
})
export class PrtMaApTableComponent extends BaseComponent {

	apAngles = { ...ConstsBase.paleyAngles.AP };

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	/**
	* Check if MAD is visible.
	*/
	get isMAD(): boolean {
		return this.isLongLeg;
	}

	/**
	* Check if MNSA is visible.
	*/
	get isMNSA(): boolean {
		return this.isLongLeg || this.isFemur;
	}

	/**
	* Check if LPFA is visible.
	*/
	get isLPFA(): boolean {
		return this.isLongLeg || this.isFemur;
	}

	/**
	* Check if MPFA is visible.
	*/
	get isMPFA(): boolean {
		return this.isLongLeg || this.isFemur;
	}

	/**
	* Check if MLDFA is visible.
	*/
	get isMLDFA(): boolean {
		return this.isLongLeg || this.isFemur;
	}

	/**
	* Check if JLCA is visible.
	*/
	get isJLCA(): boolean {
		return this.isLongLeg;
	}

	/**
	* Check if Knee Translation is visible.
	*/
	get isKneeTransl(): boolean {
		return this.isLongLeg;
	}

	/**
	* Check if MPTA is visible.
	*/
	get isMPTA(): boolean {
		return this.isLongLeg || this.isTibia;
	}

	/**
	* Check if LDTA is visible.
	*/
	get isLDTA(): boolean {
		return this.isLongLeg || this.isTibia;
	}


	// ANGLES RANGE

	/**
	* Get MAD range.
	*/
	get rangeMAD(): string {
		return `(${this.apAngles.MAD.min}mm - ${this.apAngles.MAD.max}mm)`;
	}

	/**
	* Get MNSA range.
	*/
	get rangeMNSA(): string {
		return `(${this.apAngles.MNSA.min}° - ${this.apAngles.MNSA.max}°)`;
	}

	/**
	* Get LPFA range.
	*/
	get rangeLPFA(): string {
		return `(${this.apAngles.mLPFA.min}° - ${this.apAngles.mLPFA.max}°)`;
	}

	/**
	* Get MPFA range.
	*/
	get rangeMPFA(): string {
		return `(${this.apAngles.MPFA.min}° - ${this.apAngles.MPFA.max}°)`;
	}

	/**
	* Get MLDFA range.
	*/
	get rangeMLDFA(): string {
		return `(${this.apAngles.mLDFA.min}° - ${this.apAngles.mLDFA.max}°)`;
	}

	/**
	* Get JLCA range.
	*/
	get rangeJLCA(): string {
		return `(${this.apAngles.JLCA.min}° - ${this.apAngles.JLCA.max}°)`;
	}

	/**
	* Get Knee Translation range.
	*/
	get rangeKneeTransl(): string {
		return `(${this.apAngles.KneeTransl.min}mm - ${this.apAngles.KneeTransl.max}mm)`;
	}

	/**
	* Get MPTA range.
	*/
	get rangeMPTA(): string {
		return `(${this.apAngles.MPTA.min}° - ${this.apAngles.MPTA.max}°)`;
	}

	/**
	* Get LDTA range.
	*/
	get rangeLDTA(): string {
		return `(${this.apAngles.LDTA.min}° - ${this.apAngles.LDTA.max}°)`;
	}


}
