import { AppModel as AppModelBase, AxialRotationEnum, BoneTypeEnum, LayerElementsBase, Link, NextRayMeasures as NextRayMeasuresBase, ViewType } from '@ortho-next/nextray-core';
import { Context } from "../States/Context";
import { OsteotomyCut, StateDescription, StateTypes } from "../States/State";
import { PrintResult } from "../Utils/PrintUtils";
import { AxialTranslationEnum, DeformityAnalysisType } from './Enums';
import { PlateModel } from "./PlateModel";

export interface BOM {
	screwNoLockingList?: number[];
	screwLockingList?: number[];
}

export enum PlateOrientationType {
	lateral,
	medial
}

export interface NextRayMeasures extends NextRayMeasuresBase, NextrayMeasuresEoCMech, NextrayMeasuresEoCAnat {
	def_ap_apex1?: NextrayMeasuresAnatomicalApex;
	def_ap_apex2?: NextrayMeasuresAnatomicalApex;
	def_lt_apex1?: NextrayMeasuresAnatomicalApex;
	def_lt_apex2?: NextrayMeasuresAnatomicalApex;
	def_suggestedBoneLength?: number; //move it
}

export interface NextrayMeasuresAnatomicalApex {
	def_angDef?: number;
	def_angDefSign?: string;
	def_transl?: number;
	def_translSign?: string;
	def_ax_transl?: number;
	def_ax_translSign?: string;
}

export interface NextrayMeasuresEoCAnat {
	eoc_ap_overAng?: number;
	eoc_ap_overAngSign?: string;
	eoc_ap_overTransl?: number;
	eoc_ap_overTranslSign?: string;
	eoc_lt_overAng?: number;
	eoc_lt_overAngSign?: string;
	eoc_lt_overTransl?: number;
	eoc_lt_overTranslSign?: string;

	eoc_boneLength?: number;
	eoc_boneLengthSign?: string;
}

export interface NextrayMeasuresEoCMech {
	eoc_ap_LPFA?: number;
	eoc_ap_mLDFA?: number;
	eoc_ap_MPTA?: number;
	eoc_ap_LDTA?: number;
	eoc_ap_JLCA?: number;
	eoc_ap_MAD?: number;
	eoc_ap_MNSA?: number;
	eoc_ap_MPFA?: number;
	eoc_ap_aLDFA?: number;
	eoc_ap_kneeTranslation?: number;
	eoc_ap_femurLength?: number;
	eoc_ap_tibiaLength?: number;
	eoc_ap_fullLength?: number;

	eoc_lt_PDFA?: number;
	eoc_lt_PPTA?: number;
	eoc_lt_ADTA?: number;
	eoc_lt_kneeTranslation?: number;

	eoc_ap_angle?: number;
	eoc_lt_angle?: number;
	eoc_ap_cortexLength?: number;
	eoc_lt_cortexLength?: number;
	eoc_ap_axialLength?: number;
	eoc_lt_axialLength?: number;
	eoc_ap_secondaryTransl?: number;
	eoc_lt_secondaryTransl?: number;
}

export interface LayerElements extends LayerElementsBase {
	mechanicalAxis_anatomical?: boolean;
	mechanicalAxis_mechanical?: boolean;
	mechanicalAxis_weightBearing?: boolean;
	contralateral_all?: boolean;
	referencePoint?: boolean;
	anatomicalAxis?: boolean;
	osteotomy?: boolean;
	plate?: boolean;
}

/**
 * Model used by angular to create bindings. Only three.js part can set this values.
 */
export class AppModel extends AppModelBase {
	protected _context: Context;
	public measures: NextRayMeasures; //override
	public layers: LayerElements; //override
	public layersValue: LayerElements; //override
	/**
	 * Indicates if the plate is inserted.
	 */
	public isPlateInserted = false;
	/**
	 * Gets the selected osteotomy cut to perform.
	 */
	public osteotomyCut: OsteotomyCut;
	/**
	 * Indicates if EoC cut switch is on/off.
	 */
	public eocCut: boolean;
	/**
	 * Gets the plate orientation.
	 */
	public plateOrientation = PlateOrientationType.lateral;
	/**
	 * Gets selected plate model data.
	 */
	public plate: PlateModel;
	/**
	 * Indicates if it's loading AP plate mesh.
	 */
	public isAPPlateLoading: boolean;
	/**
	 * Indicates if it's loading lateral plate mesh.
	 */
	public isLTPlateLoading: boolean;
	/**
	 * Gets BOM data.
	 */
	public screwsBom: BOM;
	/**
	 * Gets images for print report.
	 */
	public printResult: PrintResult;
	/**
	 * Gets images to use in zoomed popup during calibration.
	 */
	public magnifier: string;
	/**
	 * Gets ostoetomy level measure for print report.
	 */
	public print_osteotomyLevel = 0;
	/**
	 * Gets cortex wire measure for print report.
	 */
	public print_cortexWire = 0;
	/**
	 * Gets wire angle measure for print report.
	 */
	public print_wireAngle = 0;
	/**
	 * Gets bone type of Lateral view.
	 */
	public boneTypeLT: BoneTypeEnum;
	/**
	 * Indicates if reference point is inserted.
	 */
	@Link() public isReferencePointInserted = false;
	/**
	 * Indicates if the anatomical axis is inserted.
	 */
	public isAnatomicalAxisInserted = false;
	/**
	 * Indicates if the third line is inserted.
	 */
	public isThirdLineInserted = false;
	/**
	 * Indicates deformity analysis type.
	 */
	public deformityAnalysisType = DeformityAnalysisType.none;
	/**
	 * Indicates anatomical axial rotation value in deformity analsysis.
	 */
	public def_anatAxialRot = 0;
	/**
	 * Indicates anatomical axial rotation sign in deformity analysis.
	 */
	public def_anatAxialRotSign = AxialRotationEnum.external;
	/**
	 * Indicates anatomical axial translation value in deformity analsysis.
	 */
	public def_anatAxialTrans = 0;
	/**
	 * Indicates anatomical axial translation sign in deformity analysis.
	 */
	public def_anatAxialTransSign = AxialTranslationEnum.short;
	/**
	 * Set anatomical axial translation reference.
	 */
	public anatAxialTranslRef: ViewType;
	/**
	 * Indicates anatomical axial rotation value in correction analsysis.
	 */
	public eoc_anatAxialRot = 0;
	/**
	 * Indicates anatomical axial rotation sign in correction analysis.
	 */
	public eoc_anatAxialRotSign = AxialRotationEnum.external;
	/**
	 * Indicates if osteotomy is more inclinated than 15 degrees.
	 */
	@Link() public ostBeyond15Degrees = false;
	/**
	 * Indicates if bone length is equals to suggested bone length.
	 */
	@Link() public boneLengthisEqualsToSuggested;

	constructor(context: Context) {
		super(context);
	}

	//override
	public get stateType(): StateTypes {
		return super.stateType;
	}
	public get stateDescription(): StateDescription {
		return super.stateDescription;
	}
}
