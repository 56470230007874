import { Component, OnInit } from '@angular/core';
import { LanguageService, Screw } from '../../../core';
import { CanvasService, ProductService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
* Component of table of material list
*/
@Component({
  selector: 'list-material-table',
  templateUrl: './list-material-table.component.html',
})
export class ListMaterialTableComponent extends BaseComponent implements OnInit {

  screwPlateList = [];

  constructor(
    private langSrv: LanguageService,
    private canvasSrv: CanvasService,
    private prodSrv: ProductService
  ) {
    super(langSrv, canvasSrv);
  }

  ngOnInit() {
    const screwsBom = this.canvasSrv.screwsBom;
    if (screwsBom) {
      const screwList = this.prodSrv.bomScrewList;
      if (screwList) {
        this.populateScrewList(screwList.screwLockingList, screwsBom.screwLockingList);
        this.populateScrewList(screwList.screwNoLockingList, screwsBom.screwNoLockingList);
      }
    }
    this.prodSrv.bomScrewList = undefined;
  }

  private groupByCounter = (screwLengths: number[]): any => {
    const result = {};
    for (const key of screwLengths) {
      result[key] = (result[key] || 0) + 1;
    }
    return result;
  }

  private populateScrewList(screwList: Screw[], screwsBomList: number[]): void {
    if (screwsBomList) {
      const screwsBomLockCount = this.groupByCounter(screwsBomList);
      for (const legth in screwsBomLockCount) {
        if (screwsBomLockCount.hasOwnProperty(legth)) {
          const screw = screwList.find(item => item.length === +legth);
          if (screw) {
            this.screwPlateList.push({
              code: screw.code,
              description: screw.description,
              count: screwsBomLockCount[legth]
            });
          }
        }
      }
    }
  }

}
