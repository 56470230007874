import { Component, OnInit } from '@angular/core';
import { BoneTypeEnum, LanguageService } from '../../core';
import { CanvasService } from '../../services';
import { BaseComponent } from '../../shared';


/**
* Component of first vertical sidebar to handle the bone type selection.
*/
@Component({
	selector: 'bone-type-selection',
	templateUrl: './bone-type-selection.component.html',
	styles: []
})
export class BoneTypeSelectionComponent extends BaseComponent implements OnInit {

	boneType: BoneTypeEnum;
	boneTypeEnum = BoneTypeEnum;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.boneType = this.boneTypeLT;
	}

	/**
	 * Ends bone type selection workflow without confirm.
	 */
	back(): void {
		this.canvasSrv.dispatch("cancelBoneTypeSelection");
	}

	/**
	 * Confirm bone type selection workflow and ends workflow.
	 */
	confirm(): void {
		this.canvasSrv.dispatch("confirmBoneTypeSelection", this.boneType);
		this.canvasSrv.dispatch("mechanical-axes-btn");
	}

}
