import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AxialRotationEnum } from '@ortho-next/nextray-core';
import { AxialTranslationEnum } from '../../../../nextray/Models/Enums';
import { Consts } from '../../../../nextray/Utils/Consts';
import { LanguageService, ReferenceTypeEnum } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';


/**
 * Table for axial view measures in anatomical analysis.
 */
@Component({
	selector: 'axial-view-table',
	templateUrl: './axial-view-table.component.html'
})
export class AxialViewTableComponent extends BaseComponent implements OnInit {

	isCollapsed: boolean = false;

	// rotation
	axialRotForm: FormGroup;
	axialRotEnum = AxialRotationEnum;
	externalLabel: string;
	internalLabel: string;
	invalidRotMsg: string;

	axRotationExternalValue: AxialRotationEnum.external | AxialRotationEnum.internal;
	axRotationInternalValue: AxialRotationEnum.external | AxialRotationEnum.internal;

	// translation
	axialTransForm: FormGroup;
	axialTransEnum = AxialTranslationEnum;
	invalidTransMsg: string;

	private readonly twoDigitsPattern: RegExp = /^\d$|^[1-9]\d?$/;
	private readonly MIN_ROT = 0;
	private readonly MAX_ROT = 90;
	private readonly threeDigitsPattern: RegExp = /^\d$|^[1-9]\d{0,2}$/;
	private readonly MIN_TRANS = 0;
	private readonly MAX_TRANS = 200;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.initAxialRotForm();
		if (this.isDeformity) {
			this.initAxialTransForm();
		}
	}

	// AXIAL ROTATION
	private initAxialRotForm(): void {
		if (this.canvasSrv.currentCase.referenceType == ReferenceTypeEnum.Distal && (this.isForeFoot || this.isHindFoot)) {
			this.axRotationExternalValue = AxialRotationEnum.internal;
			this.axRotationInternalValue = AxialRotationEnum.external;
		}
		else {
			this.axRotationExternalValue = AxialRotationEnum.external;
			this.axRotationInternalValue = AxialRotationEnum.internal;
		}
		const validators: ValidatorFn[] = [Validators.pattern(this.twoDigitsPattern), Validators.min(this.MIN_ROT), Validators.max(this.MAX_ROT)];
		this.axialRotForm = new FormGroup({
			'axialRotValue': new FormControl(this.defAnatAxialRot, validators),
			'axialRotSign': new FormControl(this.defAnatAxialRotSign)
		});
		this.initRotSignLabels();
		this.initInvalidRotMsg();
	}

	private initInvalidRotMsg(): void {
		const label: string = this.labels['AXIAL_VIEW_TABLE_COMPONENT_ROTATION_VALIDATION'];
		this.invalidRotMsg = label?.replace("%1", this.MIN_ROT.toString())?.replace("%2", this.MAX_ROT.toString());
	}

	private initRotSignLabels(): void {
		const boneType = this.canvasSrv.currentCase.boneType;
		const signLabels = Consts.signLabels[boneType]?.axial?.rotation ?? [];
		this.externalLabel = this.anatSignLabel(signLabels[0]) ?? this.labels['AXIAL_VIEW_TABLE_COMPONENT_ROTATION_EXTERNAL'];
		this.internalLabel = this.anatSignLabel(signLabels[1]) ?? this.labels['AXIAL_VIEW_TABLE_COMPONENT_ROTATION_INTERNAL'];
	}

	/**
	* Update axial rotation sign.
	*/
	updateAxialRotSign(sign: AxialRotationEnum): void {
		if (this.stateType === this.stateTypeEnum.deformityAnalysis) {
			this.canvasSrv.dispatch('setDefAnatAxialRotSign', sign);
		}
	}

	/**
	* Update axial rotation value.
	*/
	updateAxialRotValue(): void {
		if (this.stateType === this.stateTypeEnum.deformityAnalysis) {
			if (this.axialRotForm.controls.axialRotValue.valid) {
				const value = this.axialRotForm.value.axialRotValue ? parseInt(this.axialRotForm.value.axialRotValue) : 0;
				this.canvasSrv.dispatch('setDefAnatAxialRot', value);
			}
			this.axialRotForm.controls.axialRotValue.setValue(this.defAnatAxialRot);
		}
	}

	// AXIAL TRANSLATION

	private initAxialTransForm(): void {
		const validators: ValidatorFn[] = [Validators.pattern(this.threeDigitsPattern), Validators.min(this.MIN_TRANS), Validators.max(this.MAX_TRANS)];
		this.axialTransForm = new FormGroup({
			'axialTransValue': new FormControl(this.defAnatAxialTrans, validators),
			'axialTransSign': new FormControl(this.defAnatAxialTransSign)
		});
		this.initInvalidTransMsg();
	}

	private initInvalidTransMsg(): void {
		const label: string = this.labels['AXIAL_VIEW_TABLE_COMPONENT_TRANSLATION_VALIDATION'];
		this.invalidTransMsg = label?.replace("%1", this.MIN_TRANS.toString())?.replace("%2", this.MAX_TRANS.toString());
	}

	/**
	* Update axial translation sign.
	*/
	updateAxialTransSign(sign: AxialTranslationEnum): void {
		if (this.stateType === this.stateTypeEnum.deformityAnalysis) {
			this.canvasSrv.dispatch('setDefAnatAxialTransSign', sign);
		}
	}

	/**
	* Update axial translation value.
	*/
	updateAxialTransValue(): void {
		if (this.stateType === this.stateTypeEnum.deformityAnalysis) {
			if (this.axialTransForm.controls.axialTransValue.valid) {
				const value = this.axialTransForm.value.axialTransValue ? parseInt(this.axialTransForm.value.axialTransValue) : 0;
				this.canvasSrv.dispatch('setDefAnatAxialTrans', value);
			}
			this.axialTransForm.controls.axialTransValue.setValue(this.defAnatAxialTrans);
		}
	}

}
