import { ActionsBase, AxialRotationEnum, BoneTypeEnum, BridgeResultMessagesBase, CaseModel, SelectedApexAnat, SelectedApexMech, State as StateBase, StateDescription as StateDescriptionBase, StateTransitionData, ViewType } from "@ortho-next/nextray-core";
import { ScrewList } from "../../app/core";
import { BridgeResultMessages } from "../Core/Bridge";
import { PlateOrientationType } from "../Models/AppModel";
import { AxialTranslationEnum, BoneLengthSign, BoneTypeMinEnum, DeformityAnalysisType, OverAngulationSign, OverTranslSign } from "../Models/Enums";
import { PlateModel } from "../Models/PlateModel";
import { Context } from "./Context";

/**
 * List of context events.
 */
export interface Actions extends ActionsBase {
	/**
	 * Starts insert mechanical axis workflow.
	 */
	insertMechanicalAxis: ViewType;
	/**
	 * Starts insert anatomical axis workflow.
	 */
	insertAnatomicalAxis: ViewType;
	/**
	 * Starts insert contralateral mechanical axis workflow.
	 */
	insertControlaterMechanicalAxis: never;
	/**
	 * Starts the bone type selection workflow.
	 */
	startBoneTypeSelection: never;
	/**
	 * Ends bone type selection workflow without confirm.
	 */
	cancelBoneTypeSelection: never;
	/**
	 * Confirm bone type selection and ends workflow.
	 */
	confirmBoneTypeSelection: BoneTypeEnum;
	/**
	 * Set insert selected apex during ostoetomy workflow.
	 */
	selectApex: SelectedApexMech | SelectedApexAnat;
	/**
	 * Sets osteotomy cut.
	 */
	osteotomyCut: OsteotomyCut;
	/**
	 * Ends insert osteotomy workflow.
	 */
	confirmApex: never;
	/**
	 * Sets standard angles to calculate apex.
	 */
	setStandardAngle: SetStandardAngleParams;
	/**
	 * Adds or removes the plane cut along the osteotomy.
	 */
	cutEoC: boolean;
	/**
	 * Sends a simple code message to the Bridge object.
	 */
	onBridgeResult: BridgeResultMessages | BridgeResultMessagesBase;
	/**
	 * Inserts plate to the scenes.
	 */
	insertPlate: PlateModel;
	/**
	 * Sets the axial rotation angle degrees for a specific bone.
	 */
	setAxialRotation: SetAxialRotation;
	/**
	* Sets the axial rotation angle sign for a specific bone.
	*/
	setAxialRotationSign: SetAxialRotationSign;
	/**
	 * Starts select osteotomy workflow.
	 */
	startSelectingApex: never;
	/**
	 * Ends select apex workflow and restore old selected apex value.
	 */
	cancelSelectingApex: never;
	/**
	 * Switches the current state to calibrated.
	 */
	switchToCalibrated: never;
	/**
	 * Switches the current state to deformity.
	 */
	switchToDeformity: never;
	/**
	 * Switches the current state to EOC.
	 */
	switchToEOC: DeformityAnalysisType;
	/**
	 * Switches the current state to templating.
	 */
	switchToMounting: never;
	/**
	 * Switches the current state to RPM.
	 */
	switchToRPM: never;
	/**
	 * Switches the current state to Tlhex.
	 */
	switchToTlhex: never;
	/**
	 * Rotate camera based on current state.
	 */
	rotateCamera: never;
	/**
	 * Starts the inserting plate workflow.
	 */
	startInsertingPlate: never;
	/**
	 * Ends inserting plate workflow.
	 */
	confirmPlate: never;
	/**
	 * Ends inserting plate workflow and restore old selected plate.
	 */
	cancelPlate: never;
	/**
	 * Flips the plate.
	 */
	flipPlate: PlateOrientationType;
	/**
	 * Generates images for print report.
	 */
	generateImagesToPrint: never;
	/**
	 * Updates measures available for print report only.
	 */
	updateMeausuresOnlyPrint: never;
	/**
	* Update screw list.
	*/
	updateScrews: ScrewList;
	/**
	 * Switch apex - third line on anatomical axis.
	 */
	switchApex: ViewType;
	/**
	 * Switch proximal axis on anatomical axis.
	 */
	switchProximal: ViewType;
	/**
	 * Switch distal axis on anatomical axis.
	 */
	switchDistal: ViewType;
	/**
	 * Set anatomical axial rotation value in deformity analysis.
	 */
	setDefAnatAxialRot: number;
	/**
	 * Set anatomical axial rotation sign in deformity analysis.
	 */
	setDefAnatAxialRotSign: AxialRotationEnum;
	/**
	 * Set anatomical axial translation value in deformity analysis.
	 */
	setDefAnatAxialTrans: number;
	/**
	 * Set anatomical axial translation sign in deformity analysis.
	 */
	setDefAnatAxialTransSign: AxialTranslationEnum;
	/**
	 * Set anatomical axial translation reference.
	 */
	setAnatAxialTranslRef: ViewType;
	/**
	 * Starts the reference point workflow.
	 */
	startRefPointInsertion: never;
	/**
	 * Confirm reference point and ends the workflow.
	 */
	confirmRefPoint: never;
	/**
	 * Ends reference point workflow without confirm.
	 */
	cancelRefPoint: never;
	/**
	 * Set anatomical axial rotation value in correction analysis.
	 */
	setEocAnatAxialRot: number;
	/**
	 * Set anatomical axial rotation sign in correction analysis.
	 */
	setEocAnatAxialRotSign: AxialRotationEnum;
	/**
	 * Set anatomical over angulation in correction analysis.
	 */
	setOverAngulation: SetAnatEoCMeasure<OverAngulationSign>;
	/**
	 * Set anatomical over translation in correction analysis.
	 */
	setOverTransl: SetAnatEoCMeasure<OverTranslSign>;
	/**
	 * Set anatomical bone length in correction analysis.
	 */
	setBoneLength: SetAnatEoCMeasure<BoneLengthSign>;
	/**
	 * Starts the postop osteotomy workflow.
	 */
	startPostopOstInsertion: never;
	/**
	 * Confirm postop osteotomy and ends the workflow.
	 */
	confirmPostopOst: never;
	/**
	 * Ends postop osteotomy workflow without confirm.
	 */
	cancelPostopOst: never;
	/**
	 * Reset checkup EOC initial data.
	 */
	resetCheckupEOC: never;
}

export interface SetAxialRotation {
	value: number;
	boneType: BoneTypeMinEnum;
}

export interface SetAxialRotationSign {
	value: AxialRotationEnum;
	boneType: BoneTypeMinEnum;
}

export interface SetStandardAngleParams {
	angle: CustomStandardAngles;
	value: number;
}
export interface SetAnatEoCMeasure<T> {
	view: ViewType;
	value: number;
	sign: T
}

export enum CustomStandardAngles {
	LPFA,
	mLDFA,
	MPTA,
	LDTA,
	PPTA,
	ADTA
}

export enum RegisteringType {
	mechanicalAxisAP,
	mechanicalAxisLT,
	contralateral
}

export enum OsteotomyCut {
	none,
	opening,
	closing
}

export enum PrintStateTypes {
	none,
	deformityAnalysis,
	defAnalysisNext,
}

export enum StateTypes { // important: sorted by flow
	loading,
	new,  //todo remove
	calibrated, //todo remove
	deformityAnalysis,
	EOC,
	templating,
	RPM,
	tlhex,
	tlhexSchedule
}

/**
 * Workflow list.
 */
export enum WorkflowRunning {
	none,
	insertMechanicalAxis,
	insertContralateral,
	crop,
	rotate,
	insertOsteotomy,
	insertPlate,
	boneTypeSelection,
	insertRefPoint,
	insertPostopOst
}

/**
 * Data of current state.
 */
export interface StateDescription extends StateDescriptionBase {
	isWorkflowRunning: WorkflowRunning; //override
}

/**
 * Abstract class to create a state.
 */
export abstract class State extends StateBase {
	public abstract stateDescription: StateDescription; //todo StateDescription override
	protected abstract handleChangeStateEvent<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData; //override
	protected _context: Context;
	protected _caseModel: CaseModel;
	public type: StateTypes;

	public onAfterInit(): void {
		super.onAfterInit();
		this.handle("rotateCamera");
	}

	constructor(context: Context, caseModel: CaseModel, type: StateTypes, restore?: object) {
		super(context, caseModel, type, restore);
	}

	protected handleNotChangeStateEvent<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData {
		switch (event) {
			case "rotateCamera":
				this.dispatchToMain(event, args);
				return { type: this.type };
		}
		return super.handleNotChangeStateEvent(event, args);
	}

	//override

	public handle<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData {
		return super.handle(event, args);
	}
	protected dispatchToMain<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData {
		return super.dispatchToMain(event, args);
	}
}
