import { Component } from '@angular/core';
import { LanguageService } from '../core';
import { CanvasService } from '../services';
import { BaseComponent } from '../shared';


/**
 * First left vertical bar 
 */
@Component({
	selector: 'sidebar',
	templateUrl: './sidebar.component.html'
})
export class SidebarComponent extends BaseComponent {

	constructor(
		private langSrv: LanguageService,
		public canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

}
