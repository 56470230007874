<!-- EMPTY DRAWER -->
<div class="sidebar" *ngIf="stateType < stateTypeEnum.deformityAnalysis || deformityAnalysisType === defTypeEnum.none">

	<div class="spacing-top"></div>

	<span class="text-vertical"></span>

</div>
<div class="sidebar second" *ngIf="!isCorrectionDrawerVisible">

	<span class="text-vertical"></span>

</div>


<!-- DEFORMITY DRAWER -->
<!--NOTA: aggiungere la classe "out-range" al seguente div "sidebar" quando è presente almeno un errore di "out of range" nella tabella -->
<div class="sidebar first-color" [ngClass]="{'open': isDeformityDrawerOpen, 'out-range': isDeformityOutOfRange }"
	customTooltip [activeTooltip]="isFractureTooltipVisible" [valueTooltip]="fractureTooltipContent"
	*ngIf="stateType >= stateTypeEnum.deformityAnalysis && deformityAnalysisType !== defTypeEnum.none">

	<div class="spacing-top">
		<div class="open-sidebar" (click)="isDeformityDrawerOpen = !isDeformityDrawerOpen">
			<i class="material-icons">chevron_right</i>
		</div>
	</div>

	<span class="text-vertical clickable" (click)="isDeformityDrawerOpen = !isDeformityDrawerOpen">
		{{ labels.DRAWER_COMPONENT_TEXT_VERTICAL_DEFORMITY }}
	</span>

	<div class="wrapper-table-accordion" *ngIf="deformityAnalysisType === defTypeEnum.mechanical">

		<h3>{{ labels.DRAWER_COMPONENT_TITLE_DEFORMITY }}</h3>

		<ma-ap-table *ngIf="hasAP"></ma-ap-table>

		<lld-table *ngIf="hasAP"></lld-table>

		<ma-lt-table *ngIf="hasLT"></ma-lt-table>

		<apex-table></apex-table>

	</div>

	<div class="wrapper-table-accordion" *ngIf="deformityAnalysisType === defTypeEnum.anatomical">

		<h3>{{ labels.DRAWER_COMPONENT_TITLE_DEFORMITY }}</h3>

		<anatomical-axis-table></anatomical-axis-table>

	</div>

</div>



<!-- CORRECTION DRAWER -->
<div class="sidebar second second-color"
	[ngClass]="{'open': isCorrectionDrawerOpen, 'out-range': isCorrectionOutOfRange }" *ngIf="isCorrectionDrawerVisible">

	<div class="spacing-top">
		<div class="open-sidebar" (click)="isCorrectionDrawerOpen = !isCorrectionDrawerOpen">
			<i class="material-icons">chevron_right</i>
		</div>
	</div>

	<span class="text-vertical clickable" (click)="isCorrectionDrawerOpen = !isCorrectionDrawerOpen">
		{{ labels.DRAWER_COMPONENT_TEXT_VERTICAL_CORRECTION }}
	</span>


	<div class="wrapper-table-accordion" *ngIf="deformityAnalysisType === defTypeEnum.mechanical">

		<h3>{{ labels.DRAWER_COMPONENT_TITLE_CORRECTION }}</h3>

		<wedge-table></wedge-table>

		<ma-ap-recap *ngIf="hasAP"></ma-ap-recap>

		<lld-recap *ngIf="hasAP"></lld-recap>

		<ma-lt-recap *ngIf="hasLT"></ma-lt-recap>

	</div>

	<div class="wrapper-table-accordion" *ngIf="deformityAnalysisType === defTypeEnum.anatomical">

		<h3>{{ labels.DRAWER_COMPONENT_TITLE_END_OF_CORRECTION }}</h3>

		<correction-anatomical-table></correction-anatomical-table>

	</div>

</div>


<!-- fracture axial translation tooltip -->
<ng-template #fractureTooltipContent><span [innerHTML]="labels.DRAWER_COMPONENT_TOOLTIP_FRACTURE"></span></ng-template>