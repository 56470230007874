import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { CorrectionModule } from '../correction';
import { DeformityModule } from '../deformity';
import { ImageSetupModule } from '../image-setup';
import { ProductModule } from '../product';
import { SharedModule } from '../shared';
import { DrawerComponent } from './drawer';
import { ImageElaborationComponent } from './image-elaboration';
import { SidebarComponent } from './sidebar.component';
import { ToolsMenuComponent } from './tools-menu';

@NgModule({
	declarations: [
		SidebarComponent,
		DrawerComponent,
		ToolsMenuComponent,
		ImageElaborationComponent
	],
	exports: [
		SidebarComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgbCollapseModule,
		ImageSetupModule,
		DeformityModule,
		CorrectionModule,
		ProductModule,
		SharedModule
	]
})
export class SidebarModule { }
