import { Tools as ToolsBase } from "@ortho-next/nextray-core/Core/Tools";
import { ViewType } from "@ortho-next/nextray-core/Models/AppModel";
import { FullAnatomicalAxis } from "../Tools/AnatomicalAxis/FullAnatomicalAxis";
import { MechanicalAxisAP } from "../Tools/DeformityAnalyzer/FullAnalyzerAP";
import { MechanicalAxisLT } from "../Tools/DeformityAnalyzer/FullAnalyzerLT";
import { EoCPlane } from "../Tools/EoCPlane/EoCPlane";
import { Osteotomy } from "../Tools/Osteotomy/Osteotomy";
import { OsteotomyAnatomical } from "../Tools/Osteotomy/OsteotomyAnatomical";
import { OsteotomyAP } from "../Tools/Osteotomy/OsteotomyAP";
import { OsteotomyLT } from "../Tools/Osteotomy/OsteotomyLT";
import { Plate } from "../Tools/Plate/Plate";
import { PlateManager } from "../Tools/Plate/PlateManager";
import { ReferencePoint } from "../Tools/ReferencePoint";

/**
 * Group that contains all tools.
 */
export abstract class Tools extends ToolsBase {
  public plateManager: PlateManager;
  public referencePoint: ReferencePoint;
  public EoCPlane: EoCPlane;
  public anatomicalAxis: FullAnatomicalAxis;
  public abstract mechanicalAxis: MechanicalAxisAP | MechanicalAxisLT;
  public abstract contralateralMechanicalAxis: MechanicalAxisAP | undefined;
  public abstract osteotomy: Osteotomy;
  /**
   * Get the cloned mechanical axis moves togheter with EoCPlane.
   */
  public abstract clonedMechanicalAxis: MechanicalAxisAP | MechanicalAxisLT;
  /**
   * Get the cloned osteotomy moves togheter with EoCPlane.
   */
  public abstract clonedOsteotomy: OsteotomyAP | OsteotomyLT;

  public get plate(): Plate {
    return this.plateManager && this.plateManager.plate;
  }
}

/**
 * Group that contains all AP tools.
 */
export class ToolsAP extends Tools {
  public viewType = ViewType.AP;
  public mechanicalAxis: MechanicalAxisAP;
  public contralateralMechanicalAxis: MechanicalAxisAP;
  public osteotomy: OsteotomyAP | OsteotomyAnatomical;

  public get clonedMechanicalAxis(): MechanicalAxisAP {
    return this.EoCPlane && this.EoCPlane.clonedAxis as MechanicalAxisAP;
  }
  public get clonedOsteotomy(): OsteotomyAP {
    return this.EoCPlane && this.EoCPlane.clonedOsteotomy as OsteotomyAP;
  }
}

/**
 * Group that contains all lateral tools.
 */
export class ToolsLT extends Tools {
  public viewType = ViewType.LT;
  public mechanicalAxis: MechanicalAxisLT;
  public contralateralMechanicalAxis: undefined;
  public osteotomy: OsteotomyLT | OsteotomyAnatomical;

  public get clonedMechanicalAxis(): MechanicalAxisLT {
    return this.EoCPlane && this.EoCPlane.clonedAxis as MechanicalAxisLT;
  }

  public get clonedOsteotomy(): OsteotomyLT {
    return this.EoCPlane && this.EoCPlane.clonedOsteotomy as OsteotomyLT;
  }
}
