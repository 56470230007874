import { CaseModel, StateTransitionData, ViewType } from "@ortho-next/nextray-core";
import { Context } from "./Context";
import { Actions, State, StateTypes, WorkflowRunning } from "./State";

/**
 * State indicating that user is setting plates.
 */
export class Templating extends State {
	public stateDescription = {
		isWorkflowRunning: WorkflowRunning.none,
		isPointsPositioningRunning: false
	};

	constructor(context: Context, caseModel: CaseModel, restore?: object) {
		super(context, caseModel, StateTypes.templating, restore);
	}

	public initState(): void {
		if (this._restore) {
			if (this._caseModel.imgAP) {
				this.dispatchToMain("setView", ViewType.AP);
			} else {
				this.dispatchToMain("setView", ViewType.LT);
			}
		}
	}

	protected handleChangeStateEvent<K extends keyof Actions>(event: K, args?: Actions[K]): StateTransitionData {
		switch (event) {
			case "startInsertingPlate":
				this.stateDescription.isWorkflowRunning = WorkflowRunning.insertPlate;
				return this.dispatchToMain(event, args);
			case "cancelPlate":
			case "confirmPlate":
				this.stateDescription.isWorkflowRunning = WorkflowRunning.none;
				return this.dispatchToMain(event, args);
			case "switchToCalibrated":
				this.dispatchToMain(event, args);
				return { type: StateTypes.calibrated, data: { caseModel: this._caseModel } };
			case "switchToDeformity":
				this.dispatchToMain(event, args);
				return { type: StateTypes.deformityAnalysis, data: { caseModel: this._caseModel } };
			case "switchToEOC":
				this.dispatchToMain(event, args);
				return { type: StateTypes.EOC, data: { caseModel: this._caseModel } };
			case "generateImagesToPrint":
			case "updateMeausuresOnlyPrint":
			case "updateScrews":
			case "insertPlate":
			case "flipPlate":
				return this.dispatchToMain(event, args);
		}
	}

}
