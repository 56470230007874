import { ToolEvents, ToolToSave, ToolType } from "@ortho-next/nextray-core/Core/ITool";
import { Save, SaveChild } from "@ortho-next/nextray-core/Core/Save";
import { AnatomicalAxis, AnatomicalSideEnum } from "@ortho-next/nextray-core";
import { ViewType } from "@ortho-next/nextray-core/Models/AppModel";
import { Plane } from "@ortho-next/nextray-core/Tools/Plane";
import { CalculationPoint } from "@ortho-next/nextray-core/Tools/Primitive/CalculationPoint";
import { DraggablePoint } from "@ortho-next/nextray-core/Tools/Primitive/DraggablePoint";
import { LinePointToPoint } from "@ortho-next/nextray-core/Tools/Primitive/LinePointToPoint";
import { VectorUtils } from "@ortho-next/nextray-core/Utils/VectorUtils";
import { Cursors } from "@ortho-next/three-base/RaycasterHandler/CursorHandler";
import { Group, Shape, ShapeBufferGeometry, Vector3 } from "@ortho-next/three-base/three.js/build/three.module";
import { EoCPlaneConfig as Config } from "../../Config/EoCPlaneConfig";
import { BindedModel } from "../../Models/BindedModel";
import { OsteotomyCut, PrintStateTypes, StateTypes } from "../../States/State";
import { Consts } from "../../Utils/Consts";
import { FullAnatomicalAxis } from "../AnatomicalAxis/FullAnatomicalAxis";
import { MechanicalAxisAP } from "../DeformityAnalyzer/FullAnalyzerAP";
import { MechanicalAxisLT } from "../DeformityAnalyzer/FullAnalyzerLT";
import { Osteotomy } from "../Osteotomy/Osteotomy";
import { BlackPlane } from "./BlackPlane";
import { ClonedOsteotomyManager } from "./ClonedOsteotomyManager";
import { CroppedPlane } from "./CroppedPlane";
import { DeformityAnalysisType } from "../../Models/Enums";

export interface EOCPlaneVertices {
  A: Vector3;
  B: Vector3;
  C: Vector3;
  D?: Vector3;
  E?: Vector3;
}

export abstract class EoCPlane extends Group implements ToolToSave {
  public toolType = ToolType.EOCCrop;
  public osteotomy: Osteotomy;
  @SaveChild("position", "scale", "rotation") public croppedPlane: CroppedPlane;
  @SaveChild("position", "scale", "rotation") public blackPlane: BlackPlane;
  @Save("position") public A: CalculationPoint;
  @Save("position") public B: CalculationPoint;
  @Save("position") public C: CalculationPoint;
  @Save("position") public D: CalculationPoint;
  @Save("position") public E: CalculationPoint;
  @Save("position") public ost1: DraggablePoint;
  @Save("position") public ost2: DraggablePoint;
  public line1: LinePointToPoint;
  public line2: LinePointToPoint;
  public line3: LinePointToPoint;
  public line4: LinePointToPoint;
  public line5: LinePointToPoint;
  @SaveChild() protected _clonedOsteotomyManager: ClonedOsteotomyManager;
  protected _plane: Plane;
  protected _originalAxis: MechanicalAxisAP | MechanicalAxisLT | FullAnatomicalAxis;
  protected _viewType: ViewType;
  protected _side: AnatomicalSideEnum;
  @Save() protected _rotationCenter: Vector3;
  
  public abstract deformityAnalysisType: DeformityAnalysisType;
  public abstract set angle(value: number);
  public abstract get angle(): number;
  public abstract get verticalTranslation(): number;
  public abstract set verticalTranslation(value: number);
  public abstract get horizontalTranslation(): number;
  public abstract get clonedAxis(): MechanicalAxisAP | MechanicalAxisLT | AnatomicalAxis;
  public abstract cutPlane(planePoint: Vector3, verticalTransl: number, cutType?: OsteotomyCut): boolean;

  public onAfterRestore(): void {
    if (this.E.position.x !== 0 || this.E.position.y !== 0) {
      this.line3.v1 = this.C;
      this.line3.v2 = this.D;
      this.line4.v1 = this.D;
      this.line4.v2 = this.E;
      this.line5.v1 = this.E;
      this.line5.v2 = this.A;
      this.add(this.D, this.E, this.line4, this.line5);
    } else if (this.D.position.x !== 0 || this.D.position.y !== 0) {
      this.line3.v1 = this.C;
      this.line3.v2 = this.D;
      this.line4.v1 = this.D;
      this.line4.v2 = this.A;
      this.add(this.D, this.line4);
    } else {
      this.line3.v1 = this.C;
      this.line3.v2 = this.A;
    }
    this.dispatchEvent({ type: ToolEvents.updated });
  }

  constructor(viewType: ViewType, side: AnatomicalSideEnum, plane: Plane, osteotomy: Osteotomy, originalAxis: MechanicalAxisAP | MechanicalAxisLT | FullAnatomicalAxis) {
    super();
    this.name = "EoCPlane";
    this._plane = plane;
    this._viewType = viewType;
    this.osteotomy = osteotomy;
    this._originalAxis = originalAxis;
    this._clonedOsteotomyManager = new ClonedOsteotomyManager(this.osteotomy, viewType);
    this._side = side;

    this.add(
      this.blackPlane = new BlackPlane(),
      this.croppedPlane = new CroppedPlane(plane.material),
      this.ost1 = new DraggablePoint("Ost1", viewType, Config.ost1_color),
      this.ost2 = new DraggablePoint("Ost2", viewType, Config.ost2_color),
      this.A = new CalculationPoint("A"),
      this.B = new CalculationPoint("B"),
      this.C = new CalculationPoint("C"),
      this.line1 = new LinePointToPoint("Line1", Config.line1_color, this.A, this.B).translateZ(1),
      this.line2 = new LinePointToPoint("Line2", Config.line2_color, this.B, this.C).translateZ(1),
      this.line3 = new LinePointToPoint("Line3", Config.line3_color).translateZ(1),
      this._clonedOsteotomyManager.obj
    );

    this.ost1.cursorOnDrag = this.ost1.cursorOnHover = Config.CURSOR_ROTATE;
    this.ost2.cursorOnDrag = this.ost2.cursorOnHover = Config.CURSOR_ROTATE;

    this.D = new CalculationPoint("D");
    this.E = new CalculationPoint("E");

    this.line4 = new LinePointToPoint("Line4", Config.line4_color).translateZ(1);
    this.line5 = new LinePointToPoint("Line5", Config.line5_color).translateZ(1);

    this.bindEvents();

    const isRegisteringClick: keyof BindedModel = viewType ? 'isRegisteringClickAP' : 'isRegisteringClickLT';

    this.bindProperty('visible', (m: BindedModel) => {
      return m.EOCCropVisible && (m.printState === PrintStateTypes.none || m.printState === PrintStateTypes.defAnalysisNext);
    }, ['EOCCropVisible', 'printState']);

    this.bindProperty('isEnabled', (m: BindedModel) => {
      return !m.readonly && !m[isRegisteringClick] && m.appState === StateTypes.EOC;
    }, ['readonly', isRegisteringClick, 'appState']);
  }


  public get clonedOsteotomy(): Osteotomy {
    return this._clonedOsteotomyManager.obj;
  }

  protected bindEvents(): void {
    this.bindEvent("onDragMove", (args) => {
      args.preventDefault = true;
      this.dispatchEvent({ type: ToolEvents.updated });
    });

    this.croppedPlane.bindEvent("onDragMove", (args) => {
      this.translationByPoint(args.position);
    });

    this.ost1.bindEvent("onDragMove", (args) => {
      this.rotationByPoint(args.position, this.ost1);
    });

    this.ost2.bindEvent("onDragMove", (args) => {
      this.rotationByPoint(args.position, this.ost2);
    });

    this.bindEvent("onEnabledChange", (value) => {
      this.ost1.cursorOnHover = value ? Config.CURSOR_ROTATE : Cursors["not-allowed"];
      this.ost2.cursorOnHover = value ? Config.CURSOR_ROTATE : Cursors["not-allowed"];
    });
  }

  protected translationByPoint(vector: Vector3): void {
    const pan = vector.clone().sub(this.croppedPlane.position);
    this.croppedPlane.position.add(pan);
    this.ost1.position.add(pan);
    this.ost2.position.add(pan);
    this.A.position.add(pan);
    this.B.position.add(pan);
    this.C.position.add(pan);
    this.D.parent && this.D.position.add(pan);
    this.E.parent && this.E.position.add(pan);
    this._clonedOsteotomyManager.makeTranslation(pan);
  }

  protected rotationByPoint(vector: Vector3, point: DraggablePoint): void { // apex funge solo se ruoti
    const angle = VectorUtils.linesAngle(point.position, vector, this._rotationCenter);
    const shapeOrigin = this.croppedPlane.position.clone().sub(this._rotationCenter);

    this.croppedPlane.rotateZ(angle);
    this.croppedPlane.position.sub(shapeOrigin).add(shapeOrigin.applyAxisAngle(Consts.planeNormal, angle))

    this.ost1.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(this._rotationCenter);
    this.ost2.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(this._rotationCenter);
    this.A.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(this._rotationCenter);
    this.B.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(this._rotationCenter);
    this.C.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(this._rotationCenter);
    this.D.parent && this.D.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(this._rotationCenter);
    this.E.parent && this.E.position.sub(this._rotationCenter).applyAxisAngle(Consts.planeNormal, angle).add(this._rotationCenter);
    this._clonedOsteotomyManager.makeRotation(angle, this._rotationCenter);
  }

  protected reset(): void {
    this.croppedPlane.position.set(0, 0, 2);
    this.croppedPlane.rotation.set(0, 0, 0);
    this.blackPlane.position.set(0, 0, 1);
    this.blackPlane.rotation.set(0, 0, 0);
    this.ost1.position.set(0, 0, 0);
    this.ost2.position.set(0, 0, 0);
    this.A.position.set(0, 0, 0);
    this.B.position.set(0, 0, 0);
    this.C.position.set(0, 0, 0);
    this.D.position.set(0, 0, 0);
    this.E.position.set(0, 0, 0);
    this.remove(this.D, this.E, this.line4, this.line5);
  }

  protected executeCutPlane(planePoint: Vector3): boolean {
    this.reset();
    const vertices = this._plane.getVertices(0) as EOCPlaneVertices;
    const ost1 = this.osteotomy.A.position.clone().setZ(0); // TODO: remove set z and insert line intersection with no z considering
    const ost2 = this.osteotomy.B.position.clone().setZ(0);
    const intersectionAB = VectorUtils.lines2DIntersection(ost1, ost2, vertices.A, vertices.B);
    const intersectionBC = VectorUtils.lines2DIntersection(ost1, ost2, vertices.B, vertices.C);
    const intersectionCD = VectorUtils.lines2DIntersection(ost1, ost2, vertices.C, vertices.D);
    const intersectionDA = VectorUtils.lines2DIntersection(ost1, ost2, vertices.D, vertices.A);

    // takes the part of plane from given point
    const normOsteotomyPlane = Consts.planeNormal.clone().cross(ost1.clone().sub(ost2)); // TODO: perp
    const signA = Math.sign(normOsteotomyPlane.clone().dot(vertices.A.clone().sub(ost1))); // TODO: use computesign
    const signB = Math.sign(normOsteotomyPlane.clone().dot(vertices.B.clone().sub(ost1)));
    const signC = Math.sign(normOsteotomyPlane.clone().dot(vertices.C.clone().sub(ost1)));
    const signD = Math.sign(normOsteotomyPlane.clone().dot(vertices.D.clone().sub(ost1)));
    const signPoint = Math.sign(normOsteotomyPlane.clone().dot(planePoint.clone().sub(ost1)));

    let unlockedVertices = "";
    let intersection1: Vector3, intersection2: Vector3;

    if (signA === signPoint) { unlockedVertices += "A"; }
    if (signB === signPoint) { unlockedVertices += "B"; }
    if (signC === signPoint) { unlockedVertices += "C"; }
    if (signD === signPoint) { unlockedVertices += "D"; }

    if (unlockedVertices.length === 0 || unlockedVertices.length === 4) return false;

    if (unlockedVertices.length === 2) {
      //CROP 4 VERTICES

      if (intersectionAB === undefined) {
        intersection1 = intersectionDA;
        intersection2 = intersectionBC;
      } else if (intersectionBC === undefined) {
        intersection1 = intersectionAB;
        intersection2 = intersectionCD;
      } else {
        if (intersectionAB.distanceTo(intersectionCD) > intersectionDA.distanceTo(intersectionBC)) {
          intersection1 = intersectionDA;
          intersection2 = intersectionBC;
        } else {
          intersection1 = intersectionAB;
          intersection2 = intersectionCD;
        }
      }

      let blockedVertex = "";
      if (signA !== signPoint && signA !== 0) { blockedVertex += "A"; }
      if (signB !== signPoint && signB !== 0) { blockedVertex += "B"; }
      if (signC !== signPoint && signC !== 0) { blockedVertex += "C"; }
      if (signD !== signPoint && signD !== 0) {
        blockedVertex += "D";
        if (blockedVertex === "AD") { blockedVertex = "DA" }
      }

      //update vertex
      const otherindex: number = this.setIntersectionFromVector(vertices[blockedVertex[0]], vertices[blockedVertex[1]], intersection1);
      vertices[blockedVertex[otherindex]].copy(intersection2);

      this.A.position.copy(vertices.A).add(Consts.planeNormal);
      this.B.position.copy(vertices.B).add(Consts.planeNormal);
      this.C.position.copy(vertices.C).add(Consts.planeNormal);
      this.D.position.copy(vertices.D).add(Consts.planeNormal);

      this.setCroppedAndBlackPlane(vertices, this._plane.rotation.z);
      this.add(this.D, this.line4);

      this.line3.v1 = this.C;
      this.line3.v2 = this.D;
      this.line4.v1 = this.D;
      this.line4.v2 = this.A;
    }
    else if (unlockedVertices.length == 1) {
      //CROP 3 VERTICES
      if (unlockedVertices == "A") {
        this.A.position.copy(vertices.A).add(Consts.planeNormal);
        this.B.position.copy(intersectionAB).add(Consts.planeNormal);
        this.C.position.copy(intersectionDA).add(Consts.planeNormal);
        intersection1 = intersectionAB;
        intersection2 = intersectionDA;
      }
      if (unlockedVertices == "B") {
        this.A.position.copy(intersectionAB).add(Consts.planeNormal);
        this.B.position.copy(vertices.B).add(Consts.planeNormal);
        this.C.position.copy(intersectionBC).add(Consts.planeNormal);
        intersection1 = intersectionAB;
        intersection2 = intersectionBC;
      }
      if (unlockedVertices == "C") {
        this.A.position.copy(intersectionCD).add(Consts.planeNormal);
        this.B.position.copy(intersectionBC).add(Consts.planeNormal);
        this.C.position.copy(vertices.C).add(Consts.planeNormal);
        intersection1 = intersectionCD;
        intersection2 = intersectionBC;
      }
      if (unlockedVertices == "D") {
        this.A.position.copy(intersectionDA).add(Consts.planeNormal);
        this.B.position.copy(intersectionCD).add(Consts.planeNormal);
        this.C.position.copy(vertices.D).add(Consts.planeNormal);
        intersection1 = intersectionDA;
        intersection2 = intersectionCD;
      }

      this.setCroppedAndBlackPlane({ A: this.A.position.clone().setZ(0), B: this.B.position.clone().setZ(0), C: this.C.position.clone().setZ(0) }, this._plane.rotation.z);

      this.line3.v1 = this.C;
      this.line3.v2 = this.A;
    }
    else if (unlockedVertices.length == 3) {
      //CROP 5 VERTICES
      if (unlockedVertices == "ABC") {
        this.A.position.copy(vertices.A).add(Consts.planeNormal);
        this.B.position.copy(vertices.B).add(Consts.planeNormal);
        this.C.position.copy(vertices.C).add(Consts.planeNormal);
        this.D.position.copy(intersectionCD).add(Consts.planeNormal);
        this.E.position.copy(intersectionDA).add(Consts.planeNormal);
        intersection1 = intersectionCD;
        intersection2 = intersectionDA;
      }
      if (unlockedVertices == "BCD") {
        this.A.position.copy(intersectionAB).add(Consts.planeNormal);
        this.B.position.copy(vertices.B).add(Consts.planeNormal);
        this.C.position.copy(vertices.C).add(Consts.planeNormal);
        this.D.position.copy(vertices.D).add(Consts.planeNormal);
        this.E.position.copy(intersectionDA).add(Consts.planeNormal);
        intersection1 = intersectionAB;
        intersection2 = intersectionDA;
      }
      if (unlockedVertices == "ACD") {
        this.A.position.copy(vertices.A).add(Consts.planeNormal);
        this.B.position.copy(intersectionAB).add(Consts.planeNormal);
        this.C.position.copy(intersectionBC).add(Consts.planeNormal);
        this.D.position.copy(vertices.C).add(Consts.planeNormal);
        this.E.position.copy(vertices.D).add(Consts.planeNormal);
        intersection1 = intersectionAB;
        intersection2 = intersectionBC;
      }
      if (unlockedVertices == "ABD") {
        this.A.position.copy(vertices.A).add(Consts.planeNormal);
        this.B.position.copy(vertices.B).add(Consts.planeNormal);
        this.C.position.copy(intersectionBC).add(Consts.planeNormal);
        this.D.position.copy(intersectionCD).add(Consts.planeNormal);
        this.E.position.copy(vertices.D).add(Consts.planeNormal);
        intersection1 = intersectionBC;
        intersection2 = intersectionCD;
      }

      this.setCroppedAndBlackPlane({
        A: this.A.position.clone().setZ(0), B: this.B.position.clone().setZ(0), C: this.C.position.clone().setZ(0),
        D: this.D.position.clone().setZ(0), E: this.E.position.clone().setZ(0)
      }, this._plane.rotation.z);
      this.add(this.D, this.E, this.line4, this.line5);

      this.line3.v1 = this.C;
      this.line3.v2 = this.D;
      this.line4.v1 = this.D;
      this.line4.v2 = this.E;
      this.line5.v1 = this.E;
      this.line5.v2 = this.A;
    }

    this.ost1.position.copy(intersection1).setZ(5);
    this.ost2.position.copy(intersection2).setZ(5);
    this._clonedOsteotomyManager.reset();

    return true;
  }

  protected setIntersectionFromVector(vertex1: Vector3, vertex2: Vector3, intersection: Vector3): number {
    if (vertex1.distanceTo(intersection) > vertex2.distanceTo(intersection)) {
      vertex2.copy(intersection);
      return 0;
    }
    vertex1.copy(intersection);
    return 1;
  }

  protected setCroppedAndBlackPlane(vertices: EOCPlaneVertices, angle: number): void {
    const geo = this.generateGeometry(vertices, angle);
    this.blackPlane.setGeometry(geo);
    this.blackPlane.scale.copy(this._plane.scale).multiplyScalar(100);
    this.blackPlane.scale.y *= this._plane.heightGeometry / this._plane.widthGeometry;
    this.blackPlane.scale.z = 1; //todo refactor
    this.blackPlane.rotation.z = angle;
    this.croppedPlane.setGeometry(geo);
    this.croppedPlane.scale.copy(this.blackPlane.scale);
    this.croppedPlane.rotation.z = angle;
    const { D } = this._plane.getNoClippedVertices(0);
    this.blackPlane.position.add(D);
    this.croppedPlane.position.add(D);
  }

  protected generateGeometry(vertices: EOCPlaneVertices, angle: number): ShapeBufferGeometry {
    const { A, C, D } = this._plane.getNoClippedVertices(0);

    A.applyAxisAngle(Consts.planeNormal, -angle);
    C.applyAxisAngle(Consts.planeNormal, -angle);
    D.applyAxisAngle(Consts.planeNormal, -angle);

    const sub = new Vector3(D.x, D.y, 0);
    const div = new Vector3(C.x - D.x, A.y - D.y, 1);
    vertices.A.applyAxisAngle(Consts.planeNormal, -angle).sub(sub).divide(div);
    vertices.B.applyAxisAngle(Consts.planeNormal, -angle).sub(sub).divide(div);
    vertices.C.applyAxisAngle(Consts.planeNormal, -angle).sub(sub).divide(div);
    vertices.D && vertices.D.applyAxisAngle(Consts.planeNormal, -angle).sub(sub).divide(div);
    vertices.E && vertices.E.applyAxisAngle(Consts.planeNormal, -angle).sub(sub).divide(div);

    const planeShape = new Shape().
      moveTo(vertices.A.x, vertices.A.y).
      lineTo(vertices.B.x, vertices.B.y).
      lineTo(vertices.C.x, vertices.C.y);
    vertices.D && planeShape.lineTo(vertices.D.x, vertices.D.y);
    vertices.E && planeShape.lineTo(vertices.E.x, vertices.E.y);
    return new ShapeBufferGeometry(planeShape);
  }

}
