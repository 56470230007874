import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared';
import { AaApTableComponent, AaLtTableComponent, AnatomicalAxisTableComponent, AxialTranslTableComponent, AxialViewTableComponent, DefBoneLengthTableComponent } from './anatomical-axis-table';
import { ApexTableComponent } from './apex-table';
import { BoneTypeSelectionComponent } from './bone-type-selection';
import { DeformityMenuComponent } from './deformity-menu';
import { LldTableComponent } from './lld-table';
import { MaApTableComponent, MaLtTableComponent } from './ma-table';
import { MechanicalAxisComponent } from './mechanical-axis';
import { PostopOsteotomyWorkflowComponent } from './postop-osteotomy-workflow';
import { ReferencePointWorkflowComponent } from './reference-point-workflow';

@NgModule({
	declarations: [
		ApexTableComponent,
		MaApTableComponent,
		MaLtTableComponent,
		LldTableComponent,
		MechanicalAxisComponent,
		BoneTypeSelectionComponent,
		AnatomicalAxisTableComponent,
		AaApTableComponent,
		AaLtTableComponent,
		AxialViewTableComponent,
		AxialTranslTableComponent,
		ReferencePointWorkflowComponent,
		DeformityMenuComponent,
		PostopOsteotomyWorkflowComponent,
		DefBoneLengthTableComponent
	],
	exports: [
		ApexTableComponent,
		MaApTableComponent,
		MaLtTableComponent,
		LldTableComponent,
		MechanicalAxisComponent,
		BoneTypeSelectionComponent,
		AnatomicalAxisTableComponent,
		ReferencePointWorkflowComponent,
		DeformityMenuComponent,
		PostopOsteotomyWorkflowComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgbCollapseModule,
		SharedModule
	]
})
export class DeformityModule { }
