<prt-template [isDemo]="isDemo" [today]="today" [plan]="currentCase">
	<div class="row">
		<div class="col-6">
			<div class="wrapper-print-tab">
				<h4>{{labels.PROXIMAL_FEMUR_REF_MEASURE_EXP}}</h4>
			</div>
			<img width="600" height="355" [src]="cdnBaseUrl + '/imgorplan/proximalFemur.png'" alt="proximal femur">
			<div class="wrapper-print-tab">
				<h4>{{labels.DISTAL_FEMUR_REF_MEASURE_EXP}}</h4>
			</div>
			<img width="600" height="355" [src]="cdnBaseUrl + '/imgorplan/distalFemur.png'" alt="distal femur">
		</div>
		<div class="col-6">
			<div class="wrapper-print-tab">
				<h4>{{labels.PROXIMAL_TIBIA_REF_MEASURE_EXP}}</h4>
			</div>
			<img width="600" height="355" [src]="cdnBaseUrl + '/imgorplan/proximalTibia.png'" alt="proximal tibia">
			<div class="wrapper-print-tab">
				<h4>{{labels.DISTAL_TIBIA_REF_MEASURE_EXP}}</h4>
			</div>
			<img width="600" height="355" [src]="cdnBaseUrl + '/imgorplan/distalTibia.png'" alt="distal tibia">
		</div>
	</div>
</prt-template>