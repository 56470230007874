import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IIntegrationRepository } from './Iintegration.repository';
import { ApiResponse, RayModelTlHexDeformity } from './models';



@Injectable()
export class IntegrationRepository implements IIntegrationRepository {

	constructor(private httpClient: HttpClient) { }

	saveRayModelAndTlHexDeformity(model: RayModelTlHexDeformity): Observable<ApiResponse<void>> {
		return this.httpClient.post<ApiResponse<void>>(environment.domainIntegrationAPI + `/api/Integration/SaveRayModelAndTlHexDeformity`, model);
	}
}