import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AxialRotationEnum } from '@ortho-next/nextray-core';
import { Consts } from '../../../../nextray/Utils/Consts';
import { LanguageService, ReferenceTypeEnum } from '../../../core';
import { CanvasService } from '../../../services';
import { BaseComponent } from '../../../shared';

/**
 * Table for axial view measures in correction anatomical analysis.
 */
@Component({
	selector: 'corr-axial-view-table',
	templateUrl: './corr-axial-view-table.component.html'
})
export class CorrAxialViewTableComponent extends BaseComponent implements OnInit {

	isCollapsed: boolean = false;

	axialRotForm: FormGroup;
	axialRotEnum = AxialRotationEnum;
	externalLabel: string;
	internalLabel: string;
	invalidRotMsg: string;

	axRotationExternalValue: AxialRotationEnum.external | AxialRotationEnum.internal;
	axRotationInternalValue: AxialRotationEnum.external | AxialRotationEnum.internal;

	private readonly twoDigitsPattern: RegExp = /^\d$|^[1-9]\d?$/;
	private readonly MIN_ROT = 0;
	private readonly MAX_ROT = 90;

	constructor(
		private langSrv: LanguageService,
		private canvasSrv: CanvasService
	) {
		super(langSrv, canvasSrv);
	}

	ngOnInit() {
		this.initAxialRotForm();
	}

	// AXIAL ROTATION
	private initAxialRotForm(): void {
		if (this.canvasSrv.currentCase.referenceType == ReferenceTypeEnum.Distal && (this.isForeFoot || this.isHindFoot)) {
			this.axRotationExternalValue = AxialRotationEnum.internal;
			this.axRotationInternalValue = AxialRotationEnum.external;
		}
		else {
			this.axRotationExternalValue = AxialRotationEnum.external;
			this.axRotationInternalValue = AxialRotationEnum.internal;
		}
		const validators: ValidatorFn[] = [Validators.pattern(this.twoDigitsPattern), Validators.min(this.MIN_ROT), Validators.max(this.MAX_ROT)];
		this.axialRotForm = new FormGroup({
			'axialRotValue': new FormControl(this.eocAnatAxialRot, validators),
			'axialRotSign': new FormControl(this.eocAnatAxialRotSign)
		});
		this.initRotSignLabels();
		this.initInvalidRotMsg();
	}

	private initInvalidRotMsg(): void {
		const label: string = this.labels['CORR_AXIAL_VIEW_TABLE_COMPONENT_ROTATION_VALIDATION'];
		this.invalidRotMsg = label?.replace("%1", this.MIN_ROT.toString())?.replace("%2", this.MAX_ROT.toString());
	}

	private initRotSignLabels(): void {
		const boneType = this.canvasSrv.currentCase.boneType;
		const signLabels = Consts.signLabels[boneType]?.axial?.rotation ?? [];
		this.externalLabel = this.anatSignLabel(signLabels[0]) ?? this.labels['CORR_AXIAL_VIEW_TABLE_COMPONENT_ROTATION_EXTERNAL'];
		this.internalLabel = this.anatSignLabel(signLabels[1]) ?? this.labels['CORR_AXIAL_VIEW_TABLE_COMPONENT_ROTATION_INTERNAL'];
	}

	/**
	* Update axial rotation sign.
	*/
	updateAxialRotSign(sign: AxialRotationEnum): void {
		if (this.stateType === this.stateTypeEnum.EOC) {
			this.canvasSrv.dispatch('setEocAnatAxialRotSign', sign);
		}
	}

	/**
	* Update axial rotation value.
	*/
	updateAxialRotValue(): void {
		if (this.stateType === this.stateTypeEnum.EOC) {
			if (this.axialRotForm.controls.axialRotValue.valid) {
				const value = this.axialRotForm.value.axialRotValue ? parseInt(this.axialRotForm.value.axialRotValue) : 0;
				this.canvasSrv.dispatch('setEocAnatAxialRot', value);
			}
			this.axialRotForm.controls.axialRotValue.setValue(this.eocAnatAxialRot);
		}
	}

}
